import * as React from 'react';
import type { IANATimezones, MinifiedTask } from '@gcv/shared';

import { ReactComponent as SpeechBubbles } from 'assets/images/empty-states/speech-bubbles.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { Container, ContainerItem, Header, Text } from 'ui/atoms';
import { theme } from 'ui/theme';
import { CommentsPresenter } from '../comments.presenter';
import { CommentContentContainer } from './comment-content-container';

interface Props extends Record<string, unknown> {
  bankId: string;
  iana_timezone: IANATimezones;
  orgId: string;
}

export const CommentsTab: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(CommentsPresenter);

  const [tasks, setTasks] = React.useState<MinifiedTask[]>([]);

  const unarchivedComments =
    presenter.viewModel.post?.comments.filter((comment) => !comment.archived_by) || [];

  return (
    <Container justify="center" width="100%" align="flex-start" padding="0px 0px 167px 0px">
      {!unarchivedComments.length ? (
        <ContainerItem padding="0 30px 45px 30px" justify="center">
          <SpeechBubbles style={{ width: '110px', marginBottom: '.5rem' }} />
          <Header content="No comments" type="h3" />
          <Text content="Post a comment to start a discussion." type="body2" sx={{ paddingTop: '8px' }} />
          <Text content="@Mention" type="body2" sx={{ color: theme.palette.primary.main }} inline />
          <Text content=" someone to notify them." type="body2" inline />
        </ContainerItem>
      ) : (
        unarchivedComments.map((comment) => (
          <CommentContentContainer
            key={comment.id}
            comment={comment}
            iana_timezone={props.iana_timezone}
            orgId={props.orgId}
            bankId={props.bankId}
          />
        ))
      )}
    </Container>
  );
});
