import type { Bank, Dispensary, DueDiligenceStatus, User, UserInterests } from '@gcv/shared';

// https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-the-Pendo-Snippet
// https://developers.pendo.io/docs/?bash#initialization-and-identification

export const initializePendo = (user: User) => {
  if (!process.env.REACT_APP_CYPRESS_TESTING) {
    // Pendo does not have an SDK or typscript typings
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    pendo.initialize({
      visitor: {
        id: user.id, // Required if user is logged in
        email: user.username?.toLowerCase(), // Recommended if using Pendo Feedback, or NPS Email
        full_name: `${user.firstName} ${user.lastName}`, // Recommended if using Pendo Feedback
        first_name: user.firstName,
        role: user.role // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  }
};

export const updatePendoForCrb = (
  dispensary: Dispensary,
  user: User,
  invitedBy: string,
  bankId: string,
  status: DueDiligenceStatus,
  numberOfActiveServiceProviders: number,
  serviceProviders: { id: string; status: DueDiligenceStatus; numberOfDaysSinceConnected: number }[],
  data?: Record<string, unknown>
) => {
  const userInterests = data?.userInterests as UserInterests[];
  if (!process.env.REACT_APP_CYPRESS_TESTING) {
    const pendoObject = {
      visitor: {
        id: user.id, // Required if user is logged in
        email: user.username?.toLowerCase(), // Recommended if using Pendo Feedback, or NPS Email
        full_name: `${user.firstName} ${user.lastName}`, // Recommended if using Pendo Feedback
        first_name: user.firstName,
        // role:         // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
        is_account_owner:
          dispensary.groups
            ?.find((g) => g.type === 'account_owner')
            ?.users?.some((userId) => userId === user.id) ?? false,
        interests: userInterests?.length ? userInterests : []
      },
      account: {
        id: dispensary.id, // Required if using Pendo Feedback
        name: dispensary.name, // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        creationDate: dispensary.date_created, // Optional
        invited_by: invitedBy,
        self_invited: dispensary.created_by_org === dispensary.id,
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
        type: 'Dispensary',
        state: dispensary.state,
        businessType: dispensary.business_type,
        posConfigName: dispensary.posConfig?.posName,
        posName: dispensary.pos_name,
        posNameOther: dispensary.pos_name_other,
        bankId: bankId,
        status: status,
        numberOfActiveServiceProviders,
        ...getServiceProviderFields(serviceProviders),
        ...data
      }
    };
    // Pendo does not have an SDK or typscript typings
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    pendo.identify(pendoObject);
  }
};

const getServiceProviderFields = (
  serviceProviders: { id: string; status: DueDiligenceStatus; numberOfDaysSinceConnected: number }[]
) => {
  const DEFAULT_NUM_SERVICE_PROVIDERS = 5;
  const results: Record<string, any> = {};
  for (let i = 0; i < DEFAULT_NUM_SERVICE_PROVIDERS; i++) {
    const idKey = `serviceProvider${i + 1}Id`;
    const statusKey = `serviceProvider${i + 1}Status`;
    const numDaysKey = `serviceProvider${i + 1}DaysSinceConnected`;

    results[idKey] = serviceProviders[i]?.id;
    results[statusKey] = serviceProviders[i]?.status;
    results[numDaysKey] = serviceProviders[i]?.numberOfDaysSinceConnected;
  }
  return results;
};

export const updatePendoAccountForFi = (bank: Bank, user: User) => {
  if (!process.env.REACT_APP_CYPRESS_TESTING) {
    // Pendo does not have an SDK or typscript typings
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    pendo.identify({
      visitor: {
        id: user.id, // Required if user is logged in
        email: user.username?.toLowerCase(), // Recommended if using Pendo Feedback, or NPS Email
        full_name: `${user.firstName} ${user.lastName}`, // Recommended if using Pendo Feedback
        first_name: user.firstName,
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
        is_account_owner:
          bank.groups.find((g) => g.type === 'account_owner')?.users.some((userId) => userId === user.id) ??
          false
      },

      account: {
        id: bank.id, // Required if using Pendo Feedback
        name: bank.orgName, // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        creationDate: bank.date_created, // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
        type: 'Bank',
        state: bank.state
      }
    });
  }
};

export const track = (name: string, data?: unknown) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  pendo.track(name, data);
};
