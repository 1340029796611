import type {
  AccountCommentIdComponents,
  AccountReviewCommentIdComponents,
  CommentCCNotificationData,
  CommentIdComponents,
  CommentNotificationData,
  CommentTaskAssignedNotificationData,
  CrbDisconnectedNotificationData,
  CrbmLicenseStatusUpdateNotificationData,
  CrbTaskAssignedNotificationData,
  CrbUnarchivedNotificationData,
  DepositCommentIdComponents,
  FiEditInfoNotificationData,
  FincenReportCommentIdComponents,
  FiQuestionnaireCompleteNotificationData,
  MaxDepositUpdatedNotificationData,
  NewDepositNotificationData,
  NewDispensaryToReviewNotificationData,
  NewDocumentUploadedForCrbNotificationData,
  NewDocumentUploadedNotificationData,
  NewMarketplaceApplicationNotificationData,
  NewPayqwickAdminUserNotificationData,
  Notification,
  OnboardWithTraceBannerNotificationData,
  OnboardWithTraceDrawerNotificationData,
  PosAuthFailureNotificationData,
  ProfileUpdatedCrbNotificationData,
  ProfileUpdatedUserNotificationData,
  QuestionnaireCommentIdComponents,
  RecurringQuestionnaireNotificationData,
  RequestDocumentUploadNotificationData,
  RequirementCommentIdComponents,
  RequirementDocumentCommentIdComponents,
  ServiceProviderSalesJobNotificationData,
  SftpMissedScheduleNotificationData,
  UpcomingOrOverdueTaskReminderNotificationData,
  UserAddedNotificationData,
  UserTaskAssignedNotificationData
} from '@gcv/shared';
import {
  CommentType,
  DueDiligenceStatus,
  FincenReportType,
  NotificationTypes,
  OnboardWithTraceNotificationDataOnboardingStatus,
  TaskReminderType
} from '@gcv/shared';
import { noop } from 'lodash';

import { DispensariesApi, FinCENApi, NotificationsApi } from 'api';
import { getCommentStore } from 'stores/CommentStore';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { getFiBankStore } from 'stores/FiBankStore';
import { getFiDispensaryStore } from 'stores/FiDispensaryStore';
import { getSnackbarStore, SnackbarSeverity } from 'stores/SnackBarStore';
import { DateTimeHelpers } from './dateTime.util';
import { createDateExpectedMessage } from './lib/notifications-lib';

const getUserRole = (role: string) => {
  switch (role) {
    case 'dispensary_admin':
      return 'an Admin';
    case 'dispensary_account_owner':
      return 'an Account Owner';
    case 'dispensary_user':
      return 'a User';
    default:
      return '';
  }
};

export const renderNotificationIcon = (type: string) => {
  switch (type) {
    case NotificationTypes.ACTIVITY_REPORTING:
    case NotificationTypes.SFTP_UPLOAD_REPORT:
      return <img src="/img/notification-icons/icon-notification-reports.svg" height={30} width={30} />;
    case NotificationTypes.NEW_AUTO_GENENRATED_REVIEW_AVAILABLE:
    case NotificationTypes.BATCH_NEW_AUTO_GENENRATED_REVIEW_AVAILABLE:
      return <img src="/img/notification-icons/icon-notification-reviews.svg" height={30} width={30} />;
    case NotificationTypes.MARKETPLACE_NEW_APPLICATION:
      return <img src="/img/notification-icons/icon-notification-account.svg" height={30} width={30} />;
    case NotificationTypes.SFTP_NO_DATA:
    case NotificationTypes.SFTP_PARSING_FAILURE:
    case NotificationTypes.SFTP_MISSED_SCHEDULE:
      return <img src="/img/notification-icons/icon-notification-coredata.svg" height={30} width={30} />;
    case NotificationTypes.BANKING_PARTNER_IDENTIFIED:
    case NotificationTypes.COMPLETED_DISPENSARY_REVIEW:
    case NotificationTypes.NEW_DISPENSARY_TO_REVIEW:
    case NotificationTypes.NEW_COMMENT_ADDED_TO_REQUIREMENT:
    case NotificationTypes.NEW_DOCUMENT_UPLOADED:
    case NotificationTypes.CRBM_LICENSE_STATUS_UPDATE:
    case NotificationTypes.NEW_PAYQWICK_ADMIN_USER:
      return <img src="/img/notification-icons/icon-notification-account.svg" height={30} width={30} />;
    case NotificationTypes.COMMENT_REPLY:
    case NotificationTypes.REQUEST_DOCUMENT_UPLOAD:
      return <img src="/img/notification-icons/icon-notification-documents.svg" height={30} width={30} />;
    case NotificationTypes.NEW_DOCUMENT_UPLOADED_FOR_CRB:
      return (
        <img
          src="/img/notification-icons/icon-notification-document-uploaded-for-crb.svg"
          height={30}
          width={30}
        />
      );
    case NotificationTypes.COMPLETE_EVS:
    case NotificationTypes.EXPIRED_BUSINESS_LICENSE:
    case NotificationTypes.EXPIRED_EMPLOYEE_LICENSE:
    case NotificationTypes.EXPIRING_BUSINESS_LICENSE:
    case NotificationTypes.EXPIRING_EMPLOYEE_LICENSE:
      return <img src="/img/notification-icons/icon-notification-license.svg" height={30} width={30} />;
    case NotificationTypes.MAX_DEPOSIT_UPDATED:
    case NotificationTypes.NEW_DEPOSIT:
      return <img src="/img/notification-icons/icon-notification-deposit.svg" height={30} width={30} />;
    case NotificationTypes.NEW_FINCEN_REPORT:
    case NotificationTypes.SUBMITTED_FINCEN_REPORT:
    case NotificationTypes.ASSIGN_FINCEN_REPORT:
      return <img src="/img/notification-icons/icon-notification-fincen.svg" height={30} width={30} />;
    case NotificationTypes.SALES_INGESTION_FAILURE:
    case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_NON_RETAIL:
    case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_RETAIL:
      return <img src="/img/notification-icons/icon-notification-sales.svg" height={30} width={30} />;
    case NotificationTypes.COMMENT_REPLY:
    case NotificationTypes.COMMENT_MENTION:
    case NotificationTypes.COMMENT_CC:
      return <img src="/img/notification-icons/icon-notification-comment.svg" height={30} width={30} />;
    case NotificationTypes.ONE_TIME_QUESTIONNAIRE:
    case NotificationTypes.RECURRING_QUESTIONNAIRE:
    case NotificationTypes.FI_QUESTIONNAIRE_COMPLETE:
    case NotificationTypes.BENEFICIAL_OWNERSHIP_ONE_TIME_QUESTIONNAIRE:
      return <img src="/img/notification-icons/icon-notification-questionnaire.svg" height={30} width={30} />;
    case NotificationTypes.POS_AUTH_FAILURE:
      return (
        <img
          src="/img/notification-icons/icon-notification-sales-ingestion-alert.svg"
          height={30}
          width={30}
        />
      );
    case NotificationTypes.COMMENT_TASK_ASSIGNED:
    case NotificationTypes.USER_TASK_ASSIGNED:
    case NotificationTypes.CRB_TASK_ASSIGNED:
    case NotificationTypes.UPCOMING_OR_OVERDUE_TASK_REMINDER:
      return <img src="/img/notification-icons/icon-notification-task.svg" height={30} width={30} />;
    case NotificationTypes.USER_ADDED:
    case NotificationTypes.PROFILE_UPDATED_CRB:
    case NotificationTypes.PROFILE_UPDATED_USER:
      return <img src="/img/notification-icons/icon-notification-account.svg" height={30} width={30} />;
    case NotificationTypes.DISCONNECT_CRB:
      return <img src="/img/notification-icons/icon-notification-account.svg" height={30} width={30} />;
    case NotificationTypes.UNARCHIVE_CRB:
      return <img src="/img/notification-icons/icon-notification-account.svg" height={30} width={30} />;
    case NotificationTypes.FI_EDIT_ADDITIONAL_INFO:
    case NotificationTypes.FI_EDIT_BUSINESS_DETAILS:
    case NotificationTypes.FI_EDIT_OPERTATION_DETAILS:
    case NotificationTypes.CRB_EDIT_ADDITIONAL_INFO:
    case NotificationTypes.CRB_EDIT_BUSINESS_DETAILS:
    case NotificationTypes.CRB_EDIT_OPERTATION_DETAILS:
    case NotificationTypes.OTHER_SP_EDIT_BUSINESS_DETAILS:
    case NotificationTypes.OTHER_SP_EDIT_OPERTATION_DETAILS:
      return <img src="/img/notification-icons/icon-notification-account.svg" height={30} width={30} />;
    case NotificationTypes.ONBOARD_WITH_TRACE_DRAWER:
      return (
        <img
          src="/img/notification-icons/icon-notification-async-credential-test.svg"
          height={30}
          width={30}
        />
      );
    default:
      break;
  }
};

export const renderNotificationTitle = (notification: Notification) => {
  const type = notification.type;

  switch (type) {
    case NotificationTypes.NEW_DOCUMENT_UPLOADED_FOR_CRB:
      return 'New Document Uploaded';
    case NotificationTypes.ACTIVITY_REPORTING:
    case NotificationTypes.SFTP_UPLOAD_REPORT:
      return 'Reports';
    case NotificationTypes.NEW_AUTO_GENENRATED_REVIEW_AVAILABLE:
    case NotificationTypes.BATCH_NEW_AUTO_GENENRATED_REVIEW_AVAILABLE:
      return 'Monitoring';
    case NotificationTypes.MARKETPLACE_NEW_APPLICATION:
      return 'New Application Started';
    case NotificationTypes.SFTP_NO_DATA:
    case NotificationTypes.SFTP_PARSING_FAILURE:
    case NotificationTypes.SFTP_MISSED_SCHEDULE:
      return 'Core Data';
    case NotificationTypes.BANKING_PARTNER_IDENTIFIED:
    case NotificationTypes.COMPLETED_DISPENSARY_REVIEW:
    case NotificationTypes.NEW_DISPENSARY_TO_REVIEW:
    case NotificationTypes.NEW_COMMENT_ADDED_TO_REQUIREMENT:
    case NotificationTypes.NEW_DOCUMENT_UPLOADED:
    case NotificationTypes.CRBM_LICENSE_STATUS_UPDATE:
    case NotificationTypes.USER_ADDED:
    case NotificationTypes.PROFILE_UPDATED_CRB:
    case NotificationTypes.PROFILE_UPDATED_USER:
      return 'Account Update';
    case NotificationTypes.REQUEST_DOCUMENT_UPLOAD:
      return 'Documents';
    case NotificationTypes.COMPLETE_EVS:
    case NotificationTypes.EXPIRED_BUSINESS_LICENSE:
    case NotificationTypes.EXPIRED_EMPLOYEE_LICENSE:
    case NotificationTypes.EXPIRING_BUSINESS_LICENSE:
    case NotificationTypes.EXPIRING_EMPLOYEE_LICENSE:
      return 'License Alert';
    case NotificationTypes.MAX_DEPOSIT_UPDATED:
    case NotificationTypes.NEW_DEPOSIT:
      return 'Deposits';
    case NotificationTypes.NEW_FINCEN_REPORT:
    case NotificationTypes.SUBMITTED_FINCEN_REPORT:
    case NotificationTypes.ASSIGN_FINCEN_REPORT:
      return 'FinCEN';
    case NotificationTypes.SALES_INGESTION_FAILURE:
    case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_NON_RETAIL:
    case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_RETAIL:
      return 'Sales Update';
    case NotificationTypes.COMMENT_REPLY:
      return 'Comment Reply';
    case NotificationTypes.COMMENT_MENTION:
      return 'Comment Mention';
    case NotificationTypes.COMMENT_CC:
      return 'Heads Up: New Comment Alert';
    case NotificationTypes.ONE_TIME_QUESTIONNAIRE:
    case NotificationTypes.RECURRING_QUESTIONNAIRE:
    case NotificationTypes.BENEFICIAL_OWNERSHIP_ONE_TIME_QUESTIONNAIRE:
      return 'New Questionnaire';
    case NotificationTypes.FI_QUESTIONNAIRE_COMPLETE:
      return 'Questionnaire';
    case NotificationTypes.COMMENT_TASK_ASSIGNED:
    case NotificationTypes.USER_TASK_ASSIGNED:
    case NotificationTypes.CRB_TASK_ASSIGNED:
      return 'New Task Assigned';
    case NotificationTypes.UPCOMING_OR_OVERDUE_TASK_REMINDER:
      if (
        (notification.data as UpcomingOrOverdueTaskReminderNotificationData).reminder_type ===
        TaskReminderType.Overdue
      ) {
        return 'Task Overdue';
      } else {
        return 'Task Due Soon';
      }
    case NotificationTypes.POS_AUTH_FAILURE:
      return 'Sales Ingestion Alert';
    case NotificationTypes.NEW_PAYQWICK_ADMIN_USER:
      return 'Accounts';
    case NotificationTypes.DISCONNECT_CRB:
      return 'Account Archived';
    case NotificationTypes.UNARCHIVE_CRB:
      return 'Account Unarchived';
    case NotificationTypes.FI_EDIT_ADDITIONAL_INFO:
    case NotificationTypes.FI_EDIT_BUSINESS_DETAILS:
    case NotificationTypes.FI_EDIT_OPERTATION_DETAILS:
    case NotificationTypes.CRB_EDIT_ADDITIONAL_INFO:
    case NotificationTypes.CRB_EDIT_BUSINESS_DETAILS:
    case NotificationTypes.CRB_EDIT_OPERTATION_DETAILS:
    case NotificationTypes.OTHER_SP_EDIT_BUSINESS_DETAILS:
    case NotificationTypes.OTHER_SP_EDIT_OPERTATION_DETAILS:
      return 'Account Update';
    case NotificationTypes.ONBOARD_WITH_TRACE_DRAWER:
      return 'Integration Status';
    default:
      break;
  }
};

export const renderNotificationDescription = (notification: Notification) => {
  switch (notification.type) {
    case NotificationTypes.NEW_DOCUMENT_UPLOADED_FOR_CRB:
      return `${
        (notification.data as NewDocumentUploadedForCrbNotificationData).fi_name
      } uploaded a new document.`;
    case NotificationTypes.ACTIVITY_REPORTING:
      return 'A report is available to download.';
    case NotificationTypes.NEW_AUTO_GENENRATED_REVIEW_AVAILABLE:
      return 'A new monitoring review has been generated.';
    case NotificationTypes.BATCH_NEW_AUTO_GENENRATED_REVIEW_AVAILABLE:
      return 'New monitoring review(s) have been generated.';
    case NotificationTypes.MARKETPLACE_NEW_APPLICATION:
      return `A new account, ${
        (notification.data as NewMarketplaceApplicationNotificationData).crb_org_name
      }, has started the application process.`;
    case NotificationTypes.SFTP_UPLOAD_REPORT:
      return 'An sftp report upload had an issue';
    case NotificationTypes.SFTP_NO_DATA:
      return 'An empty file has been received.';
    case NotificationTypes.SFTP_PARSING_FAILURE:
      return 'Your file was received but could not be processed.';
    case NotificationTypes.SFTP_MISSED_SCHEDULE:
      return createDateExpectedMessage(notification.data as SftpMissedScheduleNotificationData);
    case NotificationTypes.BANKING_PARTNER_IDENTIFIED:
      return 'Your banking partner has been identified.';
    case NotificationTypes.COMPLETED_DISPENSARY_REVIEW:
      return 'Your account has been approved.';
    case NotificationTypes.NEW_DISPENSARY_TO_REVIEW:
      return `${
        (notification.data as NewDispensaryToReviewNotificationData).dispensary_name
      } finished onboarding and is ready for review.`;
    case NotificationTypes.COMPLETE_EVS:
      return 'You are being asked to verify your identity.';
    case NotificationTypes.REQUEST_DOCUMENT_UPLOAD:
      const comment = (notification.data as RequestDocumentUploadNotificationData).comment;
      return comment ? `A document has been requested: ${comment}` : 'A document has been requested';
    case NotificationTypes.NEW_DOCUMENT_UPLOADED:
      return `${
        (notification.data as NewDocumentUploadedNotificationData).dispensary_name
      } uploaded a document to fulfill a Due Diligence requirement.`;
    case NotificationTypes.EXPIRED_BUSINESS_LICENSE:
      return 'You have an expired business license.';
    case NotificationTypes.EXPIRED_EMPLOYEE_LICENSE:
      return 'You have an expired employee license.';
    case NotificationTypes.EXPIRING_BUSINESS_LICENSE:
      return 'Your business license is expiring.';
    case NotificationTypes.EXPIRING_EMPLOYEE_LICENSE:
      return 'An employee license is expiring.';
    case NotificationTypes.MAX_DEPOSIT_UPDATED:
      return `${
        (notification.data as MaxDepositUpdatedNotificationData).bankName || 'Your bank'
      } has updated your max deposit.`;
    case NotificationTypes.NEW_DEPOSIT:
      return `${
        (notification.data as NewDepositNotificationData).dispensary_name
      } created a deposit that is ready for review.`;
    case NotificationTypes.NEW_FINCEN_REPORT:
      return 'A new FinCEN report has been generated.';
    case NotificationTypes.SUBMITTED_FINCEN_REPORT:
      return 'SUBMITTED_FINCEN_REPORT';
    case NotificationTypes.ASSIGN_FINCEN_REPORT:
      return 'ASSIGN_FINCEN_REPORT';
    case NotificationTypes.SALES_INGESTION_FAILURE:
      return 'Your sales ingestion has failed.';
    case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_NON_RETAIL:
      return 'Your sales ingestion was a partial success.';
    case NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_RETAIL:
      return 'Your sales ingestion was a partial success.';
    case NotificationTypes.COMMENT_REPLY:
      return `${
        (notification.data as CommentNotificationData).poster
      } replied to your comment on ${getCommentType(
        (notification.data as CommentNotificationData).commentType
      )}`;
    case NotificationTypes.COMMENT_MENTION:
      return `${
        (notification.data as CommentNotificationData).poster
      } mentioned you in a comment on ${getCommentType(
        (notification.data as CommentNotificationData).commentType
      )}`;
    case NotificationTypes.COMMENT_CC:
      return `A comment has been made on ${(notification.data as CommentCCNotificationData).account_name}.`;
    case NotificationTypes.ONE_TIME_QUESTIONNAIRE:
    case NotificationTypes.RECURRING_QUESTIONNAIRE:
    case NotificationTypes.BENEFICIAL_OWNERSHIP_ONE_TIME_QUESTIONNAIRE:
      const data = notification.data as RecurringQuestionnaireNotificationData;
      return `A new questionnaire, ${data.questionnaire_name}, has been assigned and is due on ${data.due_date}.`;
    case NotificationTypes.CRBM_LICENSE_STATUS_UPDATE:
      return `The status of the Business License for ${
        (notification.data as CrbmLicenseStatusUpdateNotificationData).dispensaryName
      } has changed.`;
    case NotificationTypes.FI_QUESTIONNAIRE_COMPLETE:
      return `A questionnaire has been completed by ${
        (notification.data as FiQuestionnaireCompleteNotificationData).crb_name
      }`;
    case NotificationTypes.USER_TASK_ASSIGNED:
      const note = notification.data as UserTaskAssignedNotificationData;
      return `${note.user?.firstName} ${note.user?.lastName} assigned you a new task that is due on ${note.due_date}.`;
    case NotificationTypes.CRB_TASK_ASSIGNED:
      const notif = notification.data as CrbTaskAssignedNotificationData;
      return `${notif.bank_name} assigned you a new task that is due on ${notif.due_date}.`;
    case NotificationTypes.POS_AUTH_FAILURE:
      return `We are unable to connect to ${
        (notification.data as PosAuthFailureNotificationData).pos_type
      } with the given credentials. Please check them and try to reconnect.`;
    case NotificationTypes.COMMENT_TASK_ASSIGNED:
      const commentTask = notification.data as CommentTaskAssignedNotificationData;
      return `You were mentioned in a comment and assigned a new task that is due on ${commentTask.due_date}.`;
    case NotificationTypes.NEW_PAYQWICK_ADMIN_USER:
      const pqAdminData = notification.data as NewPayqwickAdminUserNotificationData;
      return `${pqAdminData.crbName} has added a new account owner.`;
    case NotificationTypes.USER_ADDED:
      const userAddedData = notification.data as UserAddedNotificationData;
      return `${userAddedData.fi_name} has invited ${userAddedData.new_user_full_name} as ${getUserRole(
        userAddedData.new_user_role
      )} on your Green Check account.`;
    case NotificationTypes.PROFILE_UPDATED_CRB:
      const userData = notification.data as ProfileUpdatedCrbNotificationData;
      return `${userData.fi_name} has edited ${userData.updated_user_full_name}’s user profile in your Green Check account. `;
    case NotificationTypes.PROFILE_UPDATED_USER:
      const editedUser = notification.data as ProfileUpdatedUserNotificationData;
      return `${editedUser.fi_name} has edited your user profile in your Green Check account.`;
    case NotificationTypes.DISCONNECT_CRB:
      const archiveData = notification.data as CrbDisconnectedNotificationData;
      return `The ${archiveData.org_name} account has been archived and disconnected from ${archiveData.service_provider_name}.`;
    case NotificationTypes.UNARCHIVE_CRB:
      const unarchiveData = notification.data as CrbUnarchivedNotificationData;
      return `The ${unarchiveData.crb_name} account has been unarchived by ${unarchiveData.fi_name}`;
    case NotificationTypes.FI_EDIT_ADDITIONAL_INFO:
      const fiEditAdditionalInfoData = notification.data as FiEditInfoNotificationData;
      return `${fiEditAdditionalInfoData.fi_name} has made changes to your Additional Info.`;
    case NotificationTypes.FI_EDIT_BUSINESS_DETAILS:
      const fiEditBusinessDetailsData = notification.data as FiEditInfoNotificationData;
      return `${fiEditBusinessDetailsData.fi_name} has made changes to your company profile. (General)`;
    case NotificationTypes.FI_EDIT_OPERTATION_DETAILS:
      const fiEditOperationDetailsData = notification.data as FiEditInfoNotificationData;
      return `${fiEditOperationDetailsData.fi_name} has made changes to your company profile. (Operational)`;
    case NotificationTypes.CRB_EDIT_ADDITIONAL_INFO:
      const crbEditAdditionalInfoData = notification.data as FiEditInfoNotificationData;
      return `Changes have been made to ${crbEditAdditionalInfoData.crb_name} Additional Info.`;
    case NotificationTypes.CRB_EDIT_BUSINESS_DETAILS:
    case NotificationTypes.OTHER_SP_EDIT_BUSINESS_DETAILS:
      const crbEditBusinessDetailsData = notification.data as FiEditInfoNotificationData;
      return `Changes have been made to ${crbEditBusinessDetailsData.crb_name} company profile (General)`;
    case NotificationTypes.OTHER_SP_EDIT_OPERTATION_DETAILS:
    case NotificationTypes.CRB_EDIT_OPERTATION_DETAILS:
      const crbEditOperationDetailsData = notification.data as FiEditInfoNotificationData;
      return `Changes have been made to ${crbEditOperationDetailsData.crb_name} company profile (Operational)`;
    case NotificationTypes.ONBOARD_WITH_TRACE_DRAWER:
      const onboardWithTraceNotificationData = notification.data as OnboardWithTraceDrawerNotificationData;
      switch (onboardWithTraceNotificationData.onboardingStatus) {
        case OnboardWithTraceNotificationDataOnboardingStatus.SUCCESSFUL:
          return `We have successfully connected your Metrc account with the credentials provided`;
        case OnboardWithTraceNotificationDataOnboardingStatus.FAILED:
        case OnboardWithTraceNotificationDataOnboardingStatus.ERROR:
        default:
          return `We were unable to connect your Metrc account with the credentials provided. Please check your credentials and try again. If the issue persists, please contact our support team at support@greencheckverified.com`;
      }
    case NotificationTypes.UPCOMING_OR_OVERDUE_TASK_REMINDER:
      const upcomingOrOverdueTaskReminderData =
        notification.data as UpcomingOrOverdueTaskReminderNotificationData;
      if (upcomingOrOverdueTaskReminderData.reminder_type === TaskReminderType.Overdue) {
        return `You were assigned a task ${upcomingOrOverdueTaskReminderData.task_title} that was due on ${upcomingOrOverdueTaskReminderData.due_date}.`;
      } else {
        return `You were assigned a task ${upcomingOrOverdueTaskReminderData.task_title} that is due on ${upcomingOrOverdueTaskReminderData.due_date}.`;
      }
    default:
      break;
  }
};

const getCommentType = (type: CommentType) => {
  switch (type) {
    case CommentType.ACCOUNT:
      return 'an account';
    case CommentType.ACCOUNT_REVIEW:
      return 'an account review';
    case CommentType.ACCOUNT_REVIEW_ACCOUNT_ANALYSIS:
      return 'an account analysis review';
    case CommentType.ACCOUNT_REVIEW_ACCOUNT_INFO:
      return 'an account info review';
    case CommentType.ACCOUNT_REVIEW_ACCOUNT_NARRATIVE:
      return 'an account narrative review';
    case CommentType.ACCOUNT_REVIEW_ACCOUNT_SALES_ACTIVITY:
      return 'an account sales activity review';
    case CommentType.ACCOUNT_REVIEW_ACCOUNT_TRANSACTIONS:
      return 'an account transactions review';
    case CommentType.DEPOSIT:
      return 'a deposit';
    case CommentType.FINCEN_REPORT:
      return 'a FinCEN report';
    case CommentType.REQUIREMENT:
      return 'a requirement';
    case CommentType.REQUIREMENT_DOCUMENT:
      return 'a requirement document';
    case CommentType.QUESTIONNAIRE:
      return 'a questionnaire';
    default:
      return;
  }
};

export const goToComment = (
  appType: string,
  commentType: CommentType,
  idComponents: CommentIdComponents,
  openComment?: boolean
) => {
  const commentStore = getCommentStore();
  const fiBankStore = getFiBankStore();
  const fiDispensaryStore = getFiDispensaryStore();

  const fincenApi = new FinCENApi();

  if (openComment) {
    commentStore.openComments(true);
  }

  switch (commentType) {
    case CommentType.ACCOUNT:
      if (appType === 'crb') {
        return `/secure/crb/${
          idComponents.crbId ? `${idComponents.crbId}/` : ''
        }my-providers/provider-details/${(idComponents as AccountCommentIdComponents).fiId}`;
      } else {
        const id = (idComponents as AccountCommentIdComponents).orgId;
        const dispensary = fiDispensaryStore.allOrgs().find((o) => o.id === id);
        const status = dispensary?.assigned_onboarding_template.status;

        if (status === DueDiligenceStatus.BANK_APPROVED) {
          return `/secure/fi/accounts/${id}/view`;
        } else {
          return `/secure/fi/accounts/${id}/review`;
        }
      }
    case CommentType.ACCOUNT_REVIEW:
      const id = (idComponents as AccountReviewCommentIdComponents).crbId;
      const dispensary = fiDispensaryStore.allOrgs().find((o) => o.id === id);

      commentStore.setCurrentPost({
        type: CommentType.ACCOUNT_REVIEW,
        title: dispensary?.name ?? 'Account',
        idComponents: {
          reviewId: (idComponents as any).reviewId,
          crbId: (idComponents as any).crbId,
          fiId: (idComponents as any).fiId
        }
      });

      return `/secure/fi/monitoring/review/${(idComponents as AccountReviewCommentIdComponents).reviewId}`;
    case CommentType.ACCOUNT_REVIEW_ACCOUNT_ANALYSIS:
      commentStore.setCurrentPost({
        type: CommentType.ACCOUNT_REVIEW_ACCOUNT_ANALYSIS,
        title: 'Analysis',
        idComponents: {
          reviewId: (idComponents as any).reviewId,
          crbId: (idComponents as any).crbId,
          fiId: (idComponents as any).fiId
        }
      });

      return `/secure/fi/monitoring/review/${(idComponents as AccountReviewCommentIdComponents).reviewId}`;
    case CommentType.ACCOUNT_REVIEW_ACCOUNT_INFO:
      commentStore.setCurrentPost({
        type: CommentType.ACCOUNT_REVIEW_ACCOUNT_INFO,
        title: 'Account Information',
        idComponents: {
          reviewId: (idComponents as AccountReviewCommentIdComponents).reviewId,
          crbId: (idComponents as AccountReviewCommentIdComponents).crbId,
          fiId: (idComponents as AccountReviewCommentIdComponents).fiId
        }
      });

      return `/secure/fi/monitoring/review/${(idComponents as AccountReviewCommentIdComponents).reviewId}`;
    case CommentType.ACCOUNT_REVIEW_ACCOUNT_NARRATIVE:
      commentStore.setCurrentPost({
        type: CommentType.ACCOUNT_REVIEW_ACCOUNT_NARRATIVE,
        title: 'Narrative',
        idComponents: {
          reviewId: (idComponents as AccountReviewCommentIdComponents).reviewId,
          crbId: (idComponents as AccountReviewCommentIdComponents).crbId,
          fiId: (idComponents as AccountReviewCommentIdComponents).fiId
        }
      });

      return `/secure/fi/monitoring/review/${(idComponents as AccountReviewCommentIdComponents).reviewId}`;
    case CommentType.ACCOUNT_REVIEW_ACCOUNT_SALES_ACTIVITY:
      commentStore.setCurrentPost({
        type: CommentType.ACCOUNT_REVIEW_ACCOUNT_SALES_ACTIVITY,
        title: 'Sales Activity',
        idComponents: {
          reviewId: (idComponents as AccountReviewCommentIdComponents).reviewId,
          crbId: (idComponents as AccountReviewCommentIdComponents).crbId,
          fiId: (idComponents as AccountReviewCommentIdComponents).fiId
        }
      });

      return `/secure/fi/monitoring/review/${(idComponents as AccountReviewCommentIdComponents).reviewId}`;
    case CommentType.ACCOUNT_REVIEW_ACCOUNT_TRANSACTIONS:
      commentStore.setCurrentPost({
        type: CommentType.ACCOUNT_REVIEW_ACCOUNT_TRANSACTIONS,
        title: 'Transaction Data',
        idComponents: {
          reviewId: (idComponents as AccountReviewCommentIdComponents).reviewId,
          crbId: (idComponents as AccountReviewCommentIdComponents).crbId,
          fiId: (idComponents as AccountReviewCommentIdComponents).fiId
        }
      });

      return `/secure/fi/monitoring/review/${(idComponents as AccountReviewCommentIdComponents).reviewId}`;
    case CommentType.DEPOSIT:
      if (appType === 'crb') {
        return `/secure/crb/dashboard?tab=deposits&depositId=${
          (idComponents as DepositCommentIdComponents).depositId
        }`;
      } else {
        return `/secure/fi/deposits?depositId=${(idComponents as DepositCommentIdComponents).depositId}`;
      }
    case CommentType.FINCEN_REPORT:
      const reportId = (idComponents as FincenReportCommentIdComponents).reportId;

      return fincenApi.getReportById(fiBankStore.bank.id, reportId).then((report) => {
        if (report.report_type === FincenReportType.SAR) {
          return `/secure/fi/fincen/sar/${reportId}`;
        } else {
          return `/secure/fi/fincen/ctr/${reportId}`;
        }
      });
    case CommentType.REQUIREMENT:
      if (appType === 'crb') {
        return `/secure/crb/${idComponents.crbId}/my-providers/provider-details/${
          (idComponents as RequirementCommentIdComponents).fiId
        }/requirement/${(idComponents as RequirementCommentIdComponents).requirementId}`;
      } else {
        const orgId = (idComponents as RequirementCommentIdComponents).crbId;
        const requirementId = (idComponents as RequirementCommentIdComponents).requirementId;
        const dispensary = fiDispensaryStore.allOrgs().find((o) => o.id === orgId);
        const status = dispensary?.assigned_onboarding_template.status;

        if (status === DueDiligenceStatus.BANK_APPROVED) {
          return `/secure/fi/accounts/${orgId}/view/requirement/${requirementId}`;
        } else {
          return `/secure/fi/accounts/${orgId}/review`;
        }
      }
    case CommentType.REQUIREMENT_DOCUMENT:
      if (appType === 'crb') {
        return `/secure/crb/${idComponents.crbId}/my-providers/provider-details/${
          (idComponents as RequirementDocumentCommentIdComponents).fiId
        }/requirement/${(idComponents as RequirementDocumentCommentIdComponents).requirementId}?documentId=${
          (idComponents as RequirementDocumentCommentIdComponents).documentId
        }`;
      } else {
        const orgId = (idComponents as RequirementDocumentCommentIdComponents).crbId;
        const requirementId = (idComponents as RequirementDocumentCommentIdComponents).requirementId;
        const documentId = (idComponents as RequirementDocumentCommentIdComponents).documentId;
        const dispensary = fiDispensaryStore.allOrgs().find((o) => o.id === orgId);
        const status = dispensary?.assigned_onboarding_template.status;

        if (status === DueDiligenceStatus.BANK_APPROVED) {
          return `/secure/fi/accounts/${orgId}/view/requirement/${requirementId}?documentId=${documentId}`;
        } else {
          return `/secure/fi/accounts/${orgId}/review?documentId=${documentId}`;
        }
      }
    case CommentType.QUESTIONNAIRE:
      const questionnaireData = idComponents as QuestionnaireCommentIdComponents;

      if (appType === 'crb') {
        return `/secure/crb/${idComponents.crbId ? `${idComponents.crbId}/` : ''}questionnaires/${
          questionnaireData.questionnaireId
        }/results/${questionnaireData.questionnaireResultId}`;
      } else {
        return `/secure/fi/accounts/${idComponents.crbId}/questionnaire/${questionnaireData.questionnaireId}/response/${questionnaireData.questionnaireResultId}`;
      }
  }
};

export const renderSnackBarNotification = async (bannerNotification: Notification) => {
  const dispensariesApi = new DispensariesApi();
  const snackbarStore = getSnackbarStore();
  const crbDispensaryStore = getCrbDispensaryStore();
  const notificationsApi = new NotificationsApi();
  switch (bannerNotification.type) {
    case NotificationTypes.SERVICE_PROVIDER_SALES_JOB_SUCCESS:
      let data = bannerNotification.data as ServiceProviderSalesJobNotificationData;
      let timezone = getFiBankStore().bank.iana_timezone;
      let fromDate = DateTimeHelpers.formatISOToTableDateString(data.from_date, timezone);
      let toDate = DateTimeHelpers.formatISOToTableDateString(data.to_date, timezone);
      snackbarStore.showSuccessSnackbarMessage(
        `Ingestion for ${data.org_name} from ${fromDate} to ${toDate} has finished running and has succeeded`
      );
      break;
    case NotificationTypes.SERVICE_PROVIDER_SALES_JOB_FAILURE:
      data = bannerNotification.data as ServiceProviderSalesJobNotificationData;
      timezone = getFiBankStore().bank.iana_timezone;
      fromDate = DateTimeHelpers.formatISOToTableDateString(data.from_date, timezone);
      toDate = DateTimeHelpers.formatISOToTableDateString(data.to_date, timezone);
      snackbarStore.showErrorSnackbarMessage(
        `Ingestion for ${data.org_name} from ${fromDate} to ${toDate} has failed`
      );
      break;
    case NotificationTypes.ONBOARD_WITH_TRACE_BANNER:
      const onboardWithTraceData = bannerNotification.data as OnboardWithTraceBannerNotificationData;
      const updatedDispensary = await dispensariesApi.getDispensary(onboardWithTraceData.dispensaryId);
      crbDispensaryStore.updateDispensary(updatedDispensary);
      switch (onboardWithTraceData.onboardingStatus) {
        case OnboardWithTraceNotificationDataOnboardingStatus.SUCCESSFUL:
          snackbarStore.showSnackbar(
            SnackbarSeverity.Success,
            `We have successfully connected your Metrc account with the credentials provided`,
            noop,
            false,
            20000
          );
          break;
        case OnboardWithTraceNotificationDataOnboardingStatus.ERROR:
        case OnboardWithTraceNotificationDataOnboardingStatus.FAILED:
          snackbarStore.showSnackbar(
            SnackbarSeverity.Error,
            `We were unable to connect your Metrc account with the credentials provided. Please check your credentials and try again. If the issue persists, please contact our support team at support@greencheckverified.com`,
            noop,
            false,
            20000
          );
          break;
      }
      break;
    default:
      break;
  }
  if (bannerNotification.id) {
    notificationsApi.markNotificationSeen(bannerNotification.id);
  }
};

const bannerNotificationTypes = [
  NotificationTypes.SERVICE_PROVIDER_SALES_JOB_SUCCESS,
  NotificationTypes.SERVICE_PROVIDER_SALES_JOB_FAILURE,
  NotificationTypes.ONBOARD_WITH_TRACE_BANNER
];

export const filterNotificationsByBannerOrDrawerType = (
  notifications: Notification[]
): { bannerNotifications: Notification[]; drawerNotifications: Notification[] } => {
  const bannerNotifications: Notification[] = [];
  const drawerNotifications: Notification[] = [];
  notifications.forEach((n) => {
    bannerNotificationTypes.includes(n.type) ? bannerNotifications.push(n) : drawerNotifications.push(n);
  });
  return { bannerNotifications, drawerNotifications };
};

export const filterNotificationsByOrg = (orgId: string, notifications: Notification[]) => {
  notifications = notifications.filter((n) => n.orgId === orgId);
  return notifications;
};
