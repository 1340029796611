import type { DueDiligenceStatus, NotificationPreferences } from '@gcv/shared';
import { UserInterests } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { track, updatePendoForCrb } from 'third-party-integrations/pendo';

import { UsersApi } from 'api';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { UserStore } from 'stores/UserStore';

export interface UserInfo {
  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  ssn?: string;
}

export interface PM {
  userInfo: UserInfo;
  notificationPreferences: NotificationPreferences;
  interests: { [key in UserInterests]: boolean };
}

export interface UserSettings extends PM {}

@injectable()
export class CrbUserSettingsRepo {
  @inject(UserStore) private userStore: UserStore;
  @inject(CrbDispensaryStore) private dispensaryStore: CrbDispensaryStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = {} as PM;

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = action(async () => {
    const {
      firstName,
      minitial: middleInitial,
      lastName,
      email,
      phone,
      address,
      city,
      state,
      zipcode,
      interests,
      ssn
    } = { ...this.userStore.user };
    this.updateProgrammersModel({
      userInfo: { firstName, middleInitial, lastName, email, phone, address, city, state, zipcode, ssn },
      notificationPreferences: this.userStore.user.preferences.notifications,
      interests: {
        [UserInterests.AchAndWires]: interests?.includes(UserInterests.AchAndWires) === true,
        [UserInterests.BillPay]: interests?.includes(UserInterests.BillPay) === true,
        [UserInterests.BusinessBanking]: interests?.includes(UserInterests.BusinessBanking) === true,
        [UserInterests.BusinessInsurance]: interests?.includes(UserInterests.BusinessInsurance) === true,
        [UserInterests.CashLogistics]: interests?.includes(UserInterests.CashLogistics) === true,
        [UserInterests.CommercialLoans]: interests?.includes(UserInterests.CommercialLoans) === true,
        [UserInterests.PayrollAndHr]: interests?.includes(UserInterests.PayrollAndHr) === true,
        [UserInterests.Payments]: interests?.includes(UserInterests.Payments) === true
      }
    });
  });

  private userSettingsAreEqual = (a: UserInterests[], b: UserInterests[]): boolean => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (const a_interest of a) {
      if (!b.includes(a_interest)) {
        return false;
      }
    }
    return true;
  };

  public updateUserSettings = async (settings: UserSettings) => {
    const userIdDetails: {
      firstName?: string;
      minitial?: string;
      lastName?: string;
      email?: string;
      phone?: string;
      address?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      ssn?: string;
    } = { ...settings.userInfo };

    userIdDetails.minitial = settings.userInfo.middleInitial;

    const usersApi = new UsersApi();
    const interests = Object.keys(settings.interests).filter(
      (interest) => settings.interests[interest as UserInterests]
    ) as UserInterests[];

    const userStoreUser = this.userStore.user;
    let user = await usersApi.updateUserIdentification(userStoreUser.id, userIdDetails);
    const provider = this.dispensaryStore.getDefaultProvider();
    const currentDispensary = this.dispensaryStore.currentDispensary;
    const preferences = await usersApi.updateUserPreferences(userStoreUser.id, {
      notifications: settings.notificationPreferences
    });

    if (!user.interests || !this.userSettingsAreEqual(user.interests, interests)) {
      user = await usersApi.updateUserInterests(userStoreUser.id, interests);
      track('CRB | Marketplace | Change Interests Save', {
        user_id: user.id,
        crb_name: currentDispensary.name,
        [UserInterests.AchAndWires]: interests.includes(UserInterests.AchAndWires),
        [UserInterests.BillPay]: interests.includes(UserInterests.BillPay),
        [UserInterests.BusinessBanking]: interests.includes(UserInterests.BusinessBanking),
        [UserInterests.BusinessInsurance]: interests.includes(UserInterests.BusinessInsurance),
        [UserInterests.CashLogistics]: interests.includes(UserInterests.CashLogistics),
        [UserInterests.CommercialLoans]: interests.includes(UserInterests.CommercialLoans),
        [UserInterests.PayrollAndHr]: interests.includes(UserInterests.PayrollAndHr),
        [UserInterests.Payments]: interests.includes(UserInterests.Payments)
      });

      updatePendoForCrb(
        currentDispensary,
        user,
        currentDispensary.name,
        provider.id,
        provider.templates.value.onboarding.status as DueDiligenceStatus,
        0,
        [],
        {
          userInterests: interests
        }
      );
    }

    user.preferences = preferences;

    if (user.id === userStoreUser.id) {
      this.userStore.updateUser(user);
    }

    this.dispensaryStore.updateDispensaryStaff(user);

    return this.updateProgrammersModel(settings);
  };
}
