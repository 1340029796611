import type { Group, Role, User } from '@gcv/shared';
import { IANATimezones, OrganizationRoleResolver } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

import { BanksApi, UsersApi } from 'api';
import { FiBankStore } from 'stores/FiBankStore';
import { FiDispensaryStore } from 'stores/FiDispensaryStore';
import type { SelectOption } from 'ui';
import type { TaskAssignee } from './tasks-modal.model';
import { TaskAssigneeType } from './tasks-modal.model';

export interface PM {
  assignees: TaskAssignee[];
  accounts: SelectOption[];
  dispensaries: SelectOption[];
  timezone: IANATimezones;
}

@injectable()
export class FiTasksModalRepo {
  @inject(FiBankStore) private fiBankStore: FiBankStore;
  @inject(FiDispensaryStore) private fiDispensaryStore: FiDispensaryStore;
  @inject(OrganizationRoleResolver) private roleResolver: OrganizationRoleResolver;

  @inject(UsersApi)
  private usersApi: UsersApi;
  @inject(BanksApi)
  private banksApi: BanksApi;

  constructor() {
    makeAutoObservable(this);
  }

  createTaskAssignees = async (staff: User[], groups: Group[]): Promise<TaskAssignee[]> => {
    const unassignableRoles: Role[] = ['bank_examiner'];
    const userAssignees = staff
      .filter(
        (s) =>
          s.invitation_status !== 'archived' &&
          !unassignableRoles.some((role) => this.roleResolver.userHasRole(groups, s, role))
      )
      .map((u) => {
        const fullName = `${u.firstName} ${u.lastName}`;

        return {
          id: u.id,
          name: fullName,
          userIds: [u.id],
          type: TaskAssigneeType.USER
        } as TaskAssignee;
      });

    const groupAssignees = groups
      .filter((g) => !unassignableRoles.some((role) => g.roles.includes(role)))
      .filter((g) => g.users.length > 0)
      .map((g) => {
        return {
          id: g.id,
          name: g.name,
          userIds: [g.id],
          type: TaskAssigneeType.GROUP
        } as TaskAssignee;
      });

    return [...userAssignees, ...groupAssignees];
  };

  public programmersModel: PM = {
    assignees: [],
    accounts: [],
    dispensaries: [],
    timezone: IANATimezones.America_NewYork
  };

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = action(async () => {
    const timezone = this.fiBankStore.bank.iana_timezone;

    const accounts = this.fiDispensaryStore
      .allOrgs()
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
      .map((org) => {
        return {
          value: org.id,
          label: org.name
        } as SelectOption;
      });

    const dispensaries = this.fiDispensaryStore.dispensaries
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
      .map((org) => {
        return {
          value: org.id,
          label: org.name
        } as SelectOption;
      });

    const assignees = await this.createTaskAssignees(this.fiBankStore.staff, this.fiBankStore.bank.groups);

    this.updateProgrammersModel({
      timezone,
      assignees,
      accounts,
      dispensaries
    });
  });

  getAssignees = async (orgId: string): Promise<TaskAssignee[]> => {
    let assignees = await this.createTaskAssignees(this.fiBankStore.staff, this.fiBankStore.bank.groups);
    if (orgId === '') return assignees;
    const dispUsers = await this.usersApi.getDispensaryUsers(this.fiBankStore.bank.id, orgId);
    const dispensaryDetails = await this.banksApi.getBankDispensary(this.fiBankStore.bank.id, orgId);
    assignees = await this.createTaskAssignees(dispUsers as unknown as User[], dispensaryDetails.groups);
    return assignees;
  };
}
