import type {
  GroupedTask,
  IANATimezones,
  MinifiedTask,
  TaskInputRequest,
  TaskInternalExternal,
  TaskStatus,
  User
} from '@gcv/shared';
import { TaskUser } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { DateTime } from 'luxon';
import { action, makeAutoObservable } from 'mobx';

import { BanksApi } from 'api';
import { TasksApi } from 'api/TasksApi';
import { FiBankStore } from 'stores/FiBankStore';
import { FiDispensaryStore } from 'stores/FiDispensaryStore';
import { UserStore } from 'stores/UserStore';
import { DateTimeHelpers } from 'util/dateTime.util';
import type { DecoratedTask, TaskDetails } from './tasks.model';
import { isGroupedTask } from './tasks.model';

export interface PM {
  currentTask: TaskDetails | null;
  isLoading: boolean;
  tasks: DecoratedTask[];
}

@injectable()
export class FiTasksRepo {
  @inject(TasksApi)
  private tasksApi: TasksApi;

  @inject(BanksApi)
  private banksApi: BanksApi;

  @inject(FiBankStore)
  private bankStore: FiBankStore;

  @inject(FiDispensaryStore)
  private dispStore: FiDispensaryStore;

  @inject(UserStore)
  private userStore: UserStore;

  public programmersModel: PM = {
    currentTask: null,
    isLoading: false,
    tasks: []
  };

  constructor() {
    makeAutoObservable(this);
  }

  private updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  private mapTaskToTaskDetails = (decoratedTask: DecoratedTask): TaskDetails => {
    const task = decoratedTask.tasks[0];
    const assignee = this.getAssigneeName(task.assigned_users, task.assigned_group_ids, task.assigned_groups);
    const completedUser =
      task.completed_by_user &&
      (task.completed_by_user as User).firstName + ' ' + (task.completed_by_user as User).lastName;
    const account = task.account?.orgType === 'bank' ? '--' : task.account?.name;
    const createdByUser =
      task.created_by_user && task.created_by_user === TaskUser.AUTO_GENERATED_USER
        ? 'Auto Generated'
        : task.created_by_user && task.created_by_user.firstName && task.created_by_user.lastName
        ? `${task.created_by_user.firstName} ${task.created_by_user.lastName}`
        : '';
    return {
      account: account ?? '--',
      assignee: assignee === '' ? task.assigned_org.name : assignee,
      associatedWith: task.associated_with ?? '--',
      comment_id_components: task.comment_id_components,
      comment_type: task.comment_type,
      completedBy: completedUser ?? '--',
      completedDate: task.completed_on ?? '--',
      completedNote: task.completed_note ?? '--',
      createdDate: task.date_created,
      createdBy: createdByUser,
      data: task.data,
      description: task.description ?? '--',
      dueDate: task.process_date,
      groupedTasks: decoratedTask.tasks,
      id: task.id === '--' ? decoratedTask.groupKey : task.id,
      internalType: task.internal_type,
      isGrouped: decoratedTask.isGrouped,
      name: task.title ?? '--',
      status: task.status,
      taskCategory: task.task_category,
      lastReminderDate:
        decoratedTask.tasks
          .filter((t) => t.last_reminder_date)
          .sort((a, b) => {
            return DateTime.fromISO(a.last_reminder_date!) > DateTime.fromISO(b.last_reminder_date!) ? -1 : 1;
          })[0]?.last_reminder_date ?? '',
      nextReminderDate:
        decoratedTask.tasks
          .filter((t) => t.next_reminder_date)
          .sort((a, b) => {
            return DateTime.fromISO(a.next_reminder_date!) > DateTime.fromISO(b.next_reminder_date!) ? 1 : -1;
          })[0]?.next_reminder_date ?? ''
    };
  };

  private decorateTask = (t: MinifiedTask | GroupedTask) => {
    if (isGroupedTask(t)) {
      return {
        id: '--',
        isGrouped: t.is_grouped,
        groupKey: t.group_key,
        name: t.tasks[0].title ?? '--',
        tasks: t.tasks
      };
    } else {
      return {
        id: t.id,
        isGrouped: undefined,
        groupKey: '',
        name: t.title ?? '--',
        tasks: [t]
      };
    }
  };

  private updateTasksWithNickname = (
    tasks: (MinifiedTask | GroupedTask)[]
  ): (MinifiedTask | GroupedTask)[] => {
    const allDisps = [...this.dispStore.dispensaries, ...this.dispStore.dispensariesArchived];
    tasks.map((task) => {
      if (isGroupedTask(task)) {
        task.tasks = this.updateTasksWithNickname(task.tasks) as MinifiedTask[];
      } else {
        const account = task.account ? task.account : null;
        if (account !== null) {
          const name = allDisps.find((disp) => disp.id === account.id)?.name;
          task.account = {
            ...account,
            name: name ? name : account.name
          };
        }
      }
    });
    return tasks;
  };

  public load = async (
    bankId: string,
    taskDirection: TaskInternalExternal,
    startDate: string,
    endDate: string,
    statuses: TaskStatus[]
  ) => {
    this.updateProgrammersModel({
      isLoading: true
    });

    const response = await this.tasksApi.getBankTasksV2(
      bankId,
      bankId,
      taskDirection,
      startDate,
      endDate,
      statuses
    );
    response.tasks = this.updateTasksWithNickname(response.tasks);

    this.updateProgrammersModel({
      isLoading: false,
      tasks: response.tasks.map((t) => this.decorateTask(t))
    });
  };

  public getTaskById = async (bankId: string, taskId: string) => {
    const task =
      this.programmersModel.tasks.find((t) => t.id === taskId) ??
      this.programmersModel.tasks.find((t) => t.groupKey === taskId);

    if (!task) {
      const response = await this.tasksApi.getTaskById(bankId, taskId);
      this.updateProgrammersModel({ currentTask: this.mapTaskToTaskDetails(this.decorateTask(response[0])) });
    } else {
      this.updateProgrammersModel({ currentTask: this.mapTaskToTaskDetails(task) });
    }
  };

  public createNewTask = async (bankId: string, payload: TaskInputRequest) => {
    await this.tasksApi.createNewTask(bankId, payload);
  };

  public deleteCurrentTask = async (bankId: string) => {
    if (!this.programmersModel.currentTask) {
      throw new Error('Task not found');
    }

    await this.tasksApi.deleteTask(bankId, this.programmersModel.currentTask.id);
  };

  public updateCurrentTask = async (
    bankId: string,
    status: TaskStatus,
    completed_note: string,
    timezone: IANATimezones
  ) => {
    if (!this.programmersModel.currentTask) {
      throw new Error('Task not found');
    }

    const updatedTask = await this.tasksApi.updateFiTask(
      bankId,
      this.programmersModel.currentTask.id,
      status,
      completed_note,
      this.userStore.user.id,
      DateTimeHelpers.formatJsDateToISO(new Date(Date.now()), timezone)
    );
    const tasks = [...this.programmersModel.tasks];
    const index = tasks.findIndex((t) => this.programmersModel.currentTask?.id === t.id);
    tasks[index] = this.decorateTask(updatedTask[0]);
    this.updateProgrammersModel({ tasks });
  };

  // Tasks can be assigned to one or more users or assigned to a group
  // Check to see if an assigned user exists, otherwise lookup the group name
  public getAssigneeName = (
    users?: Partial<User>[],
    group?: string[],
    groups?: {
      id: string;
      name: string;
    }[]
  ) => {
    if (users) {
      return `${users[0].firstName} ${users[0].lastName}`;
    }

    if (group) {
      if (group.length > 1) {
        return 'Multiple';
      }

      const bankGroup = this.bankStore.bank.groups.find((g) => g.id === group[0])?.name;
      if (!bankGroup && groups) {
        return groups[0].name || '--';
      }

      return bankGroup ?? '--';
    }

    return '--';
  };
}
