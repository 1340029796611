import * as React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import { ReactComponent as ArrowForward } from 'assets/images/arrow-forward.svg';
import { theme } from 'ui/theme';

interface AccordionProps {
  label: string;
  disabled?: boolean;
  isOpen?: boolean; // use this to set a default or control the accordion open state in another component
  dataCy?: string;
  width?: string;
  noBorder?: boolean;
  summary?: React.ReactNode;
  marginTop?: boolean;
  arrowLeft?: boolean;
  fullBorder?: boolean;
  noPaddingTable?: boolean;
  onAccordianChange?: (isOpen: boolean) => void; // use this to callback to your component when the user clicks it open/close
  children?: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = (props) => {
  const [expanded, setExpanded] = React.useState(props.isOpen);

  React.useEffect(() => {
    setExpanded(props.isOpen);
  }, [props.isOpen]);

  return (
    <Box
      sx={{
        width: props.width ? props.width : 'unset'
      }}
    >
      <MuiAccordion
        disableGutters
        elevation={0}
        expanded={expanded}
        onChange={(e, expanded) => {
          setExpanded(expanded);
          if (props.onAccordianChange) {
            props.onAccordianChange(expanded);
          }
        }}
        data-cy={props.dataCy}
        disabled={props.disabled}
        sx={{
          '&.MuiAccordion-root': props.fullBorder
            ? { border: `1px solid ${theme.palette.divider}` }
            : {
                borderRadius: '0px',
                boxShadow: 'none',
                borderBottom: props.noBorder ? `1px solid transparent` : `1px solid ${theme.palette.divider}`
              },
          '& .MuiAccordionDetails-root': {
            padding: props.noPaddingTable ? '0px' : '0 10px 0 2px',
            '& .MuiBox-root:first-of-type': props.noPaddingTable
              ? {
                  padding: '0px',
                  '& .MuiCardContent-root:last-of-type': {
                    paddingTop: '0px',
                    paddingBottom: '0px'
                  }
                }
              : {}
          },
          '& .MuiPaper-root': props.noPaddingTable
            ? {
                margin: '0px !important',
                border: '0px'
              }
            : {},
          '& .MuiCollapse-root': props.marginTop
            ? {
                top: '0px',
                padding: '0px'
              }
            : {}
        }}
      >
        <MuiAccordionSummary
          expandIcon={<ArrowForward />}
          sx={{
            alignItems: 'center',
            '&.MuiAccordionSummary-root': {
              display: 'flex',
              padding: '0 10px 0 2px',
              borderRadius: '0',
              flexDirection: props.arrowLeft ? 'row-reverse' : 'row'
            },
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)'
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              padding: props.arrowLeft ? '0px 8px' : '0px'
            }
          }}
        >
          <Typography variant="h4">{props.label}</Typography>
          {props.summary}
        </MuiAccordionSummary>
        <MuiAccordionDetails
          sx={{
            '& .MuiCollapse-root': {
              marginBottom: '0px',
              paddingLeft: '19px',
              position: 'relative',
              top: '0px'
            }
          }}
        >
          {props.children}
        </MuiAccordionDetails>
      </MuiAccordion>
    </Box>
  );
};
