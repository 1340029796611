import React from 'react';
import { Chip } from '@mui/material';

import palette from 'ui/theme/palette';

export interface PillProps {
  text: string;
  onDelete?: () => void;
  dataCy?: string;
  dataCyDelete?: string;
  size?: 'small' | 'medium';
}

export const Pill: React.FC<PillProps> = (props) => {
  return (
    <Chip
      color={'primary'}
      label={props.text}
      onDelete={props.onDelete}
      variant="outlined"
      data-cy={props.dataCy}
      size={props.size ? props.size : 'medium'}
      deleteIcon={
        <span
          className="material-icons"
          style={{
            color: palette.primary.main,
            width: '16px',
            fontSize: '10px',
            fontWeight: 'bold'
          }}
          data-cy={props.dataCyDelete}
        >
          clear_rounded
        </span>
      }
    ></Chip>
  );
};
