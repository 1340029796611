import * as React from 'react';
import { Box } from '@mui/material';
import { useButtonOverlayContext } from 'app-context';

import { useComponent } from 'hooks/useComponent';
import { Button, Container, ContainerItem } from '../../atoms';

export const ButtonOverlay: React.FC = useComponent(() => {
  const [buttonOverlayState] = useButtonOverlayContext();
  const { isShowing, buttonTitle, buttonAction, cancelAction } = { ...buttonOverlayState };

  return (
    <Box
      sx={{
        display: isShowing ? 'flex' : 'none',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '78px',
        backgroundColor: '#3A3C45',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        opacity: '80%',
        zIndex: 1001
      }}
    >
      <Container margin="0" padding="0">
        <ContainerItem margin="0" padding="0">
          <Button
            width="96px"
            label="Cancel"
            onClick={() => {
              if (cancelAction) {
                cancelAction();
              }
            }}
            color={'default'}
          />
        </ContainerItem>
        <ContainerItem margin="0 0 0 0.25rem" padding="0">
          <Button
            width="96px"
            label={buttonTitle ?? 'Save'}
            onClick={() => {
              if (buttonAction) {
                buttonAction();
              }
            }}
            color={'primary'}
          />
        </ContainerItem>
      </Container>
    </Box>
  );
});
