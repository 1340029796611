import type { FieldValues } from 'react-hook-form';
import type {
  CrbServiceProvider,
  CreateDocumentsRequest,
  CustomRequirement,
  Dispensary,
  DocumentUpload,
  DueDiligenceDocument,
  LicenseDataMap,
  LicenseDocumentDataRequest,
  LicenseManagerData,
  LinkedDocId,
  OnboardingDocumentRequirement,
  RequirementCategory,
  RequirementId,
  RequirementReference,
  TemplateResponse,
  TemplateResultResponse,
  User
} from '@gcv/shared';
import { CommentType, GCD_RIVERBANK_BANK_ID } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import _ from 'lodash';
import { action, makeAutoObservable, observe } from 'mobx';
import { track } from 'third-party-integrations/pendo';

import { CommentStore } from 'stores/CommentStore';
import { ComplianceStore } from 'stores/ComplianceStore';
import { CrbBankStore } from 'stores/CrbBankStore';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';
import { UserStore } from 'stores/UserStore';
import {
  getOnboardingRequirementsForCrb,
  isOnboardingDocumentRequirementFulfilled
} from 'util/due-diligence.util';
import { getMimeType, openFileInNewWindow } from 'util/files.util';
import { filterObjectMap } from 'util/objectUtils';
import {
  hasDispensaryFulfilledAllAddtionalRequirements,
  hasDispensaryFulfilledAllDocumentRequirements
} from 'util/org.util';
import { uploadOrgDocumentToS3, viewDocument } from 'util/s3.util';
import type { OnboardingProgrammersModel } from './onboarding.repo';
import { OnboardingRepo } from './onboarding.repo';

export type DocumentationCategoryFilters =
  | 'all'
  | RequirementCategory.Financial
  | RequirementCategory.Legal
  | RequirementCategory.License
  | RequirementCategory.Operational;
export type DocumentationStatusFilters = 'all' | 'fulfilled' | 'unfulfilled';

export interface OnboardingDocumentRequirementMap {
  [id: string]: OnboardingDocumentRequirement & RequirementReference;
}
export interface VM {
  redirectPath: string;
  dispenaryIsPlantTouching: boolean;
  provider: CrbServiceProvider;
  onboardingTemplate: TemplateResponse;
  user: User;
  dispensaryStaff: User[];
  isLoading: boolean;
  isAutoSaving: boolean;
  navDrawerOpen: boolean;
  showOnboardingSuccessModal: boolean;
  onboardingSuccessModalOpen: boolean;
  isConnectedToPayqwick: boolean;
  posType: string;
  licenseData: LicenseManagerData;
  licenseDataMap: LicenseDataMap;
  isLoadingDocumentation: boolean;
  isSavingDocumentation: boolean;
  allRequirements: OnboardingDocumentRequirementMap;
  templateResults: TemplateResultResponse;
  onboardingTemplateResult: TemplateResultResponse;
  allDocumentsMap: { [id: string]: DocumentUpload };
  categoryFilter: DocumentationCategoryFilters;
  statusFilter: DocumentationStatusFilters;
  filteredRequirements: string[];
  isLoadingDocumentChange: boolean;
  isLoadingDocumentUpload: boolean;
  isLoadingLicenseUpload: boolean;
  uploadingOnboardingTemplateDocuments: boolean;
  deletingOnboardingTemplateDocuments: boolean;
  sharedRootDocs: {
    requirement: OnboardingDocumentRequirement;
    ddDocuments: DueDiligenceDocument[];
    documents: DocumentUpload[];
  }[];
  isBusinessDetailsSectionComplete: boolean;
  isOperationalDetailsSectionComplete: boolean;
  isDocumentationDetailsSectionComplete: boolean;
  isIdVerificationSectionComplete: boolean;
  isAdditionalInfoSectionComplete: boolean;
}

@injectable()
export class OnboardingPresenter {
  @inject(OnboardingRepo)
  public repo: OnboardingRepo;

  @inject(ComplianceStore)
  public complianceStore: ComplianceStore;

  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(UserStore)
  public userStore: UserStore;

  @inject(CrbDispensaryStore)
  public crbDispensaryStore: CrbDispensaryStore;

  @inject(CrbBankStore)
  public crbBankStore: CrbBankStore;

  @inject(CommentStore)
  public commentStore: CommentStore;

  constructor() {
    makeAutoObservable(this);
  }

  public viewModel: VM = {
    redirectPath: '',
    dispenaryIsPlantTouching: false,
    provider: {} as CrbServiceProvider,
    onboardingTemplate: {} as TemplateResponse,
    user: {} as User,
    dispensaryStaff: [],
    isLoading: true,
    isAutoSaving: false,
    navDrawerOpen: false,
    showOnboardingSuccessModal: false,
    onboardingSuccessModalOpen: false,
    isConnectedToPayqwick: false,
    posType: '',
    licenseData: {} as LicenseManagerData,
    licenseDataMap: {} as LicenseDataMap,
    isLoadingDocumentation: false,
    isSavingDocumentation: false,
    allRequirements: {},
    templateResults: {} as TemplateResultResponse,
    onboardingTemplateResult: {} as TemplateResultResponse,
    allDocumentsMap: {},
    categoryFilter: 'all',
    statusFilter: 'all',
    filteredRequirements: [],
    isLoadingDocumentChange: false,
    isLoadingDocumentUpload: false,
    isLoadingLicenseUpload: false,
    uploadingOnboardingTemplateDocuments: false,
    deletingOnboardingTemplateDocuments: false,
    sharedRootDocs: [],
    isBusinessDetailsSectionComplete: false,
    isOperationalDetailsSectionComplete: false,
    isDocumentationDetailsSectionComplete: false,
    isIdVerificationSectionComplete: false,
    isAdditionalInfoSectionComplete: false
  };

  public updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  public load = action(async (templateId: string, templateResultId: string, bankId: string) => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as OnboardingProgrammersModel;
      //get a full mapping of requirements that we need
      const licenseData =
        this.complianceStore.licenseDataMap[this.crbDispensaryStore.currentDispensary.state];
      const allRequirements = this.getAllRequirementData(programmersModel.onboardingTemplate, licenseData);

      const documentationComplete = hasDispensaryFulfilledAllDocumentRequirements(
        programmersModel.onboardingTemplate,
        programmersModel.onboardingResult,
        licenseData?.employee_license_required
      );

      this.updateViewModel({
        onboardingTemplate: programmersModel.onboardingTemplate,
        posType: posType,
        licenseDataMap: this.complianceStore.licenseDataMap,
        licenseData,
        redirectPath: redirectPath,
        allDocumentsMap: programmersModel.allDocumentsMap,
        sharedRootDocs: programmersModel.sharedRootDocs,
        onboardingTemplateResult: programmersModel.onboardingResult,
        //create a dereferenced copy of the template results to prevent annoying re-rendering issue on OnboardingStepper
        templateResults: _.cloneDeep(programmersModel.onboardingResult),
        allRequirements,
        isDocumentationDetailsSectionComplete: documentationComplete,
        isAdditionalInfoSectionComplete: hasDispensaryFulfilledAllAddtionalRequirements(
          programmersModel.onboardingTemplate,
          programmersModel.onboardingResult
        ),
        isLoading: programmersModel.loading,
        isAutoSaving: programmersModel.isAutoSaving
      });
      this.filterRequirements(allRequirements);
    });

    observe(this.crbDispensaryStore, 'currentDispensary', (obj) => {
      const storeDispensary = obj.newValue as Dispensary;

      this.updateViewModel({
        isBusinessDetailsSectionComplete: this.isBusinessSectionComplete(storeDispensary),
        isOperationalDetailsSectionComplete: this.isOperationalSectionComplete(storeDispensary)
      });
    });

    observe(this.userStore, 'user', (obj) => {
      const user = obj.newValue as User;

      this.updateViewModel({
        isIdVerificationSectionComplete: this.isIdVerificationSectionComplete(user)
      });
    });

    const posType = this.crbDispensaryStore.currentDispensary.pos_name || '';
    const redirectPath = this.crbDispensaryStore.currentDispensaryIsPlantTouching
      ? '/secure/crb/dashboard'
      : `/secure/crb/${this.crbDispensaryStore.currentDispensary.id}/my-providers`;

    // one time things for initial load
    this.updateViewModel({
      user: this.userStore.user,
      dispenaryIsPlantTouching: this.crbDispensaryStore.currentDispensaryIsPlantTouching,
      dispensaryStaff: this.crbDispensaryStore.currentDispensaryStaff,
      provider: this.crbBankStore.banks[bankId],
      isBusinessDetailsSectionComplete: this.isBusinessSectionComplete(
        this.crbDispensaryStore.currentDispensary
      ),
      isOperationalDetailsSectionComplete: this.isOperationalSectionComplete(
        this.crbDispensaryStore.currentDispensary
      ),
      isIdVerificationSectionComplete: this.isIdVerificationSectionComplete(this.userStore.user)
    });

    await this.repo.loadOnboarding(templateId, templateResultId, bankId);

    this.commentStore.setCurrentPost({
      type: CommentType.ACCOUNT,
      title: this.crbDispensaryStore.currentDispensary.name,
      idComponents: {
        orgId: this.crbDispensaryStore.currentDispensary.id,
        crbId: this.crbDispensaryStore.currentDispensary.id,
        fiId: bankId
      }
    });
  });

  isBusinessSectionComplete = (dispensary: Dispensary): boolean => {
    const isBusinessSectionComplete =
      dispensary.name?.length &&
      dispensary.entityType?.length &&
      dispensary.ein?.length &&
      dispensary.establishedDate != null &&
      dispensary.streetAddress?.length &&
      dispensary.city?.length &&
      dispensary.state?.length &&
      dispensary.postalCode?.length &&
      dispensary.iana_timezone?.length &&
      dispensary.phone?.length;
    return !!isBusinessSectionComplete;
  };

  isOperationalSectionComplete = (dispensary: Dispensary): boolean => {
    const isOperationalSectionComplete =
      dispensary.ftEmployees?.toString().length &&
      dispensary.ptEmployees?.toString().length &&
      dispensary.monthlyCustomers?.toString().length &&
      dispensary.monthlySales?.toString().length &&
      dispensary.businessHours &&
      dispensary.pos_name?.length &&
      (dispensary.pos_name?.toLowerCase() !== 'other' ||
        (dispensary.pos_name?.toLowerCase() === 'other' && dispensary.pos_name_other?.length)) &&
      dispensary.business_type?.length;

    return !!isOperationalSectionComplete;
  };

  isIdVerificationSectionComplete = (user: User): boolean => {
    // if this org is connected to GCD, require SSN
    const isConnectedToGcd = this.crbBankStore.banks[GCD_RIVERBANK_BANK_ID];

    const isIdVerificationSectionComplete =
      user.firstName?.length &&
      user.lastName?.length &&
      user.dateOfBirth?.length &&
      user.address?.length &&
      user.city?.length &&
      user.state?.length &&
      user.zipcode?.length &&
      user.phone?.length &&
      user.identification != null &&
      user.identification.idType?.length &&
      user.identification.idFront != null &&
      user.identification.idBack != null &&
      user.identityVerified &&
      (user.ssn || !isConnectedToGcd);

    return !!isIdVerificationSectionComplete;
  };

  setOpenNavDrawer = (isOpen: boolean) => {
    this.updateViewModel({ navDrawerOpen: isOpen });
  };

  toggleNavDrawerOpen = () => {
    this.updateViewModel({ navDrawerOpen: !this.viewModel.navDrawerOpen });
  };

  setShowOnboardingSuccessModal = (isOpen: boolean) => {
    this.updateViewModel({ showOnboardingSuccessModal: isOpen });
  };

  trackSuccessModalClick = (bankId: string) => {
    const data = {
      provider_id: this.repo.crbBankStore.banks[bankId].id,
      provider_name: this.repo.crbBankStore.banks[bankId].orgName,
      crb_id: this.repo.dispensaryStore.currentDispensary.id,
      crb_name: this.repo.dispensaryStore.currentDispensary.name,
      user_id: this.userStore.user.id,
      user_name: this.userStore.user.firstName + ' ' + this.userStore.user.lastName
    };

    if (this.repo.dispensaryStore.currentDispensaryIsPlantTouching) {
      track('CRB | Onboarding | Application Success (PT)', data);
    } else {
      track('CRB | Onboarding | Application Success (NPT)', data);
    }
  };

  getActiveRequirements = (requirements: {
    [requirement_id: string]: CustomRequirement & RequirementReference;
  }) => {
    const activeRequirements: { [requirement_id: string]: CustomRequirement & RequirementReference } = {};
    // remove the archived requirements
    // though all the requirements are archived, archived requirements check is not done
    //  and Additional Information step is enabled
    if (requirements && Object.keys(requirements).length > 0) {
      Object.keys(requirements).forEach((req) => {
        if (!requirements[req].archived) {
          // when we delete a section on FI, an empty section is being added. This was to support
          // standalone fields which now is deprecated
          // below condition is to check the same, as this is enabling the Additional Information step in onboarding
          if (requirements[req].custom_section.label !== '') {
            activeRequirements[req] = requirements[req];
          }
        }
      });
    }
    return activeRequirements;
  };

  submitOnboardingTemplateResults = action(async () => {
    await this.repo.submitOnboardingTemplateResults();
    this.updateViewModel({ showOnboardingSuccessModal: true });

    track('CRB | Onboarding | Submit Application', {
      provider_id: this.viewModel.provider.id,
      provider_name: this.viewModel.provider.orgName,
      crb_id: this.crbDispensaryStore.currentDispensary.id,
      crb_name: this.crbDispensaryStore.currentDispensary.name,
      user_id: this.userStore.user.id,
      user_name: this.userStore.user.firstName + ' ' + this.userStore.user.lastName
    });
  });

  // ----------------- Identity Verification functionality -----------------

  // ----------------- Documentation functionality -----------------
  getAllRequirementData = (template: TemplateResponse, licenseData: LicenseManagerData) => {
    const isEmployeeLicenseRequired = licenseData?.employee_license_required;
    const activeBankRequirements = getOnboardingRequirementsForCrb(template, isEmployeeLicenseRequired);

    return activeBankRequirements;
  };

  filterRequirements = action((updatedRequirements?: OnboardingDocumentRequirementMap) => {
    const reqsToFilers = updatedRequirements ?? this.viewModel.allRequirements;
    const filterFunction = (requirement: OnboardingDocumentRequirement): boolean => {
      const result = this.viewModel.templateResults.requirements_results.find(
        (r) => r.requirement_id === requirement.requirement_id
      );
      return (
        this.viewModel.statusFilter === 'all' ||
        (this.viewModel.statusFilter === 'fulfilled' && isOnboardingDocumentRequirementFulfilled(result)) ||
        (this.viewModel.statusFilter === 'unfulfilled' && !isOnboardingDocumentRequirementFulfilled(result))
      );
    };
    const filteredRequirements = filterObjectMap(reqsToFilers, filterFunction);
    this.viewModel.filteredRequirements = Object.keys(filteredRequirements);
  });

  hasDocumentRequirement = () => {
    const filteredRequirements = filterObjectMap(this.viewModel.allRequirements, (req) => {
      return this.viewModel.filteredRequirements.includes(req.requirement_id);
    });
    let hasDocumentRequirements = false;
    Object.values(filteredRequirements).map((requirement) => {
      const documentRequirement = this.viewModel.templateResults.requirements_results.find(
        (r) => requirement && r.requirement_id === requirement.requirement_id
      )?.documents;

      if (documentRequirement && Object.keys(documentRequirement).length > 0) {
        hasDocumentRequirements = true;
      }
    });
    return hasDocumentRequirements;
  };

  submitDocumentation = (onNext: () => void) => {
    this.viewModel.onboardingTemplateResult = JSON.parse(JSON.stringify(this.viewModel.templateResults));
    if (this.crbDispensaryStore.currentDispensary && this.checkIfDocumentationSectionComplete()) {
      onNext();
      this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'Documents have been updated successfully.');
    } else {
      this.viewModel.statusFilter = 'unfulfilled';
      this.filterRequirements();
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'You have some unfulfilled requirements. Add them below in order to continue.'
      );
    }
  };

  checkIfDocumentationSectionComplete = () => {
    const isEmployeeLicenseRequired = this.viewModel.licenseData.employee_license_required;
    const complete = hasDispensaryFulfilledAllDocumentRequirements(
      this.viewModel.onboardingTemplate,
      this.viewModel.templateResults,
      isEmployeeLicenseRequired
    );
    return complete;
  };

  setStatusFilter = action(async (status: DocumentationStatusFilters) => {
    this.viewModel.statusFilter = status;
    this.filterRequirements();
  });

  downloadRequirementAttachments = action(async (requirement: OnboardingDocumentRequirement) => {
    await this.repo.downloadRequirementAttachment(requirement);
  });

  updateDoNotHaveCommentOnRequirement = action(
    async (requirementId: RequirementId, doNotHave: boolean, doNotHaveComment: string) => {
      await this.repo.setOnboardingDocumentRequirementDoNotHave(
        this.crbDispensaryStore.currentDispensary.id,
        this.viewModel.onboardingTemplate.template_id,
        requirementId,
        doNotHave,
        doNotHaveComment
      );
    }
  );

  viewAttachment = action(async (documentId: string) => {
    await this.repo.getAtttachment(this.viewModel.templateResults.bank_id, documentId);
  });

  removeDocumentFromRequirement = action(
    async (requirment: OnboardingDocumentRequirement, documentId: string) => {
      this.viewModel.isLoadingDocumentChange = true;
      await this.repo.removeDocumentFromRequirement(
        requirment,
        documentId,
        this.crbDispensaryStore.currentDispensary.id,
        this.viewModel.onboardingTemplate.template_id
      );
      this.viewModel.isLoadingDocumentChange = false;
    }
  );

  addDocsToRequirement = action(
    async (
      documentsRequest: CreateDocumentsRequest,
      documentUpload: DocumentUpload,
      requirement: OnboardingDocumentRequirement,
      documentId: string
    ) => {
      this.viewModel.isLoadingDocumentUpload = true;
      if (
        documentsRequest.documents.length > 0 &&
        documentUpload &&
        documentsRequest.documents[0].s3_key !== documentUpload.s3_key
      ) {
        await this.removeDocumentFromRequirement(requirement, documentId);
        await this.repo.addDocumentToRequirement(
          requirement,
          this.crbDispensaryStore.currentDispensary.id,
          this.viewModel.onboardingTemplate.template_id,
          documentsRequest
        );
      } else {
        await this.repo.updateLicenseDocument(
          requirement,
          documentId,
          documentsRequest.license_data ?? ({} as LicenseDocumentDataRequest),
          this.viewModel.onboardingTemplate.template_id
        );
      }
      this.viewModel.isLoadingDocumentUpload = false;
    }
  );

  addLicenseDocument = action(
    async (requirement: OnboardingDocumentRequirement, documentsRequest: CreateDocumentsRequest) => {
      this.viewModel.isLoadingLicenseUpload = true;
      await this.repo.addDocumentToRequirement(
        requirement,
        this.crbDispensaryStore.currentDispensary.id,
        this.viewModel.onboardingTemplate.template_id,
        documentsRequest
      );
      this.viewModel.isLoadingLicenseUpload = false;
    }
  );

  uploadFilesToRequirement = action(
    async (
      requirement: OnboardingDocumentRequirement,
      files: File[],
      sharedDocs?: DocumentUpload[],
      licenseData?: LicenseDocumentDataRequest
    ) => {
      this.viewModel.isLoadingDocumentUpload = true;
      const promises = files.map(async (file) => {
        const s3Key = await uploadOrgDocumentToS3(file, this.crbDispensaryStore.currentDispensary.id);
        return { filename: file.name, s3_key: s3Key, req_name: requirement.name, shared: true };
      });

      const uploadedDocs = await Promise.all(promises);

      const sharedDocsRequest: {
        filename: string;
        s3_key: string;
        req_name: string;
        linked_doc_id: LinkedDocId;
      }[] = [];
      if (sharedDocs) {
        sharedDocs.map((doc) => {
          sharedDocsRequest.push({
            filename: doc.file_name,
            req_name: requirement.name,
            s3_key: doc.s3_key,
            linked_doc_id: `${doc.org_id}_${doc.id}`
          });
        });
      }

      const newDocuments: CreateDocumentsRequest = {
        documents: [...uploadedDocs, ...sharedDocsRequest],
        license_data: licenseData
      };

      await this.repo.addOnboardingDocumentRequirementDocuments(
        this.crbDispensaryStore.currentDispensary.id,
        this.viewModel.onboardingTemplate.template_id,
        requirement.requirement_id,
        newDocuments
      );
      const reloadedSharedRootDocs = await this.repo.getSharedDcouments(
        this.crbDispensaryStore.currentDispensary.id
      );
      this.viewModel.sharedRootDocs = reloadedSharedRootDocs;
      this.viewModel.isLoadingDocumentUpload = false;
    }
  );

  updateLicenseDocument = action(
    async (
      requirement: OnboardingDocumentRequirement,
      documentId: string,
      data: LicenseDocumentDataRequest
    ) => {
      this.viewModel.isLoadingDocumentChange = true;
      await this.repo.updateLicenseDocument(
        requirement,
        documentId,
        data,
        this.viewModel.onboardingTemplate.template_id
      );
      this.viewModel.isLoadingDocumentChange = false;
    }
  );

  // ----------------- Additional Information functionality -----------------

  getDefaultValues = () => {
    if (this.viewModel.onboardingTemplateResult.custom_requirement_result === undefined) return {};
    const updatedValues = {} as FieldValues;
    for (
      let index = 0;
      index < this.viewModel.onboardingTemplateResult.custom_requirement_result.length;
      index++
    ) {
      const customResult = this.viewModel.onboardingTemplateResult.custom_requirement_result[index];
      if (
        customResult.custom_fields &&
        customResult.custom_fields.responses &&
        Object.keys(customResult.custom_fields.responses).length > 0
      ) {
        Object.entries(customResult.custom_fields.responses).forEach((entry) => {
          updatedValues[entry[0]] = entry[1];
        });
      }
    }

    return updatedValues;
  };

  onNewFilesChange = action(async (requirementId: string, fieldId: string, files: File[]) => {
    const promises = [];
    const docs: DocumentUpload[] = [];

    // uploads file
    const fileUpload = async (file: File) => {
      const doc = await this.uploadOnboardingTemplateDocument(requirementId, fieldId, file);
      if (doc) docs.push(doc);
    };

    for (const file of files) {
      promises.push(fileUpload(file));
    }
    await Promise.all(promises);

    const ids = docs.map((doc) => {
      this.viewModel.allDocumentsMap[doc.id] = doc;
      return doc.id;
    });
    return ids;
  });

  submitAdditionalInformation = action(async (data: FieldValues, onNext: () => void) => {
    await this.repo.saveAndCompleteOnboardingTemplate(data);
    onNext();
  });

  openDocument = async (docId: string, file_name: string) => {
    const blob = await viewDocument(
      { orgId: this.crbDispensaryStore.currentDispensary.id, userType: 'dispensary' },
      docId
    );

    if (blob) {
      openFileInNewWindow(blob, getMimeType(file_name), file_name);
    }
  };

  addNewDocuments = action((docs: DocumentUpload[]) => {
    this.repo.addNewDocuments(docs);
  });

  onSubmitError = () => {
    this.snackbarStore.showErrorSnackbarMessage(
      'Please fill out all required fields and try submitting again.'
    );
  };

  uploadOnboardingTemplateDocument = action(async (requirementId: string, fieldId: string, file: File) => {
    this.viewModel.uploadingOnboardingTemplateDocuments = true;
    const document = await this.repo.uploadOnboardingTemplateDocument(requirementId, fieldId, file);
    this.viewModel.uploadingOnboardingTemplateDocuments = false;
    return document;
  });

  autoSaveAdditionalInfoSections = action(
    async (sections: { customFieldValues: { responses: Record<string, any> }; sectionId: string }[]) => {
      this.updateViewModel({ isAutoSaving: true });
      await this.repo.autoSaveAdditionalInfoSections(sections);
      this.updateViewModel({ isAutoSaving: false });
    }
  );

  deleteOnboardingTemplateDocument = action(
    async (requirementId: string, fieldId: string, file: DocumentUpload) => {
      this.viewModel.deletingOnboardingTemplateDocuments = true;
      await this.repo.deleteOnboardingTemplateDocument(requirementId, fieldId, file);
      this.viewModel.deletingOnboardingTemplateDocuments = false;
    }
  );
}
