import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRollbarPerson } from '@rollbar/react';
import { updateLogRocketConfig } from 'third-party-integrations/log-rocket';

import { useComponent } from 'hooks';
import { getFiBankStore } from 'stores/FiBankStore';
import { getUserType, userHasRole } from 'ui/routing/router-util';
import { getUserStore } from '../../stores/UserStore';
import { Spinner } from '../atoms';

export const FiRoute: React.FC<{ path: string }> = useComponent(({ path }) => {
  let isBankUser = false;
  let userHasRoles = false;

  const userStore = getUserStore();
  const bankStore = getFiBankStore();

  //check user type
  if (userStore.isLoaded) {
    const type = getUserType(userStore.user);

    if (type === 'bank') {
      isBankUser = true;
    }

    useRollbarPerson({
      id: userStore.user?.id,
      name: userStore.user?.firstName + ' ' + userStore?.user?.lastName,
      email: userStore.user?.email
    });
    updateLogRocketConfig(userStore.user);
  }

  //check user role
  if (userStore.isLoaded && bankStore.isLoaded && bankStore.bank.groups) {
    userHasRoles = userHasRole(bankStore.bank.groups, userStore.user, path);
  }

  return isBankUser && userHasRoles ? (
    <Outlet />
  ) : !userStore.isLoaded || !bankStore.isLoaded || !bankStore.bank.groups ? (
    <Spinner />
  ) : !userHasRoles ? (
    <Navigate to={{ pathname: '/error' }} />
  ) : (
    <Navigate to={{ pathname: '/' }} />
  );
});
