import * as React from 'react';

import type { Report } from 'ui';
import { ReportsCard, ViewItem } from 'ui';

interface Props {
  appType: 'crb' | 'fi';
  reports: Report[];
}

export const ReportsCardList: React.FC<Props> = (props) => {
  return (
    <>
      {props.reports.map((report) => {
        return (
          <ViewItem key={report.id} size={[12, 12, 6, 4, 3]}>
            <ReportsCard
              alignContent="left"
              key={report.id}
              height="325px"
              header={{
                title: report.name,
                justify: 'left',
                icon: report.type
              }}
              noPadding={false}
              report={report}
            >
              <div>{report.description}</div>
            </ReportsCard>
          </ViewItem>
        );
      })}
    </>
  );
};
