import React from 'react';
import { Tab, Tabs } from '@mui/material';

import { ContainerItem } from 'ui/atoms';
import type { PageTab } from 'ui/templates';
import { TabLabel } from '..';

/**
 * Customize tab navigation.
 *
 * The class was initially created to allow the SaveChangesModal to block tab navigation. An example of
 * its use can be found there, where the tab selected by the user is stored and then navigated to only
 * after confirmation in the modal. The class is included here as it may have more general applications.
 *
 * Instantiate the class in a central location and then set it to the following properties:
 *
 *   1. tabNavigationDelegate of TabbedPage
 *   2. tabController of SaveChangesModal
 *
 * See: src/ui/organisms/SaveChangesModal/save-changes-modal.organism.tsx
 */
export class TabNavigationDelegate {
  public isTabChangeAllowed = true;

  public constructor() {
    this._changeTab = (newValue: number) =>
      console.log(`WARNING: Default changeTab is a no-op! (Tab: ${newValue})`);
  }

  private _activeTab = 0;
  private _changeTab: (newValue: number) => void;
  private _blockedTabChangeCallback: (() => void) | undefined;

  public setChangeTabFunction(f: (newValue: number) => void) {
    this._changeTab = f;
  }

  public setBlockedTabChangeCallback(f: () => void) {
    this._blockedTabChangeCallback = f;
  }

  public unsetBlockedTabChangeCallback() {
    this._blockedTabChangeCallback = undefined;
  }

  public setAllowTabChange(allow: boolean) {
    this.isTabChangeAllowed = allow;
  }

  public changeTab(newValue: number) {
    this._activeTab = newValue;

    if (this.isTabChangeAllowed) {
      this._changeTab(newValue);
    } else {
      if (this._blockedTabChangeCallback) {
        this._blockedTabChangeCallback();
      }
    }
  }

  public triggerTabChangeUsingIndex(newValue: number) {
    this.isTabChangeAllowed = true;
    this.changeTab(newValue);
  }

  public triggerTabChange() {
    this.triggerTabChangeUsingIndex(this._activeTab);
  }
}

interface Props {
  activeTab: number;
  tabs: PageTab[];
  changeTab: (newValue: number) => void;
  delegate?: TabNavigationDelegate;
}

// The individual Tab component has a disabled property. However, we want the hover cursor for
// a disabled tab to be the "not-allowed" icon (the greyed out circle with a slash through it).
// That wasn't working with the tab disabled. So, I disabled every functional part of the tab
// manually, and then added the appropriate cursor. - mdiana

export const TabNavigation: React.FC<Props> = (props) => {
  const changeTab = React.useMemo(() => {
    if (!props.delegate) {
      return props.changeTab;
    } else {
      const delegate = props.delegate;
      delegate.setChangeTabFunction(props.changeTab);
      return (newValue: number) => {
        delegate.changeTab(newValue);
      };
    }
  }, [props.changeTab, props.delegate]);

  return (
    <ContainerItem width="100%">
      <Tabs
        variant="scrollable"
        value={props.activeTab}
        onChange={(_, newValue) => {
          const index = newValue as number;
          if (props.tabs[index].disabled) {
            // This is a no-op.
          } else {
            changeTab(index);
          }
        }}
        sx={{ minHeight: '35px' }}
        TabIndicatorProps={{
          style: {
            display: 'none'
          }
        }}
      >
        {props.tabs.map((t) => {
          return (
            <Tab
              disableRipple={t.disabled ? true : false}
              label={
                <TabLabel
                  title={t.title}
                  count={t.notificationCount}
                  countColor={t.notificationCountColor}
                ></TabLabel>
              }
              key={t.title}
              data-cy={`${t.id}-tab`}
              sx={{
                opacity: t.disabled ? '50%' : '100%',
                textTransform: 'none',
                color: 'text.secondary',
                fontWeight: 'fontWeightBold',
                mr: 1,
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                maxWidth: '200px',
                '&.MuiTab-root': {
                  minHeight: 0,
                  minWidth: 0,
                  padding: '5px 16px',
                  border: '1px solid transparent',
                  height: '32px'
                },
                '&.Mui-selected': {
                  opacity: 1,
                  backgroundColor: 'primary.light',
                  borderColor: 'primary.main',
                  borderRadius: 25,
                  color: 'primary.main',
                  fontWeight: 700
                },
                '&:hover': {
                  color: t.disabled ? 'none' : 'primary.main',
                  cursor: t.disabled ? 'not-allowed' : 'pointer'
                },
                '& > .MuiBox-root': {
                  padding: '0'
                }
              }}
            />
          );
        })}
      </Tabs>
    </ContainerItem>
  );
};
