import * as React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { CustomField, CustomSection, IANATimezones } from '@gcv/shared';
import { Box, debounce, Dialog } from '@mui/material';

import { Button, Container, ContainerItem, Header, Text, Title } from 'ui';
import palette from 'ui/theme/palette';
import { allParentsShouldDisplay, getField, shouldDisplay } from './preview-form-util';

export const PreviewCustomFormModal: React.FC<{
  isOpen: boolean;
  sections: CustomSection[];
  timezone: IANATimezones;
  title: string;
  subTitle: string | React.ReactNode;
  onClose: () => void;
}> = (props) => {
  const form = useForm({ mode: 'onBlur' });
  const [animate, setAnimation] = React.useState(false);
  const [formValues, setFormValues] = React.useState(form.getValues());

  const debouncedSaveFormValues = React.useCallback(
    debounce((data: FieldValues) => {
      setFormValues(data);
    }, 150),
    []
  );

  const onValueChange = () => {
    debouncedSaveFormValues(form.getValues());
    if (!animate) {
      setAnimation(true);
    }
  };

  const fieldAnimation = (field: CustomField | CustomSection) =>
    field.smart_rule && animate ? '1.5s linear SMART_RULE_ANIM' : null;

  return (
    <Dialog open={props.isOpen} fullScreen>
      <Box
        sx={{
          width: '100%',
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          pb: '0.25rem'
        }}
      >
        <Container dataCy="dialog-header" padding="1rem 1rem 0.25rem 1rem" width="100%">
          <ContainerItem grow maxWidth="80%">
            <Box sx={{ mb: '0.5rem' }}>
              <Title justify="left" content={props.title} />
            </Box>

            {!props.subTitle ? null : typeof props.subTitle === 'string' ? (
              <Text
                content={props.subTitle}
                sx={{ display: 'inline', color: (theme) => theme.palette.text.secondary }}
              />
            ) : (
              props.subTitle
            )}
          </ContainerItem>
          <ContainerItem grow align="flex-end" justify="right">
            <Button
              label="Close Preview"
              color="primary"
              onClick={props.onClose}
              dataCy="close-preview-button"
            />
          </ContainerItem>
        </Container>
      </Box>

      <Box
        sx={{
          height: '100%',
          px: 'auto',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box sx={{ width: '550px', maxWidth: '600px', height: '100%' }}>
          {props.sections.map((section: CustomSection) => {
            if (
              allParentsShouldDisplay(section, props.sections, formValues) &&
              shouldDisplay(section, formValues)
            ) {
              return (
                <Box
                  sx={{
                    width: '100%',
                    WebkitAnimation: fieldAnimation(section),
                    MozAnimation: fieldAnimation(section),
                    OAnimation: fieldAnimation(section),
                    animation: fieldAnimation(section),
                    animationIterationCount: 1,
                    '@-webkit-keyframes SMART_RULE_ANIM': {
                      from: {
                        backgroundColor: '#FFFCE6'
                      },
                      to: {
                        backgroundColor: 'white'
                      }
                    }
                  }}
                  key={section.id}
                >
                  <Container column width="100%">
                    <Box sx={{ width: '100%' }}>
                      <ContainerItem align="center">
                        <Header content={section.label} type={'h2'} />
                      </ContainerItem>

                      {section.description && (
                        <ContainerItem padding={'0 8px 8px'}>
                          <Text sx={{ whiteSpace: 'pre-line' }} content={section.description} type="body2" />
                        </ContainerItem>
                      )}
                    </Box>

                    {section.fields.map((field) => {
                      if (
                        allParentsShouldDisplay(field, props.sections, formValues) &&
                        shouldDisplay(field, formValues)
                      ) {
                        return (
                          <Box
                            sx={{
                              width: '100%',
                              WebkitAnimation: fieldAnimation(field),
                              MozAnimation: fieldAnimation(field),
                              OAnimation: fieldAnimation(field),
                              animation: fieldAnimation(field),
                              animationIterationCount: 1,
                              '@-webkit-keyframes SMART_RULE_ANIM': {
                                from: {
                                  backgroundColor: '#FFFCE6'
                                },
                                to: {
                                  backgroundColor: 'white'
                                }
                              }
                            }}
                            key={field.id}
                          >
                            <ContainerItem width="100%" align="center">
                              <Box sx={{ mb: '0.5rem' }}>
                                {getField(field, section.id, form, onValueChange, props.timezone)}
                              </Box>

                              {field.helpText && (
                                <Box>
                                  <Text
                                    content={field.helpText}
                                    sx={{
                                      color: palette.text.secondary,
                                      fontFamily: 'Lato',
                                      fontStyle: 'normal',
                                      fontWeight: 400,
                                      fontSize: '13px',
                                      lineHeight: '18.2px'
                                    }}
                                  />
                                </Box>
                              )}
                            </ContainerItem>
                          </Box>
                        );
                      }
                    })}
                  </Container>
                </Box>
              );
            } else {
              return null;
            }
          })}
        </Box>
      </Box>
    </Dialog>
  );
};
