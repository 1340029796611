import type { ChangeEvent } from 'react';
import {
  TableCell as MaterialTableCell,
  TableHead as MaterialTableHead,
  TableRow as MaterialTableRow
} from '@mui/material';

import type { Column, Row } from 'ui';
import { Container, ContainerItem } from 'ui';
import { TableHeaderCell } from '../TableHeaderCell/table-header-cell.molecule';
import { TableHeaderCheckboxCell as TableHeaderCheckboxCell } from '../TableHeaderCheckboxCell/table-header-checkbox-cell.molecule';

interface Props<T> {
  selectable: boolean;
  columns: Column<T>[];
  selectedRows: string[];
  rows: Row<T>[];
  orderBy: keyof T;
  order: 'asc' | 'desc';
  handleSelectAllClick: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  handleRequestSortBySelected: (event: React.MouseEvent<unknown>) => void;
  actions?: React.ReactNode;
  singleSelectable: boolean;
}

export const TableHeader = <T,>(props: Props<T>) => {
  return (
    <MaterialTableHead sx={{ width: '100%', bgcolor: 'background.secondary' }}>
      <MaterialTableRow data-cy="table-header" key={'0'}>
        {props.selectable && (
          <TableHeaderCheckboxCell
            selected={props.selectedRows}
            rows={props.rows}
            orderBy={props.orderBy}
            order={props.order}
            handleSelectAllClick={props.handleSelectAllClick}
            handleSort={props.handleRequestSortBySelected}
          />
        )}

        {props.singleSelectable && (
          <MaterialTableCell
            align={'center'}
            padding={'none'}
            sx={{
              width: '35px',
              height: '40px',
              p: 0,
              color: 'text.secondary',
              fontWeight: 'bold'
            }}
          >
            &nbsp;
          </MaterialTableCell>
        )}

        {props.selectedRows && props.selectedRows.length > 0 && props.actions ? (
          <Container padding="0" width="100%">
            <ContainerItem padding="0">{props.actions}</ContainerItem>
          </Container>
        ) : (
          props.columns.map((column) => (
            <TableHeaderCell
              key={column.id as string}
              selected={props.selectedRows}
              rows={props.rows}
              orderBy={props.orderBy}
              order={props.order}
              columnsLength={props.columns.length}
              column={column}
              handleSelectAllClick={props.handleSelectAllClick}
              handleSort={props.handleRequestSort}
            />
          ))
        )}
      </MaterialTableRow>
    </MaterialTableHead>
  );
};
