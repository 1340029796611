import type {
  CustomCheckboxField,
  CustomField,
  CustomForm,
  CustomRadioField,
  CustomSection,
  CustomSelectField,
  CustomTextSingleLineField
} from '@gcv/shared';
import { CustomFieldType } from '@gcv/shared';

import { reorder } from 'util/array.util';

export class CustomFormReducer {
  private _form: CustomForm;

  constructor(form: CustomForm) {
    this._form = form;
  }

  private fieldDecorator(currentField: CustomField, newField: Record<string, any>) {
    // shared properties
    currentField.id = newField.id;
    currentField.label = newField.label;
    currentField.helpText = newField.helpText;
    currentField.required = newField.required;
    currentField.section = newField.section;
    currentField.type = newField.type;
    if (newField.smart_rule) {
      currentField.smart_rule = newField.smart_rule;
    }
    if (newField.attachments) {
      currentField.attachments = newField.attachments;
    }
    if (newField.paragraph) {
      currentField.paragraph = newField.paragraph;
    }

    // unique properties
    switch (currentField.type) {
      case CustomFieldType.TextSingleLine:
        (currentField as CustomTextSingleLineField).dataType = newField.dataType;
        return currentField;
      case CustomFieldType.Radio:
        (currentField as CustomRadioField).options = newField.options;
        return currentField;
      case CustomFieldType.Select:
        (currentField as CustomSelectField).options = newField.options;
        return currentField;
      case CustomFieldType.Checkbox:
        (currentField as CustomCheckboxField).options = newField.options;
        return currentField;
      case CustomFieldType.Date:
      case CustomFieldType.File:
      case CustomFieldType.TextMultiLine:
      default:
        return currentField;
    }
  }

  private findSection(id: string) {
    const index =
      id === '' || id === 'field-droppable'
        ? this._form.sections.findIndex((s) => !s.named_section)
        : this._form.sections.findIndex((s) => s.id === id);

    if (index === -1) {
      throw new Error('Section not found');
    }

    const section = this._form.sections[index];

    return { index, section };
  }

  private findField(id: string) {
    let index = -1;
    let field: CustomField | undefined;

    for (const section of this._form.sections) {
      index = section.fields.findIndex((f) => f.id === id);

      if (index > -1) {
        field = section.fields[index];
        break;
      }
    }

    if (!field) {
      throw new Error('Field not found');
    }

    return { index, field };
  }

  public isFieldSmartRuleTargetForSection(section: CustomSection, fieldId: string) {
    let isTarget = false;
    if (section?.smart_rule) {
      section.smart_rule?.conditions.forEach((rule) => {
        if (rule.targetFieldId === fieldId) {
          isTarget = true;
        }
      });
    }
    return isTarget;
  }

  public addSection(section: CustomSection) {
    this._form.sections.push(section);

    return { ...this._form };
  }

  public editSection(section: CustomSection) {
    const { section: currentSection } = this.findSection(section.id);

    // all editable sections are named sections
    // the unnamed section may only move fields
    currentSection.named_section = true;

    currentSection.label = section.label ?? currentSection.label;
    currentSection.order = section.order ?? currentSection.order;
    currentSection.description = section.description ?? currentSection.description;
    currentSection.fields = section.fields ?? currentSection.fields;
    if (section.smart_rule) {
      currentSection.smart_rule = section.smart_rule;
    }

    return { ...this._form };
  }

  public moveSection(fromIndex: number, toIndex: number) {
    this._form.sections = reorder(this._form.sections, fromIndex, toIndex);

    this._form.sections.forEach((s, i) => {
      s.order = i;
    });

    return { ...this._form };
  }

  public deleteSection(section: CustomSection) {
    const { index, section: currentSection } = this.findSection(section.id);
    this._form.sections.splice(index, 1);

    return { ...this._form };
  }

  public addField(field: CustomField, indexToAddItem?: number) {
    const { section } = this.findSection(field.section ?? '');

    if (indexToAddItem) {
      section.fields.splice(indexToAddItem, 0, field);
    } else {
      section.fields.push(field);
    }

    return { ...this._form };
  }

  public editField(field: CustomField) {
    const { index, field: currentField } = this.findField(field.id);

    if (field.section !== currentField.section) {
      const { section } = this.findSection(field.section ?? '');

      if (section) {
        this.moveField(
          currentField.section ?? '',
          index,
          field.section ?? '',
          section.fields.length,
          field.id
        );
      }
    }

    this.fieldDecorator(currentField, field);

    return { ...this._form };
  }

  public moveField(
    from: string,
    fromIndex: number,
    to: string,
    toIndex: number,
    elementId: string | undefined
  ) {
    // special treatment for field-droppable because it is our
    // unnamed section (i.e. no id, but droppable requires an id)
    if (to === 'field-droppable') {
      to = '';
    }

    if (from === 'field-droppable') {
      from = '';
    }

    const { section: toSection } = this.findSection(to);
    const { section: fromSection } = this.findSection(from);

    if (toSection.id === fromSection.id) {
      if (elementId && this.isFieldSmartRuleTargetForSection(toSection, elementId)) {
        console.log(this.isFieldSmartRuleTargetForSection(toSection, elementId));
      } else {
        const fields = reorder(toSection.fields, fromIndex, toIndex);
        toSection.fields = fields;
      }
    } else {
      if (elementId && this.isFieldSmartRuleTargetForSection(toSection, elementId)) {
        console.log(this.isFieldSmartRuleTargetForSection(toSection, elementId));
      } else {
        const removed = fromSection.fields.splice(fromIndex, 1);

        if (removed) {
          removed[0].section = toSection.id;
          toSection.fields.splice(toIndex, 0, removed[0]);
        }
      }
    }

    return { ...this._form };
  }

  public deleteField(field: CustomField) {
    const { index } = this.findField(field.id);
    const { section } = this.findSection(field.section ?? '');

    section.fields.splice(index, 1);

    return { ...this._form };
  }
}
