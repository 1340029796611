import type { FieldValues } from 'react-hook-form';
import type { CustomField, CustomSection } from '@gcv/shared';

const getExternalFieldsWithInternalTargets = (internalFields: string[], allSections: CustomSection[]) => {
  // get all fields in form
  // filter out fields in current section and ones without smart rules
  const externalSmartRuleFields = allSections
    .flatMap((s) => s.fields)
    .filter((field) => !internalFields.includes(field.id) && field.smart_rule);
  // if we have any smart rule fields that are outside the current section
  if (externalSmartRuleFields.length) {
    // filter to fields that target fields in the current section
    const externalFieldsWithInternalTargets =
      externalSmartRuleFields.filter((field) => {
        return field.smart_rule?.conditions.some((rule) => {
          // if rules target for current external field is in section ids return
          return internalFields.includes(rule.targetFieldId);
        });
      }) ?? [];
    return externalFieldsWithInternalTargets;
  }
};
const getExternalSectionsWithInternalTargets = (
  internalFields: string[],
  allSections: CustomSection[],
  currentSectionId: string
) => {
  // get all sections in form filter for sections with smart rules
  const externalSmartRuleSections = allSections.filter(
    (section) => section.smart_rule && section.id !== currentSectionId
  );
  // if we have any smart rule sections
  if (externalSmartRuleSections.length) {
    // filter to sections that target fields in the current section
    const externalSectionsWithInternalTargets =
      externalSmartRuleSections.filter((section) => {
        return section.smart_rule?.conditions.some((rule) => {
          // if rules target for current external field is in section ids return
          return internalFields.includes(rule.targetFieldId);
        });
      }) ?? [];
    return externalSectionsWithInternalTargets;
  }
};

const getInternalFieldsWithExternalTargets = (
  internalFields: string[],
  section: CustomSection | undefined
) => {
  if (!section) return [];
  // filter to fields in section with smart rules
  const internalFieldsWithExternalTargets =
    section.fields.filter((field) => {
      return (
        field.smart_rule &&
        field.smart_rule?.conditions.some((rule) => {
          // if rules target for current internal field is not an internal id return
          return !internalFields.includes(rule.targetFieldId);
        })
      );
    }) ?? [];
  return internalFieldsWithExternalTargets;
};

export const getAllBlockingRules = (section: CustomSection | undefined, allSections: CustomSection[]) => {
  if (!section) return [];
  const fieldIdsInSection = section?.fields?.map((field) => field.id) ?? [];
  const foundRulesList: (CustomField | CustomSection)[] = [];
  // if the section has fields that are targets of external fields list them
  const externalFieldsWithInternalTargets = getExternalFieldsWithInternalTargets(
    fieldIdsInSection,
    allSections
  );
  if (externalFieldsWithInternalTargets?.length) {
    foundRulesList.push(...externalFieldsWithInternalTargets);
  }

  // if the section has fields that are the targets of external sections list them
  const externalSectionsWithInternalTargets = getExternalSectionsWithInternalTargets(
    fieldIdsInSection,
    allSections,
    section.id
  );
  if (externalSectionsWithInternalTargets?.length) {
    foundRulesList.push(...externalSectionsWithInternalTargets);
  }

  // if the section has fields that are targeting external fields list them
  const internalFieldsWithExternalTargets = getInternalFieldsWithExternalTargets(fieldIdsInSection, section);
  if (internalFieldsWithExternalTargets?.length) {
    foundRulesList.push(...internalFieldsWithExternalTargets);
  }

  // if a section has a smart rule it will always be for an external target
  if (section && section.smart_rule) {
    foundRulesList.push(section);
  }
  return foundRulesList;
};

export const isFieldSmartRuleTarget = (field: FieldValues | undefined, allSections: CustomSection[]) => {
  let isTarget = false;
  const smartRuleSubjects: (CustomField | CustomSection)[] = [];
  if (field) {
    for (const section of allSections) {
      section.fields.findIndex((subject) => {
        subject.smart_rule?.conditions.forEach((rule) => {
          if (rule.targetFieldId === field.id) {
            smartRuleSubjects.push(subject);
            isTarget = true;
          }
        });
      });
      section.smart_rule?.conditions.forEach((rule) => {
        if (rule.targetFieldId === field.id) {
          smartRuleSubjects.push(section);
          isTarget = true;
        }
      });
    }
  }
  return { isTarget, smartRuleSubjects };
};

export const isFieldSmartRuleTargetForSection = (
  sectionId: string,
  fieldId: string,
  allSections: CustomSection[]
) => {
  let isTarget = false;
  const section = allSections.find((s) => s.id === sectionId);
  if (section?.smart_rule) {
    section.smart_rule?.conditions.forEach((rule) => {
      if (rule.targetFieldId === fieldId) {
        isTarget = true;
      }
    });
  }
  return isTarget;
};
