import type { AccountOwnerMetadata, AppAreaOwner, DispensaryId } from '@gcv/shared';
import { AppAreaName } from '@gcv/shared';
import { injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuid } from 'uuid';

import { BanksApi } from 'api';
import { getFiBankStore } from './FiBankStore';
import { getSnackbarStore } from './SnackBarStore';
import { getUserStore } from './UserStore';

// TODO move the bank staff functionality to this store instead of the bank store to clear up dependencies here
@injectable()
export class FiRelationshipManagerStore {
  relationshipManagers: Record<DispensaryId, string> = {};
  accountOwnerMetadata: AccountOwnerMetadata = {} as AccountOwnerMetadata;
  bankApi = new BanksApi();

  bankStore = getFiBankStore();
  snackbarStore = getSnackbarStore();
  userStore = getUserStore();

  constructor() {
    makeAutoObservable(this);
  }

  public loadRelationshipManagers = async () => {
    const accountOwnerMetadata = await this.bankApi.getAccountOwnerMetadata(this.bankStore.bank.id);
    this.accountOwnerMetadata = accountOwnerMetadata;
    this.setRelationshipManagers(accountOwnerMetadata);
  };

  public setRelationshipManagers = action((accountOwnerMetadata: AccountOwnerMetadata) => {
    const managers: Record<DispensaryId, string> = {};

    Object.entries(accountOwnerMetadata.value.crb_level).forEach(([key, value]) => {
      for (const appArea of value) {
        if (appArea.label === 'Relationship Manager') {
          const assigned = this.bankStore.staff.find((s) => s.id === appArea.assigned_user);

          if (assigned) {
            managers[key] = `${assigned.firstName} ${assigned.lastName}`;
          } else {
            const group = this.bankStore.bank.groups.find((g) => g.id === appArea.assigned_group);

            if (group) {
              managers[key] = group.name;
            } else {
              managers[key] = '--';
            }
          }
        }
      }
    });
    runInAction(() => {
      this.relationshipManagers = managers;
    });
  });

  private findAndReplaceOrPush<T>(array: T[], newItem: T, compareFn: (a: T, b: T) => boolean): T[] {
    const index = array.findIndex((item) => compareFn(item, newItem));

    if (index > -1) {
      array.splice(index, 1, newItem);
    } else {
      array.push(newItem);
    }
    return array;
  }

  public saveRelationshipManager = action(async (orgId: string, relationshipManagerId: string) => {
    const accountOwnerData = this.accountOwnerMetadata.value;

    if (!accountOwnerData.crb_level[orgId]) {
      accountOwnerData.crb_level[orgId] = [];
    }

    let relationshipManagerData = accountOwnerData.crb_level[orgId].find(
      (appAreaOwner) => appAreaOwner.label === AppAreaName.RelationshipManager
    );

    if (relationshipManagerData === undefined) {
      const newRelationshipManagerData = {
        label: 'Relationship Manager',
        assigned_user: null,
        assigned_group: null,
        id: uuid()
      } as AppAreaOwner;

      relationshipManagerData = newRelationshipManagerData;
      accountOwnerData.crb_level[orgId].push(relationshipManagerData);
    }

    if (this.bankStore.staff.some((u) => u.id === relationshipManagerId)) {
      const user = this.bankStore.staff.find((s) => s.id === relationshipManagerId);

      if (user) {
        relationshipManagerData.assigned_user = user.id;
        relationshipManagerData.assigned_group = null;
      }
    } else if (this.bankStore.bank.groups.some((g) => g.id === relationshipManagerId)) {
      const group = this.bankStore.bank.groups.find((g) => g.id === relationshipManagerId);

      if (group) {
        relationshipManagerData.assigned_user = null;
        relationshipManagerData.assigned_group = group.id;
      }
    } else {
      relationshipManagerData.assigned_user = null;
      relationshipManagerData.assigned_group = null;
    }

    accountOwnerData.crb_level[orgId] = this.findAndReplaceOrPush(
      accountOwnerData.crb_level[orgId],
      relationshipManagerData,
      (appAreaOwner) => appAreaOwner.id === relationshipManagerData?.id
    );

    const updatedOwnerData = await this.bankApi.updateAccountOwnerMetadata(
      this.bankStore.bank.id,
      accountOwnerData
    );
    runInAction(() => {
      this.accountOwnerMetadata = updatedOwnerData;
    });
    this.setRelationshipManagers(updatedOwnerData);
  });
}

let store: FiRelationshipManagerStore | undefined;

export function getFiRelationshipManagerStore(): FiRelationshipManagerStore {
  if (!store) {
    store = new FiRelationshipManagerStore();
  }

  return store;
}
