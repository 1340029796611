import * as React from 'react';
import type { TooltipProps } from '@mui/material';
import { styled, Tooltip as TooltipContainer, tooltipClasses, useMediaQuery, useTheme } from '@mui/material';

interface Props {
  title: string | React.ReactElement;
  disabled?: boolean;
  variant?: 'text' | 'link';
  dataCy?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  ellipses?: boolean;
  display?: string;
  children?: React.ReactNode;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipContainer {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderColor: '#C6CCDA',
    color: '#3A3C45',
    boxShadow: 'none',
    fontSize: '12px',
    lineHeight: '15px',
    padding: '4px 6px',
    whiteSpace: 'pre-line'
  }
}));

export const Tooltip: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const placement = props.placement ? props.placement : 'bottom';
  const variant = props.variant ? props.variant : 'text';
  const maxWidth = isMobile ? '260px' : '360px';

  return props.disabled ? (
    <span>{props.children}</span>
  ) : (
    <CustomTooltip
      placement={placement}
      title={props.title}
      data-cy={props.dataCy ? props.dataCy : 'tooltip'}
      sx={{ maxWidth: maxWidth }}
    >
      <span>
        {variant === 'text' && (
          <span
            style={{
              display: props.display ? props.display : props.ellipses ? 'inline' : 'flex',
              overflow: props.ellipses ? 'hidden' : undefined,
              whiteSpace: props.ellipses ? 'nowrap' : undefined,
              textOverflow: props.ellipses ? 'ellipsis' : undefined
            }}
          >
            {props.children}
          </span>
        )}
        {variant === 'link' && <span style={{ color: '#5d8bfa', cursor: 'pointer' }}>{props.children}</span>}
      </span>
    </CustomTooltip>
  );
};
