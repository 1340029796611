import { Checkbox as MaterialCheckbox } from '@mui/material';
import { TableCell as MaterialTableCell } from '@mui/material';

import { Tooltip } from 'ui';

export interface Row<T> {
  id: string;
  data: T;
}

interface Props<T> {
  isItemSelected: boolean;
  row: Row<T>;
  handleSelect: (event: React.MouseEvent<unknown>, id: string) => void;
  isLastItem: boolean;
  disabled?: boolean;
  tooltip?: string;
}

export const TableCheckboxCell = <T,>(props: Props<T>) => {
  return (
    <MaterialTableCell
      padding="none"
      sx={{
        p: 0,
        pl: '0.75rem',
        width: '30px',
        borderBottom: (theme) => (props.isLastItem ? 'none' : `0.016rem solid ${theme.palette.divider}`)
      }}
    >
      {props.disabled && props.tooltip ? (
        <Tooltip title={props.tooltip}>
          <MaterialCheckbox
            checked={props.isItemSelected}
            color="primary"
            onClick={(event) => props.handleSelect(event, props.row.id)}
            data-cy="select-checkbox"
            sx={{ p: 0, color: (theme) => theme.palette.text.disabled }}
            disabled={props.disabled}
          />
        </Tooltip>
      ) : (
        <MaterialCheckbox
          checked={props.isItemSelected}
          color="primary"
          onClick={(event) => props.handleSelect(event, props.row.id)}
          data-cy="select-checkbox"
          sx={{ p: 0, color: (theme) => theme.palette.text.disabled }}
        />
      )}
    </MaterialTableCell>
  );
};
