import type { DocumentUpload } from '@gcv/shared';
import { injectable } from 'inversify';

import { api, banksApi } from './api-util/api';

export interface S3DocumentLink {
  s3LinkPath: string;
  s3_key: string;
}

@injectable()
export class DocumentsApi {
  async getDocument(orgId: string, docId: string): Promise<DocumentUpload> {
    return await api().get(`/documents/${orgId}/${docId}`);
  }

  async getDocuments(orgId: string): Promise<DocumentUpload[]> {
    return await api().get(`/documents/${orgId}`);
  }

  async getOtherDocuments(
    bankId: string,
    orgId: string
  ): Promise<{ location: string; description: string; document: DocumentUpload }[]> {
    return await banksApi().get(`/banks/${bankId}/documents/${orgId}/other`);
  }

  async getUserDocument(userId: string, docId: string): Promise<DocumentUpload> {
    return await api().get(`/users/${userId}/documents/${docId}`);
  }

  async putDocument(fileName: string, s3Key: string, orgId: string): Promise<DocumentUpload> {
    return await api().post(`/documents`, { fileName, s3Key, orgId });
  }

  async putUserDocument(fileName: string, s3Key: string, userId: string): Promise<DocumentUpload> {
    return await api().post(`/users/${userId}/documents`, { fileName, s3Key, userId });
  }

  async getDocumentLink(
    bucket: string,
    key: string,
    action: 'get' | 'put',
    userType?: string
  ): Promise<S3DocumentLink> {
    return await api().post(`/permissions/s3`, { bucket, key, action, userType });
  }

  async deleteDocument(orgId: string, documentId: string): Promise<any> {
    return await api().delete(`/documents/${orgId}/${documentId}`);
  }
}
