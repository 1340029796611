import Rollbar from 'rollbar';
import { logRocketConfig } from 'third-party-integrations/log-rocket';

import { environment } from 'environments/environment';

export const rollBarAccessToken = 'c5842baa1cd345b8a9b96fa4cb78d480';
export const rollbarConfig = {
  accessToken: rollBarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: environment.env,
  payload: {
    environment: environment.env,
    client: {
      javascript: {
        source_map_enabled: true, // true by default
        //enable source map by providing the git sha
        code_version: process.env.REACT_APP_GIT_SHA,
        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true
      }
    }
  }
};

export const rollbar = new Rollbar(rollbarConfig);

export const logError = (error: Error, data?: unknown) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  rollbar.error(error.message, error, { ...data, additional: logRocketConfig.session_url });
};
