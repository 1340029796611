import * as React from 'react';

import { ReactComponent as CommentIcon } from 'assets/images/external-comment-icon.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { Button, Container, ContainerItem, Dialog, Header, Text } from 'ui';
import { CommentsPresenter } from '../comments.presenter';

const POPOVER_CLASS_NAME = 'archive-unarchive-popover';

export const CommentModalExternalConfirmation: React.FC = useComponent(() => {
  const presenter = useInjection(CommentsPresenter);

  return (
    <Dialog
      isOpen={presenter.viewModel.isExternalConfirmationModalOpen}
      handleClose={() => presenter.openExternalConfirmationModal(false)}
      title=""
      noActions
      className={POPOVER_CLASS_NAME}
      sx={{ zIndex: 100001 }}
    >
      <Container justify="center" padding="0">
        <ContainerItem width="100%" justify="center" padding="0">
          <CommentIcon style={{ width: '' }} />
        </ContainerItem>
        <ContainerItem width="100%" justify="center">
          <Header type="h3" content="Post External Comment" />
        </ContainerItem>
        <ContainerItem width="100%" justify="center">
          <Text
            type="body1"
            content={
              presenter.isCrbApp
                ? 'You are about to post this comment externally, making it visible to others outside your organization. Your account\'s relationship manager or an admin will be copied on all external comments. To mention a specific user, use the "@" symbol.'
                : 'You are about to post this comment externally, making it visible to others outside your organization. Mention users with the "@" symbol to ensure they receive a notification.'
            }
          />
        </ContainerItem>
        <ContainerItem width="100%" justify="center">
          <Text type="body1" content="Are you sure you want to proceed?" />
        </ContainerItem>
        <ContainerItem align="center" justify="center" width="335px" margin="2rem 0 0 0" padding="0">
          <Button
            color="primary"
            label={'Confirm'}
            onClick={() => presenter.postComment()}
            fullWidth
            className={POPOVER_CLASS_NAME}
          ></Button>
        </ContainerItem>
        <ContainerItem align="center" justify="center" width="335px" margin=".25rem 0 0 0" padding="0">
          <Button
            color="default-text"
            label={'Cancel'}
            onClick={() => presenter.openExternalConfirmationModal(false)}
            className={POPOVER_CLASS_NAME}
            fullWidth
          ></Button>
        </ContainerItem>
      </Container>
    </Dialog>
  );
});
