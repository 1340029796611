import type { MinifiedTask, TaskInputRequest, TaskInternalExternal, TaskResponse } from '@gcv/shared';
import { TaskStatus } from '@gcv/shared';
import { injectable } from 'inversify';
import qs from 'qs';

import { api, banksApi } from './api-util/api';

@injectable()
export class TasksApi {
  async getBankTasks(
    bankId: string,
    currentUserOrgId: string,
    status: TaskStatus,
    start: string,
    end: string
  ): Promise<TaskResponse> {
    let urlParams = `?org_id=${currentUserOrgId}&status=${status}`;

    if (status === TaskStatus.OPEN) {
      urlParams += `&date_created[start_date]=${start}&date_created[end_date]=${end}`;
    } else {
      urlParams += `&completed_on[start_date]=${start}&completed_on[end_date]=${end}`;
    }

    return await banksApi().get(`/banks/${bankId}/tasks` + urlParams);
  }

  async getBankTasksV2(
    bankId: string,
    currentUserOrgId: string,
    taskDirection: TaskInternalExternal,
    start: string,
    end: string,
    status: TaskStatus[]
  ): Promise<TaskResponse> {
    return await banksApi().get(`/banks/${bankId}/tasks`, {
      status,
      org_id: currentUserOrgId,
      internal_or_external: taskDirection,
      'date_created[start_date]': start,
      'date_created[end_date]': end
    });
  }

  async getTaskById(bankId: string, taskId: string): Promise<MinifiedTask[]> {
    return await banksApi().get(`/banks/${bankId}/tasks/${taskId}`);
  }

  async getTasks(bankId: string, orgId: string, status: TaskStatus): Promise<TaskResponse> {
    return await banksApi().get(`/banks/${bankId}/tasks?org_id=${orgId}&status=${status}`);
  }

  async createNewTask(bankId: string, task: TaskInputRequest): Promise<MinifiedTask> {
    return await banksApi().post(`/banks/${bankId}/tasks`, task);
  }

  async deleteTask(bankId: string, taskId: string): Promise<void> {
    return await banksApi().delete(`/banks/${bankId}/tasks/${taskId}`);
  }

  async updateFiTask(
    bankId: string,
    taskId: string,
    status: string,
    completed_note: string,
    completed_by_user_id: string,
    completed_on: string
  ): Promise<MinifiedTask[]> {
    return await banksApi().put(`/banks/${bankId}/tasks/${taskId}`, {
      status,
      completed_note: completed_note ?? '',
      completed_by_user_id,
      completed_on
    });
  }

  async updateCrbTask(
    crbId: string,
    taskId: string,
    status: string,
    completed_note: string,
    completed_by_user_id: string,
    completed_on: string
  ): Promise<MinifiedTask[]> {
    return await api().put(`/crbs/${crbId}/tasks/${taskId}`, {
      status,
      completed_note: completed_note ?? '',
      completed_by_user_id,
      completed_on
    });
  }

  async getCrbTasks(crbId: string, status: TaskStatus[]): Promise<TaskResponse> {
    const urlParams = qs.stringify({
      status: status,
      org_id: crbId
    });

    return await api().get(`/crbs/${crbId}/tasks?${urlParams}`);
  }

  async getCrbOpenTasksStatus(crbId: string): Promise<{ has_open_tasks: boolean; open_tasks_count: number }> {
    return await api().get(`/crbs/${crbId}/open-tasks-status`);
  }
}
