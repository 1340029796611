import * as React from 'react';
import type { RootComment } from '@gcv/shared';
import { Box } from '@mui/material';

import { environment } from 'environments/environment';
import { Button, Label, Text } from 'ui/atoms';
import { DocumentUpload } from 'ui/atoms/DocumentUpload/document-upload.atom';
import { theme } from 'ui/theme';
import { removeUuid } from 'util/format.util';

interface Props {
  comment: RootComment;
}

export const CommentContentBody: React.FC<Props> = (props) => {
  const [expandedDocs, setExpandedDocs] = React.useState(false);
  const [expandedText, setExpandedText] = React.useState(false);

  // Parse the string that contains mentions.  Mentions are bound by [@@@].
  // After splitting, all odd chunks will be names, all even chunks will be regular text.
  // The name chunks will be of the format [@@@]{displayName}({id})[@@@] where the id will be removed by splitting on '('
  // We will cut off regular text in the middle if over char limit, but will avoid splitting names as that would be weird UX
  const initialVisibleChars = 100;
  const seeAllChunks: string[] = removeUuid(props.comment.content).split('[@@@]');
  const seeLessChunks: string[] = [];

  let tempStringLength = 0;

  seeAllChunks.forEach((chunk, index) => {
    if (index % 2 !== 0) {
      chunk = chunk.split('(')[0];
    }
    if (tempStringLength + chunk.length < initialVisibleChars) {
      tempStringLength = tempStringLength + chunk.length;
      seeLessChunks.push(chunk);
    } else {
      if (index % 2 === 0) {
        const lengthLeft = initialVisibleChars - tempStringLength;
        const partialChunk = chunk.slice(0, lengthLeft);
        seeLessChunks.push(partialChunk);
      }
    }
  });

  return (
    <>
      <Box
        sx={{
          '& a': {
            textDecoration: 'none'
          },
          '& .text': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            color: theme.palette.text.primary
          }
        }}
      >
        {!expandedText ? (
          <>
            {seeLessChunks.map((content, index) =>
              index % 2 === 0 ? (
                <Text content={content} type="body2" inline key={content + index} />
              ) : (
                <Text
                  sx={{
                    color: theme.palette.primary.main
                  }}
                  key={content + index}
                  content={content}
                  type="body2"
                  inline
                />
              )
            )}
            {props.comment.content.length > initialVisibleChars ? (
              <>
                <Text content={'...   '} type="body2" inline />
                <span
                  style={{ cursor: 'pointer', marginLeft: '6px' }}
                  onClick={() => setExpandedText(!expandedText)}
                >
                  <Label inline content="Show More" type="subtitle2" sx={{ fontSize: '13px' }} />
                </span>
              </>
            ) : null}
          </>
        ) : (
          <>
            {seeAllChunks.map((content, index) =>
              index % 2 === 0 ? (
                <Text content={content} type="body2" inline key={content + index} />
              ) : (
                <Text
                  sx={{
                    color: theme.palette.primary.main
                  }}
                  key={content + index}
                  content={content}
                  type="body2"
                  inline
                />
              )
            )}
            <span
              style={{ cursor: 'pointer', marginLeft: '6px' }}
              onClick={() => setExpandedText(!expandedText)}
            >
              <Label inline content="Show Less" type="subtitle2" sx={{ fontSize: '13px' }} />
            </span>
          </>
        )}
      </Box>
      {expandedDocs ? (
        <>
          {props.comment.docs?.map((file) => {
            return (
              <DocumentUpload
                fileBucket={environment.storageConfig.commentDocument}
                key={file.s3_key}
                documentUpload={file}
                fullWidth={true}
                borderNone
                viewOnly
                sx={{
                  backgroundColor: '#F2F4FA',
                  borderRadius: '6px',
                  padding: '0 2px',
                  margin: '2px 0'
                }}
              />
            );
          })}
          <Button label="Show Less" onClick={() => setExpandedDocs(!expandedDocs)} color="default-text" />
        </>
      ) : props?.comment?.docs?.length ? (
        <div style={{ marginTop: '8px' }}>
          {props.comment.docs?.slice(0, 3).map((file) => {
            return (
              <DocumentUpload
                fileBucket={environment.storageConfig.commentDocument}
                key={file.s3_key}
                documentUpload={file}
                fullWidth={true}
                borderNone
                viewOnly
                sx={{
                  backgroundColor: '#F2F4FA',
                  borderRadius: '6px',
                  padding: '0 2px',
                  margin: '2px 0'
                }}
              />
            );
          })}
          {props.comment.docs && props.comment.docs?.length > 3 ? (
            <Button label="Show More" onClick={() => setExpandedDocs(!expandedDocs)} color="default-text" />
          ) : null}
        </div>
      ) : null}
    </>
  );
};
