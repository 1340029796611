import React from 'react';
import { TablePagination as MaterialTablePagination, Typography } from '@mui/material';

interface Props {
  rowsPerPage: number;
  page: number;
  defaultRowCount: number;
  rowsCount: number;
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleRowsPerPageChange: (value: number) => void;
  minWidth?: string | number;
}

export const TablePagination = (props: Props) => {
  return (
    <MaterialTablePagination
      rowsPerPageOptions={[5, 10, 25, 50]}
      count={props.rowsCount}
      rowsPerPage={props.rowsPerPage}
      component="div"
      labelRowsPerPage={
        <span style={{ display: 'flex', alignItems: 'center', width: '220px' }}>
          <span>
            <Typography
              component={'span'}
              sx={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '13px',
                lineHeight: '18px',
                textAlign: 'right',
                color: (theme) => theme.palette.text.secondary,
                marginRight: '0.5rem'
              }}
            >
              View
            </Typography>
          </span>
          <span style={{ width: '40px' }}>
            {/* Empty div to hold a slot for pulling the rows per page dropdown to the left */}
          </span>
          <span>
            <Typography
              component={'span'}
              sx={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '13px',
                lineHeight: '18px',
                textAlign: 'right',
                color: (theme) => theme.palette.text.secondary,
                marginRight: '0.5rem'
              }}
            >
              Rows per page
            </Typography>
          </span>
        </span>
      }
      page={props.page}
      onPageChange={props.handlePageChange}
      onRowsPerPageChange={(e) => props.handleRowsPerPageChange(Number(e.target.value))}
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        minWidth: props.minWidth ? props.minWidth : 'none',
        '& .MuiTablePagination-spacer': {
          flex: 'unset'
        },
        '& .MuiTablePagination-displayedRows': {
          flex: '1 1 100%',
          textAlign: 'right'
        },
        '& .MuiTablePagination-input': {
          marginLeft: '-192px',
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '13px',
          lineHeight: '18px',
          color: (theme) => theme.palette.text.secondary
        }
      }}
      labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
        return (
          <Typography
            component={'span'}
            sx={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '13px',
              lineHeight: '18px',
              textAlign: 'right',
              color: (theme) => theme.palette.text.secondary
            }}
          >
            {`Showing ${from} to ${to} of ${count} entries`}
          </Typography>
        );
      }}
    />
  );
};
