import type {
  Bank,
  InitialUserInfo,
  InviteNewUserRequest,
  MinifiedUser,
  PartialDispensaryForGcvAdmin,
  User
} from '@gcv/shared';
import { OrganizationType } from '@gcv/shared';
import { action, makeAutoObservable, runInAction } from 'mobx';

import { BanksApi, DispensariesApi, GcvAdminApi, UsersApi } from 'api';
import { getObjectMap } from 'util/objectUtils';
import { getSnackbarStore, SnackbarSeverity } from './SnackBarStore';

class GcvAdminStore {
  dispensaries: { [id: string]: PartialDispensaryForGcvAdmin } = {};
  banks: { [id: string]: Bank } = {};
  users: { [id: string]: MinifiedUser } = {};
  isLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  dispensariesApi = new DispensariesApi();
  usersApi = new UsersApi();
  banksApi = new BanksApi();
  gcvApi = new GcvAdminApi();
  snackbarStore = getSnackbarStore();

  loadStore = action(async () => {
    const promises = [
      this.dispensariesApi
        .getAllDispensaries()
        .then((dispensaries) => {
          runInAction(() => {
            this.dispensaries = getObjectMap(dispensaries, 'id');
          });
        })
        .catch(() => {
          this.snackbarStore.showErrorSnackbarMessage(
            'Failed to load CRBs! Let support know! Some features may be broken!'
          );
        }),
      this.gcvApi
        .getAllUsers()
        .then((users) => {
          runInAction(() => {
            this.users = getObjectMap(users, 'id');
          });
        })
        .catch(() => {
          this.snackbarStore.showErrorSnackbarMessage(
            'Failed to load users! Let support know! Some features may be broken!'
          );
        }),
      ,
      this.banksApi
        .getBanks()
        .then((banks) => {
          runInAction(() => {
            this.banks = getObjectMap(banks, 'id');
          });
        })
        .catch(() => {
          this.snackbarStore.showErrorSnackbarMessage(
            'Failed to load FIs! Let support know! Some features may be broken!'
          );
        })
    ];

    await Promise.all(promises).then(() => {
      runInAction(() => {
        this.isLoaded = true;
      });
    });
  });

  clearStore = action(() => {
    this.dispensaries = {};
    this.banks = {};
    this.users = {};
    this.isLoaded = false;
  });

  addUser = action(async (companyType: OrganizationType, companyId: string, userInfo: InitialUserInfo) => {
    try {
      let user: User;
      const request: InviteNewUserRequest = {
        userInfo
      };
      switch (companyType) {
        case OrganizationType.BANK:
          user = (await this.banksApi.inviteBankUser(companyId, request)).user;
          break;
        case OrganizationType.DISPENSARY:
          user = (await this.dispensariesApi.inviteDispensaryUser(companyId, request)).user;
          break;
        case OrganizationType.GCV:
          user = (await this.gcvApi.inviteGcvUser(request)).user;
          break;
      }
      runInAction(() => {
        const newUsers = { ...this.users };
        newUsers[user.id] = user;
        this.users = newUsers;
        this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'User has been invited successfully.');
      });
    } catch (e) {
      getSnackbarStore().showSnackbar(SnackbarSeverity.Error, 'There was an issue creating the user.');
    }
  });

  updateUser = action((user: User) => {
    const newUsers = { ...this.users };
    newUsers[user.id] = user;
    this.users = newUsers;
  });

  deleteUser = action((userId: string) => {
    this.usersApi
      .deleteUser(userId)
      .then(() => {
        const newUsers = { ...this.users };
        delete newUsers[userId];
        this.users = newUsers;
        this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'User has been deleted successfully.');
      })
      .catch(() => {
        this.snackbarStore.showSnackbar(SnackbarSeverity.Error, 'There was an issue deleting user.');
      });
  });

  addBank = action((bank: Bank) => {
    const newBanks = { ...this.banks };
    newBanks[bank.id] = bank;
    this.banks = newBanks;
  });

  updateBank = action((bank: Bank) => {
    const newBanks = { ...this.banks };
    newBanks[bank.id] = bank;
    this.banks = newBanks;
  });

  addDispensary = action((dispensary: PartialDispensaryForGcvAdmin) => {
    const newDispensaries = { ...this.dispensaries };
    newDispensaries[dispensary.id] = { ...dispensary };
    this.dispensaries = newDispensaries;
  });
}

let store: GcvAdminStore | undefined;

export function getGcvAdminStore(): GcvAdminStore {
  if (!store) {
    store = new GcvAdminStore();
  }

  return store;
}
