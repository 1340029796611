import { createTheme } from '@mui/material';

import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const baseTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1728
    }
  },
  components: {
    ...overrides
  },
  palette,
  typography: {
    fontFamily: 'Lato, Helvetica, Arial, san-serif',
    ...typography
  }
};

export const theme = createTheme(baseTheme);
