import { useState } from 'react';
import React from 'react';
import type { DocumentUpload as DocumentUploadType } from '@gcv/shared';

import { Label } from 'ui/atoms';
import { DocumentUpload } from 'ui/atoms/DocumentUpload/document-upload.atom';
import { InputFile } from '../../molecules/InputFile/input-file.molecule';

interface Props {
  fileBucket: string;
  existingS3Files: DocumentUploadType[];
  onRemoveExistingFile?: (file: DocumentUploadType) => void;
  onNewFilesChange?: (files: File[]) => void;
  acceptedTypes?: 'all' | 'csv' | 'images' | 'imagesAndPdfs' | 'xml';
  multiple?: boolean;
  existingFilesLabel?: string;
  uploadFilesLabel?: string;
  readOnly?: boolean;
  viewOnly?: boolean;
  fullWidth?: boolean;
  dataCy?: string;
  newFilesPadding?: string; // added this prop because, the existingFiles & newFiles are not aligned properly in Custom Field Questionnaire modal
  hardResetComponent?: boolean; // to reset the FileContainer when checked Create Another Field check box as data needs to be cleared
  triggerRefresh?: (value: boolean) => void;
  disableFileDelete?: boolean;
}

export const FileContainer: React.FC<Props> = (props) => {
  const [existingS3Files, setExistingS3Files] = useState(props.existingS3Files);
  const [tempFiles, setTempFiles] = useState<File[]>([]);

  // to reset the FileContainer when checked Create Another Field check box as data needs to be cleared
  const resetFileContainer = () => {
    setExistingS3Files([]);
    if (props.triggerRefresh) props.triggerRefresh(false);
  };

  React.useEffect(() => {
    if (props.hardResetComponent) {
      resetFileContainer();
    }
  }, [props.hardResetComponent]);

  const removeFile = (index: number, file: DocumentUploadType) => {
    if (props.onRemoveExistingFile) {
      const newExistingS3Files = existingS3Files.slice();
      newExistingS3Files.splice(index, 1);
      setExistingS3Files(newExistingS3Files);
      props.onRemoveExistingFile(file);
    }
  };

  React.useEffect(() => {
    setExistingS3Files(props.existingS3Files);
  }, [props.existingS3Files]);

  return (
    <div data-cy={props.dataCy} style={{ width: '100%' }}>
      {existingS3Files.length ? (
        <div data-cy="existing-files">
          {props.existingFilesLabel ? (
            <Label
              content={props.existingFilesLabel}
              type="subtitle1"
              sx={{ margin: props.newFilesPadding ? '0.5rem 0' : '1rem 0' }}
              dataCy="existing-files-title"
            />
          ) : null}
          <div style={{ width: '100%', maxHeight: '200px', overflow: 'auto' }}>
            {existingS3Files.map((file, index) => (
              <DocumentUpload
                disableFileDelete={props.disableFileDelete}
                dataCy="existing-file"
                fileBucket={props.fileBucket}
                key={file.s3_key}
                documentUpload={file}
                onRemoveFile={() => {
                  !props.readOnly && removeFile(index, file);
                }}
                fullWidth={props.fullWidth}
                viewOnly={props.viewOnly}
                readOnly={props.readOnly}
                borderNone={index === existingS3Files.length - 1}
              />
            ))}
          </div>
        </div>
      ) : null}
      {(!existingS3Files.length || (existingS3Files.length && props.multiple)) && !props.readOnly ? (
        <div
          data-cy="file-container-upload"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
        >
          {props.uploadFilesLabel ? (
            <Label
              content={props.uploadFilesLabel}
              sx={{ margin: props.newFilesPadding ? '0.5rem 0' : '1rem 0' }}
              type="subtitle1"
              dataCy="file-container-upload-title"
            />
          ) : null}
          <InputFile
            hardResetComponent={props.hardResetComponent}
            newFilesPadding={props.newFilesPadding} // added this prop because, the existingFiles & newFiles are not aligned properly in Custom Field Questionnaire modal .
            multiple={!!props.multiple}
            acceptedTypes={props.acceptedTypes || 'all'}
            onFilesChange={(files) => {
              setTempFiles(files);
              if (props.onNewFilesChange) {
                props.onNewFilesChange(files);
              }
            }}
            dataCy={'file-container-upload-input'}
            fullWidth={props.fullWidth}
          />
        </div>
      ) : null}
    </div>
  );
};
