import useWebSocket from 'react-use-websocket';
import type { GreenCheckWebSocketMessage } from '@gcv/shared';

import { environment } from '../environments/environment';
import { getNotificationStore } from '../stores/NotificationStore';

/**
 * Consolidated implementation of websockets
 *
 * ref: https://www.npmjs.com/package/react-use-websocket
 */
export const useGcvWebSocket = (userId: string) => {
  const notificationStore = getNotificationStore();
  const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState, getWebSocket } =
    useWebSocket(`${environment.gcvConfig.webSocketUrl}`, {
      queryParams: { token: userId },
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: () => true,
      // If set to true, a new WebSocket will not be instantiated if one for the same url has already
      // been created for another component. Once all subscribing components have either unmounted or
      // changed their target socket url, shared WebSockets will be closed and cleaned up.
      // No other APIs should be affected by this.
      share: true,
      onMessage: (event) => {
        // parse the message and
        const socketMessage = JSON.parse(event.data) as GreenCheckWebSocketMessage;
        // save it to the store for other pages to react to
        notificationStore.handleWebsocket(socketMessage);
      }
    });

  return { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState, getWebSocket };
};
