import * as React from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { Container, ContainerItem, Form, Header, InputPhone, InputUrl } from 'ui';

interface Props {
  form: UseFormReturn<FieldValues, any>;
  isMobile: boolean;
  onChange: () => void;
}

export const ContactCard: React.FC<Props> = (props) => {
  return (
    <Container padding="28px 0" dataCy="contact-card">
      <Container padding="0 0 8px 0">
        <Header mobileHeader={props.isMobile} content="Contact Information" type="h2" />
      </Container>
      <Form>
        <Container column={props.isMobile} padding={0} width="100%">
          <ContainerItem
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputPhone
              {...props.form}
              name="phone"
              label="Phone"
              rules={{
                required: { message: 'is required', value: true }
              }}
              dataCy="phone"
              onChange={props.onChange}
              onBlur={props.onChange}
            />
          </ContainerItem>
          <ContainerItem
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputUrl
              {...props.form}
              name="website"
              label="Business Website"
              dataCy="website"
              onChange={props.onChange}
              onBlur={props.onChange}
            />
          </ContainerItem>
        </Container>
      </Form>
    </Container>
  );
};
