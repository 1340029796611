import { createHash } from 'crypto';
import { injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';

import type { FilterListChild, FilterListItem, Order } from 'ui';

export interface TimeRange {
  start: string;
  end: string;
}

export enum TimePeriodValue {
  today = 'today',
  yesterday = 'yesterday',
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  thisQuarter = 'thisQuarter',
  lastQuarter = 'lastQuarter',
  thisYear = 'thisYear',
  lastYear = 'lastYear',
  last7Days = 'last7Days',
  last30Days = 'last30Days',
  custom = 'custom'
}

export interface TimePeriod {
  dateRange: TimeRange;
  timeRange: TimeRange;
  value: TimePeriodValue | '';
}

export interface PaginationFilters {
  page: number;
  pageSize: number;
  defaultSort: string;
  defaultSortDirection: Order;
}

export interface PageFilters extends TimePeriod {
  selectedFilters: FilterListChild[];
  searchTerm: string;
}

export enum AppPage {
  dashboard = 'dashboard',
  dailySummaries = 'daily-summaries',
  accountActivity = 'account-activity',
  monitoring = 'monitoring',
  fincen = 'fincen',
  data = 'data',
  tasks = 'tasks'
}

const hashTimePeriod = (period: TimePeriod) => {
  const serialize = (p: TimePeriod) => {
    const data: string[] = [];
    data.push(p.dateRange.start);
    data.push(p.dateRange.end);
    data.push(p.timeRange.start);
    data.push(p.timeRange.end);
    data.push(p.value);
    return data;
  };

  const hash = createHash('sha256');
  const data = serialize(period);
  data.forEach((s) => hash.update(s));

  return hash.digest('hex');
};

export const isEqualTimePeriod = (first: TimePeriod, second: TimePeriod) => {
  return hashTimePeriod(first) === hashTimePeriod(second);
};

@injectable()
export class AppViewStateStore {
  dashboardAccounts: FilterListItem[] = [];

  dashboardTimePeriod: TimePeriod = {
    dateRange: { start: '', end: '' },
    timeRange: { start: '', end: '' },
    value: ''
  };

  dailySummariesAccounts: FilterListItem[] = [];

  dailySummariesTimePeriod: TimePeriod = {
    dateRange: { start: '', end: '' },
    timeRange: { start: '', end: '' },
    value: ''
  };

  dailySummariesTable: PaginationFilters = {
    page: 0,
    pageSize: 10,
    defaultSort: '',
    defaultSortDirection: 'asc'
  };

  depositsFilters: { accountsFilters: FilterListChild[]; statusFilters: FilterListChild[] } = {
    accountsFilters: [],
    statusFilters: []
  };

  depositsTable: PaginationFilters = {
    page: 0,
    pageSize: 10,
    defaultSort: '',
    defaultSortDirection: 'desc'
  };

  accountActivityTimePeriod: TimePeriod = {
    dateRange: { start: '', end: '' },
    timeRange: { start: '', end: '' },
    value: TimePeriodValue.last30Days
  };

  archivedAccountRelationshipManagerFilters: {
    selectedAccountFilters: FilterListChild[];
  } = {
    selectedAccountFilters: []
  };

  defaultAccountPaginationFilters: { [tableName: string]: PaginationFilters } = {
    active: {
      page: 0,
      pageSize: 10,
      defaultSort: '',
      defaultSortDirection: 'asc'
    },
    reviewInProgress: {
      page: 0,
      pageSize: 10,
      defaultSort: '',
      defaultSortDirection: 'desc'
    },
    awaitingReview: {
      page: 0,
      pageSize: 10,
      defaultSort: '',
      defaultSortDirection: 'desc'
    },
    invited: {
      page: 0,
      pageSize: 10,
      defaultSort: '',
      defaultSortDirection: 'desc'
    }
  };
  accountPaginationFilters = { ...this.defaultAccountPaginationFilters };
  accountsFilters: { [tabName: string]: FilterListChild[] } = {};

  defaultMonitoringPaginationFilters: { [tableName: string]: PaginationFilters } = {
    open: {
      page: 0,
      pageSize: 25,
      defaultSort: '',
      defaultSortDirection: 'asc'
    },
    completed: {
      page: 0,
      pageSize: 25,
      defaultSort: '',
      defaultSortDirection: 'asc'
    }
  };
  monitoringPaginationFilters = { ...this.defaultMonitoringPaginationFilters };
  monitoringFilters: { [tabName: string]: FilterListChild[] } = { openReviews: [], completedReviews: [] };
  monitoringTimePeriods: { [tabName: string]: TimePeriod } = {
    openReviews: {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.last30Days
    },
    completedReviews: {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.last30Days
    }
  };

  defaultFincenPaginationFilters: { [tableName: string]: PaginationFilters } = {
    open: {
      page: 0,
      pageSize: 25,
      defaultSort: '',
      defaultSortDirection: 'asc'
    },
    exports: {
      page: 0,
      pageSize: 25,
      defaultSort: '',
      defaultSortDirection: 'asc'
    },
    completed: {
      page: 0,
      pageSize: 25,
      defaultSort: '',
      defaultSortDirection: 'asc'
    },
    upcoming: {
      page: 0,
      pageSize: 25,
      defaultSort: '',
      defaultSortDirection: 'asc'
    }
  };
  fincenPaginationFilters = this.defaultFincenPaginationFilters;
  fincenFilters: { [tabName: string]: FilterListItem[] } = {};

  defaultDataPaginationFilters: { [tableName: string]: PaginationFilters } = {
    data_summary: {
      page: 0,
      pageSize: 25,
      defaultSort: '',
      defaultSortDirection: 'asc'
    },
    imports: {
      page: 0,
      pageSize: 25,
      defaultSort: '',
      defaultSortDirection: 'asc'
    },
    unmatched_transactions: {
      page: 0,
      pageSize: 25,
      defaultSort: '',
      defaultSortDirection: 'asc'
    },
    integrations: {
      page: 0,
      pageSize: 25,
      defaultSort: '',
      defaultSortDirection: 'asc'
    }
  };
  dataPaginationFilters = { ...this.defaultDataPaginationFilters };
  dataFilters: { [tabName: string]: FilterListChild[] } = {
    dataSummary: [],
    imports: [],
    unmatchedTransactions: [],
    integrations: []
  };
  dataTimePeriod: { [tabName: string]: TimePeriod } = {
    dataSummary: {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.thisWeek
    },
    imports: {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.thisWeek
    },
    unmatchedTransactions: {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.thisWeek
    },
    integrations: {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.thisWeek
    }
  };

  defaultTasksPaginationFilters: { [tableName: string]: PaginationFilters } = {
    internal: {
      page: 0,
      pageSize: 10,
      defaultSort: '',
      defaultSortDirection: 'asc'
    },
    external: {
      page: 0,
      pageSize: 10,
      defaultSort: '',
      defaultSortDirection: 'asc'
    }
  };
  tasksPaginationFilters = { ...this.defaultTasksPaginationFilters };
  tasksFilters: { [tabName: string]: FilterListChild[] } = { internal: [], external: [] };
  tasksSearchTerm = '';
  tasksTimePeriod: { [tabName: string]: TimePeriod } = {
    internal: {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.last30Days
    },
    external: {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.last30Days
    }
  };

  connectedOrg = false;

  constructor() {
    makeAutoObservable(this);
  }

  setConnectedOrg = action((value: boolean) => {
    this.connectedOrg = value;
  });

  setTimePeriod = action((data: TimePeriod, type: AppPage, currentTab?: string) => {
    switch (type) {
      case AppPage.dashboard: {
        runInAction(() => {
          this.dashboardTimePeriod = data;
          this.clearPaginationStore();
        });
        break;
      }
      case AppPage.dailySummaries: {
        runInAction(() => {
          this.dailySummariesTimePeriod = data;
          this.clearPaginationStore();
        });
        break;
      }
      case AppPage.accountActivity: {
        runInAction(() => {
          this.accountActivityTimePeriod = data;
        });
        break;
      }
      case AppPage.monitoring: {
        runInAction(() => {
          this.monitoringTimePeriods[currentTab ?? 'openReviews'] = data;
          this.clearPaginationStore();
        });
        break;
      }
      case AppPage.data: {
        runInAction(() => {
          this.dataTimePeriod[currentTab ?? 'dataSummary'] = data;
          this.clearPaginationStore();
        });
        break;
      }
      case AppPage.tasks: {
        runInAction(() => {
          this.tasksTimePeriod[currentTab ?? 'internal'] = data;
          this.clearPaginationStore();
        });
        break;
      }
      default: {
        break;
      }
    }
  });

  getTimePeriod = (type: string, currentTab?: string) => {
    switch (type) {
      case AppPage.dashboard: {
        return this.dashboardTimePeriod;
      }
      case AppPage.dailySummaries: {
        return this.dailySummariesTimePeriod;
      }
      case AppPage.accountActivity: {
        return this.accountActivityTimePeriod;
      }
      case AppPage.monitoring: {
        return this.monitoringTimePeriods[currentTab ?? 'openReviews'];
      }
      case AppPage.data: {
        return this.dataTimePeriod[currentTab ?? 'dataSummary'];
      }
      case AppPage.tasks: {
        return this.tasksTimePeriod[currentTab ?? 'internal'];
      }
      default: {
        return;
      }
    }
  };

  clearPaginationStore = action(() => {
    this.accountPaginationFilters = {
      active: {
        ...this.accountPaginationFilters.active,
        page: 0,
        pageSize: 10
      },
      reviewInProgress: {
        ...this.accountPaginationFilters.reviewInProgress,
        page: 0,
        pageSize: 10
      },
      awaitingReview: {
        ...this.accountPaginationFilters.awaitingReview,
        page: 0,
        pageSize: 10
      },
      invited: {
        ...this.accountPaginationFilters.invited,
        page: 0,
        pageSize: 10
      }
    };

    this.depositsTable = {
      ...this.depositsTable,
      page: 0,
      pageSize: 10
    };

    this.dailySummariesTable = {
      ...this.dailySummariesTable,
      page: 0,
      pageSize: 10
    };

    this.fincenPaginationFilters = {
      open: {
        ...this.fincenPaginationFilters.open,
        page: 0,
        pageSize: 25
      },
      exports: {
        ...this.fincenPaginationFilters.exports,
        page: 0,
        pageSize: 25
      },
      completed: {
        ...this.fincenPaginationFilters.completed,
        page: 0,
        pageSize: 25
      },
      upcoming: {
        ...this.fincenPaginationFilters.upcoming,
        page: 0,
        pageSize: 25
      }
    };

    this.tasksPaginationFilters = {
      internal: {
        ...this.tasksPaginationFilters.internal,
        page: 0,
        pageSize: 10
      },
      external: {
        ...this.tasksPaginationFilters.external,
        page: 0,
        pageSize: 10
      }
    };

    this.monitoringPaginationFilters = {
      open: {
        ...this.monitoringPaginationFilters.open,
        page: 0,
        pageSize: 25
      },
      completed: {
        ...this.monitoringPaginationFilters.completed,
        page: 0,
        pageSize: 25
      }
    };

    this.dataPaginationFilters = {
      data_summary: {
        ...this.dataPaginationFilters.data_summary,
        page: 0,
        pageSize: 25
      },
      imports: {
        ...this.dataPaginationFilters.imports,
        page: 0,
        pageSize: 25
      },
      unmatched_transactions: {
        ...this.dataPaginationFilters.unmatched_transactions,
        page: 0,
        pageSize: 25
      },
      integrations: {
        ...this.dataPaginationFilters.integrations,
        page: 0,
        pageSize: 25
      }
    };
  });

  clearStore = action(() => {
    this.dashboardTimePeriod = {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.last30Days
    };
    this.dailySummariesTimePeriod = {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.last30Days
    };
    this.depositsFilters = {
      accountsFilters: [],
      statusFilters: []
    };
    this.accountActivityTimePeriod = {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: TimePeriodValue.last30Days
    };
  });
}

let store: AppViewStateStore | undefined;

export function getAppViewStateStore(): AppViewStateStore {
  if (!store) {
    store = new AppViewStateStore();
  }

  return store;
}
