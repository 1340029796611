import * as React from 'react';
import { Box } from '@mui/system';

import { Button } from 'ui/atoms';

interface Props {
  onCancel: () => void;
  onSave: () => void;
}

export const FilterFooter: React.FC<Props> = (props) => {
  return (
    <Box
      sx={{
        padding: '12px 22px',
        borderTop: '1px solid',
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <Box sx={{ marginRight: '13px' }}>
        <Button label={'Cancel'} color={'default-outlined'} onClick={props.onCancel} />
      </Box>
      <Button label={'Apply'} color={'primary'} onClick={props.onSave} />
    </Box>
  );
};
