import type { DeliveryMethod, Deposit, DepositTable, MinifiedDispensary, User } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';

import { BanksApi, DepositsApi, DispensariesApi, UsersApi } from 'api';
import { FiBankStore, getFiBankStore } from 'stores/FiBankStore';
import { FiDispensaryStore } from 'stores/FiDispensaryStore';
import { UserStore } from 'stores/UserStore';
import type { FilterListChild, Row } from 'ui';
import { DateTimeHelpers } from 'util/dateTime.util';

export interface DepositTableExtended extends DepositTable {
  arrived_date: string;
}

export interface DepositRow {
  id: string;
  dispensary_name: string;
  dispensary_id: string;
  deposit_amount: number;
  method_of_transportation: string;
  expected_arrival_date: string;
  arrived_date: string;
  status: 'pending' | 'accepted' | 'under_review';
}

export interface PM {
  currentDeposit: Deposit;
  currentDispensaryUsers: User[];
  defaultDeposits: DepositTableExtended[];
  depositRows: Row<DepositRow>[];
  deposits: DepositTableExtended[];
  dispensaries: MinifiedDispensary[];
  isCurrentDepositLoading: boolean;
  isDeletingDeposits: boolean;
  isLoading: boolean;
  isSavingBusinessDetails: boolean;
  isSavingOperationalDetails: boolean;
}

@injectable()
export class FiDepositsRepo {
  @inject(DispensariesApi)
  private dispensariesApi: DispensariesApi;

  @inject(BanksApi)
  private banksApi: BanksApi;

  @inject(UsersApi)
  private usersApi: UsersApi;

  @inject(DepositsApi)
  private depositsApi: DepositsApi;

  @inject(FiBankStore)
  private bankStore: FiBankStore;

  @inject(FiDispensaryStore)
  private dispensaryStore: FiDispensaryStore;

  @inject(UserStore)
  private userStore: UserStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = {
    currentDeposit: {} as Deposit,
    currentDispensaryUsers: [],
    defaultDeposits: [],
    depositRows: [],
    deposits: [],
    dispensaries: [],
    isCurrentDepositLoading: false,
    isDeletingDeposits: false,
    isLoading: false,
    isSavingBusinessDetails: false,
    isSavingOperationalDetails: false
  };

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = action(async () => {
    this.updateProgrammersModel({ dispensaries: this.dispensaryStore.dispensaries });
  });

  getDepositsTable = action(async (timeStatusTerm: FilterListChild) => {
    if (!timeStatusTerm.selectValue) {
      return [];
    }

    return await this.banksApi.getDepositsTable(
      this.bankStore.bank.id,
      timeStatusTerm.selectValue.dateRange.start,
      timeStatusTerm.selectValue.dateRange.end,
      timeStatusTerm.value
    );
  });

  getDepositById = action(async (depositId: string) => {
    try {
      this.updateProgrammersModel({ isCurrentDepositLoading: true });
      const deposit = await this.banksApi.getDepositById(this.bankStore.bank.id, depositId);
      const dispUsers = await this.usersApi.getUsersForOrg(deposit.dispensary_id);

      runInAction(() => {
        this.updateProgrammersModel({ currentDeposit: deposit, currentDispensaryUsers: dispUsers });
      });
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => {
        this.updateProgrammersModel({ isCurrentDepositLoading: false });
      });
    }
  });

  reconcileDeposit = action(
    async (
      reconcileRecord: {
        depositAmount: number;
        mot: string;
        dateRecieved: string;
        datePosted: string;
        description: string;
      },
      mots: DeliveryMethod[]
    ) => {
      const newDepositRecord = {
        ...this.programmersModel.currentDeposit,
        anticipated_delivery_method: this.programmersModel.currentDeposit.delMethod,
        final_deposit: reconcileRecord.depositAmount,
        delMethod: mots.find((mot) => mot.id === reconcileRecord.mot),
        arrived_date: reconcileRecord.dateRecieved,
        posted_date: reconcileRecord.datePosted,
        bank_comments: reconcileRecord.description,
        accepted_date: DateTimeHelpers.getUtcIsoString(),
        accepted_by_user: this.userStore.user.id
      };
      this.updateProgrammersModel({ isLoading: true });
      const updatedDeposit = await this.banksApi.reconcileDeposit(this.bankStore.bank.id, newDepositRecord);
      this.updateDepositStatus(updatedDeposit);
      this.updateProgrammersModel({ isLoading: false });
    }
  );

  editDepositStatus = action(async (status: string) => {
    try {
      this.updateProgrammersModel({ isLoading: true });
      const newDeposit = await this.banksApi.editDepositStatus(
        this.bankStore.bank.id,
        this.programmersModel.currentDeposit,
        status,
        DateTimeHelpers.getUtcIsoString()
      );
      this.updateProgrammersModel({ isLoading: false });
      this.updateDepositStatus(newDeposit);
    } catch (e) {
      console.log(e);
    }
  });

  deleteDeposit = action(async (depositIds: string[]) => {
    const bankId = getFiBankStore().bank.id;
    const deposits = [...this.programmersModel.deposits];

    try {
      this.updateProgrammersModel({ isDeletingDeposits: true });

      for (let index = 0; index < depositIds.length; index++) {
        const depositId = depositIds[index];
        await this.depositsApi.deleteDepositFi(bankId, depositId);

        const idx = deposits.findIndex((deposit) => deposit.deposit_id === depositId);
        deposits.splice(idx, 1);
      }
    } catch (error) {
      throw new Error('There was an issue deleting the deposit.');
    } finally {
      this.updateProgrammersModel({ isDeletingDeposits: false, deposits });
      this.load();
    }
  });

  updateDepositStatus = action((newDeposit: Deposit) => {
    const foundIdx = this.programmersModel.deposits.findIndex(
      (deposit) => deposit.deposit_id === newDeposit.deposit_id
    );

    const foundRowIdx = this.programmersModel.depositRows.findIndex(
      (deposit) => deposit.id === newDeposit.deposit_id
    );

    this.programmersModel.deposits[foundIdx].status = newDeposit.status || 'pending';
    this.programmersModel.depositRows[foundRowIdx].data.status = newDeposit.status || 'pending';

    //Updating the deposit amount
    this.programmersModel.deposits[foundIdx].final_deposit = newDeposit.final_deposit;
    this.programmersModel.depositRows[foundRowIdx].data.deposit_amount = newDeposit.final_deposit;

    //Updating the arriving date
    if (newDeposit.arrived_date) {
      this.programmersModel.deposits[foundIdx].arrived_date = newDeposit.arrived_date;
      this.programmersModel.depositRows[foundRowIdx].data.arrived_date = newDeposit.arrived_date;
    }

    //Updating the mode of transportation
    if (
      newDeposit.delMethod &&
      newDeposit.delMethod?.name !==
        this.programmersModel.depositRows[foundRowIdx].data.method_of_transportation
    ) {
      this.programmersModel.depositRows[foundRowIdx].data.method_of_transportation =
        newDeposit.delMethod.name;
      this.programmersModel.deposits[foundIdx].delMethod = newDeposit.delMethod;
    }

    if (this.programmersModel.currentDeposit.deposit_id === newDeposit.deposit_id) {
      this.updateProgrammersModel({ currentDeposit: newDeposit });
    }
  });

  makeRows = action((filteredTable: DepositTableExtended[]) => {
    this.updateProgrammersModel({ deposits: filteredTable });

    const depositRows = filteredTable.map((d: DepositTableExtended) => {
      return {
        id: d.deposit_id ? d.deposit_id : '',
        data: {
          id: d.deposit_id ? d.deposit_id : '',
          dispensary_name: d.dispensary_name,
          dispensary_id: d.dispensary_id,
          deposit_amount: d.final_deposit,
          method_of_transportation: d.delMethod ? d.delMethod.name : '',
          expected_arrival_date: d.expected_arrival_date,
          arrived_date: d.arrived_date,
          status: d.status
        }
      };
    });

    this.updateProgrammersModel({ depositRows: depositRows });
  });
}
