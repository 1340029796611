import * as React from 'react';
import { Icon as IconContainer } from '@mui/material';

interface Props {
  color?: 'primary' | 'secondary' | 'disabled' | 'action' | 'error' | 'info' | 'success' | 'warning';
  dataCy?: string;
  onClick?: (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => void;
  size?: number;
  type: string;
}

export const Icon: React.FC<Props> = (props) => {
  const color = props.color ? props.color : 'inherit';
  const size = props.size ? `${props.size}px` : 'inherit';

  return (
    <IconContainer
      color={color}
      data-cy={props.dataCy}
      fontSize="inherit"
      onClick={(...e) => {
        if (props.onClick) props.onClick(...e);
      }}
      sx={{ cursor: props.onClick ? 'pointer' : 'default', fontSize: size }}
    >
      {props.type}
    </IconContainer>
  );
};
