import * as React from 'react';
import type { IANATimezones } from '@gcv/shared';
import { CommentType } from '@gcv/shared';
import { AccordionDetails } from '@mui/material';

import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { CommentStore } from 'stores/CommentStore';
import { Container, ContainerItem, Spinner } from 'ui';
import { CommentsPresenter } from '../comments.presenter';
import { ActivityTab } from './comments-tab-activity';
import { ArchivedTab } from './comments-tab-archived';
import { CommentsTab } from './comments-tab-comments';
import { CommentsTabsHeader } from './comments-tabs-header';

interface Props extends Record<string, unknown> {
  bankId: string;
  iana_timezone: IANATimezones;
  orgId: string;
  viewFile?: (id: string) => void;
}

export const CommentsBarBody: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(CommentsPresenter);
  const commentStore = useInjection(CommentStore);

  const onViewFile = (docId: string) => {
    if (props.viewFile) {
      props.viewFile(docId);
    }

    commentStore.openComments(false);
  };

  return (
    <AccordionDetails>
      <Container column width="100%">
        <ContainerItem padding={0} width="100%">
          <Container width="100%" padding={0} align="center">
            <ContainerItem grow padding="0px">
              <Container padding="0" align="center">
                <ContainerItem padding="0px">
                  <CommentsTabsHeader title="Comments" type={'comments'} />
                </ContainerItem>

                {(commentStore.type === CommentType.REQUIREMENT ||
                  commentStore.type === CommentType.REQUIREMENT_DOCUMENT) && (
                  <ContainerItem padding="0 0 0 8px">
                    <CommentsTabsHeader title="Activity" type={'activity'} />
                  </ContainerItem>
                )}

                <ContainerItem padding="0 0 0 8px">
                  <CommentsTabsHeader title="Archived" type={'archived'} />
                </ContainerItem>
              </Container>
            </ContainerItem>
          </Container>
        </ContainerItem>

        <ContainerItem width="100%">
          {presenter.commentBarLoading ? (
            <Spinner />
          ) : commentStore.activeTab === 'activity' ? (
            <ActivityTab viewFile={onViewFile} iana_timezone={props.iana_timezone} />
          ) : commentStore.activeTab === 'archived' ? (
            <ArchivedTab bankId={props.bankId} iana_timezone={props.iana_timezone} orgId={props.orgId} />
          ) : commentStore.activeTab === 'comments' ? (
            <CommentsTab bankId={props.bankId} iana_timezone={props.iana_timezone} orgId={props.orgId} />
          ) : null}
        </ContainerItem>
      </Container>
    </AccordionDetails>
  );
});
