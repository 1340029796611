import * as React from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { USStateOptions } from 'domain/consts';
import { IANATimezoneOptions } from 'domain/consts/timezones';
import { useInjection } from 'ioc';
import { Container, ContainerItem, Form, Header, InputCheckBox, InputSelect, InputText, InputZip } from 'ui';
import { BusinessDetailsPresenter } from '../business-details-presenter';

interface Props {
  form: UseFormReturn<FieldValues, any>;
  isMobile: boolean;
  onChange: () => void;
}

export const AddressCard: React.FC<Props> = (props) => {
  const presenter = useInjection(BusinessDetailsPresenter);
  const [sameMailingAddress, setSameMailingAddress] = React.useState(
    !presenter.crbDispensaryStore.currentDispensary.mailingStreetAddress ||
      presenter.crbDispensaryStore.currentDispensary.streetAddress ===
        presenter.crbDispensaryStore.currentDispensary.mailingStreetAddress
  );

  const handleMailingAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSameMailingAddress(event.target.checked);
  };

  return (
    <Container padding="28px 0 0 0" dataCy="address-card">
      <Container padding="0 0 8px 0">
        <Header mobileHeader={props.isMobile} content="Registered Entity Address" type="h2" />
      </Container>
      <Form>
        <Container column={props.isMobile} padding={0} width="100%">
          <ContainerItem
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputText
              name="streetAddress"
              label="Address"
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="street-address"
              onChange={props.onChange}
              onBlur={props.onChange}
              {...props.form}
            />
          </ContainerItem>
          <ContainerItem
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputText
              name="city"
              label="City"
              rules={{
                required: { message: 'is required', value: true },
                pattern: { message: 'must be valid', value: /^[a-zA-Z ]+$/ }
              }}
              dataCy="city"
              onChange={props.onChange}
              onBlur={props.onChange}
              {...props.form}
            />
          </ContainerItem>
          <ContainerItem
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputSelect
              {...props.form}
              readOnly
              dataCy="state"
              label="State"
              name="state"
              options={USStateOptions}
              rules={{ required: { message: 'is required', value: true } }}
              tooltip="Please contact Green Check support to update the State."
              additionalOnChange={props.onChange}
              onBlur={props.onChange}
            />
          </ContainerItem>
          <ContainerItem
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputZip
              name="postalCode"
              placeholder=" " // added a whitespace to remove the placeholder instead of adding a additional prop
              label="Zip Code"
              rules={{
                required: { message: 'is required', value: true }
              }}
              dataCy="postal-code"
              onChange={props.onChange}
              onBlur={props.onChange}
              {...props.form}
            />
          </ContainerItem>
          <ContainerItem
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputSelect
              name="iana_timezone"
              label="Timezone"
              options={IANATimezoneOptions}
              rules={{
                required: { message: 'is required', value: true }
              }}
              dataCy="timezone"
              additionalOnChange={props.onChange}
              onBlur={props.onChange}
              {...props.form}
            />
          </ContainerItem>
        </Container>
      </Form>
      <Form>
        <Container padding="0" width="100%">
          <ContainerItem padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}>
            <InputCheckBox
              label="Mailing Address Same as above"
              name="sameMailingAddress"
              onChange={handleMailingAddress}
              dataCy="same-mailing-address-checkbox"
              {...props.form}
            />
          </ContainerItem>
        </Container>
      </Form>

      <Form>
        <Container padding={0} width="100%">
          {(!sameMailingAddress ||
            (!sameMailingAddress && presenter.crbDispensaryStore.currentDispensary.mailingStreetAddress)) && (
            <>
              <ContainerItem
                padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
                width="100%"
                maxWidth={props.isMobile ? 'none' : '20%'}
                minWidth={props.isMobile ? 'none' : '210px'}
              >
                <InputText
                  name="mailingStreetAddress"
                  label="Mailing Address"
                  rules={{ required: { message: 'is required', value: true } }}
                  dataCy="mailing-street-address"
                  onChange={props.onChange}
                  onBlur={props.onChange}
                  {...props.form}
                />
              </ContainerItem>
              <ContainerItem
                padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
                width="100%"
                maxWidth={props.isMobile ? 'none' : '20%'}
                minWidth={props.isMobile ? 'none' : '210px'}
              >
                <InputText
                  name="mailingCity"
                  label="Mailing City"
                  rules={{
                    required: { message: 'is required', value: true },
                    pattern: { message: 'must be valid', value: /^[a-zA-Z ]+$/ }
                  }}
                  dataCy="mailing-city"
                  onChange={props.onChange}
                  onBlur={props.onChange}
                  {...props.form}
                />
              </ContainerItem>
              <ContainerItem
                padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
                width="100%"
                maxWidth={props.isMobile ? 'none' : '20%'}
                minWidth={props.isMobile ? 'none' : '210px'}
              >
                <InputSelect
                  name="mailingState"
                  label="Mailing State"
                  options={USStateOptions}
                  rules={{ required: { message: 'is required', value: true } }}
                  dataCy="mailing-state"
                  additionalOnChange={props.onChange}
                  onBlur={props.onChange}
                  {...props.form}
                />
              </ContainerItem>
              <ContainerItem
                padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
                width="100%"
                maxWidth={props.isMobile ? 'none' : '20%'}
                minWidth={props.isMobile ? 'none' : '210px'}
              >
                <InputZip
                  name="mailingPostalCode"
                  placeholder=" "
                  label="Mailing Zip Code"
                  rules={{
                    required: { message: 'is required', value: true }
                  }}
                  dataCy="mailing-postal-code"
                  onChange={props.onChange}
                  onBlur={props.onChange}
                  {...props.form}
                />
              </ContainerItem>
            </>
          )}
        </Container>
      </Form>
    </Container>
  );
};
