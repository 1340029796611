import { Box } from '@mui/material';

import type { TimePeriod } from 'stores/AppViewStateStore';
import { getFiBankStore } from 'stores/FiBankStore';
import { ContainerItem, TimePeriodDropdown } from 'ui';

interface Props {
  label: string;
  emitData: (data: TimePeriod) => void;
  limitTimePeriodBy90Days?: boolean;
}

const TimePeriodReportsDropdown = (props: Props) => {
  return (
    <ContainerItem width="100%" padding={'0'}>
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          position: 'relative'
        }}
      >
        <TimePeriodDropdown
          limitTimePeriodBy90Days={props.limitTimePeriodBy90Days}
          defaultValue="today"
          label={props.label}
          verticalLabel={true}
          fullWidth={true}
          background="white"
          emitData={(data: TimePeriod) => {
            props.emitData(data);
          }}
          timezone={getFiBankStore().bank.iana_timezone}
        />
      </Box>
    </ContainerItem>
  );
};

export default TimePeriodReportsDropdown;
