import type { FieldValues } from 'react-hook-form';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

import { SnackbarStore } from 'stores/SnackBarStore';
import { CrbBankStore } from '../../../../../../stores/CrbBankStore';
import { CrbDispensaryStore } from '../../../../../../stores/CrbDispensaryStore';
import { OnboardingRepo } from '../../onboarding.repo';
import { BusinessDetailsRepo } from './business-details-repo';

export interface VM {
  serviceProviderName: string;
  isSaving: boolean;
}

@injectable()
export class BusinessDetailsPresenter {
  @inject(OnboardingRepo)
  public onboardingRepo: OnboardingRepo;

  @inject(BusinessDetailsRepo)
  public businessDetailsRepo: BusinessDetailsRepo;

  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(CrbDispensaryStore)
  public crbDispensaryStore: CrbDispensaryStore;

  @inject(CrbBankStore)
  public crbBankStore: CrbBankStore;

  constructor() {
    makeAutoObservable(this);
  }

  public viewModel: VM = {
    serviceProviderName: '',
    isSaving: false
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  public load = action((bankId: string) => {
    const serviceProviderName = this.crbBankStore.banks[bankId]?.orgName ?? 'Your Service provider';
    this.updateViewModel({ serviceProviderName });
  });

  autoSaveBusinessDetails = action(async (data: FieldValues) => {
    await this.businessDetailsRepo.autoSaveBusinessDetails(data);
  });

  submitBusinessDetails = action(async (data: FieldValues, onNext: () => void) => {
    this.updateViewModel({ isSaving: true });
    await this.businessDetailsRepo.updateBusinessDetails(data, onNext);
    this.updateViewModel({ isSaving: false });
  });
}
