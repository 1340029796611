import type {
  DataFetcherStrategy,
  DataFetcherWorkflowTypes,
  IntegrationTiming,
  IntegrationTimingMetaV3
} from '@gcv/shared';
import { PosType } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

import { GcvAdminApi } from 'api';
import { SnackbarStore } from 'stores/SnackBarStore';
import { getObjectMap } from 'util/objectUtils';

export interface GcvAccessTimingConfigsPM {
  isLoading: boolean;
  allTimingConfigsMap: { [posType: string]: IntegrationTimingMetaV3 };
}

@injectable()
export class GcvAccessTimingConfigsRepo {
  @inject(GcvAdminApi)
  private gcvAdminApi: GcvAdminApi;
  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  public gcvTimingConfigsProgrammersModel: GcvAccessTimingConfigsPM = {
    isLoading: true,
    allTimingConfigsMap: {}
  };

  load = action(async () => {
    this.loadAllTimingConfigs();
  });

  loadAllTimingConfigs = action(() => {
    this.gcvAdminApi
      .getAllTimingConfigs()
      .then((records) => {
        this.updateProgrammersModel({
          allTimingConfigsMap: getObjectMap(
            records.filter((t) => t.label !== PosType.CRBMonitor),
            'label'
          )
        });
      })
      .catch(() => {
        this.snackbarStore.showErrorSnackbarMessage('Failed to load timing configs');
      })
      .finally(() => {
        this.updateProgrammersModel({
          isLoading: false
        });
      });
  });

  updateProgrammersModel = action((programmersModel: Partial<GcvAccessTimingConfigsPM>) => {
    this.gcvTimingConfigsProgrammersModel = { ...this.gcvTimingConfigsProgrammersModel, ...programmersModel };
  });

  upsertTimingConfig = action(async (posType: PosType, timingConfigs: IntegrationTiming[]) => {
    await this.gcvAdminApi.upsertTimingConfig(posType, timingConfigs).then((newRecord) => {
      this.gcvTimingConfigsProgrammersModel.allTimingConfigsMap[posType] = newRecord;
      this.updateProgrammersModel({
        allTimingConfigsMap: this.gcvTimingConfigsProgrammersModel.allTimingConfigsMap,
        isLoading: false
      });
    });
  });

  kickoffDataFetcher = action(
    async (
      posType: PosType,
      dataWorkflowType: DataFetcherWorkflowTypes,
      strategy: DataFetcherStrategy,
      toDate: string,
      fromDate: string,
      crbId?: string
    ) => {
      await this.gcvAdminApi.kickoffDataFetcher(posType, dataWorkflowType, strategy, toDate, fromDate, crbId);
    }
  );
}
