import * as React from 'react';
import { Link } from 'react-router-dom';
import type { Group, User } from '@gcv/shared';
import { Box } from '@mui/material';

import { AvatarGroup, Button, Card, Text, ViewItem } from 'ui';
import { isGroupLocked } from 'util/org.util';

interface Props {
  appType: 'crb' | 'fi';
  currentStaff: User[];
  groups: Group[];
}

export const GroupsCardList: React.FC<Props> = (props) => {
  return (
    <>
      {props.groups.map((g) => {
        return (
          <ViewItem size={[12, 12, 6, 4, 3]}>
            <Box
              sx={{
                '& .title-with-icon': {
                  maxWidth: '250px'
                },
                '& .title': {
                  maxWidth: '278px'
                }
              }}
            >
              <Card
                key={g.id}
                dataCy={`${g.id}-group-card`}
                height="200px"
                header={{
                  title: g.name,
                  icon: isGroupLocked(g) ? 'lock-icon' : undefined,
                  justify: 'center'
                }}
                noPadding
              >
                {g.users && g.users?.length < 1 ? (
                  <Box sx={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}>
                    <Text content="There are no members in this group" type="body2" />
                  </Box>
                ) : null}
                <AvatarGroup
                  dataCy="group-users"
                  length={4}
                  size="medium"
                  sx={{ marginTop: '1rem' }}
                  avatarList={
                    g.users?.reduce<{ label: string; tooltip: string }[]>((acc, curr) => {
                      const user = props.currentStaff.find((s) => s.id == curr);

                      if (user && user.invitation_status !== 'archived') {
                        acc.push({
                          label: `${user?.firstName.slice(0, 1).toUpperCase()}${user?.lastName
                            .slice(0, 1)
                            .toUpperCase()}`,
                          tooltip: user?.firstName + ' ' + user?.lastName
                        });
                      }

                      return acc;
                    }, []) ?? []
                  }
                />
                <br />
                <div style={{ textAlign: 'center' }}>
                  <Link
                    to={`/secure/${props.appType}/users/group-details/${g.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      color="secondary-outlined"
                      label="View Group"
                      onClick={() => {
                        return;
                      }}
                      dataCy="view-group-button"
                    />
                  </Link>
                </div>
              </Card>
            </Box>
          </ViewItem>
        );
      })}
    </>
  );
};
