import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import type { DocumentUpload } from '@gcv/shared';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import _ from 'lodash';

import { useAdditionalInfo, useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { Button, Container, ContainerItem, OnBoardingPage, ZeroState } from 'ui';
import { CustomFormGenerator } from '../../../../../organisms/CustomFormGenerator/custom-form-generator.organism';
import { OnboardingPresenter } from '../../onboarding.presenter';

interface Props extends Record<string, unknown> {
  isLastStep: boolean;
  onBack: () => void;
  onNext: () => void;
  stepNumber: number;
  totalSteps: number;
}

export const AdditionalInformation: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const form = useForm({ mode: 'onChange' });
  const {
    addExistingFilesToMap,
    onFilesChange,
    existingFilesIdsMap,
    setExistingFilesIdsMap,
    refreshFileComponent
  } = useAdditionalInfo(form, presenter.viewModel.allDocumentsMap);
  const { template_id, template_result_id, bank_id } = useParams() as {
    template_id: string;
    template_result_id: string;
    bank_id: string;
  };

  const subtitle = presenter.viewModel.provider.orgName || 'Your service provider';

  React.useEffect(() => {
    form.reset(presenter.getDefaultValues());
  }, [template_id, template_result_id, bank_id]);

  // upload new files to s3 and submit form data
  const onNewFilesChange = async (fieldId: string, sectionId: string, files: File[]) => {
    onFilesChange(presenter.crbDispensaryStore.currentDispensary.id, fieldId, files, (docs) => {
      presenter.addNewDocuments(docs);
      handleOnBlur(fieldId, sectionId);
    });
  };

  const handleOnBlur = async (fieldId?: string, sectionId?: string) => {
    const customFieldValues = form.getValues() as FieldValues;

    const section = Object.values(presenter.viewModel.onboardingTemplate.custom_requirements).find(
      (s) => s.requirement_id === sectionId
    );
    if (section) {
      const fields = section?.custom_section.fields.map((f) => f.id);

      const updatedValues: Record<string, any> = {};
      for (const field of fields) {
        if (customFieldValues[field] != null) {
          updatedValues[field] = customFieldValues[field];
        }
      }

      const existingReq = presenter.viewModel.templateResults.custom_requirement_result.find(
        (c) => c.requirement_id === sectionId
      );
      if (!existingReq || (existingReq && !_.isEqual(updatedValues, existingReq.custom_fields.responses))) {
        await presenter.autoSaveAdditionalInfoSections([
          { sectionId: section.requirement_id, customFieldValues: { responses: updatedValues } }
        ]);
      }
    }
  };

  return (
    <OnBoardingPage
      alignBottomActions="center"
      title="Additional Information"
      subtitle={`You’re almost done! Below are just a few more questions that ${subtitle} needs you to answer as part of your application.`}
      step={props.stepNumber}
      totalSteps={props.totalSteps}
      bgCard
      bottomActions={
        <Container padding={0}>
          <ContainerItem>
            <Button color="default-outlined" label="Back" onClick={props.onBack} dataCy="back-button" />
          </ContainerItem>
          <ContainerItem>
            <Button
              label={props.isLastStep ? 'Submit' : 'Continue'}
              color="primary"
              onClick={form.handleSubmit(props.onNext, presenter.onSubmitError)}
              isLoading={presenter.viewModel.isAutoSaving}
              dataCy="save-button"
            />
          </ContainerItem>
        </Container>
      }
      dataCy="custom-additional-information"
    >
      <Box padding="0" width="100%" sx={{ overflowAnchor: 'none' }}>
        {presenter.viewModel.onboardingTemplate &&
        presenter.viewModel.onboardingTemplate?.custom_requirements &&
        Object.keys(presenter.viewModel.onboardingTemplate.custom_requirements).length > 0 ? (
          <CustomFormGenerator
            form={form}
            fieldWidth={isMobile ? '100%' : '50%'}
            requirement={presenter.viewModel.onboardingTemplate}
            editMode={true}
            onFileUpload={async (requirementId, fieldId, files) => {
              onNewFilesChange(fieldId, requirementId, files);
              return undefined;
            }}
            onFileDelete={async (reqId: string, fieldId: string, doc: DocumentUpload) => {
              await presenter.deleteOnboardingTemplateDocument(reqId, fieldId, doc);
              const updatedFiles = existingFilesIdsMap[fieldId].filter((id) => id !== doc.id);
              existingFilesIdsMap[fieldId] = updatedFiles;
              setExistingFilesIdsMap(existingFilesIdsMap);
              form.setValue(fieldId, updatedFiles);
              handleOnBlur(fieldId, reqId);
            }}
            documentMap={presenter.viewModel.allDocumentsMap}
            isLoading={
              presenter.viewModel.uploadingOnboardingTemplateDocuments ||
              presenter.viewModel.deletingOnboardingTemplateDocuments
            }
            timezone={presenter.crbDispensaryStore.currentDispensary.iana_timezone}
            addExistingFilesToMap={addExistingFilesToMap}
            onBlur={handleOnBlur}
            refreshFileComponent={!refreshFileComponent}
          />
        ) : (
          <ZeroState
            title={'There is no additional information needed at this time'}
            subTitle={'If your banking partner requests any additional information, it will show up here.'}
            icon="/img/empty-states/bankDash.svg"
            dataCy="additional-info-zero-state"
          />
        )}
      </Box>
    </OnBoardingPage>
  );
});
