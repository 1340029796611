import * as React from 'react';
import type { Control, FieldValues, Validate } from 'react-hook-form';

import { EinMask } from 'domain/consts/input-masks';
import { InputMasked } from 'ui';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputEinProps {
  label: string;
  name: string;
  control?: Control;
  dataCy?: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  viewOnly?: boolean;
  defaultValue?: string;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  invalidErrorMessage?: 'invalid';
  background?: 'white' | 'gray';
  placeholder?: string;
  tooltip?: string | React.ReactElement;
  noTooltip?: boolean;
  children?: React.ReactNode;
}

export const InputEin: React.FC<InputEinProps> = (props) => {
  const { rules, invalidErrorMessage, placeholder, ...rest } = props;

  return (
    <InputMasked
      mask={EinMask}
      rules={{
        ...rules,
        minLength: { message: invalidErrorMessage || 'invalid', value: 10 }
      }}
      inputProps={{ maxLength: 10 }}
      placeholder={placeholder || '11-1111111'}
      tooltip={
        props.noTooltip
          ? ''
          : props.tooltip
          ? props.tooltip
          : 'An Employer Identification Number (EIN) is a 9-digit number used to identify a business entity and is used to maintain banking records.'
      }
      {...rest}
    />
  );
};
