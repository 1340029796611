import type { CrbServiceProvider, TemplateResultResponse } from '@gcv/shared';
import {
  DueDiligenceStatus,
  GCD_RIVERBANK_BANK_ID,
  GREEN_CHECK_ONBOARDING_BANK_ID,
  MarketplaceOfferCategory,
  PAYQWICK_BANK_ID
} from '@gcv/shared';
import { injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';

import { DispensariesApi, DocumentsApi } from 'api';

@injectable()
export class CrbBankStore {
  dispensaryApi = new DispensariesApi();
  documentsApi = new DocumentsApi();

  /**
   * This will be a mapping of all service providers that the dispensary ever connected with.
   * Contains basic data like org name, address, timezone, and template metadata
   * */
  banks: { [bankId: string]: CrbServiceProvider } = {} as { [bankId: string]: CrbServiceProvider };

  isLoaded = false;
  isLoadingBank = false;
  /**
   * ID of the PQ or GCD org that this CRB is currently connected to (if any)
   * When we migrate CRBs from PQ to GCD, we only want to show GCD options
   */
  payqwickOrgId?: string;

  constructor() {
    makeAutoObservable(this);
  }

  private getEarliestConnectedBank = (banks: CrbServiceProvider[]) => {
    if (!banks.length) {
      return undefined;
    }

    const firstBank = banks.sort((a, b) => {
      const connectedA = a.templates.value.connected_date;
      const connectedB = b.templates.value.connected_date;

      if (connectedA && connectedB) {
        return connectedA < connectedB ? -1 : 1;
      } else {
        return 0;
      }
    })[0];

    return firstBank;
  };

  public getPrimaryBank = (): CrbServiceProvider | undefined => {
    const activeBanks = [];

    for (const sp of Object.values(this.banks)) {
      if (
        sp.templates.value.onboarding.status === DueDiligenceStatus.BANK_APPROVED &&
        sp.templates.value.onboarding.marketplace_offer_snapshot?.offer_category ===
          MarketplaceOfferCategory.Banking
      ) {
        activeBanks.push(sp);
      }
    }

    let firstBank: CrbServiceProvider | undefined;

    if (activeBanks.length) {
      firstBank = this.getEarliestConnectedBank(activeBanks);
    } else {
      firstBank = this.getEarliestConnectedBank(
        Object.values(this.banks).filter(
          (b) =>
            b.templates.value.onboarding.status === DueDiligenceStatus.BANK_APPROVED &&
            !b.templates.value.onboarding.marketplace_offer_snapshot &&
            b.id !== GREEN_CHECK_ONBOARDING_BANK_ID
        )
      );
    }

    return firstBank;
  };

  loadBanks = action(async (dispensaryId: string) => {
    this.isLoadingBank = true;
    const banks = await this.dispensaryApi.getDispensaryServiceProviders(dispensaryId);
    runInAction(() => {
      this.banks = banks;
      this.isLoaded = true;
      this.isLoadingBank = false;
      this.payqwickOrgId = this.getPayqwickOrgId();
    });
  });

  /**
   * Payqwick is migrating off of their existing system and will be moving CRBs to a new FI
   * When they migrate, the CRBs will be connected to both FIs, and we want to prioritize the new GCD
   */
  getPayqwickOrgId = () => {
    if (
      this.banks[GCD_RIVERBANK_BANK_ID] &&
      this.banks[GCD_RIVERBANK_BANK_ID]?.templates.value.onboarding.status !==
        DueDiligenceStatus.BANK_DISCONNECTED
    ) {
      return GCD_RIVERBANK_BANK_ID;
    }
    if (
      this.banks[PAYQWICK_BANK_ID] &&
      this.banks[PAYQWICK_BANK_ID]?.templates.value.onboarding.status !== DueDiligenceStatus.BANK_DISCONNECTED
    ) {
      return PAYQWICK_BANK_ID;
    }
  };

  clearStore = action(() => {
    this.banks = {};
    this.isLoaded = false;
  });

  updateOnboardingTemplateInfo = action((bankId: string, templateResult: TemplateResultResponse) => {
    const bank = this.banks[bankId];
    if (bank) {
      bank.templates.value.onboarding.status = templateResult.status;
      bank.templates.value.onboarding.last_updated = templateResult.date_updated;
    }
  });
}

let store: CrbBankStore | undefined;

export function getCrbBankStore(): CrbBankStore {
  if (!store) {
    store = new CrbBankStore();
  }

  return store;
}
