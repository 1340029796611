import { useState } from 'react';
import { Box } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ReactComponent as ArchiveIcon } from 'assets/images/ic-archive-dispensary.svg';
import type { ListOption } from 'ui';
import { ContainerItem, MultiSelectSearch, Text, Tooltip } from 'ui';

interface Props {
  options: ListOption[];
  setSelectedAccounts: (selected: string) => void;
  title?: string;
  toggleArchived?: (value: boolean) => void;
  showTopActions?: boolean;
  clearAllSelected: () => void;
  selectAll: () => void;
}

const AccountsList = (props: Props) => {
  const options = props.options;
  const { archivedCrbs } = useFlags();

  const [showArchived, setShowArchived] = useState(false);

  return (
    <ContainerItem padding={0} width="100%">
      <MultiSelectSearch
        searchLabel="Search accounts"
        background="white"
        options={options}
        title={props.title ?? 'Accounts'}
        archiveIcon={
          <Tooltip title="Archived Account" placement="top-start">
            <ArchiveIcon />
          </Tooltip>
        }
        showArchived={showArchived}
        topActions={
          archivedCrbs &&
          props.showTopActions && (
            <Box
              onClick={() => {
                setShowArchived(!showArchived);
                if (props.toggleArchived) {
                  props.toggleArchived(!showArchived);
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <ContainerItem flex align="center" padding="0">
                <Text
                  sx={{ fontWeight: '700', fontSize: '13px', color: '#828599', marginRight: '4px' }}
                  content={showArchived ? 'Hide archived' : 'Show archived'}
                />
                <ArchiveIcon />
              </ContainerItem>
            </Box>
          )
        }
        selectAll={() => {
          if (props.selectAll) {
            props.selectAll();
          }
        }}
        clearAll={() => {
          if (props.clearAllSelected) {
            props.clearAllSelected();
          }
        }}
        onSelect={(selected: string) => {
          if (props.setSelectedAccounts) {
            props.setSelectedAccounts(selected);
          }
        }}
      />
    </ContainerItem>
  );
};

export default AccountsList;
