import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

import { MessagesRepo } from './messages.repo';

@injectable()
export class MessagesPresenter {
  @inject(MessagesRepo)
  repo: MessagesRepo;

  get messages() {
    return this.repo.appMessages;
  }

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.repo.init();
  };
}
