import type {
  BankOverview,
  DispensaryDailySummaryOverview,
  DispensaryDepositOverview,
  IANATimezones,
  MinifiedDispensary,
  MUO,
  MUOBankResponse
} from '@gcv/shared';
import { DueDiligenceStatus, OrganizationType } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe, runInAction } from 'mobx';
import qs from 'query-string';

import type { TimePeriod } from 'stores/AppViewStateStore';
import { AppPage, AppViewStateStore } from 'stores/AppViewStateStore';
import { FiBankStore } from 'stores/FiBankStore';
import { FiDispensaryStore } from 'stores/FiDispensaryStore';
import { getSnackbarStore, SnackbarStore } from 'stores/SnackBarStore';
import type { FilterListChild, FilterListItem, VerticalBarConfig } from 'ui';
import { tooltip } from 'ui';
import { DateTimeHelpers } from 'util/dateTime.util';
import { formatMoney } from 'util/format.util';
import type { PM } from './dashboard.repo';
import { FiDashboardRepo } from './dashboard.repo';

interface DispensarySalesMap {
  [id: string]: DispensaryDailySummaryOverview;
}
interface DispensaryDepositsMap {
  [id: string]: DispensaryDepositOverview;
}

export interface VM {
  activeTab: string;
  bankOverview: null | BankOverview;
  currentFilter: FilterListChild[];
  filterOptions: FilterListItem[];
  filteredOverviewDispensaries: (MinifiedDispensary | MUO)[];
  defaultTimeRangeValue: string;
  isLoading: boolean;
  loadingOverview: boolean;
  filteringData: boolean;
  overviewFilterOptions: FilterListItem[];
  overviewFilterWith: FilterListItem[];
  salesDate: () => string;
  timezone: () => IANATimezones;
  barChartData: VerticalBarConfig | null;
  salesData: { verified?: number; unverified?: number; uncheckable?: number; total?: number };
  accountsData: { active: number; reviewInProgress: number; waiting: number; invited: number };
  loadingDataError: boolean;
  chartUrl: string;
  dispensaryHasChartOn: boolean;
}

@injectable()
export class FiDashboardPresenter {
  @inject(FiDashboardRepo)
  private repo: FiDashboardRepo;

  @inject(AppViewStateStore)
  private appViewStore: AppViewStateStore;

  @inject(FiDispensaryStore)
  private dispStore: FiDispensaryStore;

  @inject(FiBankStore)
  private bankStore: FiBankStore;

  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  private get timezone() {
    return this.repo.programmersModel.timezone;
  }

  private get startDate() {
    return this.appViewStore.dashboardTimePeriod.dateRange.start;
  }

  private get endDate() {
    return this.appViewStore.dashboardTimePeriod.dateRange.end;
  }

  private get salesDate() {
    return this.startDate === this.endDate
      ? DateTimeHelpers.formatDateStringToFriendlyDateString(this.startDate, this.timezone)
      : DateTimeHelpers.formatDateStringToFriendlyDateString(this.startDate, this.timezone) +
          ' - ' +
          DateTimeHelpers.formatDateStringToFriendlyDateString(this.endDate, this.timezone);
  }

  public viewModel: VM = {
    activeTab: 'Overview',
    bankOverview: null,
    currentFilter: [],
    filterOptions: [],
    filteredOverviewDispensaries: [],
    defaultTimeRangeValue: 'last7Days',
    isLoading: true,
    loadingOverview: true,
    overviewFilterOptions: [],
    overviewFilterWith: [],
    salesDate: () => this.salesDate,
    timezone: () => this.timezone,
    filteringData: true,
    accountsData: { active: 0, reviewInProgress: 0, invited: 0, waiting: 0 },
    barChartData: null,
    salesData: {},
    loadingDataError: false,
    chartUrl: '',
    dispensaryHasChartOn: false
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  private loadStore = action(async () => {
    try {
      await this.repo.load();
    } catch (e) {
      const error = e as Error;
      this.snackbarStore.showErrorSnackbarMessage(error.message);
    }
  });

  private reloadData = action(async () => {
    await this.loadStore();
    if (this.viewModel.bankOverview) {
      const { barChartData, salesData, accountsData } = this.filterAndFormatData(
        this.viewModel.filteredOverviewDispensaries,
        this.viewModel.bankOverview!.sales!,
        this.viewModel.bankOverview!.deposits!
      );
      this.updateViewModel({ isLoading: false, barChartData, salesData, accountsData });
    } else {
      this.updateViewModel({ isLoading: false });
    }
  });

  public load = action(async () => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      try {
        this.updateViewModel({
          bankOverview: programmersModel.bankOverview,
          loadingOverview: programmersModel.loadingBankOverview,
          loadingDataError: programmersModel.loadingDataError,
          chartUrl: programmersModel.chartUrl
        });
      } catch (e) {
        // In this example, we're going to decide to show a generic message.
        getSnackbarStore().showErrorSnackbarMessage(
          'There was an issue loading page data. Contact support for additional help.'
        );
      }
    });

    this.viewModel.dispensaryHasChartOn = this.bankStore.bank.sigma_config?.enabled ?? false;

    await this.setDispensaries();
    await this.reloadData();
    const tabQuery = this.getTabQuery();
    this.toggleTab(Array.isArray(tabQuery) ? tabQuery[0] : tabQuery);
  });

  public applyFilter = action((data: any[], all: string[], added: string[], removed: string[]) => {
    // apply filter to page to show correct results
    this.setFilterWith(data);

    const { barChartData, depositData, salesData, accountsData } = this.filterAndFormatData(
      this.viewModel.filteredOverviewDispensaries,
      this.viewModel.bankOverview!.sales!,
      this.viewModel.bankOverview!.deposits!
    );

    this.updateViewModel({ isLoading: false, barChartData, salesData, accountsData });
  });

  public selectFilter = action(async () => {
    this.setFilterWith([]);

    await this.setDispensaries();

    this.setCurrentFilter(this.viewModel.overviewFilterOptions);
    this.setFilterWith(this.viewModel.overviewFilterWith);
    this.setFilterOptions(this.viewModel.overviewFilterOptions);
  });

  public filterAndFormatData = (
    dispensaries: (MinifiedDispensary | MUO)[],
    sales: DispensarySalesMap,
    deposits: DispensaryDepositsMap
  ) => {
    const accountsToInclude = dispensaries.map((dispensary) => {
      return dispensary.id || '';
    });

    const depositData: { reconciled?: number; pending?: number; total?: number; under_review?: number } =
      deposits
        ? Object.keys(deposits)
            .filter((dispId) => accountsToInclude.includes(dispId))
            .reduce(
              (acc, dispId) => {
                const dep = deposits[dispId];
                acc.reconciled += dep.reconciled;
                acc.pending += dep.pending;
                acc.under_review += dep.under_review;
                acc.total += dep.total;
                return acc;
              },
              { reconciled: 0, pending: 0, total: 0, under_review: 0 }
            )
        : {};

    const barChartAccounts = Object.keys(sales).filter((dispId) => accountsToInclude.includes(dispId));

    const salesData: { verified?: number; unverified?: number; uncheckable?: number; total?: number } =
      barChartAccounts
        ? barChartAccounts.reduce(
            (acc, dispId) => {
              const summary = sales[dispId];

              acc.uncheckable += summary.uncheckable_total;
              acc.verified += summary.verified_total;
              acc.unverified += summary.unverified_total;
              acc.total += summary.uncheckable_total + summary.verified_total + summary.unverified_total;

              return acc;
            },
            { verified: 0, unverified: 0, uncheckable: 0, total: 0 }
          )
        : {};

    const barChartData: VerticalBarConfig = {
      data: barChartAccounts.map((dispId) => {
        const dispensarySales = sales[dispId];

        return {
          label: barChartAccounts.length > 30 ? '' : dispensarySales.dispensary_name,
          value: dispensarySales.total / 100,
          color: '#00BC66',
          tooltext: tooltip(
            `Sales`,
            [
              {
                value: `${formatMoney(dispensarySales.verified_total)}`,
                label: 'Verified'
              },
              {
                value: `${formatMoney(dispensarySales.unverified_total)}`,
                label: 'Unverified'
              },
              {
                value: `${formatMoney(dispensarySales.uncheckable_total)}`,
                label: 'Unchecked'
              },
              {
                value: formatMoney(dispensarySales.total),
                label: 'Total'
              }
            ],
            dispensarySales.dispensary_name
          )
        };
      })
    };

    if (salesData.total === 0) {
      barChartData.data = [];
    }

    const accounts = this.getAccountsList();
    const accountsData = { active: 0, reviewInProgress: 0, waiting: 0, invited: 0 };
    accounts.forEach((a) => {
      if (a.orgType === OrganizationType.MUO) {
        accountsData.active = accountsData.active + 1;
      }

      if (a.orgType === OrganizationType.DISPENSARY) {
        if (this.isActive(a)) {
          accountsData.active = accountsData.active + 1;
        }
        if (this.isInvited(a)) {
          accountsData.invited = accountsData.invited + 1;
        }
        if (this.isWaiting(a)) {
          accountsData.waiting = accountsData.waiting + 1;
        }
        if (this.isReviewInProgress(a)) {
          accountsData.reviewInProgress = accountsData.reviewInProgress + 1;
        }
      }
    });
    this.viewModel.filteringData = false;
    return { salesData, depositData, barChartData, accountsData };
  };

  isActive = (dispensary: MinifiedDispensary) => {
    return dispensary.assigned_onboarding_template.status === DueDiligenceStatus.BANK_APPROVED;
  };

  isReviewInProgress = (dispensary: MinifiedDispensary) => {
    const status = dispensary.assigned_onboarding_template.status;
    return status === DueDiligenceStatus.BANK_REVIEW_IN_PROGRESS;
  };

  isWaiting = (dispensary: MinifiedDispensary) => {
    return dispensary.assigned_onboarding_template.status === DueDiligenceStatus.BANK_AWAITING_REVIEW;
  };

  isInvited = (dispensary: MinifiedDispensary) => {
    const status = dispensary.assigned_onboarding_template.status;
    return (
      status === DueDiligenceStatus.BANK_IN_PROGRESS ||
      status === DueDiligenceStatus.BANK_PENDING ||
      status === DueDiligenceStatus.GCV_PENDING ||
      status === DueDiligenceStatus.GCV_IN_PROGRESS ||
      status === DueDiligenceStatus.GCV_AWAITING_REVIEW ||
      status === DueDiligenceStatus.GCV_APPROVED
    );
  };

  /** Return combined list of MUO's and dispensaries. */
  public getAccountsList = (): (MinifiedDispensary | MUOBankResponse)[] => {
    const getDispensaries = (): MinifiedDispensary[] => {
      return this.dispStore.dispensaries;
    };

    const getMUOs = (): MUOBankResponse[] => {
      return this.dispStore.bankMuos;
    };

    return [...getMUOs(), ...getDispensaries()].sort((a, b) => a.name.localeCompare(b.name));
  };

  public getTabQuery = () => {
    const values = qs.parse(window.location.search);
    return values['tab'] ?? 'Overview';
  };

  public resetFilterOptions = action(() => {
    this.updateViewModel({
      overviewFilterOptions: this.viewModel.filteredOverviewDispensaries
        .filter((dispensary) => !(dispensary as MinifiedDispensary).muo_id)
        .map((dispensary) => {
          if (dispensary.orgType === 'muo') {
            return {
              value: dispensary.id || '',
              label: dispensary.name || '',
              children: this.dispStore.dispensaries
                .filter((subdisp) => subdisp.muo_id === dispensary.id)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((subdisp) => {
                  return {
                    value: subdisp.id,
                    label: subdisp.name,
                    selected: false,
                    parentValue: subdisp.id,
                    children: []
                  } as FilterListChild;
                })
            };
          } else {
            return {
              value: dispensary.id || '',
              label: dispensary.name || '',
              children: []
            };
          }
        })
    });
  });

  public setCurrentFilter = action((currentFilter: FilterListChild[]) => {
    this.updateViewModel({ currentFilter: currentFilter });
  });

  public setDispensaries = action(async () => {
    try {
      this.updateViewModel({
        filteredOverviewDispensaries: this.getAccountsList().filter(
          (d) => d.assigned_onboarding_template.status === DueDiligenceStatus.BANK_APPROVED
        )
      });

      this.resetFilterOptions();
    } catch (e) {
      this.snackbarStore.showErrorSnackbarMessage(
        'There was an issue loading page data. Contact support for additional help.'
      );
    }
  });

  public setFilterOptions = action((filterOptions: FilterListItem[]) => {
    this.updateViewModel({ filterOptions: filterOptions });
  });

  public setFilterWith = action(async (options: FilterListItem[]) => {
    if (options === null) {
      return;
    }

    const selected = options.filter((option) => option.selected);

    if (!Array.isArray(options)) {
      options = [options];
    }

    let filteredDispensaries: MinifiedDispensary[] = [];

    if (selected.length === 0) {
      filteredDispensaries = this.dispStore.dispensaries;
    } else {
      const findDispensary = (dispId: string) =>
        this.dispStore.dispensaries.find((dispensary) => {
          if (dispensary.id === dispId) {
            filteredDispensaries.push(dispensary);
          }
        });

      selected.forEach((filterTerm) => {
        findDispensary(filterTerm.value);

        if (filterTerm.children && filterTerm.children.length > 0) {
          filterTerm.children.forEach((child) => {
            if (child.selected) {
              findDispensary(child.value);
            }
          });
        }
      });
    }

    runInAction(() => {
      this.updateViewModel({
        filteredOverviewDispensaries: filteredDispensaries.filter(
          (d) => d.assigned_onboarding_template.status === DueDiligenceStatus.BANK_APPROVED
        ),
        overviewFilterWith: selected
      });
    });
  });

  public onEmitTimePeriod = action((data: TimePeriod) => {
    if (data.value !== this.appViewStore.dashboardTimePeriod.value) {
      this.setTimePeriod(data);
      if (!this.viewModel.isLoading) {
        this.reloadData();
      }
    }
  });

  private setTimePeriod = action((data: TimePeriod) => {
    this.appViewStore.setTimePeriod(data, AppPage.dashboard);
  });

  public toggleTab = action(async (activeTab: string) => {
    this.viewModel.activeTab = activeTab;
    await this.selectFilter();

    if (this.appViewStore.dashboardAccounts && this.appViewStore.dashboardAccounts.length > 0) {
      this.applyFilter(this.appViewStore.dashboardAccounts, [], [], []);
    }
  });

  public clearChartUrl = action(() => {
    this.repo.clearChartUrl();
  });
}
