import type { Sale } from '@gcv/shared';

import { api } from './api-util/api';

export class SalesApi {
  async getSale(dispensaryId: string, saleId: string): Promise<Sale> {
    return await api().get(`/gcv/dispensaries/${dispensaryId}/sales`, { saleId });
  }

  async saveSale(sale: Sale) {
    return await api().patch(`/gcv/dispensaries/${sale.dispensary_id}/sales`, sale);
  }

  async deleteSale(dispensaryId: string, saleId: string) {
    return await api().delete(`/gcv/dispensaries/${dispensaryId}/sales`, {
      saleId,
      secret: process.env.REACT_APP_SALES_SECRET
    });
  }

  async getSalesIngestionInformation(
    dispensaryId: string,
    s3DateUploaded?: string,
    startDate?: string,
    endDate?: string
  ): Promise<{ numberOfSales: number; dateCreated: string }> {
    return await api().get(`/gcv/dispensaries/${dispensaryId}/sales-ingestions`, {
      s3DateUploaded,
      startDate,
      endDate
    });
  }

  async deleteSalesFromIngestion(
    dispensaryId: string,
    s3DateUploaded?: string,
    startDate?: string,
    endDate?: string
  ): Promise<{ numberOfSales: number; dateCreated: string }> {
    return await api().delete(`/gcv/dispensaries/${dispensaryId}/sales-ingestions`, {
      s3DateUploaded,
      startDate,
      endDate
    });
  }
}
