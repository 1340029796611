import * as React from 'react';
import type { FieldError } from 'react-hook-form';
import type { InputBaseComponentProps } from '@mui/material';
import { Input as MuiInput } from '@mui/material';

import palette from 'ui/theme/palette';

interface Props {
  name: string;

  background?: 'white' | 'gray' | 'lightGray';
  color?: string;
  dataCy?: string;
  disableAutoWidth?: boolean;
  error?: FieldError;
  focus?: boolean;
  fullWidth?: boolean;
  inline?: boolean;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  inputProps?: InputBaseComponentProps;
  inputRef?: any;
  maxRows?: number;
  minRows?: number;
  multiline?: boolean;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: string | number;
  viewOnly?: boolean;
}

export const Input: React.FC<Props> = (props) => {
  const color = props.readOnly ? palette.text.secondary : props.color ? props.color : palette.text.primary;
  // font size 16px / 2 because width is half the font height
  const FONT_SIZE = 8;
  // smallest inputs in designs are city and zip at 117 and 124 respectively
  const MINIMUM_INPUT_SIZE = 115;
  const DEFAULT_INPUT_WIDTH = 'auto';

  const [inputWidth, setInputWidth] = React.useState(DEFAULT_INPUT_WIDTH);

  // in viewOnly mode calculate the size of the input container in order to show all data without need for ellipses or scrolling
  React.useEffect(() => {
    if (props.viewOnly) {
      if (
        props.value &&
        typeof props.value === 'string' &&
        props.value.length * FONT_SIZE > MINIMUM_INPUT_SIZE
      ) {
        // increase size by 1 for spacing and add 8px for padding
        setInputWidth(`${(props.value.length + 1) * FONT_SIZE + 8}px`);
      } else {
        setInputWidth(DEFAULT_INPUT_WIDTH);
      }
    }
  }, [props.value]);

  return (
    <MuiInput
      autoComplete="none"
      autoFocus={props.focus}
      data-cy={props.dataCy || 'input'}
      disableUnderline
      id={props.name}
      maxRows={props.maxRows}
      minRows={props.minRows ? props.minRows : 1}
      multiline={props.multiline}
      name={props.name}
      ref={props.inputRef}
      inputComponent={
        props.viewOnly && (!props.value || props.value === '' || props.value === '--')
          ? undefined
          : props.inputComponent
      }
      inputProps={
        props.viewOnly && (!props.value || props.value === '' || props.value === '--')
          ? undefined
          : props.inputProps
      }
      onBlur={props.onBlur}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e as React.ChangeEvent<HTMLInputElement>);
        }
      }}
      placeholder={props.placeholder}
      size="small"
      sx={{
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
        maxWidth: '100%',
        mt: 0,
        width: props.fullWidth ? '100%' : props.disableAutoWidth ? undefined : inputWidth,

        '& .MuiInput-input': {
          background: (theme) =>
            props.viewOnly
              ? 'none'
              : props.background === 'lightGray'
              ? theme.palette.background.default
              : props.background ?? theme.palette.background.default,
          border: props.viewOnly ? 'none' : `1px solid`,
          borderColor: (theme) => (props.error ? theme.palette.error.main : theme.palette.divider),
          borderRadius: '5px',
          color: color,
          fontFamily: 'Lato',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          padding: props.viewOnly ? '1px 0 5px' : '0.5rem 0.75rem',
          width: '100%',

          '&:focus': {
            borderColor: (theme) => (props.error ? theme.palette.error.main : theme.palette.primary.main),
            boxShadow: props.error
              ? `0px 0px 0px 3px rgba(255, 78, 80, 0.25)`
              : `0px 0px 0px 3px rgba(3, 166, 91, 0.25)`,
            color: (theme) => theme.palette.text.primary
          },

          '&.Mui-disabled': {
            color: (theme) =>
              props.viewOnly
                ? props.color
                  ? props.color
                  : theme.palette.text.primary
                : theme.palette.text.secondary,
            WebkitTextFillColor: 'inherit'
          },
          '&:hover': {
            cursor: props.readOnly ? 'not-allowed' : 'default'
          },
          '&::placeholder': {
            color: '#C6CCDA'
          }
        }
      }}
      disabled={props.readOnly || props.viewOnly}
      value={
        (props.readOnly || props.viewOnly) &&
        (props.value == null || props.value === '' || props.value === '--')
          ? '--'
          : props.value
      }
    />
  );
};
