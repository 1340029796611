//TODO: As we go along, the model-actions implementation should be migrated to the presenter-repo pattern.

import type {
  BusinessLicenseDocument,
  CrbServiceProvider,
  CreateDocumentsRequest,
  Dispensary,
  DocumentUpload,
  DocumentUploadSymlink,
  DueDiligenceDocument,
  OnboardingDocumentRequirement,
  User
} from '@gcv/shared';
import { GREEN_CHECK_ONBOARDING_BANK_ID, RequirementCategory, RequirementType } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { DateTime } from 'luxon';
import { action, makeAutoObservable, observe, runInAction } from 'mobx';

import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';
import type { SelectOption } from 'ui/atoms';
import { getMimeType, openFileInNewWindow } from 'util/files.util';
import { getObjectMap } from 'util/objectUtils';
import { viewDocument } from 'util/s3.util';
import type { CompanyProfile } from './company-profile.model';
import type { PM } from './company-profile.repo';
import { CompanyProfileRepo } from './company-profile.repo';

export interface VM {
  isLoading: boolean;
  isLoadingLicenses: boolean;
  defaultCompanyProfileValues: CompanyProfile;
  isUpdatingCompanyProfile: boolean;
  allRootDocuments: {
    requirement: OnboardingDocumentRequirement;
    ddDocuments: DueDiligenceDocument[] | BusinessLicenseDocument[];
    documents: DocumentUpload[];
  }[];
  allDocumentsMap: { [id: string]: DocumentUpload };
  dispensary: Dispensary;
  drawerOpen: boolean;
  selectedRootDocument?: {
    requirement: OnboardingDocumentRequirement;
    ddDocument: DueDiligenceDocument;
    document: DocumentUpload;
  };
  docOpen: boolean;
  dispensaryStaff: User[];
  mjLicRequirement: OnboardingDocumentRequirement;
  sharingModalOpen: boolean;
  selectedFile: DocumentUpload;
  allProviders: {
    [bankId: string]: CrbServiceProvider;
  };
  availableServiceProviders: SelectOption[];
  isSavingSharing: boolean;
  docRequest?: CreateDocumentsRequest;
  providerDocMap: {
    [docId: string]: string[];
  };
}

@injectable()
export class CompanyProfilePresenter {
  public viewModel = {
    isLoading: true,
    isLoadingLicenses: true,
    defaultCompanyProfileValues: {} as CompanyProfile,
    isUpdatingCompanyProfile: false,
    allRootDocuments: [],
    allDocumentsMap: {},
    dispensary: {} as Dispensary,
    drawerOpen: false,
    docOpen: false,
    dispensaryStaff: [],
    mjLicRequirement: {} as OnboardingDocumentRequirement,
    sharingModalOpen: false,
    selectedFile: {} as DocumentUpload,
    allProviders: {},
    availableServiceProviders: [],
    isSavingSharing: false,
    providerDocMap: {}
  } as VM;

  @inject(CompanyProfileRepo)
  private repo: CompanyProfileRepo;

  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  constructor() {
    makeAutoObservable(this);
  }

  public load = action(async () => {
    observe(this.repo, 'programmersModel', async (obj) => {
      const programmersModel = obj.newValue as PM;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const dispensary = programmersModel.dispensary!;
      const serviceProviders = programmersModel.serviceProviders;
      const plantTouchingServiceProviders = Object.values(serviceProviders).filter((sp) => {
        if (!sp.plant_touching || sp.id === '1') {
          return false;
        }
        return true;
      });
      const availableServiceProviders = plantTouchingServiceProviders.map((sp) => {
        return {
          label: sp.orgName,
          value: sp.id
        };
      });

      this.updateViewModel({
        dispensary,
        defaultCompanyProfileValues: {
          name: dispensary.name,
          dba: dispensary.dba,
          dispensaryStaff: programmersModel.dispensaryStaff,
          entityType: dispensary.entityType,
          establishedDate: dispensary.establishedDate,
          ein: dispensary.ein,
          streetAddress: dispensary.streetAddress,
          city: dispensary.city,
          state: dispensary.state,
          postalCode: dispensary.postalCode,
          iana_timezone: dispensary.iana_timezone,
          mailingStreetAddress: dispensary.mailingStreetAddress,
          mailingCity: dispensary.mailingCity,
          mailingState: dispensary.mailingState,
          mailingPostalCode: dispensary.mailingPostalCode,
          phone: dispensary.phone,
          website: dispensary.website,
          allow_edit_profile: dispensary.allow_edit_profile
        } as CompanyProfile,
        allProviders: serviceProviders,
        availableServiceProviders
      });
    });

    this.viewModel.isLoading = true;

    await this.repo.load();

    this.updateViewModel({
      isLoading: false,
      allRootDocuments: this.repo.programmersModel.allRootDocuments,
      allDocumentsMap: getObjectMap(this.repo.programmersModel.allDocuments, 'id')
    });

    // a default mjLicRequirement object in case rootDoc.requirement is null
    // this occurs when users skip the normal onboarding application process
    // and begin editing items from the company profile page instead
    const defaultRequirement: OnboardingDocumentRequirement = {
      type: RequirementType.BusinessLicense,
      name: '',
      description: '',
      category: RequirementCategory.License,
      required: false,
      bank_id: '',
      id: '',
      requirement_id: 'business_licenses',
      date_created: DateTime.utc().toISO(),
      date_updated: DateTime.utc().toISO(),
      version: 'latest'
    };

    this.updateViewModel({ mjLicRequirement: defaultRequirement });

    await this.getMapOfServiceProviderDocs();
    this.viewModel.allRootDocuments.forEach((rootDoc) => {
      if (rootDoc.requirement.name === 'Marijuana Licenses') {
        this.updateViewModel({ mjLicRequirement: rootDoc.requirement });
      }
    });
  });

  public updateDispensaryConfig = action(async (data: CompanyProfile) => {
    try {
      await this.repo.updateDispensaryConfig(data);
    } catch (e) {
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'There was an issue updating business permissions'
      );
    }
  });

  public updateCompanyProfile = action(async (data: CompanyProfile) => {
    runInAction(() => {
      this.updateViewModel({ isUpdatingCompanyProfile: true });
    });

    try {
      await this.repo.updateCompanyProfile(data);
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Success,
        'Business details have been updated successfully.'
      );
    } catch (e) {
      this.snackbarStore.showSnackbar(SnackbarSeverity.Error, 'There was an issue updating business details');
    } finally {
      runInAction(() => {
        this.updateViewModel({ isUpdatingCompanyProfile: false });
      });
    }
  });

  public setSelectedRootDocument = action((id: string | undefined) => {
    if (!id) {
      this.updateViewModel({ selectedRootDocument: undefined });
      return;
    }
    const selectedRootDoc = {} as {
      requirement: OnboardingDocumentRequirement;
      ddDocument: DueDiligenceDocument;
      document: DocumentUpload;
    };
    for (const rd of this.viewModel.allRootDocuments) {
      for (const d of rd.documents) {
        if (d.id === id) {
          selectedRootDoc.document = d;
          selectedRootDoc.requirement = rd.requirement;
          break;
        }
      }
      for (const dd of rd.ddDocuments) {
        if (dd.id === id) {
          selectedRootDoc.ddDocument = dd;
          break;
        }
      }
    }
    this.updateViewModel({ selectedRootDocument: selectedRootDoc });
  });

  public setDrawerOpen = action((open: boolean) => {
    this.updateViewModel({ drawerOpen: open });
  });

  openDocument = async (docId: string, file_name: string) => {
    const blob = await viewDocument({ orgId: this.viewModel.dispensary.id, userType: 'dispensary' }, docId);

    if (blob) {
      openFileInNewWindow(blob, getMimeType(file_name), file_name);
    }
  };

  addLicenseDocument = action(async (documentsRequest: CreateDocumentsRequest, spIds: string[]) => {
    this.viewModel.isSavingSharing = true;
    const templateIds = spIds.map((spId) => {
      return spId;
    });

    await this.repo.addDocToOnboarding('business_licenses', documentsRequest, []);
    await this.getDocuments();
    this.viewModel.isSavingSharing = false;
  });

  shareExistingDocument = action(async (docId: string | undefined, spIds: string[]) => {
    this.viewModel.isSavingSharing = true;
    try {
      const templateIds = spIds.map((spId) => {
        return this.viewModel.allProviders[spId].templates.value.onboarding.template_id;
      });
      let docRequest: CreateDocumentsRequest;
      // share existing document
      // if we have an id already the doc exists
      if (docId) {
        const document = this.viewModel.allDocumentsMap[docId];
        docRequest = {
          documents: [
            {
              filename: document.file_name,
              s3_key: document.s3_key,
              req_name: this.viewModel.mjLicRequirement.name,
              linked_doc_id: `${document.org_id}_${document.id}`
            }
          ]
        };
        this.closeSharingModal();
        try {
          await this.repo.shareExistingDocument(
            this.viewModel.mjLicRequirement.requirement_id,
            docRequest,
            templateIds
          );
          this.snackbarStore.showSuccessSnackbarMessage('Document updated sucessfully');
        } catch (error) {
          this.snackbarStore.showErrorSnackbarMessage(
            'There was an issue updating license. Contact support for additional help.'
          );
          this.viewModel.isSavingSharing = false;
        }
      } else {
        // for new documents we want to follow a different process
        docRequest = this.viewModel.docRequest as CreateDocumentsRequest;
        this.closeSharingModal();
        try {
          await this.repo.addDocToOnboarding('business_licenses', docRequest, templateIds);
          this.snackbarStore.showSuccessSnackbarMessage('Document uploaded sucessfully');
        } catch (error) {
          this.snackbarStore.showErrorSnackbarMessage(
            'There was an issue uploading license. Contact support for additional help.'
          );
          this.viewModel.isSavingSharing = false;
          return;
        }
      }
      await this.getDocuments();
      await this.getMapOfServiceProviderDocs();
      this.viewModel.isSavingSharing = false;
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage(
        'There was an issue updating license. Contact support for additional help.'
      );
      this.viewModel.isSavingSharing = false;
    }
  });

  public getDocuments = action(async () => {
    this.viewModel.isLoading = true;
    await this.repo.getDocuments();
    this.updateViewModel({
      allDocumentsMap: getObjectMap(this.repo.programmersModel.allDocuments, 'id'),
      allRootDocuments: this.repo.programmersModel.allRootDocuments,
      isLoading: false
    });
  });

  public openNewDocumentSharingModal = action((docRequest: CreateDocumentsRequest) => {
    this.updateViewModel({ sharingModalOpen: true, docRequest: docRequest });
  });

  public openDocumentSharingModal = action((fileId: string) => {
    this.updateViewModel({ sharingModalOpen: true, selectedFile: this.viewModel.allDocumentsMap[fileId] });
  });

  public closeSharingModal = action(() => {
    this.updateViewModel({
      sharingModalOpen: false,
      selectedFile: {} as DocumentUpload,
      docRequest: undefined
    });
  });

  public getMapOfServiceProviderDocs = action(async () => {
    this.viewModel.isLoadingLicenses = true;
    const docMap: any = {};
    const plantTouchingServiceProviders = Object.values(this.repo.programmersModel.serviceProviders).filter(
      (sp) => {
        if (!sp.plant_touching || sp.id === GREEN_CHECK_ONBOARDING_BANK_ID) {
          return false;
        }
        return true;
      }
    );
    const spTemplateResults = await Promise.allSettled(
      plantTouchingServiceProviders.map(async (sp) => {
        const spTemplateResult = await this.repo.getServiceProviderDocs(sp);
        const spMJReq = spTemplateResult.requirements_results.filter(
          (req) => req.requirement_id === 'business_licenses'
        );
        const spDocs = spMJReq[0].documents;
        Object.values(spDocs).forEach((spDoc) => {
          const documentUpload = this.repo.programmersModel.allDocuments.find((d) => d.id === spDoc.id);
          if (documentUpload) {
            let docId = documentUpload.id;
            if ((documentUpload as DocumentUploadSymlink).linked_doc_id) {
              docId = (documentUpload as DocumentUploadSymlink).linked_doc_id.split('_')[1];
            }
            if (docMap[docId]) {
              if (docMap[docId].includes(sp.id)) return; // if the sp is already in the map for this doc, don't add it again
              docMap[docId].push(sp.id);
            } else {
              docMap[docId] = [sp.id];
            }
          }
        });
      })
    );
    this.updateViewModel({ providerDocMap: docMap, isLoadingLicenses: false });
  });
}
