import type { FieldValues } from 'react-hook-form';
import type { Dispensary } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';

import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';
import { CrbBankStore } from '../../../../../../stores/CrbBankStore';
import { CrbDispensaryStore } from '../../../../../../stores/CrbDispensaryStore';
import { UserStore } from '../../../../../../stores/UserStore';
import { OnboardingRepo } from '../../onboarding.repo';
import { AccountOwnersRepo } from './account-owners-repo';
interface InvitedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface VM {
  singleAccountOwner: boolean;
  isLoadingAccountOwner: boolean;
  accountOwnerModalOpen: boolean;
  serviceProviderName: string;
  requireAdditionalAccountOwners: boolean;
  invitedUsers: InvitedUser[];
}

@injectable()
export class AccountOwnersPresenter {
  @inject(OnboardingRepo)
  public onboardingRepo: OnboardingRepo;

  @inject(AccountOwnersRepo)
  public accountOwnersRepo: AccountOwnersRepo;

  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(CrbDispensaryStore)
  public crbDispensaryStore: CrbDispensaryStore;

  @inject(CrbBankStore)
  public crbBankStore: CrbBankStore;

  @inject(UserStore)
  public userStore: UserStore;

  constructor() {
    makeAutoObservable(this);
  }

  public viewModel: VM = {
    serviceProviderName: '',
    singleAccountOwner: true,
    isLoadingAccountOwner: false,
    requireAdditionalAccountOwners: false,
    accountOwnerModalOpen: false,
    invitedUsers: []
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  public load = action((bankId: string) => {
    observe(this.crbDispensaryStore, 'currentDispensary', (obj) => {
      const storeDispensary = obj.newValue as Dispensary;

      this.updateViewModelFromDispensary(storeDispensary);
    });
    const serviceProviderName = this.crbBankStore.banks[bankId]?.orgName ?? 'Your Service provider';
    this.updateViewModel({ serviceProviderName });
    this.updateViewModelFromDispensary(this.crbDispensaryStore.currentDispensary);
  });

  updateViewModelFromDispensary = (dispensary: Dispensary) => {
    const accountOwnersList = dispensary.groups.find((group) => group.type === 'account_owner');
    const accountOwners = accountOwnersList!.users.map((userId) => {
      const user = this.crbDispensaryStore.currentDispensaryStaff.find((s) => s.id === userId);
      return {
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email
      } as InvitedUser;
    });

    this.updateViewModel({
      singleAccountOwner: accountOwners.length <= 1,
      invitedUsers: accountOwners
    });
  };

  saveAccountOwners = action((onNext: () => void) => {
    if (this.viewModel.singleAccountOwner === true || this.viewModel.invitedUsers.length) {
      this.viewModel.requireAdditionalAccountOwners = false;
      onNext();
      this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'Account owners have been set successfully.');
      this.viewModel.isLoadingAccountOwner = false;
    } else {
      this.viewModel.requireAdditionalAccountOwners = true;
      this.viewModel.isLoadingAccountOwner = false;
    }
  });

  inviteUser = action(async (data: FieldValues) => {
    this.updateViewModel({ isLoadingAccountOwner: true, accountOwnerModalOpen: false });
    await this.accountOwnersRepo.inviteUser(data);
    this.updateViewModel({ isLoadingAccountOwner: false });
  });

  setAccountOwnerModal = action((open: boolean) => {
    this.updateViewModel({ accountOwnerModalOpen: open });
  });

  setSingleAccountOwner = action((isSingle: boolean) => {
    this.updateViewModel({ singleAccountOwner: isSingle });
  });
}
