import React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import { theme } from 'ui/theme';
import type { PageTab } from '../TabbedPage/tabbed-page.template';

interface Props extends Record<string, unknown> {
  tabs: PageTab[];
  onTabChange?: (currentTab: string) => void;
  selectedIndexOnMount?: number;
}

export const TabbedSecondaryPage: React.FC<Props> = (props) => {
  const [value, setValue] = React.useState('1');

  React.useEffect(() => {
    // Allow persisting of selected secondary tab when parent tabs switch.
    if (props.selectedIndexOnMount) {
      const index =
        props.selectedIndexOnMount > -1 && props.selectedIndexOnMount < props.tabs.length
          ? props.selectedIndexOnMount
          : 0;
      setValue(`${index + 1}`);
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    const tabId = props.tabs[parseInt(newValue) - 1].id;
    props.onTabChange ? props.onTabChange(tabId) : null;
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 0, borderColor: 'divider', paddingLeft: '4px' }}>
          <TabList
            onChange={handleChange}
            sx={{
              '& .MuiTabs-scroller': {
                '& .MuiTabs-indicator': {
                  backgroundColor: `${theme.palette.divider}`,
                  height: 0
                }
              }
            }}
          >
            {props.tabs.length > 0 &&
              props.tabs.map((tab, index) => (
                <Tab
                  label={tab.title}
                  value={`${index + 1}`}
                  sx={{
                    padding: '11px 20px 11px 20px',
                    color: `${theme.palette.text.primary}`,
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: '400',
                    textTransform: 'capitalize',
                    '&:hover': {
                      color: theme.palette.primary.main
                    },
                    '&.Mui-selected': {
                      border: `1px solid ${theme.palette.divider}`,
                      color: `${theme.palette.text.primary}`,
                      borderBottom: '0px'
                    }
                  }}
                />
              ))}
          </TabList>
        </Box>
        {props.tabs.length > 0 &&
          props.tabs.map((tab, index) => {
            // We must modify the React.FC to be a JSX.Element for TabPanel to accept it.
            const Component = tab.component;

            return (
              <TabPanel style={{ padding: 0 }} value={`${index + 1}`}>
                <Component />
              </TabPanel>
            );
          })}
      </TabContext>
    </Box>
  );
};

export default TabbedSecondaryPage;
