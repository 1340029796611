import { Icon, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import type { FilterSelectOption } from 'ui';

interface Props {
  filterToggle: boolean;
  toggleFilter: () => void;
  selectedFilters: FilterSelectOption[];
  filterHeader: string;
  headerComponent?: boolean;
  noMargin?: boolean;
  viewOnly?: boolean;
  /**
   * If true, will show "0 Accounts" when none selected. If false, will show "All Accounts"
   */
  showZeroSelected?: boolean;
  type?: 'fixed' | 'dropdown' | 'dropdown-auto-apply';
}

export const FilterSelectButton: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const selectedCount = () => {
    return props.selectedFilters.reduce((parentCount, parent) => {
      if (parent.selected) {
        parentCount += 1;
      }

      if (parent.children && parent.children.length > 0) {
        parentCount += parent.children.reduce((childCount, child) => {
          if (child.selected) {
            childCount += 1;
          }

          return childCount;
        }, 0);
      }

      return parentCount;
    }, 0);
  };

  const count = selectedCount();

  return (
    <Box
      className="filterSelectButton-topBox"
      sx={{
        color: 'text.primary',
        fontSize: props.viewOnly ? '16px' : '14px',
        lineHeight: '1.4375em',
        boxSizing: 'border-box',
        display: isMobile ? 'block' : 'inline-flex',
        width: '100%',
        marginTop: props.noMargin ? 0 : '12px'
      }}
    >
      <Box
        className="filterSelectButton-buttonBox"
        data-cy="account-select-dropdown"
        sx={{
          height: props.headerComponent ? '35px' : '41px',
          alignItems: props.viewOnly ? 'flex-start' : 'center',
          border: '1px solid',
          color: 'text.primary',
          display: 'flex',
          marginTop: isMobile ? '4px' : '0',
          padding: props.viewOnly ? undefined : '0 0.75rem',
          borderRadius: '4px',
          cursor: props.viewOnly || props.type === 'dropdown-auto-apply' ? undefined : 'pointer',
          boxShadow: props.filterToggle ? '0px 0px 0px 3px #03a65b40' : 'none',
          borderColor: props.viewOnly ? 'transparent' : props.filterToggle ? 'primary.main' : 'divider',
          minWidth: '165px',
          width: '100%',
          position: 'relative',
          backgroundColor: props.viewOnly ? 'none' : props.headerComponent ? 'background.paper' : '#F9FAFD',
          '&: hover': {
            borderColor: props.headerComponent ? 'primary.border' : undefined
          }
        }}
        onClick={props.viewOnly ? undefined : props.toggleFilter}
      >
        <Box
          sx={{
            marginRight: '10px',
            width: '145px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {count > 1 ? (
            <span>
              {count} {props.filterHeader}
            </span>
          ) : null}
          {count === 1 ? <span>{props.selectedFilters.find((s) => s.selected)?.label}</span> : null}
          {count === 0 ? <span>{(props.showZeroSelected ? '0 ' : 'All ') + props.filterHeader}</span> : null}
        </Box>
        {!props.viewOnly ? (
          props.filterToggle ? (
            <Icon
              sx={{
                fontSize: '20px',
                position: 'absolute',
                right: '6px',
                pointerEvents: 'none',
                color: 'rgba(0, 0, 0, 0.54)'
              }}
            >
              expand_less_outlined
            </Icon>
          ) : (
            <Icon
              sx={{
                fontSize: '20px',
                position: 'absolute',
                right: '6px',
                pointerEvents: 'none',
                color: 'rgba(0, 0, 0, 0.54)'
              }}
            >
              expand_more_outlined
            </Icon>
          )
        ) : null}
      </Box>
    </Box>
  );
};
