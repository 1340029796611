import { MainFeatures } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';

import {
  exportAccountSummary,
  exportCommentsReport,
  exportCoreTransactionReport,
  exportCRBLicenseData,
  exportDailySummaries,
  exportDeposits,
  exportFinCENCTRSummary,
  exportFinCENSARSummary,
  exportQuestionnairesReport,
  exportRawSalesData,
  exportSalesActivity,
  exportUserReport,
  ReportType
} from 'api';
import { FiBankStore } from 'stores/FiBankStore';
import { NotificationStore } from 'stores/NotificationStore';
import type { Report } from 'ui';
import { orgFeatureEnabled } from 'util/org.util';
import type { PM } from './reports.repo';
import { ReportsRepo } from './reports.repo';

export interface VM {
  activeReport: Report | undefined;
  defaultReport: Report;
  reports: Report[];
  savingOperationalDetails: boolean;
  savingBusinessDetails: boolean;
  showConfirmationDialog: boolean;
  showModal: boolean;
}

@injectable()
export class ReportsPresenter {
  @inject(ReportsRepo)
  private repo: ReportsRepo;

  @inject(NotificationStore)
  private notificationStore: NotificationStore;

  @inject(FiBankStore)
  private bankStore: FiBankStore;

  constructor() {
    makeAutoObservable(this);
  }

  callback = (report: Report) => {
    this.setActiveReport(report);
    this.setShowModal(true);
  };

  viewModel: VM = {
    activeReport: undefined,
    defaultReport: {
      id: '0',
      name: 'Account Summary',
      description: 'Export a detailed summary of your accounts.',
      type: ReportType.ACCOUNT,
      fields: [{ name: 'AccountsList' }],
      callback: this.callback
    },
    reports: [
      {
        id: '1',
        name: 'Account Summary',
        description:
          'Export a summary view of your accounts or a detailed view, which includes Due Diligence custom field responses.',
        type: ReportType.ACCOUNT,
        fields: [{ name: 'AccountsList', radioButtons: true }],
        callback: this.callback
      },
      {
        id: '2',
        name: 'Daily Summaries',
        description: 'Export a summary of sales totals by day over a period of time.',
        type: ReportType.DAILY,
        fields: [{ name: 'TimePeriod' }, { name: 'AccountsList' }],
        callback: this.callback
      },
      {
        id: '3',
        name: 'Deposits',
        description: 'Export a detailed summary of every deposit over a period of time.',
        type: ReportType.DEPOSITS,
        fields: [
          { name: 'TimePeriod', label: 'Created' },
          { name: 'SingleSelectStatus' },
          { name: 'AccountsList' }
        ],
        callback: this.callback
      },
      {
        id: '4',
        name: 'FinCEN CTR Summary',
        description: 'Export a detailed summary of every Currency Transation Report over a period of time.',
        type: ReportType.FINCENCTR,
        fields: [{ name: 'TimePeriod' }, { name: 'SingleSelectStatus' }, { name: 'AccountsList' }],
        callback: this.callback
      },
      {
        id: '5',
        name: 'FinCEN SAR Summary',
        description: 'Export a detailed summary of every Suspicious Activity Report over a period of time.',
        type: ReportType.FINCENSAR,
        fields: [{ name: 'TimePeriod' }, { name: 'SingleSelectStatus' }, { name: 'AccountsList' }],
        callback: this.callback
      },
      {
        id: '6',
        name: 'Raw Sales Data',
        description:
          'Export raw sales data as it was received from the POS or invoice tracking system over a period of time.',
        type: ReportType.RAWSALES,
        fields: [{ name: 'TimePeriod' }, { name: 'AccountsList' }],
        callback: this.callback
      },
      {
        id: '7',
        name: 'Sales Activity',
        description:
          'Export a detailed sales report for a period up to 90 days. For larger reports please use the Daily Summaries report.',
        type: ReportType.SALES,
        fields: [{ name: 'TimePeriod' }, { name: 'MultiSelectStatus' }, { name: 'AccountsList' }],
        callback: this.callback
      },
      {
        id: '8',
        name: 'User Report',
        description: 'Export a report of the users in your org or the users of your CRB accounts.',
        type: ReportType.USER,
        fields: [{ name: 'AccountsList', radioButtons: true }],
        callback: this.callback
      },
      {
        id: '9',
        name: 'Questionnaires Report',
        description:
          'Export a summary report with completion information by account, or a detailed report with all responses.',
        type: ReportType.QUESTIONNAIRES,
        fields: [
          { name: 'TimePeriod', label: 'Sent On' },
          { name: 'AccountsList', radioButtons: true }
        ],
        callback: this.callback
      },
      {
        id: '10',
        name: 'Marijuana Licenses Report',
        description: 'Export a detailed summary of all Marijuana Licenses tied to your accounts.',
        type: ReportType.LICENSE,
        fields: [{ name: 'MultiSelectStatus' }, { name: 'AccountsList' }],
        callback: this.callback
      },
      {
        id: '11',
        name: 'Core Transactions Report',
        description: 'Export a detailed summary of all core transactions tied to your accounts.',
        type: ReportType.TRANSACTION,
        fields: [{ name: 'TimePeriod', label: 'Created' }, { name: 'AccountsList' }],
        callback: this.callback
      },
      {
        id: '12',
        name: 'Comments Report',
        description: 'Export a detailed summary of all comments from your accounts.',
        type: ReportType.COMMENTS,
        fields: [{ name: 'TimePeriod', label: 'Date Range' }, { name: 'AccountsList' }],
        callback: this.callback
      }
    ],
    savingOperationalDetails: false,
    savingBusinessDetails: false,
    showConfirmationDialog: false,
    showModal: false
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  // This function doesn't actually run, possibly because the programmers model is empty
  public load = action(async () => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      this.updateViewModel({});
    });
  });

  public filterTiles = () => {
    const userCanViewDeposits = orgFeatureEnabled(this.bankStore.bank, MainFeatures.Deposits);
    if (!userCanViewDeposits) {
      const tiles = this.viewModel.reports.filter((reportTile) => reportTile.type !== ReportType.DEPOSITS);
      this.updateViewModel({ reports: tiles });
    }
  };

  public confirmationCallback = () => {
    this.setShowConfirmationDialog(true);
  };

  public lookupExportAction = (report: Report | undefined) => {
    if (report === undefined) {
      return () => new Promise<boolean>((resolve) => resolve(true));
    }

    const actions: Record<string, (data: any) => Promise<boolean>> = {
      account: exportAccountSummary,
      daily: exportDailySummaries,
      deposits: exportDeposits,
      finCENCTR: exportFinCENCTRSummary,
      finCENSAR: exportFinCENSARSummary,
      license: exportCRBLicenseData,
      rawSales: exportRawSalesData,
      sales: exportSalesActivity,
      user: exportUserReport,
      questionnaires: exportQuestionnairesReport,
      transaction: exportCoreTransactionReport,
      comments: exportCommentsReport
    };

    return actions[report?.type];
  };

  public setActiveReport = action((report: Report) => {
    this.updateViewModel({ activeReport: report });
  });

  public setShowConfirmationDialog = action((isVisible: boolean) => {
    this.updateViewModel({ showConfirmationDialog: isVisible });
  });

  public setShowModal = action((isVisible: boolean) => {
    this.updateViewModel({ showModal: isVisible });
  });

  public setupSnackbars = action(() => {
    if (this.viewModel.showConfirmationDialog) {
      this.notificationStore.setShowReportSnackbar(false);
    } else {
      this.notificationStore.setShowReportSnackbar(true);
      this.notificationStore.showPendingSnackbars();
    }
  });
}
