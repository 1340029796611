// reflect-metadata must be imported first
// eslint-disable-next-line simple-import-sort/imports
import 'reflect-metadata';
import type { Auth0ProviderOptions } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import { CssBaseline, LinearProgress, ThemeProvider } from '@mui/material';
import { Provider as RollbarProvider } from '@rollbar/react'; // <-- Provider imports 'rollbar' for us
import { getEndpoints } from 'api/api-util/endpoints';
import { Amplify, API, Auth, Storage } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import { useScript } from 'hooks';
import { container, createContainer, InjectionProvider } from 'ioc';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { useIdleTimer } from 'react-idle-timer';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { getUserStore } from 'stores/UserStore';
import { rollbarConfig } from 'third-party-integrations/rollbar';
import { IdleUserModal } from 'ui/organisms/IdleUserModal/idle-user-modal.organism';
import { Messages } from 'ui/organisms/Messages/messages.component';
import { appRoutes } from 'ui/routing/routes';
import { setRedirectUrl } from 'util/auth0-storage.util';
import { ButtonOverlayContextProvider, SavePromptContextProvider } from './app-context';
import { environment } from './environments/environment';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { theme } from './ui/theme';
import { cssOverride } from 'third-party-integrations/flatfile/config';

const auth0Config = {
  ...environment.auth0Config,
  redirectUri: window.location.origin,
  skipRedirectCallback: window.location.pathname === '/secure/crb/company'
} as Auth0ProviderOptions;

Amplify.configure(environment.amplify);
Auth.configure(environment.amplify);
AWS.config.region = environment.amplify.aws_cognito_region;
Storage.configure({ region: environment.amplify.aws_cognito_region });

const Main = () => {
  const [idleModalOpen, setIdleModalOpen] = React.useState(false);

  API.configure({ endpoints: Object.values(getEndpoints(undefined)) });

  // Store URL to handle refresh and redirect after login
  const path = window.location.pathname + window.location.search;
  if (path.includes('/secure/')) {
    setRedirectUrl(path);
  }

  // @ts-expect-error hsConversationsSettings is required by hubspot
  window.hsConversationsSettings = {
    loadImmediately: false,
    inlineEmbedSelector: '#hubspot-live-chat'
  };

  // hubspot form embed script
  useScript('//js.hs-scripts.com/5237927.js', 'hs-script-loader');

  const handleOnIdle = async () => {
    if (getUserStore().isLoggedIn) {
      setIdleModalOpen(true);
    }
  };

  const handleOnActive = () => {
    if (idleModalOpen) {
      setIdleModalOpen(false);
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 min
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  });

  useEffect(() => {
    const isGAEnabled = process.env.NODE_ENV === 'production';
    if (isGAEnabled) {
      ReactGA.initialize('G-YW0ELTK5LE', { gaOptions: { siteSpeedSampleRate: 100 } });
      const { location } = window;
      const page = location.pathname + location.search;
      ReactGA.send({ hitType: 'pageview', page });
    }
  }, []);

  const router = createBrowserRouter(appRoutes);

  return (
    <React.Suspense fallback={<LinearProgress />}>
      <Auth0Provider {...auth0Config}>
        <ThemeProvider theme={theme}>
          <InjectionProvider container={container}>
            <SavePromptContextProvider>
              <ButtonOverlayContextProvider>
                <CssBaseline />
                <Messages />
                {idleModalOpen && <IdleUserModal isOpen={idleModalOpen} setIsOpen={setIdleModalOpen} />}
                <RouterProvider router={router} />
              </ButtonOverlayContextProvider>
            </SavePromptContextProvider>
          </InjectionProvider>
        </ThemeProvider>
      </Auth0Provider>
    </React.Suspense>
  );
};

// instantiate ioc container
createContainer('prod');

const uiContainer = document.getElementById('root');
const root = createRoot(uiContainer!);
const LDProvider = await asyncWithLDProvider({
  clientSideID: environment.gcvConfig.launchDarklyClientSideId,
  user: {
    anonymous: true
  }
});

root.render(
  <LDProvider>
    <RollbarProvider config={rollbarConfig}>
      <style>{cssOverride}</style>
      <Main />
    </RollbarProvider>
  </LDProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
