import type {
  Dispensary,
  InitialUserInfo,
  InputMarketingOrgRegistration,
  MinifiedAccountUser,
  PayqwickUserSession,
  Preferences,
  User,
  UserIdentification,
  UserInterests
} from '@gcv/shared';
import { injectable } from 'inversify';

import { api, banksApi } from './api-util/api';
import type { GreenCheckDirectApplication } from './api-util/api-auth0-session';

@injectable()
export class UsersApi {
  /** @deprecated Use the dispensary/bank/gcv specific /invite-user calls instead */
  async createUser(user: User): Promise<User> {
    return await api().post('/users', user);
  }

  async updateUser(user: User): Promise<User> {
    return await api().put(`/users/${user.id}`, user);
  }

  async updateUserPreferences(id: string, preferences: Preferences): Promise<Preferences> {
    return await api().post(`/users/${id}/preferences`, preferences);
  }

  async deleteUser(userId: string): Promise<User> {
    return await api().delete(`/users/${userId}`, {
      secret: `374732e2-181f-4951-9306-ee1ed30da47c`
    });
  }

  async getUser(userId: string): Promise<User> {
    return await api().get(`/users/${userId}`);
  }

  async updateUserInterests(userId: string, interests: UserInterests[]): Promise<User> {
    return await api().put(`/users/${userId}/interests`, { interests: interests });
  }

  async getUsersForOrg(orgId: string): Promise<User[]> {
    return await api().get(`/organizations/${orgId}/users`);
  }

  async updateLoginEmail(userId: string, newEmail: string) {
    return await api().put(`/users/${userId}/change-login-email`, { newEmail });
  }

  async addUserToOrg(userId: string, orgId: string, body: Record<string, unknown>) {
    return await api().put(`/users/${userId}/companies/${orgId}`, body);
  }

  async removeUserFromOrg(userId: string, orgId: string) {
    return await api().delete(`/users/${userId}/companies/${orgId}`);
  }

  async updateUserIdentification(
    userId: string,
    identificationDetails: {
      address?: string;
      city?: string;
      country?: string;
      dateOfBirth?: string;
      email?: string;
      firstName?: string;
      lastName?: string;
      minitial?: string;
      identification?: {
        idType?: string;
        idNumber?: string;
        idState?: string;
        idFront?: UserIdentification;
        idBack?: UserIdentification;
      };
      phone?: string;
      state?: string;
      zipcode?: string;
      invitation_status?: string;
      archive_date?: string | null;
      lastLogin?: string;
      ssn?: string;
    }
  ): Promise<User> {
    return await api().put(`/users/${userId}/identification`, identificationDetails);
  }

  async updateUserDetails(
    fiId: string,
    crbId: string,
    userId: string,
    identificationDetails: {
      address?: string;
      city?: string;
      country?: string;
      dateOfBirth?: Date;
      email?: string;
      firstName?: string;
      lastName?: string;
      minitial?: string;
      identification?: {
        idType?: string;
        idNumber?: string;
        idState?: string;
        idFront?: UserIdentification;
        idBack?: UserIdentification;
      };
      phone?: string;
      state?: string;
      zipcode?: string;
      invitation_status?: string;
      archive_date?: string | null;
      lastLogin?: string;
    }
  ): Promise<User> {
    return await banksApi().patch(`/banks/${fiId}/crb/${crbId}/user/${userId}/edit-users`, {
      userInfo: identificationDetails
    });
  }

  async acceptTermsOfService(orgName: string) {
    return await api().put('/tos', { orgName });
  }

  async resendInviteEmail(userEmail: string) {
    return await api().get(`/auth/resend-invitation-email`, { email: userEmail });
  }

  async checkMfaRequired(userEmail: string): Promise<{ mfaEnabled: boolean; mfaRequired: boolean }> {
    return await api().get(`/auth/mfa-check`, { username: userEmail });
  }

  async obtainSsoUser(auth0Sub: string): Promise<User> {
    return await api().post(`/auth/obtain-sso-user`, { auth0_sub: auth0Sub });
  }

  async getPqUserSession(app: GreenCheckDirectApplication): Promise<PayqwickUserSession> {
    return await api().get(`/auth/pq-user-session`, { app });
  }

  async getDispensaryUsers(bankId: string, dispensaryId: string): Promise<MinifiedAccountUser[]> {
    return await banksApi().get(`/banks/${bankId}/dispensaries/${dispensaryId}/users`);
  }

  async inviteUser(
    bankId: string,
    dispensaryId: string,
    userData: InitialUserInfo
  ): Promise<{ user: User; org: Dispensary }> {
    return await banksApi().post(`/banks/${bankId}/crb/${dispensaryId}/invite-users`, { userInfo: userData });
  }

  async getUserFeatheryFormResponse(userId: string): Promise<{ form_response: Record<string, unknown> }> {
    return await api().get(`/users/${userId}/marketing-user-onboarding`);
  }

  async updateUserFeatheryFormResponse(userId: string, response: Record<string, unknown>) {
    return await api().put(`/users/${userId}/marketing-user-onboarding`, response);
  }

  async registerMarketingUserOrg(userId: string, data: InputMarketingOrgRegistration) {
    return await api().post(`/users/${userId}/org-registration`, data);
  }

  async resendEmailVerification(email: string) {
    return await api().get(`/auth/resend-email-verification?email=${email}`);
  }
}
