import * as React from 'react';
import { Typography } from '@mui/material';

interface Props {
  content: string;
  dataCy?: string;
  inline?: boolean;
}

export const SubTitle: React.FC<Props> = (props) => {
  return (
    <Typography
      component={props.inline ? 'span' : 'div'}
      data-cy={props.dataCy}
      variant="subtitle1"
      sx={{ color: 'text.secondary' }}
    >
      {props.content}
    </Typography>
  );
};
