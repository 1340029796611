import * as React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import { Text } from 'ui';
import palette from 'ui/theme/palette';

interface Props {
  color?: string;
  dataCy?: string;
  label: string;
  noBottomMargin?: boolean;
  value: string;
}

export const LabelValue: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  return (
    <Box sx={{ marginBottom: props.noBottomMargin ? '0px' : '16px' }} data-cy={props.dataCy}>
      <Text
        type={isMobile ? 'body4' : 'body3'}
        sx={{
          fontWeight: 'bold',
          lineHeight: '15px',
          color: palette.text.secondary,
          marginBottom: '10px'
        }}
        content={props.label}
      />
      <Text
        type={isMobile ? 'body3' : 'body1'}
        sx={{ lineHeight: '20px', color: props.color ? props.color : undefined }}
        content={props.value}
      />
    </Box>
  );
};
