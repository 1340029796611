import * as React from 'react';
import { Box } from '@mui/system';

import type { FilterListChild } from 'ui';
import { Button, Header } from 'ui';

interface Props {
  label: string;
  selectedItems: FilterListChild[];
  onSelectAll: () => void;
  onClearFilter: () => void;
}

export const FilterHeader: React.FC<Props> = (props) => {
  return (
    <Box
      sx={{
        padding: '10px 22px',
        borderBottom: '1px solid',
        borderColor: 'divider',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Header type={'h2'} content={props.label} />
      </Box>
      <Button label={'Select All'} color={'primary-text'} variant={'text'} onClick={props.onSelectAll} />
      {props.selectedItems.length ? (
        <Button label={'Clear All'} color={'primary-text'} variant={'text'} onClick={props.onClearFilter} />
      ) : null}
    </Box>
  );
};
