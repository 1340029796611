import * as React from 'react';
import type { Control, FieldValues, Validate } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { FormControlLabel, FormGroup } from '@mui/material';

import { CheckBox, Error } from 'ui';
import palette from 'ui/theme/palette';
import { errorMessages } from 'util/forms.util';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface CheckboxProps {
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  label: string;
  name: string;
  control?: Control;
  dataCy?: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  readOnly?: boolean;
  viewOnly?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  justifyContent?: 'left' | 'center' | 'right' | 'space-between' | 'space-around';
  checked?: boolean;
  defaultValue?: boolean;
  transformAndSave?: (checked: boolean, onChange: (value: any) => void) => void;
  doNotconsiderFormValue?: boolean;
  doNotDisplayErrors?: boolean;
}

export const InputCheckBox: React.FC<CheckboxProps> = (props) => {
  const labelPlacement = props.labelPlacement ? props.labelPlacement : 'end';

  return (
    <FormGroup row data-cy={props.dataCy}>
      <Controller
        control={props.control}
        name={props.name}
        rules={props.rules}
        defaultValue={props.defaultValue || false}
        render={({ field: { onBlur, onChange, name, value }, fieldState: { error } }) => (
          <>
            <FormControlLabel
              control={
                <CheckBox
                  labelPlacement={labelPlacement}
                  name={name}
                  onBlur={() => {
                    if (props.onBlur) {
                      props.onBlur();
                    }

                    onBlur();
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    if (props.onChange) {
                      props.onChange(e, checked);
                    }

                    if (props.transformAndSave) {
                      props.transformAndSave(checked, onChange);
                    } else {
                      onChange(checked);
                    }
                  }}
                  readOnly={props.readOnly || props.viewOnly}
                  value={!!props.checked || (!(props.doNotconsiderFormValue || false) && !!value)}
                />
              }
              data-cy="label"
              disabled={props.readOnly || props.viewOnly}
              label={props.label}
              labelPlacement={labelPlacement}
              sx={{
                alignItems: 'flex-start',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: 400,
                ml: '-4px',
                '& .MuiCheckbox-root.MuiCheckbox-root.Mui-checked.Mui-disabled': {
                  color: props.viewOnly ? palette.primary.main : palette.text.disabled
                },
                '& .MuiCheckbox-root.MuiCheckbox-root.MuiButtonBase-root.Mui-disabled': {
                  color: props.viewOnly ? palette.primary.main : palette.text.disabled
                },
                '& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.Mui-disabled': {
                  color:
                    props.viewOnly && (props.checked || value)
                      ? palette.text.primary
                      : props.readOnly
                      ? palette.text.disabled
                      : palette.text.secondary
                },
                '& .MuiCheckbox-root.MuiCheckbox-root:not(.Mui-checked)': {
                  color: palette.text.secondary
                },
                '& .MuiCheckbox-root.MuiCheckbox-root:hover': {
                  cursor: props.readOnly ? 'not-allowed' : 'default'
                }
              }}
            />

            {!(props.doNotDisplayErrors || false) && (
              <Error
                content={error ? `* ${props.label} ${error.message || errorMessages()[error.type]}` : ''}
              />
            )}
          </>
        )}
      />
    </FormGroup>
  );
};
