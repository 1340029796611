import {
  CTRValidateFederalRegulatorCodeType,
  SARValidateFederalRegulatorCodeType,
  ValidateCyberEventIndicatorsTypeCode,
  ValidateOrganizationCodeType,
  ValidatePartyIdentificationCodeType
} from '@gcv/shared';

import type { SelectOption } from 'ui';

export const ctrFinancialInstitutions: SelectOption[] = [
  {
    value: ValidateOrganizationCodeType.Casino_Card_Club,
    label: 'Casino/Card Club'
  },
  {
    value: ValidateOrganizationCodeType.Depository_Institution,
    label: 'Depository Institution'
  },
  {
    value: ValidateOrganizationCodeType.Money_Services_Business_MSB,
    label: 'Money Services Business (MSB)'
  },
  {
    value: ValidateOrganizationCodeType.Securities_Futures,
    label: 'Securities/Futures'
  },
  {
    value: ValidateOrganizationCodeType.Housing_GSE,
    label: 'Housing GSE'
  },
  { value: ValidateOrganizationCodeType.Other, label: 'Other' }
];

export const sarFinancialInstitutions: SelectOption[] = [
  {
    value: ValidateOrganizationCodeType.Casino_Card_Club,
    label: 'Casino/Card Club'
  },
  {
    value: ValidateOrganizationCodeType.Depository_Institution,
    label: 'Depository Institution'
  },
  {
    value: ValidateOrganizationCodeType.Insurance_company,
    label: 'Insurance Company'
  },
  {
    value: ValidateOrganizationCodeType.Money_Services_Business_MSB,
    label: 'MSB'
  },
  {
    value: ValidateOrganizationCodeType.Securities_Futures,
    label: 'Securities/Futures'
  },
  {
    value: ValidateOrganizationCodeType.Loan_Or_Finance_Company,
    label: 'Loan or Finance Company'
  },
  {
    value: ValidateOrganizationCodeType.Housing_GSE,
    label: 'Housing GSE'
  },
  { value: ValidateOrganizationCodeType.Other, label: 'Other' }
];

export const primaryFederalRegulators: SelectOption[] = [
  { value: 'FRB', label: 'FRB - Federal Reserve Board' },
  {
    value: 'FDIC',
    label: 'FDIC - Federal Deposit Insurance Corporation'
  },
  { value: 'IRS', label: 'IRS - Internal Revenue Service' },
  { value: 'NCUA', label: 'NCUA - National Credit Union Administration' },
  {
    value: 'OCC',
    label: 'OCC - Office of the Comptroller of the Currency'
  }
];

export const fincenPrimaryFederalRegulators: SelectOption[] = [
  { value: CTRValidateFederalRegulatorCodeType.FRB, label: 'FRB - Federal Reserve Board' },
  {
    value: CTRValidateFederalRegulatorCodeType.FDIC,
    label: 'FDIC - Federal Deposit Insurance Corporation'
  },
  { value: CTRValidateFederalRegulatorCodeType.IRS, label: 'IRS - Internal Revenue Service' },
  {
    value: CTRValidateFederalRegulatorCodeType.NCUA,
    label: 'NCUA - National Credit Union Administration'
  },
  {
    value: CTRValidateFederalRegulatorCodeType.OCC,
    label: 'OCC - Office of the Comptroller of the Currency'
  }
];

export const sarPrimaryRegulatorTypeCode: SelectOption[] = [
  {
    value: SARValidateFederalRegulatorCodeType.CFTC,
    label: 'CFTC (Commodities Futures Trading Commission)'
  },
  {
    value: SARValidateFederalRegulatorCodeType.Federal_Reserve,
    label: 'Federal Reserve'
  },
  {
    value: SARValidateFederalRegulatorCodeType.FDIC,
    label: 'FDIC (Federal Deposit Insurance Corporation)'
  },
  {
    value: SARValidateFederalRegulatorCodeType.FHFA,
    label: 'FHFA (Federal Housing Finance Agency)'
  },
  {
    value: SARValidateFederalRegulatorCodeType.IRS,
    label: 'IRS (Internal Revenue Service)'
  },
  {
    value: SARValidateFederalRegulatorCodeType.NCUA,
    label: 'NCUA (National Credit Union Administration)'
  },
  {
    value: SARValidateFederalRegulatorCodeType.OCC,
    label: 'OCC (Office of the Comptroller of the Currency)'
  },
  {
    value: SARValidateFederalRegulatorCodeType.SEC,
    label: 'SEC (Securities and Exchange Commission)'
  },
  {
    value: SARValidateFederalRegulatorCodeType.Not_applicable,
    label: 'Not Applicable'
  }
];

export const sarTinTypes: SelectOption[] = [
  { value: ValidatePartyIdentificationCodeType.EIN, label: 'EIN' },
  {
    value: ValidatePartyIdentificationCodeType.SSN_ITIN,
    label: 'SSN-ITIN'
  },
  { value: ValidatePartyIdentificationCodeType.Foreign, label: 'Foreign' }
];

export const tinTypes: SelectOption[] = sarTinTypes;

export const financialInstitutionIdentificationTypes: SelectOption[] = [
  {
    value: ValidatePartyIdentificationCodeType.CRD_Number,
    label: 'Central Registration Depository (CRD) number'
  },
  {
    value: ValidatePartyIdentificationCodeType.IARD_Number,
    label: 'Investment Adviser Registration Depository (IARD) number'
  },
  {
    value: ValidatePartyIdentificationCodeType.NFA_ID_Number,
    label: 'National Futures Association (NFA) number'
  },
  {
    value: ValidatePartyIdentificationCodeType.RSSD_Number,
    label: 'Research, Statistics, Supervision and Discount (RSSD) number'
  },
  {
    value: ValidatePartyIdentificationCodeType.SEC_Number,
    label: 'Securities and Exchange Commission (SEC) number'
  },
  {
    value: ValidatePartyIdentificationCodeType.NAIC_Number,
    label: 'National Association of Insurance Commissioners (NAIC Code)'
  },
  { value: ValidatePartyIdentificationCodeType.NMLS_Id_Number, label: 'Mortgage (NMLS ID)' }
];

export const sarFinancialInstitutionIdentificationTypes: SelectOption[] = [
  {
    value: ValidatePartyIdentificationCodeType.CRD_Number,
    label: 'Central Registration Depository (CRD) number'
  },
  {
    value: ValidatePartyIdentificationCodeType.IARD_Number,
    label: 'Investment Adviser Registration Depository (IARD) number'
  },
  {
    value: ValidatePartyIdentificationCodeType.NFA_ID_Number,
    label: 'National Futures Association (NFA) number'
  },
  {
    value: ValidatePartyIdentificationCodeType.RSSD_Number,
    label: 'Research, Statistics, Supervision and Discount (RSSD) number'
  },
  {
    value: ValidatePartyIdentificationCodeType.SEC_Number,
    label: 'Securities and Exchange Commission (SEC) number'
  },
  {
    value: ValidatePartyIdentificationCodeType.NAIC_Number,
    label: 'National Association of Insurance Commissioners (NAIC Code)'
  },
  {
    value: ValidatePartyIdentificationCodeType.NMLS_Id_Number,
    label: 'Mortgage (NMLS ID)'
  }
];

export const cyberEventIndicators: SelectOption[] = [
  {
    value: ValidateCyberEventIndicatorsTypeCode.Command_And_Control_IP_Address,
    label: 'Command and control IP address'
  }, //ValidateCyberEventIndicatorsTypeCode
  {
    value: ValidateCyberEventIndicatorsTypeCode.Command_And_Control_URL_Domain,
    label: 'Command and control URL/domain'
  },
  {
    value: ValidateCyberEventIndicatorsTypeCode.Malware_MD5_Malware_SHA_1_Or_Malware_SHA_256,
    label: 'Malware MD5, Malware SHA-1, or Malware SHA-256'
  },
  {
    value: ValidateCyberEventIndicatorsTypeCode.Media_Access_Control_MAC_Address,
    label: 'Media Access Control (MAC) Address'
  },
  { value: ValidateCyberEventIndicatorsTypeCode.Port, label: 'Port' },
  { value: ValidateCyberEventIndicatorsTypeCode.Suspicious_Email_Address, label: 'Suspicious email address' },
  { value: ValidateCyberEventIndicatorsTypeCode.Suspicious_File_Name, label: 'Suspicious file name' },
  { value: ValidateCyberEventIndicatorsTypeCode.Suspicious_IP_Address, label: 'Suspicious IP address' },
  { value: ValidateCyberEventIndicatorsTypeCode.Suspicious_IP_Address, label: 'Suspicious URL/domain' },
  { value: ValidateCyberEventIndicatorsTypeCode.Targeted_System, label: 'Targeted system' },
  { value: ValidateCyberEventIndicatorsTypeCode.Other, label: 'Other' }
];
