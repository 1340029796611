import type { FilterSelectOption } from 'ui';

type DataTransform = (data: FilterSelectOption[]) => FilterSelectOption[];

const toFlat: DataTransform = (data: FilterSelectOption[]) => {
  const flatList: FilterSelectOption[] = [];

  function flatten(list: FilterSelectOption[]) {
    for (const item of list.filter((i) => i.selected)) {
      if (item.children && item.children.length > 0) {
        flatList.push({ label: item.label, value: item.value });
        flatten(item.children);
      } else {
        flatList.push({ label: item.label, value: item.value });
      }
    }
  }

  flatten(data);

  return flatList;
};

const toSelected: DataTransform = (data: FilterSelectOption[]) => {
  const selected = data
    .filter((item) => item.selected)
    .map((option) => {
      const selectedChildren = option.children
        ?.filter((item) => item.selected)
        .map((c) => {
          return { label: c.label, value: c.value };
        });
      return { label: option.label, value: option.value, children: selectedChildren };
    });

  return selected;
};

const toRaw: DataTransform = (data: FilterSelectOption[]) => {
  return data;
};

export class DataTransformer {
  public flat = toFlat;
  public raw = toRaw;
  public selected = toSelected;
}
