import * as React from 'react';
import type { IANATimezones } from '@gcv/shared';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  FormGroup,
  Icon,
  MenuItem,
  MenuList
} from '@mui/material';

import type { FilterListChild, FilterListItem } from 'ui';
import { Button, CheckBox, Text } from 'ui';
import { FilterListSectionItem } from '..';

const ExpandIcon = () => (
  <Box
    sx={{
      '& > .y': {
        display: 'flex'
      },
      '& > .n': {
        display: 'none'
      },
      '.Mui-expanded & > .n': {
        display: 'flex'
      },
      '.Mui-expanded & > .y': {
        display: 'none'
      }
    }}
  >
    <div className="n">
      <Icon>expand_less</Icon>
    </div>
    <div className="y">
      <Icon>keyboard_arrow_right</Icon>
    </div>
  </Box>
);

interface Props {
  listItem: FilterListItem;
  onChange: (listItem: FilterListChild) => void;
  key: string;
  selectedList: FilterListChild[];
  selectAllState: number;
  searchTerm: string;
  clearSubItems: (listItems: FilterListChild[]) => void;
  selectSubItems: (listItems: FilterListChild[], parentValue: string) => void;
  timezone?: IANATimezones;
  hasParentWithAndWithOutChild?: boolean;
}

export const FilterListSection: React.FC<Props> = (props) => {
  const getSelectedItemsFromChildren = () => {
    let counter = 0;
    props.listItem.children.forEach((child) => {
      if (child.selected) counter += 1;
    });
    return counter;
  };

  const [selectedItems, setSelectedItems] = React.useState(getSelectedItemsFromChildren());
  const onSelectMenuItem = (
    checked: boolean,
    setChecked: React.Dispatch<React.SetStateAction<boolean>>,
    listItem: FilterListChild
  ) => {
    props.onChange(listItem);
    listItem.selected = !listItem.selected;
    listItem.parentValue = props.listItem.value;
    const newChecked = !checked;
    setChecked(newChecked);
    if (listItem.selected) {
      setSelectedItems(selectedItems + 1);
    } else {
      setSelectedItems(selectedItems - 1);
    }
  };

  const onClearSelected = (event: React.SyntheticEvent | React.MouseEvent) => {
    event.stopPropagation();
    localClearSelect();
    props.clearSubItems(props.listItem.children);
  };

  const localClearSelect = () => {
    props.listItem.children = props.listItem.children?.map((subItem: FilterListChild) => {
      subItem.selected = false;
      return subItem;
    });
    setSelectedItems(0);
  };

  const onCheckParent = (event: React.SyntheticEvent | React.MouseEvent) => {
    event.stopPropagation();
    if (!props.listItem.disabled) {
      if (selectedItems === 0) {
        localSelectAll();
        props.selectSubItems(props.listItem.children, props.listItem.value);
      } else if (selectedItems < props.listItem.children.length) {
        localSelectAll();
        props.selectSubItems(props.listItem.children, props.listItem.value);
      } else {
        localClearSelect();
        props.selectSubItems([], props.listItem.value);
      }
    }
  };

  const localSelectAll = () => {
    let counter = 0;
    props.listItem.children = props.listItem.children?.map((subItem: FilterListChild) => {
      if (subItem.visible !== false) {
        subItem.selected = true;
        counter += 1;
      }
      return subItem;
    });
    setSelectedItems(counter);
  };

  React.useEffect(() => {
    if (props.selectedList.length > 0) return;
    else {
      localClearSelect();
    }
  }, [props.selectedList]);

  React.useEffect(() => {
    if (props.selectAllState) {
      setSelectedItems(getSelectedItemsFromChildren());
    }
  }, [props.selectAllState]);

  return (
    <Accordion
      sx={{
        '&.MuiAccordion-root': {
          boxShadow: 'none'
        },
        '&:before': { backgroundColor: 'transparent' }
      }}
      disableGutters
      defaultExpanded={true}
    >
      <AccordionSummary
        sx={{
          flexDirection: 'row-reverse',
          padding: '4px 0 0 15px',
          minHeight: '34px',
          display: 'flex',
          alignItems: 'flex-start',
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            margin: '0'
          },
          '&:hover': {
            backgroundColor: 'primary.screen'
          },
          '& .MuiCheckbox-root': {
            borderColor: 'transparent'
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            visibility: props.listItem.children.length > 0 ? 'visible' : 'hidden'
          }
        }}
        expandIcon={props.hasParentWithAndWithOutChild ? <ExpandIcon /> : undefined}
      >
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            '& .MuiMenuItem-root:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <FormGroup
            sx={{
              padding: '0',
              margin: '0',
              '&:hover': {
                backgroundColor: 'transparent'
              },
              '& .MuiMenuItem-root.MuiMenuItem-gutters': { padding: '.1rem 0rem .5rem .5rem' },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: 'transparent'
              },
              '& .MuiFormControlLabel-label': {
                fontSize: '15px',
                fontWeight: '400'
              },
              '& .MuiCheckbox-root': {
                borderColor: 'transparent',
                marginRight: '0.1rem'
              }
            }}
          >
            <FormControlLabel
              style={{ display: 'flex', alignItems: 'flex-start', paddingRight: '12px' }}
              disabled={props.listItem.disabled}
              control={
                <MenuItem key={props.listItem.value} disableRipple>
                  <CheckBox
                    value={
                      (selectedItems === props.listItem.children.length &&
                        props.listItem.children.length !== 0 &&
                        selectedItems !== 0) ||
                      (props.listItem.children.length === 0 && (props.listItem.selected || false))
                    }
                    indeterminate={selectedItems > 0 && selectedItems !== props.listItem.children.length}
                    onClick={onCheckParent}
                    labelPlacement={'end'}
                    name={props.listItem.value}
                  />
                </MenuItem>
              }
              label={props.listItem.label}
              sx={{
                marginRight: '0',
                marginLeft: '-10px'
              }}
            />
          </FormGroup>
        </Box>
        {selectedItems > 0 ? (
          <Button
            variant={'text'}
            onClick={onClearSelected}
            label={'Clear'}
            color={'primary-text'}
            style={{ padding: '0', height: 'min-content' }}
          />
        ) : null}
      </AccordionSummary>
      {props.listItem.children.length > 0 && (
        <AccordionDetails
          sx={{
            padding: '0'
          }}
        >
          {props.listItem.children?.filter((child) => child.visible !== false).length ? (
            <MenuList>
              {props.listItem.children.map((subListItem) => (
                <FilterListSectionItem
                  subListItem={subListItem}
                  onSelectMenuItem={onSelectMenuItem}
                  selectedItems={selectedItems}
                  key={subListItem.value}
                  selectAllState={props.selectAllState}
                  parentTotal={props.listItem.children.length}
                  searchTerm={props.searchTerm}
                  timezone={props.timezone}
                ></FilterListSectionItem>
              ))}
            </MenuList>
          ) : (
            <Text content="No results" type="body2" sx={{ marginLeft: '5px' }} />
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
