import * as React from 'react';
import { useParams } from 'react-router';
import type {
  CreateDocumentsRequest,
  DocumentUpload,
  OnboardingDocumentRequirement,
  OnboardingDocumentRequirementResult
} from '@gcv/shared';
import { CommentType } from '@gcv/shared';
import { useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import { ReactComponent as DocumentIcon } from 'assets/images/document-icon.svg';
import { ReactComponent as EditIcon } from 'assets/images/ic-pencil.svg';
import { ReactComponent as TrashIcon } from 'assets/images/ic-trash.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { Button, CommentBadge, Container, ContainerItem, Dialog, Text, Tooltip } from 'ui';
import { OnboardingPresenter } from 'ui/apps/crb/onboarding-two/onboarding.presenter';
import { UploadDocumentModalV2 } from 'ui/organisms/UploadDocumentsModal/UploadDocumentModalV2';

export const FileUploadedBox: React.FC<{
  requirement: OnboardingDocumentRequirement;
  result?: OnboardingDocumentRequirementResult;
  documentUpload: DocumentUpload;
  documentId: string;
}> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { bank_id } = useParams() as { bank_id: string };

  const [fileUploadOpen, setFileUploadOpen] = React.useState(false);
  const [removeFileModalOpen, setRemoveFileModalOpen] = React.useState(false);
  const filenameCharacterLimit = isMobile && props.requirement.category === 'license' ? 16 : 20;

  const removeFileFromRequirement = async () => {
    await presenter.removeDocumentFromRequirement(props.requirement, props.documentId);
    setRemoveFileModalOpen(false);
  };

  return (
    <>
      <Container
        align="center"
        justify="center"
        padding={isMobile ? '12px' : '12px 22px'}
        nowrap
        minWidth="200px"
      >
        <ContainerItem flex padding={0}>
          <DocumentIcon width="24px" />
        </ContainerItem>
        <ContainerItem padding="0 12px 0 0">
          <Tooltip
            title={props.documentUpload?.file_name}
            disabled={props.documentUpload?.file_name.length <= filenameCharacterLimit}
          >
            <Text
              content={
                props.documentUpload?.file_name.length > filenameCharacterLimit
                  ? `${props.documentUpload?.file_name.slice(0, filenameCharacterLimit)}...`
                  : props.documentUpload?.file_name
              }
              type="body3"
            />
          </Tooltip>
        </ContainerItem>

        <ContainerItem flex dataCy="comment-added-container" padding="0 4px 0 0">
          <CommentBadge
            post={{
              type: CommentType.REQUIREMENT_DOCUMENT,
              title: props.documentUpload?.file_name,
              idComponents: {
                requirementId: props.requirement.requirement_id,
                documentId: props.documentId,
                crbId: presenter.crbDispensaryStore.currentDispensary.id,
                fiId: bank_id
              }
            }}
            thinIcon
          />
        </ContainerItem>
        {props.requirement.category === 'license' && (
          <ContainerItem flex padding="0">
            <Box
              sx={{
                cursor: 'pointer',
                display: 'flex',
                '& svg path': { fill: '#C6CCDA' },
                ':hover svg path': { fill: '#3A3C45' }
              }}
            >
              <EditIcon onClick={() => setFileUploadOpen(true)} />
            </Box>
          </ContainerItem>
        )}
        <ContainerItem flex align="center" dataCy="remove-comment-container" padding="0">
          <Box
            sx={{
              cursor: 'pointer',
              display: 'flex',
              '& svg path': { fill: '#C6CCDA' },
              ':hover svg path': { fill: '#3A3C45' }
            }}
          >
            <TrashIcon data-cy="remove-comment-icon" onClick={() => setRemoveFileModalOpen(true)} />
          </Box>
        </ContainerItem>
      </Container>

      <UploadDocumentModalV2
        dispensary={presenter.crbDispensaryStore.currentDispensary}
        companyType={'dispensary'}
        addDocumentsToRequirement={async (documentsRequest: CreateDocumentsRequest) => {
          await presenter.addDocsToRequirement(
            documentsRequest,
            props.documentUpload,
            props.requirement,
            props.documentId
          );
        }}
        requirement={props.requirement}
        userMap={presenter.viewModel.dispensaryStaff}
        modalOpen={fileUploadOpen}
        toggleModal={setFileUploadOpen}
        existingFiles={[props.documentUpload]}
        documents={
          props.result &&
          Object.values(props.result.documents).filter(
            (d) => props.documentUpload && props.documentUpload.id === d.id
          )
        }
      />

      <Dialog
        action={
          <Button
            label="Remove"
            color="warning"
            onClick={removeFileFromRequirement}
            dataCy="remove-button"
            isLoading={
              presenter.viewModel.isLoadingDocumentChange || presenter.viewModel.isLoadingDocumentUpload
            }
          />
        }
        handleClose={() => setRemoveFileModalOpen(false)}
        isOpen={removeFileModalOpen}
        title={'Remove File'}
        dataCy="remove-file-modal"
      >
        <>Are you sure you want to remove {props.documentUpload?.file_name}?</>
      </Dialog>
    </>
  );
});
