import { Box } from '@mui/system';

import { ReactComponent as FilterIcon } from 'assets/images/ic-filter.svg';
import type { FilterListChild } from 'ui';
import { Badge } from 'ui';
import palette from 'ui/theme/palette';

interface Props {
  selectedFilters: FilterListChild[] | string[];
  toggleFilter: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  filterToggle?: boolean;
  disabled?: boolean;
}

export const FilterToggleButton: React.FC<Props> = (props) => {
  return (
    <Badge badgeContent={props.selectedFilters.length} color="primary" overlap="circular">
      <Box
        sx={{
          borderRadius: '50%',
          border: props.filterToggle
            ? `1px solid ${palette.primary.main}`
            : `1px solid ${palette.text.disabled}`,
          boxShadow: props.filterToggle ? `0px 0px 0px 3px rgba(3, 166, 91, 0.25)` : '0px',
          background: `${palette.background.paper}`,
          height: '36px',
          width: '36px',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
          '& svg': {
            '&: hover': {
              '& path': {
                fill: `${palette.primary.main}`
              }
            }
          },
          '&: hover': {
            background: `${palette.background.paper}`,
            border: `1px solid ${palette.primary.main}`,
            '& path': {
              fill: `${palette.primary.main}`
            }
          }
        }}
        onClick={(e) => {
          if (props.disabled) {
            return;
          }

          props.toggleFilter(e);
        }}
        data-cy="filter-button"
      >
        <FilterIcon />
      </Box>
    </Badge>
  );
};
