import type { DailySummary, MinifiedDispensary, Sale } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';

import { DispensariesApi } from 'api';
import { FiDispensaryStore } from 'stores/FiDispensaryStore';
import { getUtcDateTimeRangeForDay } from 'util/dateRange.util';

export interface DailySummaryWithAbsTotals extends DailySummary {
  verified_total_abs: number;
  unverified_total_abs: number;
  uncheckable_total_abs: number;
}

export interface PM {
  currentDispensary: MinifiedDispensary;
  lastSyncedDate: string;
  loadingDispensary: boolean;
  loadingSales: boolean;
  loadingSummary: boolean;
  sales: Sale[];
  summary: DailySummaryWithAbsTotals;
  summaryDate: string;
}

@injectable()
export class FiDailySummariesDetailsRepo {
  @inject(DispensariesApi) private dispensariesApi: DispensariesApi;
  @inject(FiDispensaryStore) private dispensaryStore: FiDispensaryStore;

  constructor() {
    makeAutoObservable(this);
  }

  public programmersModel: PM = {
    currentDispensary: {} as MinifiedDispensary,
    lastSyncedDate: '',
    loadingDispensary: true,
    loadingSales: true,
    loadingSummary: true,
    sales: [],
    summary: {} as DailySummaryWithAbsTotals,
    summaryDate: ''
  };

  load = async (date: string, id: string) => {
    this.updateProgrammersModel({ loadingDispensary: true, loadingSummary: true, loadingSales: true });
    this.setCurrentDispensary(id);
    this.updateProgrammersModel({ summaryDate: date });

    await this.getDailySummary();
    await this.getSales();
    await this.getLastSyncedDate();
  };

  getDailySummary = action(async () => {
    this.updateProgrammersModel({ loadingSummary: true });

    //banksApi has a getDailySummaries method that uses nicknames but does not retrive the same sales information.
    const summaries = await this.dispensariesApi.getDailySummaries(
      this.programmersModel.currentDispensary.id,
      this.programmersModel.summaryDate,
      this.programmersModel.summaryDate
    );
    const summary = summaries[0];
    //summary object does not come with nickname from bank
    summary.dispensary_name = this.programmersModel.currentDispensary.name;
    runInAction(() => {
      this.updateProgrammersModel({
        summary: {
          ...summary,
          verified_total_abs:
            Math.abs(summary.verified_ach) +
            Math.abs(summary.verified_cash) +
            Math.abs(summary.verified_check) +
            Math.abs(summary.verified_credit) +
            Math.abs(summary.verified_debit) +
            Math.abs(summary.verified_other) +
            Math.abs(summary.verified_wire),
          unverified_total_abs:
            Math.abs(summary.unverified_ach) +
            Math.abs(summary.unverified_cash) +
            Math.abs(summary.unverified_check) +
            Math.abs(summary.unverified_credit) +
            Math.abs(summary.unverified_debit) +
            Math.abs(summary.unverified_other) +
            Math.abs(summary.unverified_wire),
          uncheckable_total_abs:
            Math.abs(summary.uncheckable_ach) +
            Math.abs(summary.uncheckable_cash) +
            Math.abs(summary.uncheckable_check) +
            Math.abs(summary.uncheckable_credit) +
            Math.abs(summary.uncheckable_debit) +
            Math.abs(summary.uncheckable_other) +
            Math.abs(summary.uncheckable_wire)
        },
        loadingSummary: false
      });
    });
  });

  getSales = action(async () => {
    this.updateProgrammersModel({ loadingSales: true });

    const { start, end } = getUtcDateTimeRangeForDay(
      this.programmersModel.summaryDate,
      this.programmersModel.currentDispensary.iana_timezone
    );

    const sales = await this.dispensariesApi.getSales(this.programmersModel.currentDispensary.id, start, end);

    runInAction(() => {
      this.updateProgrammersModel({ sales: sales, loadingSales: false });
    });
  });

  getLastSyncedDate = action(async () => {
    const lastSynced = await this.dispensariesApi.getLastSyncedData(
      this.programmersModel.currentDispensary.id
    );

    runInAction(() => {
      this.updateProgrammersModel({ lastSyncedDate: lastSynced?.stopDate });
    });
  });

  setCurrentDispensary = action((dispensaryId: string) => {
    this.updateProgrammersModel({ loadingDispensary: true });

    const dispensary = this.dispensaryStore.dispensaries.find((d) => d.id === dispensaryId);

    if (dispensary) {
      this.updateProgrammersModel({
        currentDispensary: dispensary,
        loadingDispensary: false
      });
    }
  });

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });
}
