import * as React from 'react';
import { DialogActions } from '@mui/material';

interface Props {
  children?: React.ReactNode;
}

export const DialogFooter: React.FC<Props> = (props) => {
  return (
    <DialogActions sx={{ padding: '0' }} data-cy="dialog-footer">
      {props.children}
    </DialogActions>
  );
};
