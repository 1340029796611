import type { FieldValues } from 'react-hook-form';
import type {
  DocumentUpload,
  QuestionnaireCommentIdComponents,
  QuestionnaireResponse,
  QuestionnaireResultResponse
} from '@gcv/shared';
import { CommentType, QuestionnaireStatus } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';

import { CommentStore } from '../../../../../../stores/CommentStore';
import { CrbBankStore } from '../../../../../../stores/CrbBankStore';
import { CrbDispensaryStore } from '../../../../../../stores/CrbDispensaryStore';
import { SnackbarStore } from '../../../../../../stores/SnackBarStore';
import { DateTimeHelpers } from '../../../../../../util/dateTime.util';
import { getModifiedDateText } from '../../../../../../util/format.util';
import { hasDispensaryFulfilledAllAddtionalRequirements } from '../../../../../../util/org.util';
import type { CrbEditViewQuestionnaireProgrammersModel } from './questionnaire.repo';
import { CrbEditViewQuestionnaireRepo } from './questionnaire.repo';

export interface VM {
  isLoading: boolean;
  isSaving: boolean;

  bankId: string;
  questionnaireName: string;
  questionnaireDescription: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  status: QuestionnaireStatus;
  statusText: FriendlyQuestionnaireStatus;
  dueDate: string;
  allDocumentsMap: { [id: string]: DocumentUpload };

  questionnaireResponse: QuestionnaireResponse;
  questionnaireResultResponse: QuestionnaireResultResponse;
  allRequiredFieldsComplete: boolean;
}

type FriendlyQuestionnaireStatus = 'In Progress' | 'Completed' | 'Canceled' | 'New';

@injectable()
export class CrbEditViewQuestionnairePresenter {
  @inject(CrbEditViewQuestionnaireRepo)
  public repo: CrbEditViewQuestionnaireRepo;

  @inject(CrbDispensaryStore)
  public crbDispensaryStore: CrbDispensaryStore;

  @inject(CrbBankStore)
  public crbBankStore: CrbBankStore;

  @inject(SnackbarStore)
  public snackBarStore: SnackbarStore;

  @inject(CommentStore)
  public commentStore: CommentStore;

  constructor() {
    makeAutoObservable(this);
  }

  public viewModel: VM = {
    isLoading: true,
    isSaving: false,
    bankId: '',
    questionnaireName: '',
    questionnaireDescription: '',
    lastModifiedDate: '',
    lastModifiedBy: '',
    status: QuestionnaireStatus.Open,
    statusText: 'New',
    dueDate: '',
    allDocumentsMap: {},
    questionnaireResponse: {} as QuestionnaireResponse,
    questionnaireResultResponse: {} as QuestionnaireResultResponse,
    allRequiredFieldsComplete: false
  };

  public updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  public load = action(async (questionnaireId: string, questionnaireResultId: string) => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as CrbEditViewQuestionnaireProgrammersModel;

      let updatedViewModel: VM = { ...this.viewModel, isLoading: programmersModel.isLoading };

      if (programmersModel.isLoading === false) {
        const serviceProvider = this.crbBankStore.banks[programmersModel.questionnaire.bank_id];

        const lastModifiedUserId =
          programmersModel.questionnaireResult.status === QuestionnaireStatus.Completed &&
          programmersModel.questionnaireResult.custom_requirement_completed_by
            ? programmersModel.questionnaireResult.custom_requirement_completed_by
            : programmersModel.questionnaireResult.custom_requirement_modified_by;
        const lastModifiedUser = this.crbDispensaryStore.currentDispensaryStaff.find(
          (s) => s.id === lastModifiedUserId
        );

        updatedViewModel = {
          ...updatedViewModel,
          bankId: programmersModel.questionnaire.bank_id,
          questionnaireName: programmersModel.questionnaire.name,
          questionnaireDescription: programmersModel.questionnaire.description,
          lastModifiedDate:
            programmersModel.questionnaireResult.status === QuestionnaireStatus.Completed &&
            programmersModel.questionnaireResult.custom_requirement_completed_on
              ? getModifiedDateText(programmersModel.questionnaireResult.custom_requirement_completed_on)
              : getModifiedDateText(programmersModel.questionnaireResult.date_updated),
          lastModifiedBy: lastModifiedUser
            ? `${lastModifiedUser.firstName} ${lastModifiedUser.lastName}`
            : '--',
          status: programmersModel.questionnaireResult.status,
          statusText: this.getStatusText(programmersModel.questionnaireResult.status),
          dueDate: DateTimeHelpers.formatISOToTableDateString(
            programmersModel.questionnaireResult.due_schedule.due_date,
            serviceProvider.iana_timezone
          ),
          questionnaireResponse: programmersModel.questionnaire,
          questionnaireResultResponse: programmersModel.questionnaireResult,
          allDocumentsMap: programmersModel.allDocumentsMap,
          allRequiredFieldsComplete: hasDispensaryFulfilledAllAddtionalRequirements(
            programmersModel.questionnaire,
            programmersModel.questionnaireResult
          )
        };
      }

      this.updateViewModel(updatedViewModel);
    });

    await this.repo.load(questionnaireId, questionnaireResultId);

    this.commentStore.setCurrentPost({
      type: CommentType.QUESTIONNAIRE,
      title: this.viewModel.questionnaireResponse.name,
      idComponents: {
        crbId: this.crbDispensaryStore.currentDispensary.id,
        questionnaireId,
        fiId: this.viewModel.questionnaireResponse.bank_id,
        questionnaireResultId
      } as QuestionnaireCommentIdComponents
    });
  });

  private getStatusText = (status: QuestionnaireStatus): FriendlyQuestionnaireStatus => {
    switch (status) {
      case QuestionnaireStatus.InProgress:
      case QuestionnaireStatus.ReadyToComplete:
        return 'In Progress';
      case QuestionnaireStatus.Completed:
        return 'Completed';
      case QuestionnaireStatus.Cancelled:
        return 'Canceled';
      case QuestionnaireStatus.Open:
      default:
        return 'New';
    }
  };

  autoSaveQuestionnaireSection = action(
    async (section: { customFieldValues: { responses: Record<string, any> }; sectionId: string }) => {
      try {
        this.updateViewModel({ isSaving: true });
        await this.repo.saveQuestionnaireSection(section);
      } catch (e) {
        this.snackBarStore.showErrorSnackbarMessage('There was an issue saving the questionnaire');
      } finally {
        this.updateViewModel({ isSaving: false });
      }
    }
  );

  completeQuestionnaire = action(async () => {
    try {
      this.updateViewModel({ isSaving: true });
      await this.repo.completeQuestionnaire();
      this.snackBarStore.showSuccessSnackbarMessage('Questionnaire successfully submitted!');
    } catch (e) {
      this.snackBarStore.showErrorSnackbarMessage('There was an issue submitting the questionnaire');
    } finally {
      this.updateViewModel({ isSaving: false });
    }
  });

  getFormValues = () => {
    if (this.viewModel.questionnaireResultResponse.custom_requirement_result === undefined) return {};
    const updatedValues = {} as FieldValues;
    for (
      let index = 0;
      index < this.viewModel.questionnaireResultResponse.custom_requirement_result.length;
      index++
    ) {
      const customResult = this.viewModel.questionnaireResultResponse.custom_requirement_result[index];
      if (
        customResult.custom_fields &&
        customResult.custom_fields.responses &&
        Object.keys(customResult.custom_fields.responses).length > 0
      ) {
        Object.entries(customResult.custom_fields.responses).forEach((entry) => {
          updatedValues[entry[0]] = entry[1];
        });
      }
    }

    return updatedValues;
  };

  addNewDocuments = action((docs: DocumentUpload[]) => {
    this.repo.addNewDocuments(docs);
  });
}
