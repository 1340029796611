import type { SetStateAction } from 'react';
import React from 'react';

interface SavePromptState {
  isBlocking: boolean;
  allowHistoryBlock: boolean;
  callback?: () => void;
  notify?: () => void;
}

type SavePromptStateTuple = [SavePromptState, React.Dispatch<SetStateAction<SavePromptState>>];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const SavePromptContext = React.createContext<SavePromptStateTuple>(null!);

const useSavePromptContext = (): SavePromptStateTuple => {
  const context = React.useContext(SavePromptContext);

  if (!context) {
    throw new Error(`SavePromptState must be used withint a SavePromptContextProvider.`);
  }

  return context;
};

const SavePromptContextProvider = (props: Record<string, unknown>) => {
  const [savePromptState, setSavePromptState] = React.useState<SavePromptState>({
    isBlocking: false,
    allowHistoryBlock: true
  });

  const [value, setValue] = React.useMemo(() => [savePromptState, setSavePromptState], [savePromptState]);

  return <SavePromptContext.Provider value={[value, setValue]} {...props} />;
};

/* Button Overlay */

interface ButtonOverlayState {
  isShowing: boolean;
  buttonTitle?: string;
  buttonAction?: () => void;
  cancelAction?: () => void;
}

type ButtonOverlayStateTuple = [ButtonOverlayState, React.Dispatch<SetStateAction<ButtonOverlayState>>];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ButtonOverlayContext = React.createContext<ButtonOverlayStateTuple>(null!);

const useButtonOverlayContext = (): ButtonOverlayStateTuple => {
  const context = React.useContext(ButtonOverlayContext);

  if (!context) {
    throw new Error(`ButtonOverlayState must be used withint a ButtonOverlayContextProvider.`);
  }

  return context;
};

const ButtonOverlayContextProvider = (props: Record<string, unknown>) => {
  const [buttonOverlayState, setButtonOverlayState] = React.useState<ButtonOverlayState>({
    isShowing: false,
    buttonTitle: undefined,
    buttonAction: undefined,
    cancelAction: undefined
  });

  const [value, setValue] = React.useMemo(
    () => [buttonOverlayState, setButtonOverlayState],
    [buttonOverlayState]
  );

  return <ButtonOverlayContext.Provider value={[value, setValue]} {...props} />;
};

export {
  ButtonOverlayContextProvider,
  SavePromptContextProvider,
  useButtonOverlayContext,
  useSavePromptContext
};
