import type { NotificationPreferences } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

import { UsersApi } from 'api';
import { FiBankStore } from 'stores/FiBankStore';
import { UserStore } from 'stores/UserStore';

export interface UserInfo {
  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

export interface PM {
  userInfo: UserInfo;
  notificationPreferences: NotificationPreferences;
}

export interface UserSettings extends PM {}

@injectable()
export class FiUserSettingsRepo {
  @inject(UserStore) private userStore: UserStore;
  @inject(FiBankStore) private bankStore: FiBankStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = {} as PM;

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = action(async () => {
    const { firstName, minitial: middleInitial, lastName, email, phone } = { ...this.userStore.user };

    this.updateProgrammersModel({
      userInfo: { firstName, middleInitial, lastName, email, phone },
      notificationPreferences: this.userStore.user.preferences.notifications
    });
  });

  public updateUserSettings = async (settings: UserSettings) => {
    const userIdDetails: {
      firstName?: string;
      minitial?: string;
      lastName?: string;
      email?: string;
      phone?: string;
    } = { ...settings.userInfo };

    userIdDetails.minitial = settings.userInfo.middleInitial;

    const usersApi = new UsersApi();

    const user = await usersApi.updateUserIdentification(this.userStore.user.id, userIdDetails);
    const preferences = await usersApi.updateUserPreferences(this.userStore.user.id, {
      notifications: settings.notificationPreferences
    });

    user.preferences = preferences;

    if (user.id === this.userStore.user.id) {
      this.userStore.updateUser(user);
    }

    this.bankStore.updateStaff(user);

    return this.updateProgrammersModel(settings);
  };
}
