import type { FieldValues, UseFormReturn } from 'react-hook-form';
import type {
  CustomCheckboxField,
  CustomField,
  CustomRadioField,
  CustomSection,
  CustomSelectField,
  CustomTextSingleLineField,
  DocumentUpload,
  IANATimezones,
  SmartRuleConditionStatement
} from '@gcv/shared';
import { CustomFieldDataType, CustomFieldType, SmartRuleAction, SmartRuleOperator } from '@gcv/shared';
import { Box } from '@mui/material';

import { environment } from 'environments/environment';
import { getFiBankStore } from 'stores/FiBankStore';
import {
  Container,
  Error,
  FileContainer,
  InputCheckBox,
  InputCurrency,
  InputDate,
  InputEmail,
  InputLabel,
  InputPhone,
  InputRadio,
  InputSelect,
  InputText,
  InputUrl
} from 'ui';
import { errorMessages } from 'util/forms.util';
import { PreviewCustomFormAttachments } from './preview-custom-form-attachments';
import { PreviewCustomFormDisplayContentType } from './preview-custom-form-display-content-type';

export const getField = (
  field: CustomField,
  requirementId: string,
  form: UseFormReturn<FieldValues, any>,
  onValueChange: () => void,
  timezone: IANATimezones
) => {
  const existingValue = form.getValues()[field.id];
  const bankStore = getFiBankStore();

  switch (field.type) {
    case CustomFieldType.TextSingleLine:
      switch ((field as CustomTextSingleLineField).dataType) {
        case CustomFieldDataType.Currency:
          return (
            <InputCurrency
              label={field.label}
              name={field.id}
              rules={{ required: { message: 'is required', value: field.required || false } }}
              {...form}
            />
          );

        case CustomFieldDataType.Email:
          if (!existingValue || existingValue === '--') form.setValue(field.id, '');
          return (
            <InputEmail
              label={field.label}
              name={field.id}
              rules={{ required: { message: 'is required', value: field.required || false } }}
              {...form}
            />
          );

        case CustomFieldDataType.Number:
          return (
            <InputText
              label={field.label}
              name={field.id}
              rules={{
                required: { message: 'is required', value: field.required || false },
                pattern: { message: 'invalid', value: /^[0-9]*$/ }
              }}
              {...form}
            />
          );

        case CustomFieldDataType.Phone:
          return (
            <InputPhone
              label={field.label}
              name={field.id}
              rules={{ required: { message: 'is required', value: field.required || false } }}
              {...form}
            />
          );

        case CustomFieldDataType.Text:
          if (!existingValue || existingValue === '--') form.setValue(field.id, '');
          return (
            <InputText
              label={field.label}
              name={field.id}
              rules={{ required: { message: 'is required', value: field.required || false } }}
              {...form}
            />
          );

        case CustomFieldDataType.Url:
          if (!existingValue || existingValue === '--') form.setValue(field.id, '');
          return (
            <InputUrl
              label={field.label}
              name={field.id}
              rules={{ required: { message: 'is required', value: field.required || false } }}
              {...form}
            />
          );

        default:
          break;
      }
      break;

    case CustomFieldType.TextMultiLine:
      if (!existingValue || existingValue === '--') form.setValue(field.id, '');
      return (
        <InputText
          label={field.label}
          name={field.id}
          multiline
          minRows={3}
          rules={{ required: { message: 'is required', value: field.required || false } }}
          {...form}
        />
      );

    case CustomFieldType.Select:
      return (
        <InputSelect
          listItemsWidth="auto"
          label={field.label}
          name={field.id}
          options={(field as CustomSelectField).options.split(';').map((option) => {
            return {
              label: option.trim(),
              value: option.trim()
            };
          })}
          rules={{ required: { message: 'is required', value: field.required || false } }}
          additionalOnChange={onValueChange}
          {...form}
        />
      );

    case CustomFieldType.Radio:
      return (
        <InputRadio
          label={field.label}
          name={field.id}
          options={(field as CustomRadioField).options.split(';').map((option) => {
            return {
              label: option.trim(),
              value: option.trim()
            };
          })}
          justifyContent="column"
          rules={{ required: { message: 'is required', value: field.required || false } }}
          onBlur={onValueChange}
          onChange={onValueChange}
          {...form}
        />
      );

    case CustomFieldType.File:
      return (
        <Box>
          <InputLabel
            dataCy={'input-label'}
            label={field.label}
            name={field.id}
            required={field.required}
            rules={{}}
          />

          <PreviewCustomFormAttachments attachments={field.attachments} noLabel />

          <Box sx={{ paddingTop: '8px' }}>
            <FileContainer
              acceptedTypes="all"
              multiple={false}
              fileBucket={environment.storageConfig.orgDocument}
              existingS3Files={[]}
              fullWidth
              onNewFilesChange={async (files: File[]) => {
                return null;
              }}
              onRemoveExistingFile={(file: DocumentUpload) => {
                return null;
              }}
              {...form}
            />
          </Box>
        </Box>
      );

    case CustomFieldType.Checkbox:
      return (
        <>
          <InputLabel label={field.label} name={field.id} required={field.required} />

          <Box sx={{ display: 'inline-flex', flexDirection: 'column', paddingTop: '5px', flexWrap: 'wrap' }}>
            {(field as CustomCheckboxField).options.split(';').map((option, index) => {
              const trimmedOption = option.trim();
              const formFieldValue = form.watch(field.id);
              const isOptionChecked =
                formFieldValue &&
                typeof formFieldValue === 'string' &&
                formFieldValue.includes(trimmedOption);

              return (
                <Box key={`${option}_${index}`} sx={{ mb: '0.5rem' }}>
                  <InputCheckBox
                    /**
                     * As we have same `name` property to all the options,
                     * adding key forces the options to re-render whenever
                     * the form value change so they are all seeing the
                     * latest form value.
                     */
                    key={Math.random()}
                    label={trimmedOption}
                    name={field.id}
                    checked={isOptionChecked}
                    doNotconsiderFormValue
                    doNotDisplayErrors
                    rules={{
                      validate: (val) => {
                        if (field.required) {
                          const formValue = form.getValues()[field.id];

                          return formValue && typeof formValue === 'string' && formValue.length > 0;
                        }
                        return true;
                      }
                    }}
                    transformAndSave={(checked: boolean, onChange: (value: any) => void) => {
                      let currentValue = form.getValues()[field.id];

                      if (checked) {
                        if (currentValue) {
                          if (typeof currentValue === 'string' && !currentValue.includes(trimmedOption)) {
                            currentValue += ';' + trimmedOption;
                          }
                        } else {
                          currentValue = trimmedOption;
                        }
                      } else {
                        if (
                          currentValue &&
                          typeof currentValue === 'string' &&
                          currentValue.includes(trimmedOption)
                        ) {
                          currentValue = currentValue
                            .split(';')
                            .filter((v) => v !== trimmedOption)
                            .join(';');
                        }
                      }

                      onChange(currentValue);
                    }}
                    {...form}
                  />
                </Box>
              );
            })}
          </Box>

          {Object.keys(form.formState.errors).includes(field.id) && (
            <Error
              content={
                form.formState.errors[field.id]
                  ? `* ${field.label} ${
                      form.formState.errors[field.id]?.message ||
                      errorMessages()[String(form.formState.errors[field.id]?.type)]
                    }`
                  : ''
              }
            />
          )}
        </>
      );

    case CustomFieldType.Date:
      return (
        <InputDate
          label={field.label}
          name={field.id}
          viewFormat="MM-dd-yyyy"
          timezone={timezone}
          rules={{ required: { message: 'is required', value: field.required || false } }}
          {...form}
        />
      );

    case CustomFieldType.DisplayContent:
      return (
        <Container width="580px" padding="0">
          <PreviewCustomFormDisplayContentType field={field} orgId={bankStore.bank.id} />
        </Container>
      );
  }
};

export const shouldDisplay = (field: CustomField | CustomSection, formValues: FieldValues) => {
  if (field && field.smart_rule) {
    let result = true;

    field.smart_rule.conditions.forEach((c) => {
      switch (c.operator) {
        case SmartRuleOperator.IsEmpty:
          result =
            result &&
            (!formValues[c.targetFieldId] ||
              formValues[c.targetFieldId] === '' ||
              formValues[c.targetFieldId] === '--');
          break;

        case SmartRuleOperator.IsEqualTo:
          result =
            result &&
            (formValues[c.targetFieldId] === c.targetValue?.trim() ||
              (typeof formValues[c.targetFieldId] === 'string' &&
                !!formValues[c.targetFieldId].includes(';') &&
                !!formValues[c.targetFieldId].split(';').find((i: string) => i === c.targetValue?.trim())));
          break;

        case SmartRuleOperator.IsFilled:
          result =
            result &&
            formValues[c.targetFieldId] &&
            formValues[c.targetFieldId] !== '' &&
            formValues[c.targetFieldId] !== '--';
          break;

        case SmartRuleOperator.IsNotEqualTo:
          result =
            result &&
            (formValues[c.targetFieldId] !== c.targetValue?.trim() ||
              (typeof formValues[c.targetFieldId] === 'string' &&
                !formValues[c.targetFieldId].includes(c.targetValue?.trim())));
          break;

        default:
          break;
      }
    });

    if (field.smart_rule.action === SmartRuleAction.Show) {
      return result;
    } else if (field.smart_rule.action === SmartRuleAction.Hide) {
      return !result;
    }
  }

  return true;
};

const getParent = (id: string, sections: CustomSection[]) => {
  let parent: CustomField = { id: '', type: CustomFieldType.TextSingleLine, label: '' };
  sections.forEach((section: CustomSection) => {
    section.fields.forEach((field: CustomField) => {
      if (field.id === id) {
        parent = field;
      }
    });
  });
  return parent;
};

const checkParent = (
  field: CustomField | CustomSection,
  condition: SmartRuleConditionStatement,
  sections: CustomSection[],
  formValues: FieldValues
): boolean => {
  // 1. find parent in sections
  const parent = getParent(condition.targetFieldId, sections);
  if (parent) {
    // 2. if parent has smart rule
    let parentResults = true;
    if (parent.smart_rule) {
      // 2a. go to 1
      parentResults = checkParent(parent, parent.smart_rule.conditions[0], sections, formValues);
    }
    // 3. next evaluate smart rule and return result with child result
    return parentResults && shouldDisplay(parent, formValues);
  } else {
    return true;
  }
};

export const allParentsShouldDisplay = (
  field: CustomField | CustomSection,
  sections: CustomSection[],
  formValues: FieldValues
) => {
  //if field has a smart rule
  if (field.smart_rule) {
    return checkParent(field, field.smart_rule.conditions[0], sections, formValues);
    // return boolean results of array
  } else {
    return true;
  }
};
