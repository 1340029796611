import type { BankTemplateMetadataResponse, VerboseTemplateMetadata } from '@gcv/shared';
import { action, makeAutoObservable } from 'mobx';

import { TemplateApi } from 'api';
import { getFiBankStore } from './FiBankStore';

class FiTemplateStore {
  templates: BankTemplateMetadataResponse = {
    templates_allowed: 0,
    questionnaires_allowed: 0,
    onboarding: {},
    questionnaires: {}
  };
  isLoadingTemplates = false;
  constructor() {
    makeAutoObservable(this);
  }

  clearStore = action(() => {
    this.templates = {
      templates_allowed: 0,
      questionnaires_allowed: 0,
      onboarding: {},
      questionnaires: {}
    };
  });

  loadTemplates = action(async () => {
    this.isLoadingTemplates = true;
    const templateApi = new TemplateApi();
    const bankStore = getFiBankStore();
    const templates = await templateApi.getBankTemplates(bankStore.bank.id);
    this.templates = templates;
    this.isLoadingTemplates = false;
  });

  updateBankTemplates = action((bankTemplates: BankTemplateMetadataResponse) => {
    this.templates = bankTemplates;
  });

  updateBankTemplate = action((templateId: string, template: VerboseTemplateMetadata) => {
    this.templates.onboarding[templateId] = template;
  });
}

let templateStore: FiTemplateStore | undefined;

export function getFiTemplateStore(): FiTemplateStore {
  if (!templateStore) {
    templateStore = new FiTemplateStore();
  }

  return templateStore;
}
