import { Navigate, Outlet } from 'react-router-dom';
import { useRollbarPerson } from '@rollbar/react';
import { updateLogRocketConfig } from 'third-party-integrations/log-rocket';

import { useComponent } from 'hooks';
import { getUserType } from 'ui/routing/router-util';
import { getUserStore } from '../../stores/UserStore';

export const GcvRoute: React.FC = useComponent(() => {
  let isGcvuser = false;
  let role = '';

  const userStore = getUserStore();

  if (userStore.isLoaded) {
    role = getUserType(userStore.user);

    if (role === 'gcv') {
      isGcvuser = true;
    }

    useRollbarPerson({
      id: userStore.user?.id,
      name: userStore.user?.firstName + ' ' + userStore?.user?.lastName,
      email: userStore.user?.email
    });
    updateLogRocketConfig(userStore.user);
  }

  return isGcvuser ? <Outlet /> : <Navigate to={{ pathname: '/' }} />;
});
