import * as React from 'react';

import { ReactComponent as FileIcon } from 'assets/images/ic-file.svg';
import { useComponent } from 'hooks';
import { Container, ContainerItem, Hyperlink, Text } from 'ui/atoms';

export const MultipleFilesUploadedBox: React.FC<{ setViewAll: (viewAll: boolean) => void }> = useComponent(
  (props) => {
    return (
      <div
        style={{
          border: '1px solid #C6CCDA',
          borderRadius: '6px',
          maxWidth: '400px',
          minWidth: '250px'
        }}
      >
        <Container
          align="center"
          justify="center"
          padding="12px 22px"
          nowrap
          dataCy="multiple-files-container"
          minWidth="250px"
        >
          <ContainerItem flex padding="0 4px 0 0">
            <FileIcon />
          </ContainerItem>
          <ContainerItem flex padding="0 12px 0 0">
            <Text content="Multiple files" type="body3" />
          </ContainerItem>
          <ContainerItem flex padding="0">
            <Hyperlink
              label="View"
              onClick={() => props.setViewAll(true)}
              dataCy="view-all-link"
              color="green"
              style={{ textDecoration: 'none', fontSize: '13px' }}
            />
          </ContainerItem>
        </Container>
      </div>
    );
  }
);
