import * as React from 'react';
import type { Control, FieldValues, Validate } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Switch as MuiSwitch, switchClasses } from '@mui/base/Switch';
import { Box, FormControlLabel, FormGroup } from '@mui/material';
import { styled } from '@mui/system';

import { Text } from 'ui';
import { theme } from 'ui/theme';
import { errorMessages } from 'util/forms.util';
import { Error } from '../../atoms/Error/error.atom';

const Root = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 33px;
  height: 18px;
  cursor: pointer;
  border: 2px solid rgba(1, 1, 1, 0);
  margin: 0 8px;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background: #b3c3d3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchClasses.thumb} {
    display: block;
    width: 10px;
    height: 10px;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchClasses.focusVisible} {
    outline: 2px solid ${theme.palette.secondary.main};
    border-radius: 10px;
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 17px;
      top: 2px;
      background-color: #fff;
    }

    .${switchClasses.track} {
      background: ${theme.palette.primary.main};
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface SwitchProps {
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  name: string;
  label?: string | JSX.Element;
  control?: Control;
  dataCy?: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  viewOnly?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  justifyContent?: 'left' | 'center' | 'right' | 'space-between' | 'space-around';
}

export const Switch: React.FC<SwitchProps> = (props) => {
  const labelPlacement = props.labelPlacement ? props.labelPlacement : 'end';

  if (props.viewOnly) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            height: '10px',
            width: '10px',
            borderRadius: '10px',
            mr: '0.5rem',
            background: (theme) =>
              props.control?._formValues[props.name]
                ? theme.palette.primary.main
                : theme.palette.text.disabled
          }}
        ></Box>

        <Text
          content={props.control?._formValues[props.name] ? 'On' : 'Off'}
          sx={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
            color: (theme) =>
              props.control?._formValues[props.name]
                ? theme.palette.text.primary
                : theme.palette.text.disabled
          }}
        />
      </Box>
    );
  }

  return (
    <FormGroup row data-cy={props.dataCy}>
      <Controller
        control={props.control}
        name={props.name}
        rules={props.rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={
                  <MuiSwitch
                    slots={{
                      root: Root
                    }}
                    checked={value}
                    disabled={props.readOnly}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (props.onChange) {
                        props.onChange(e);
                      }

                      onChange(e);
                    }}
                    data-cy="switch"
                  />
                }
                labelPlacement={labelPlacement}
                sx={{
                  fontFamily: 'lato',
                  fontSize: '16px',
                  fontWeight: 400,
                  width: 'fit-content'
                }}
                disabled={props.readOnly}
                label={props.label || ''}
                data-cy="label"
              />
              <Error
                content={error ? `* ${props.label} ${error.message || errorMessages()[error.type]}` : ''}
              />
            </div>
          );
        }}
      />
    </FormGroup>
  );
};
