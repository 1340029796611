import type { User } from '@gcv/shared';

import { UsersApi } from 'api/UsersApi';

export class UsersApiMock extends UsersApi {
  async getUsersForOrg(orgId: string): Promise<User[]> {
    const testUser = { id: '1234', email: 'user@test.com', firstName: 'Ben', lastName: 'Tester' } as User;
    return Promise.resolve([testUser]);
  }
}
