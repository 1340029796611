import * as React from 'react';
import type { IANATimezones } from '@gcv/shared';

import type { Column, Row } from 'ui';
import { Table, TableContainer } from 'ui';
import { DateTimeHelpers } from 'util/dateTime.util';

export interface ArchivedUserRow {
  id: string;
  firstName: string;
  lastName: string;
  lastLogin?: string;
}

interface Props {
  iana_timezone: IANATimezones;
  setSelectedArchiveUsers: (rows: string[]) => void;
  userRows: Row<ArchivedUserRow>[];
  showFilterZeroState?: boolean;
}

export const ArchivedUsersTable: React.FC<Props> = (props) => {
  const columns: Column<ArchivedUserRow>[] = [
    {
      label: 'First Name',
      id: 'firstName'
    },
    {
      label: 'Last Name',
      id: 'lastName'
    },
    {
      label: 'Last Login',
      id: 'lastLogin',
      format: (row) =>
        row.lastLogin
          ? DateTimeHelpers.formatISODateStringToTableDateAndTimeString(row.lastLogin, props.iana_timezone)
          : '--'
    }
  ];

  return (
    <TableContainer>
      <Table
        selectable
        columns={columns}
        rows={props.userRows}
        defaultSort="firstName"
        dataCy="archived-users-table"
        defaultRowCount={10}
        returnSelection={(rows) => props.setSelectedArchiveUsers(rows)}
        showFilterZeroState={props.showFilterZeroState}
      />
    </TableContainer>
  );
};
