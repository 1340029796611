import type { InputMarketingOrgRegistration } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

import { UsersApi } from 'api';
import { UserStore } from 'stores/UserStore';

export interface PM {
  formData: Record<string, unknown>;
}

@injectable()
export class CrbWelcomeRepo {
  @inject(UsersApi)
  usersApi: UsersApi;

  @inject(UserStore)
  userStore: UserStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = { formData: {} };

  public load = async () => {
    const formData = await this.usersApi.getUserFeatheryFormResponse(this.userStore.user.id);
    this.programmersModel.formData = formData;
  };

  public updateUserFeatheryFormResponse = async (formResponse: Record<string, unknown>) => {
    const comment = (formResponse['comment_1'] as string) ?? '';
    formResponse['comment_1'] = comment.trim();

    return this.usersApi.updateUserFeatheryFormResponse(this.userStore.user.id, {
      formResponse
    });
  };

  public registerMarketingUserOrg = async (data: InputMarketingOrgRegistration) =>
    this.usersApi.registerMarketingUserOrg(this.userStore.user.id, data);
}
