import type {
  Action,
  Bank,
  CrbServiceProvider,
  Dispensary,
  Group,
  GroupedTask,
  MinifiedDispensary,
  MinifiedTask,
  MinifiedUser,
  MUO,
  QuestionnaireResponse,
  QuestionnaireResultResponse,
  SubFeaturesFinCen,
  SubFeaturesMonitoring,
  TemplateResponse,
  TemplateResultResponse,
  User
} from '@gcv/shared';
import {
  CoreTransactionPaymentType,
  DueDiligenceStatus,
  GREEN_CHECK_ONBOARDING_BANK_ID,
  MainFeatures,
  OrganizationRoleResolver,
  PosType,
  RequirementType,
  TaskInternalType,
  TaskStatus,
  UserInterests
} from '@gcv/shared';

import { BankRoleOptions, DispensaryRoleOptions } from 'domain/consts/roles';
import { MyProvidersStatus } from 'domain/enums';
import type { PartialDispensary } from 'domain/types/gcv/partialDispensary';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { getFiBankStore } from 'stores/FiBankStore';
import { getFiTemplateStore } from 'stores/FiTemplateStore';
import { shouldDisplayFieldWithSmartRule } from 'ui/organisms/CustomFormGenerator/custom-form-generator.organism';
import { DateTimeHelpers } from './dateTime.util';
import { isOnboardingDocumentRequirementFulfilled } from './due-diligence.util';

export enum OrganizationType {
  Bank = 'BANK',
  Dispensary = 'DISPENSARY'
}

/**
 * Model properties common to FI or CRB organizations.
 */
export class Organization {
  public organizationType: OrganizationType;

  /**
   * Return FI instance.
   * @returns organization instance modeling a financial institution (FI).
   */
  public static getBank = () => {
    return new this(OrganizationType.Bank);
  };

  /**
   * Return CRB instance.
   * @returns organization instance modeling a cannabis-related business (CRB).
   */
  public static getDispensary = () => {
    return new this(OrganizationType.Dispensary);
  };

  private constructor(organizationType: OrganizationType) {
    this.organizationType = organizationType;
  }

  public getAbbreviation = () => {
    return this.organizationType === OrganizationType.Bank ? 'fi' : 'crb';
  };

  public getStore = () => {
    return this.organizationType === OrganizationType.Bank ? getFiBankStore() : getCrbDispensaryStore();
  };

  public getStaff = () => {
    return this.organizationType === OrganizationType.Bank
      ? getFiBankStore().staff
      : getCrbDispensaryStore().currentDispensaryStaff;
  };

  public isStaffLoaded = () => {
    return this.organizationType === OrganizationType.Bank
      ? getFiBankStore().isStaffLoaded
      : getCrbDispensaryStore().isStaffLoaded;
  };

  public getTimezone = () => {
    return this.organizationType === OrganizationType.Bank
      ? getFiBankStore().bank.iana_timezone
      : getCrbDispensaryStore().currentDispensary.iana_timezone;
  };

  public getId = () => {
    return this.organizationType === OrganizationType.Bank
      ? getFiBankStore().bank.id
      : getCrbDispensaryStore().currentDispensary.id;
  };

  public getAdminRole = () => {
    return this.organizationType === OrganizationType.Bank ? 'bank_admin' : 'dispensary_admin';
  };

  public getStandardRole = () => {
    return this.organizationType === OrganizationType.Bank ? 'bank_user' : 'dispensary_user';
  };

  public getRoleModifier = () => {
    return this.organizationType === OrganizationType.Bank ? 'bank_' : 'dispensary_';
  };

  public getRoleOptions = () => {
    return this.organizationType === OrganizationType.Bank ? BankRoleOptions : DispensaryRoleOptions;
  };
}

export const checkPermissions = (permissions: Action[], user: User, organization: Bank | Dispensary) => {
  const resolver = new OrganizationRoleResolver();

  let hasPermission = false;
  if (permissions && user && organization) {
    permissions.forEach((permission: Action) => {
      if (resolver.userCanDoAction(organization.groups || [], user, permission)) {
        hasPermission = true;
        return;
      }
      return;
    });
    return hasPermission;
  } else {
    return hasPermission;
  }
};

export const getLastLoginForOrg = (
  org: PartialDispensary | Bank,
  staffDictionary: { [k: string]: MinifiedUser }
): number => {
  const orgStaff: MinifiedUser[] =
    org.orgType === 'bank'
      ? getStaffUserObjectsForBank(org as Bank, staffDictionary)
      : getStaffUserObjectsForPartialDispensary(org as PartialDispensary, staffDictionary);

  if (orgStaff.length > 0) {
    const lastLoginUnixTimestamp = orgStaff.reduce((latestTime, currentUser) => {
      if (currentUser && currentUser.lastLogin) {
        const currentUserLastLogin = DateTimeHelpers.parseFromISOString(
          currentUser.lastLogin,
          'utc'
        ).valueOf();

        if (latestTime < currentUserLastLogin) {
          latestTime = currentUserLastLogin;
        }
        return latestTime;
      } else {
        return latestTime;
      }
    }, 0);

    if (lastLoginUnixTimestamp > 0) {
      return lastLoginUnixTimestamp;
    }
  }
  return 0;
};

const getStaffUserObjectsForPartialDispensary = (
  org: PartialDispensary,
  staffDictionary: { [k: string]: MinifiedUser }
): MinifiedUser[] => {
  return org.userList.reduce((acc, currentUserId) => {
    const user = staffDictionary[currentUserId];

    if (user) {
      acc = [...acc, user];
    }
    return acc;
  }, [] as MinifiedUser[]);
};

const getStaffUserObjectsForBank = (
  org: Bank,
  staffDictionary: { [k: string]: MinifiedUser }
): MinifiedUser[] => {
  return getStaffIdsForOrg(org).reduce((acc, currentUserId) => {
    const user = staffDictionary[currentUserId];

    if (user) {
      acc = [...acc, user];
    }
    return acc;
  }, [] as MinifiedUser[]);
};

const getStaffIdsForOrg = (org: Dispensary | Bank): string[] => {
  if (org.groups) {
    return org.groups.reduce((acc, curr) => {
      if (curr.users) {
        curr.users.forEach((userId) => {
          if (!acc.includes(userId)) {
            acc = [...acc, userId];
          }
        });
      }

      return acc;
    }, [] as string[]);
  }

  return [];
};

export const getDispensaryType = (posName: PosType): 'retail' | 'non_retail' => {
  if (
    posName === 'Quickbooks' ||
    posName === 'GcvCsv_Quickbooks' ||
    posName === 'MjFreewayWholesale' ||
    posName === 'BioTrackSqlWholesale' ||
    posName === 'LeafLogixWholesale' ||
    posName === PosType.MetrcS2SWholesale
  ) {
    return 'non_retail';
  } else {
    return 'retail';
  }
};

export enum DashboardType {
  ComplianceZeroStateComplete = 'ComplianceZeroStateComplete',
  ComplianceZeroStatePending = 'ComplianceZeroStatePending',
  IdentificationVerification = 'IdentificationVerification',
  ConfigurePos = 'ConfigurePos',
  DashboardState = 'DashboardState',
  StartDueDiligence = 'StartDueDiligence',
  ContinueDueDiligence = 'ContinueDueDiligence',
  RedirectMyProviders = 'RedirectMyProviders',
  RedirectMarketplaceGallery = 'RedirectMarketplaceGallery'
}

export enum RouteBypassType {
  None = 'None',
  StartCompleteApplication = 'StartCompleteApplication',
  ContinueApplication = 'ContinueApplication',
  DiscoverMarketplace = 'DiscoverMarketplace',
  SyncSales = 'SyncSales',
  IdVerification = 'IdVerification',
  GoToInbox = 'GoToInbox'
}

export enum CrbRequestedDestination {
  Dashboard = 'Dashboard',
  CompanyProfile = 'CompanyProfile',
  QuestionnairesInbox = 'QuestionnairesInbox',
  Users = 'Users'
}

export const convertDueDiligenceStatus = (status: DueDiligenceStatus): MyProvidersStatus => {
  switch (status) {
    case DueDiligenceStatus.BANK_APPROVED:
      return MyProvidersStatus.Active;
    case DueDiligenceStatus.BANK_AWAITING_REVIEW:
      return MyProvidersStatus.Submitted;
    case DueDiligenceStatus.BANK_DISCONNECTED:
      return MyProvidersStatus.Inactive;
    case DueDiligenceStatus.BANK_IN_PROGRESS:
      return MyProvidersStatus.Started;
    case DueDiligenceStatus.BANK_PENDING:
      return MyProvidersStatus.Started;
    case DueDiligenceStatus.BANK_REVIEW_IN_PROGRESS:
      return MyProvidersStatus.UnderReview;
    case DueDiligenceStatus.GCV_APPROVED:
      return MyProvidersStatus.Active;
    case DueDiligenceStatus.GCV_AWAITING_REVIEW:
      return MyProvidersStatus.NotStarted;
    case DueDiligenceStatus.GCV_IN_PROGRESS:
      return MyProvidersStatus.Started;
    case DueDiligenceStatus.GCV_PENDING:
      return MyProvidersStatus.NotStarted;
    case DueDiligenceStatus.UPDATE_REQUIRED:
      return MyProvidersStatus.UnderReview;
    default:
      return MyProvidersStatus.NotStarted;
  }
};

export const filterTasksForOnboarding = (tasks: (MinifiedTask | GroupedTask)[]) => {
  const onboardingTasks = tasks.filter((task: MinifiedTask | GroupedTask) => {
    if (!(task as GroupedTask).tasks) {
      const minTask = task as MinifiedTask;
      // if task is onboarding and open
      return minTask.internal_type === TaskInternalType.ONBOARDING && minTask.status === TaskStatus.OPEN;
    }
    return false;
  });
  return onboardingTasks;
};

export const getDueDiligenceRouteBypassType = (
  dispensary: Dispensary,
  user: User,
  dueDiligenceStatus: DueDiligenceStatus,
  hasApplicationInProgress: boolean,
  hasNoServiceProviders: boolean,
  currentDispensaryIsPlantTouching: boolean,
  tasks: (MinifiedTask | GroupedTask)[],
  requestedDestination: CrbRequestedDestination
): RouteBypassType => {
  let bypassType = RouteBypassType.None;
  const { posName } = dispensary?.posConfig;
  const resolver = new OrganizationRoleResolver();

  const accountOwnerRole = resolver
    .getUsersGroups(dispensary?.groups || [], user.id)
    .find((o) => o.type === 'account_owner');
  const depositTransportRole = resolver
    .getUsersGroups(dispensary?.groups || [], user.id)
    .find((o) => o.type === 'deposit_transport');
  const isAccountOwnerOrMotUser = accountOwnerRole !== undefined || depositTransportRole !== undefined;
  const isAccountOwner = !!accountOwnerRole;
  const isUserVerified = user.identityVerified !== false && user.identityVerified !== undefined;
  const isUnverifiedAccountOwnerOrMotUser = isAccountOwnerOrMotUser && !isUserVerified;
  const onboardingTasks = filterTasksForOnboarding(tasks);

  // Check if bank invited
  if (
    dispensary.created_by_org !== dispensary.id &&
    dispensary.created_by_org !== GREEN_CHECK_ONBOARDING_BANK_ID
  ) {
    const myProviderStatus = convertDueDiligenceStatus(dueDiligenceStatus);

    // Check if template status is NOT Active or submitted
    if (
      (myProviderStatus === MyProvidersStatus.Started || myProviderStatus === MyProvidersStatus.NotStarted) &&
      !onboardingTasks.length
    ) {
      bypassType = RouteBypassType.StartCompleteApplication;
    } else if (
      (myProviderStatus === MyProvidersStatus.Started || myProviderStatus === MyProvidersStatus.NotStarted) &&
      !!onboardingTasks.length
    ) {
      bypassType = RouteBypassType.GoToInbox;
    } else if (
      myProviderStatus === MyProvidersStatus.Inactive ||
      (!currentDispensaryIsPlantTouching && requestedDestination === CrbRequestedDestination.Dashboard)
    ) {
      bypassType = RouteBypassType.DiscoverMarketplace;
    } else {
      bypassType = checkSyncSalesOrIdVerification(
        currentDispensaryIsPlantTouching,
        dispensary,
        posName,
        bypassType,
        isUnverifiedAccountOwnerOrMotUser
      );
    }
  } else {
    // Dispensary is NOT bank invited
    if (
      hasNoServiceProviders ||
      (!currentDispensaryIsPlantTouching && requestedDestination === CrbRequestedDestination.Dashboard)
    ) {
      // Dispensary is NOT connected to a Service Provider
      bypassType = RouteBypassType.DiscoverMarketplace;
    } else {
      // Check to see if there is an application in progress and NONE that are Active
      if (hasApplicationInProgress) {
        bypassType = RouteBypassType.ContinueApplication;
      } else {
        bypassType = checkSyncSalesOrIdVerification(
          currentDispensaryIsPlantTouching,
          dispensary,
          posName,
          bypassType,
          isUnverifiedAccountOwnerOrMotUser
        );
      }
    }
  }

  return bypassType;
};

export const getDueDiligenceViewState = (
  dispensary: Dispensary,
  user: User,
  dueDiligenceStatus: DueDiligenceStatus,
  hasApplicationInProgress?: boolean,
  hasNoServiceProviders?: boolean
): DashboardType => {
  const posType = dispensary?.posConfig?.posName;
  const resolver = new OrganizationRoleResolver();

  const accountOwnerRole = resolver
    .getUsersGroups(dispensary?.groups || [], user.id)
    .find((o) => o.type === 'account_owner');
  const depositTransportRole = resolver
    .getUsersGroups(dispensary?.groups || [], user.id)
    .find((o) => o.type === 'deposit_transport');
  const isAccountOwnerOrMotUser = accountOwnerRole !== undefined || depositTransportRole !== undefined;
  const isAccountOwner = !!accountOwnerRole;
  const isUserVerified = user.identityVerified !== false && user.identityVerified !== undefined;
  const isUnverifiedAccountOwnerOrMotUser = isAccountOwnerOrMotUser && !isUserVerified;
  const needsToConfigurePos =
    !dispensary.has_uploaded_sales &&
    (!posType ||
      posType === PosType.GcvCsv_Quickbooks ||
      posType === PosType.GcvFaker_GcvCsv ||
      posType === PosType.Unknown ||
      posType === PosType.Unsupported);

  let state;

  if (hasApplicationInProgress) {
    return DashboardType.RedirectMyProviders;
  }

  if (hasNoServiceProviders) {
    return DashboardType.RedirectMarketplaceGallery;
  }

  switch (dueDiligenceStatus) {
    case DueDiligenceStatus.GCV_APPROVED:
    case DueDiligenceStatus.BANK_APPROVED:
    case DueDiligenceStatus.BANK_AWAITING_REVIEW:
    case DueDiligenceStatus.BANK_REVIEW_IN_PROGRESS:
    case DueDiligenceStatus.BANK_IN_PROGRESS:
    case DueDiligenceStatus.BANK_PENDING:
      state = isUnverifiedAccountOwnerOrMotUser
        ? DashboardType.IdentificationVerification
        : needsToConfigurePos
        ? DashboardType.ConfigurePos
        : DashboardType.DashboardState;
      break;
    case DueDiligenceStatus.GCV_AWAITING_REVIEW:
      state = isUnverifiedAccountOwnerOrMotUser
        ? DashboardType.IdentificationVerification
        : needsToConfigurePos
        ? DashboardType.ConfigurePos
        : DashboardType.ComplianceZeroStateComplete;
      break;
    case DueDiligenceStatus.GCV_IN_PROGRESS:
      state = isAccountOwner ? DashboardType.ContinueDueDiligence : DashboardType.ComplianceZeroStatePending;
      break;
    case DueDiligenceStatus.GCV_PENDING:
      state = isAccountOwner ? DashboardType.StartDueDiligence : DashboardType.ComplianceZeroStatePending;
      break;
    default:
      state = DashboardType.ComplianceZeroStateComplete;
  }

  return state;
};

export const isGroupLocked = (group: Group): boolean => group.type !== 'user_created' && !!group.type;

export const getDispensaryMotUsers = (
  dispensary: Dispensary,
  dispensaryUsers: User[]
):
  | {
      id: string;
      name: string;
      favorite: boolean;
      active: boolean;
      type: 'user';
    }[] => {
  const returnVal: {
    id: string;
    name: string;
    favorite: boolean;
    active: boolean;
    type: 'user';
  }[] = [];
  return dispensary.methodOfTransportation.user.reduce((filteredUsers, val) => {
    const motUser = dispensaryUsers.find((user) => user.id === val.id);
    if (motUser && motUser.invitation_status !== 'archived' && motUser.identityVerified) {
      const user = { ...val, name: `${motUser.firstName} ${motUser.lastName}` };
      filteredUsers.push({ ...user, type: 'user' });
    }
    return filteredUsers;
  }, returnVal);
};

export const getAvailableMethodOfTransportationForDeposit = (
  dispensary: Dispensary,
  dispensaryUsers: User[],
  bank?: CrbServiceProvider
): {
  id: string;
  name: string;
  favorite: boolean;
  active: boolean;
  type: 'bank' | 'user' | 'vendor';
}[] => {
  // check for a bank favorite MOT, if we have any return those as the only options
  // bank CrbServiceProvider object will return account specific favorite mot over fi global favorite
  if (bank) {
    const bankRequired = bank.transportVendors.filter((mot) => mot.active && mot.favorite);
    if (bankRequired.length) {
      return bankRequired.map((mot) => {
        return {
          ...mot,
          active: mot.active === true,
          favorite: mot.favorite === true,
          type: 'bank' as 'bank' | 'user' | 'vendor'
        };
      });
    }
  }
  // get MOT users with shared function to filter out archived and use user's name
  const motUsers = getDispensaryMotUsers(dispensary, dispensaryUsers);
  const bankMots = bank
    ? bank.transportVendors
        .filter((mot) => mot.active)
        .map((mot) => {
          return {
            ...mot,
            active: mot.active === true,
            favorite: mot.favorite === true,
            type: 'bank' as 'bank' | 'user' | 'vendor'
          };
        })
    : [];
  const vendorMots = dispensary.methodOfTransportation.vendor
    .filter((mot) => mot.active)
    .map((mot) => {
      return { ...mot, type: 'vendor' as 'bank' | 'user' | 'vendor' };
    });
  return [...motUsers, ...bankMots, ...vendorMots];
};

export const getLicenseType = (minDisp: MinifiedDispensary) => {
  let licenseType = '--';

  if (minDisp) {
    if (minDisp.is_tribal_nation) {
      return 'Tribal';
    }

    if (
      !getFiTemplateStore().templates.onboarding[minDisp.assigned_onboarding_template.template_id]
        ?.plant_touching
    ) {
      licenseType = 'None (non-licensed)';
    } else {
      licenseType = minDisp.assigned_onboarding_template.license_type ?? '--';
    }
  }

  return licenseType;
};

export const isMailingAddressSame = (org: Dispensary | MUO): boolean => {
  return (
    org &&
    (!org.mailingStreetAddress ||
      (org.streetAddress === org.mailingStreetAddress &&
        org.city === org.mailingCity &&
        org.state === org.mailingState &&
        org.postalCode === (org.mailingPostalCode as unknown as string)))
  );
};

export const hasDispensaryFulfilledAllDocumentRequirements = (
  template: TemplateResponse,
  results: TemplateResultResponse,
  employeeLicensesRequired: boolean
): boolean => {
  let isFulfilled = true;

  // filter out archive requirements and employee licenses if not required
  const activeRequirements = Object.values(template.requirements).filter(
    (r) => !r.archived && (employeeLicensesRequired || r.type !== RequirementType.EmployeeLicense)
  );

  for (const requirement of activeRequirements) {
    if (!isFulfilled) break;
    const result = results.requirements_results.find((r) => r.requirement_id === requirement.requirement_id);
    if (!result || !isOnboardingDocumentRequirementFulfilled(result)) {
      isFulfilled = false;
    }
  }

  return isFulfilled;
};

export const hasDispensaryFulfilledAllAddtionalRequirements = (
  template: TemplateResponse | QuestionnaireResponse,
  results: TemplateResultResponse | QuestionnaireResultResponse
): boolean => {
  let isFulfilled = true;
  const requiredFields: string[] = [];
  const requirements: string[] = [];
  const flatResults: Record<string, any> = {};

  results.custom_requirement_result.forEach((result) =>
    Object.keys(result.custom_fields.responses).forEach((id) => {
      flatResults[id] = result.custom_fields.responses[id];
    })
  );

  Object.values(template.custom_requirements)
    .filter((s) => !s.archived)
    .forEach((section) => {
      Object.values(section.custom_section.fields).forEach((field) => {
        if (
          (!field.smart_rule || (field.smart_rule && shouldDisplayFieldWithSmartRule(field, flatResults))) &&
          (!section.custom_section.smart_rule ||
            (section.custom_section.smart_rule &&
              shouldDisplayFieldWithSmartRule(section.custom_section, flatResults))) &&
          field.required
        ) {
          requiredFields.push(field.id);
        } else if (!field.smart_rule && !section.custom_section.smart_rule && field.required) {
          requiredFields.push(field.id);
        }
        requirements.push(field.id);
      });
    });
  let resultCount = 0;
  const responses: string[] = [];

  Object.values(results.custom_requirement_result).forEach((resultSection) => {
    if (!resultSection.custom_fields.responses && requirements.length) {
      isFulfilled = false;
    } else {
      Object.entries(resultSection.custom_fields.responses).forEach((result) => {
        resultCount++;
        if (requiredFields.includes(result[0]) && (result[1] == null || result[1]?.length === 0)) {
          isFulfilled = false;
        }
      });

      responses.push(...Object.keys(resultSection.custom_fields.responses));
    }
  });

  for (const requiredField of requiredFields) {
    if (!responses.includes(requiredField)) {
      isFulfilled = false;
    }
  }
  return isFulfilled && (!!resultCount || !requiredFields.length);
};

export const allServiceProvidersAreDisconnected = (banks: {
  [bankId: string]: CrbServiceProvider;
}): boolean => {
  const numberOfProviders = Object.keys(banks).length;
  let numberOfDisconnections = 0;
  for (const providerId of Object.keys(banks)) {
    if (banks[providerId].templates.value.onboarding.status === DueDiligenceStatus.BANK_DISCONNECTED) {
      numberOfDisconnections++;
    }
  }
  return numberOfDisconnections === numberOfProviders - 1;
};

export const isNotConnectedToAServiceProvider = (banks: {
  [bankId: string]: CrbServiceProvider;
}): boolean => {
  if (Object.keys(banks).length === 1) {
    return true;
  } else if (allServiceProvidersAreDisconnected(banks)) {
    return true;
  } else {
    return false;
  }
};

export const hasApplicationInProgress = (banks: { [bankId: string]: CrbServiceProvider }): boolean => {
  let appInProgress = false;
  let submittedOrApprovedCount = 0;
  Object.keys(banks).map((providerId) => {
    const status = banks[providerId].templates.value.onboarding.status as DueDiligenceStatus;
    if (providerId !== GREEN_CHECK_ONBOARDING_BANK_ID) {
      if (
        status === DueDiligenceStatus.BANK_IN_PROGRESS ||
        status === DueDiligenceStatus.BANK_PENDING ||
        status === DueDiligenceStatus.GCV_AWAITING_REVIEW ||
        status === DueDiligenceStatus.GCV_IN_PROGRESS ||
        status === DueDiligenceStatus.GCV_PENDING
      ) {
        appInProgress = true;
      } else if (
        status === DueDiligenceStatus.BANK_AWAITING_REVIEW ||
        DueDiligenceStatus.BANK_APPROVED ||
        DueDiligenceStatus.GCV_APPROVED
      ) {
        submittedOrApprovedCount++;
      }
    }
  });
  if (submittedOrApprovedCount > 0) {
    return false;
  }
  return appInProgress;
};

const checkSyncSalesOrIdVerification = (
  currentDispensaryIsPlantTouching: boolean,
  dispensary: Dispensary,
  posName: PosType,
  bypassType: RouteBypassType,
  isUnverifiedAccountOwnerOrMotUser: boolean
): RouteBypassType => {
  // If, the current USER has the GROUP 'account_owner'  or 'deposit_transport' and is unverified
  if (isUnverifiedAccountOwnerOrMotUser) {
    bypassType = RouteBypassType.IdVerification;
    // Check to see if the Dispensary is plant touching or not
  } else if (currentDispensaryIsPlantTouching) {
    // If, haven't uploaded sales and the POS is NOT unknown, unsupported, GCV_Faker or Quickbooks
    if (
      !dispensary.has_uploaded_sales &&
      (!posName ||
        posName === PosType.GcvCsv_Quickbooks ||
        posName === PosType.GcvFaker_GcvCsv ||
        posName === PosType.Unknown ||
        posName === PosType.Unsupported)
    ) {
      bypassType = RouteBypassType.SyncSales;
    }
  }
  return bypassType;
};

// Function that takes the org (right now bank but dispensary can be added in the future) and features and
// returns if that feature is enabled or not
// if an org does not have a feature or subfeature defined this function will default to true
// e.g. orgFeatureEnabled(mybank, MainFeatures.FinCEN, SubFeatures.SAR) => true/false
export const orgFeatureEnabled = (
  org: Bank,
  parentFeature: MainFeatures,
  childFeature?: 'SAR' | 'CTR' | 'CoreTransactions'
) => {
  if (org.features && org.features[parentFeature]) {
    if (childFeature === undefined) {
      // we're looking for the parent feature
      return org.features[parentFeature]?.enabled;
    } else if (childFeature && org.features[parentFeature]?.sub_features === undefined) {
      // we didn't find the subfeature
      return true;
    } else if (parentFeature === MainFeatures.FinCEN) {
      return (org.features[parentFeature]?.sub_features as SubFeaturesFinCen)[childFeature as 'SAR' | 'CTR'];
    } else if (parentFeature === MainFeatures.Monitoring) {
      return (org.features[parentFeature]?.sub_features as SubFeaturesMonitoring)[
        childFeature as 'CoreTransactions'
      ];
    }
  }
  // either the features objet or the parent feature is not defined on the
  return true;
};

export const determineDueDiligenceStatusAndProviderId = (
  banks: { [bankId: string]: CrbServiceProvider },
  currentDispensary: Dispensary
): { status: DueDiligenceStatus; zeroStateProviderId: string } => {
  let status: DueDiligenceStatus;
  let zeroStateProviderId = '';

  if (Object.keys(banks).length === 1) {
    status = banks[GREEN_CHECK_ONBOARDING_BANK_ID].templates.value.onboarding.status as DueDiligenceStatus;
    zeroStateProviderId = GREEN_CHECK_ONBOARDING_BANK_ID;
  } else if (
    currentDispensary.created_by_org &&
    currentDispensary.created_by_org !== GREEN_CHECK_ONBOARDING_BANK_ID &&
    Object.keys(banks).includes(currentDispensary.created_by_org) &&
    banks[currentDispensary.created_by_org].templates.value.onboarding.status !==
      DueDiligenceStatus.BANK_DISCONNECTED
  ) {
    zeroStateProviderId = currentDispensary.created_by_org as string;
    status = banks[zeroStateProviderId].templates.value.onboarding.status;
  } else {
    for (const providerId of Object.keys(banks)) {
      if (providerId !== GREEN_CHECK_ONBOARDING_BANK_ID) {
        const templateStatus = banks[providerId].templates.value.onboarding.status;
        if (
          templateStatus === DueDiligenceStatus.BANK_IN_PROGRESS ||
          templateStatus === DueDiligenceStatus.BANK_PENDING ||
          templateStatus === DueDiligenceStatus.GCV_AWAITING_REVIEW ||
          templateStatus === DueDiligenceStatus.GCV_IN_PROGRESS ||
          templateStatus === DueDiligenceStatus.GCV_PENDING ||
          templateStatus === DueDiligenceStatus.BANK_APPROVED ||
          templateStatus === DueDiligenceStatus.BANK_AWAITING_REVIEW ||
          templateStatus === DueDiligenceStatus.BANK_REVIEW_IN_PROGRESS
        ) {
          status = templateStatus;
          zeroStateProviderId = providerId;
          break;
        }
      }
    }
  }

  if (
    (!currentDispensary.created_by_org ||
      currentDispensary.created_by_org === GREEN_CHECK_ONBOARDING_BANK_ID) &&
    !zeroStateProviderId
  ) {
    for (const providerId of Object.keys(banks)) {
      if (providerId !== GREEN_CHECK_ONBOARDING_BANK_ID) {
        status = banks[providerId].templates.value.onboarding.status;
        zeroStateProviderId = providerId;
        break;
      }
    }
  }

  if (
    !zeroStateProviderId &&
    currentDispensary.created_by_org &&
    currentDispensary.created_by_org !== GREEN_CHECK_ONBOARDING_BANK_ID &&
    Object.keys(banks).includes(currentDispensary.created_by_org)
  ) {
    zeroStateProviderId = currentDispensary.created_by_org as string;
    status = banks[zeroStateProviderId].templates.value.onboarding.status;
  }

  return {
    status: status!,
    zeroStateProviderId
  };
};

export const userInterestsReadableNameMap = {
  [UserInterests.AchAndWires]: 'ACH and Wires',
  [UserInterests.BillPay]: 'Bill Pay',
  [UserInterests.BusinessBanking]: 'Business Banking',
  [UserInterests.BusinessInsurance]: 'Business Insurance',
  [UserInterests.CashLogistics]: 'Cash Logistics',
  [UserInterests.CommercialLoans]: 'Commercial Loans',
  [UserInterests.PayrollAndHr]: 'Payroll and HR',
  [UserInterests.Payments]: 'Payments'
};

export const coreTransactionPaymentTypeColorPicker = (type: CoreTransactionPaymentType) => {
  switch (type) {
    case CoreTransactionPaymentType.ACH:
      return '#dc8c08';
    case CoreTransactionPaymentType.ACHWIRE:
      return '#414288';
    case CoreTransactionPaymentType.Adjustment:
      return '#682d63';
    case CoreTransactionPaymentType.BillPayments:
      return '#5FB49C';
    case CoreTransactionPaymentType.Card:
      return '#d8f4e7';
    case CoreTransactionPaymentType.CardCredit:
      return '#98DFAF';
    case CoreTransactionPaymentType.CardDebit:
      return '#DEEFB7';
    case CoreTransactionPaymentType.Cash:
      return '#3581B8';
    case CoreTransactionPaymentType.Check:
      return '#E65F5C';
    case CoreTransactionPaymentType.Crypto:
      return '#0F0326';
    case CoreTransactionPaymentType.Dividend:
      return '#B5D99C';
    case CoreTransactionPaymentType.Fee:
      return '#324376';
    case CoreTransactionPaymentType.InterestDividend:
      return '#F5F7DC';
    case CoreTransactionPaymentType.Interest:
      return '#FFFF82';
    case CoreTransactionPaymentType.MonetaryInstrument:
      return '#F19A3E';
    case CoreTransactionPaymentType.Payments:
      return '#E8FCCF';
    case CoreTransactionPaymentType.P2P:
      return '#96E072';
    case CoreTransactionPaymentType.Physical:
      return '#78A9F5';
    case CoreTransactionPaymentType.ReturnedItems:
      return '#3DA35D';
    case CoreTransactionPaymentType.NSF:
      return '#3E8914';
    case CoreTransactionPaymentType.RTP:
      return '#ff4e50';
    case CoreTransactionPaymentType.Tax:
      return '#211A1D';
    case CoreTransactionPaymentType.Transfer:
      return '#f5a623';
    case CoreTransactionPaymentType.Wire:
      return '#ff4e50';
    case CoreTransactionPaymentType.Other:
      return '#6320EE';
    case CoreTransactionPaymentType.LoanAdvance:
      return '#30B6D3';
    case CoreTransactionPaymentType.Disbursement:
      return '#9753DA';
    case CoreTransactionPaymentType.PaymentReceived:
      return '#2215B5';
    case CoreTransactionPaymentType.Escrow:
      return '#FF9A24';
    case CoreTransactionPaymentType.Payoff:
      return '#00B59F';
    default:
      return '#B3CDD1';
  }
};
