import * as React from 'react';
import { Box } from '@mui/material';

import { Icon, SubTitle } from 'ui';

interface Props {
  onClick: (isOpen: boolean) => void;
}

export const SectionAddButton: React.FC<Props> = (props) => {
  return (
    <Box
      onClick={() => props.onClick(true)}
      sx={{
        alignItems: 'center',
        backgroundColor: '#F9FAFD',
        border: '1px dashed #C6CCDA',
        borderRadius: '6px',
        cursor: 'pointer',
        display: 'flex',
        height: '48px',
        justifyContent: 'center',
        p: '16px 0',
        width: '100%',

        '&:hover': {
          border: '1px solid #C6CCDA'
        }
      }}
      data-cy="add-section-button"
    >
      <Icon type="add" /> <SubTitle inline content="Add New Section" />
    </Box>
  );
};
