import type { PosDataFetcherResults, PosType } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

import { GcvAdminApi } from 'api';
import { SnackbarStore } from 'stores/SnackBarStore';

export interface GcvAccessCrbPosDataFetcherHistoryPM {
  isLoading: boolean;
  crbPosHistoryRecords: PosDataFetcherResults[];
}

@injectable()
export class GcvAccessCrbPosDataFetcherHistoryRepo {
  @inject(GcvAdminApi)
  private gcvAdminApi: GcvAdminApi;
  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  public gcvDataFetcherHistoryProgrammersModel: GcvAccessCrbPosDataFetcherHistoryPM = {
    isLoading: true,
    crbPosHistoryRecords: []
  };

  loadDataFetcherHistory = action(
    (startDate: string, endDate: string, filterParams: { crbId?: string; posType?: PosType }) => {
      if (!filterParams.crbId && !filterParams.posType) {
        return;
      }

      this.updateProgrammersModel({
        isLoading: true
      });
      this.gcvAdminApi
        .getDataFetcherHistory(startDate, endDate, filterParams)
        .then((records) => {
          this.updateProgrammersModel({
            crbPosHistoryRecords: records
          });
        })
        .catch(() => {
          this.snackbarStore.showErrorSnackbarMessage('Failed to load data fetcher history');
        })
        .finally(() => {
          this.updateProgrammersModel({
            isLoading: false
          });
        });
    }
  );

  updateProgrammersModel = action((programmersModel: Partial<GcvAccessCrbPosDataFetcherHistoryPM>) => {
    this.gcvDataFetcherHistoryProgrammersModel = {
      ...this.gcvDataFetcherHistoryProgrammersModel,
      ...programmersModel
    };
  });
}
