import * as React from 'react';
import type { Control, FieldError, FieldValues, Validate } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { InputAttributes } from 'react-number-format';
import NumberFormat from 'react-number-format';
import { FormControl, TextField } from '@mui/material';

import { Container, ContainerItem, Error, Icon, InputLabel, Tooltip } from 'ui/atoms';
import { errorMessages } from 'util/forms.util';

interface CustomProps {
  onChange: (e?: string) => void;
  setFieldError: (value: React.SetStateAction<FieldError | undefined>) => void;
  label: string;
  viewOnly?: boolean;
  readOnly?: boolean;
  unformatted?: boolean;
  name: string;
  value: any;
  maxInteger: number;
}

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputCurrencyProps {
  label: string;
  name: string;
  control?: Control;
  dataCy?: string;
  defaultValue?: string;
  onBlur?: () => void;
  onChange?: (e?: string) => void;
  readOnly?: boolean;
  viewOnly?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  tooltip?: string;
  unformatted?: boolean;
  customInput?: React.ComponentType<any>;
  maxInteger?: number;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, value, setFieldError, ...other } = props;

    return (
      <NumberFormat
        {...other}
        className={'MuiInput-input MuiInputBase-input'}
        allowLeadingZeros={true}
        decimalSeparator={props.unformatted ? undefined : '.'}
        thousandSeparator={!props.unformatted}
        disabled={props.readOnly || props.viewOnly}
        readOnly={props.readOnly}
        getInputRef={ref}
        defaultValue={value}
        value={value}
        onValueChange={(e) => {
          if (props.onChange) {
            props.onChange(e.value);
          }

          onChange(e.value);
        }}
        isAllowed={(numberValue) => {
          let isValid = true;

          // allow entering negative sign
          if (numberValue.value === '-') {
            return isValid;
          }

          if (!numberValue.value) {
            // empty is still valid, but we also want an error message
            isValid = true;
            setFieldError({
              message: `${props.label} invalid. Number field must contain numbers only (ex. 123).`,
              type: 'valueAsNumber'
            });
          } else if (isNaN(+numberValue.value)) {
            isValid = false;
            setFieldError({
              message: `${props.label} invalid. Number field must contain numbers only (ex. 123).`,
              type: 'valueAsNumber'
            });
          } else if (+numberValue.value >= (props.maxInteger ? props.maxInteger : Number.MAX_SAFE_INTEGER)) {
            isValid = false;
            setFieldError({
              message: `${props.label} invalid. Maximum number exceeded.`,
              type: 'valueAsNumber'
            });
          } else {
            setFieldError(undefined);
          }

          return isValid;
        }}
      />
    );
  }
);

export const InputNumberClone: React.FC<InputCurrencyProps> = (props) => {
  const [fieldError, setFieldError] = React.useState<FieldError>();

  return (
    <FormControl fullWidth data-cy={props.dataCy}>
      <Container padding={0} align="flex-start">
        <ContainerItem padding={0} align="center" flex margin="0 0 6px 0">
          <InputLabel
            dataCy={'input-label'}
            label={props.label}
            name={props.name}
            required={props.viewOnly ? false : props.rules?.required?.value}
            readOnly={props.readOnly}
            rules={props.rules}
            viewOnly={props.viewOnly}
            darkLabel
          />
        </ContainerItem>

        {props.tooltip ? (
          <ContainerItem padding={0} align="center" flex margin="-3px 0 0 0">
            <Tooltip variant="text" title={props.tooltip} placement="right">
              <Icon type="help" color="primary" />
            </Tooltip>
          </ContainerItem>
        ) : null}
      </Container>
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              sx={{
                backgroundColor: (theme) => (props.viewOnly ? 'none' : theme.palette.background.default),
                borderColor: (theme) => theme.palette.grey[400],
                height: '41px',
                width: '100%',
                '.MuiInputBase-input': {
                  border: 'none',
                  background: 'none',
                  borderRadius: '5px',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  height: '41px',
                  padding: props.viewOnly ? '1px 0 5px' : '0.5rem 0.75rem',
                  width: '100%',
                  color: '#3A3C45'
                },
                '.MuiInputBase-root': {
                  background: 'none',
                  border: props.viewOnly ? 'none' : `1px solid`,
                  borderColor: (theme) => (fieldError ? theme.palette.error.main : theme.palette.divider),

                  '&.Mui-focused': {
                    borderColor: (theme) =>
                      fieldError ? theme.palette.error.main : theme.palette.primary.main,
                    boxShadow: fieldError
                      ? `0px 0px 0px 3px rgba(255, 78, 80, 0.25)`
                      : `0px 0px 0px 3px rgba(3, 166, 91, 0.25)`
                  },

                  '& fieldset': {
                    border: 'none'
                  }
                }
              }}
              name={props.name}
              value={value == null ? '' : value}
              onChange={(e) => {
                onChange(e);
                if (props.onChange) {
                  props.onChange();
                }
              }}
              onBlur={() => {
                if (props.onBlur) {
                  props.onBlur();
                }

                onBlur();
              }}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  setFieldError: setFieldError,
                  label: props.label,
                  readOnly: props.readOnly,
                  viewOnly: props.viewOnly,
                  unformatted: props.unformatted,
                  maxInteger: props.maxInteger
                }
              }}
            />
            <Error
              content={
                props.viewOnly
                  ? ''
                  : fieldError
                  ? `* ${fieldError.message || errorMessages()[fieldError.type]}`
                  : error
                  ? `* ${props.label} ${error.message || errorMessages()[error.type]}`
                  : ''
              }
              dataCy="validation-message"
            />
          </>
        )}
      />
    </FormControl>
  );
};
