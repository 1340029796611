import React from 'react';
import { Box, Button as ButtonContainer, CircularProgress } from '@mui/material';

import { theme } from 'ui/theme';
import customPalette from 'ui/theme/palette';

export interface ButtonProps {
  className?: string;
  label: string | JSX.Element;
  style?: React.CSSProperties;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  height?: string;
  width?: string;
  color:
    | 'default'
    | 'default-outlined'
    | 'default-text'
    | 'text'
    | 'primary'
    | 'text'
    | 'primary-outlined'
    | 'primary-text'
    | 'secondary-text'
    | 'secondary-outlined'
    | 'warning'
    | 'warning-outlined'
    | 'icon-selected'
    | 'icon-deselected'
    | 'header-button';
  disabled?: boolean;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  icon?: string;
  iconMuiClass?: string;
  endIcon?: string;
  isLoading?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'contained' | 'text';
  dataCy?: string;
  type?: 'button' | 'submit';
  useMinWidth?: boolean;
  headerComponent?: boolean;
}

const colors = {
  default: {
    text: theme.palette.text.primary,
    background: customPalette.background.secondary,
    border: theme.palette.text.disabled,
    hoverBackground: customPalette.background.secondary,
    hoverOutline: theme.palette.text.secondary,
    hoverText: theme.palette.text.primary
  },
  text: {
    text: theme.palette.text.primary,
    background: 'transparent',
    border: 'transparent',
    hoverBackground: 'transparent',
    hoverOutline: 'transparent',
    hoverText: theme.palette.text.primary
  },
  'default-outlined': {
    text: theme.palette.text.secondary,
    background: 'transparent',
    border: theme.palette.text.disabled,
    hoverBackground: 'transparent',
    hoverOutline: 'transparent',
    hoverText: theme.palette.text.secondary
  },
  'default-text': {
    text: theme.palette.text.secondary,
    background: 'transparent',
    border: theme.palette.common.white,
    hoverBackground: theme.palette.common.white,
    hoverOutline: theme.palette.common.white,
    hoverText: theme.palette.primary.main
  },
  'header-button': {
    text: theme.palette.text.primary,
    background: theme.palette.common.white,
    border: theme.palette.text.disabled,
    hoverBackground: theme.palette.common.white,
    hoverOutline: theme.palette.primary.main,
    hoverText: theme.palette.primary.main
  },
  primary: {
    text: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    border: 'transparent',
    hoverBackground: theme.palette.primary.dark,
    hoverOutline: theme.palette.common.white,
    hoverText: theme.palette.primary.contrastText
  },
  'primary-outlined': {
    text: theme.palette.primary.main,
    background: theme.palette.common.white,
    border: theme.palette.primary.main,
    hoverBackground: theme.palette.common.white,
    hoverOutline: theme.palette.primary.dark,
    hoverText: theme.palette.primary.dark
  },
  'primary-text': {
    text: theme.palette.primary.main,
    background: 'transparent',
    border: 'transparent',
    hoverBackground: 'transparent',
    hoverOutline: 'transparent',
    hoverText: theme.palette.primary.dark
  },
  'secondary-text': {
    text: theme.palette.secondary.main,
    background: 'transparent',
    border: 'transparent',
    hoverBackground: 'transparent',
    hoverOutline: 'transparent',
    hoverText: theme.palette.secondary.dark
  },
  'secondary-outlined': {
    text: theme.palette.text.secondary,
    background: 'transparent',
    border: theme.palette.text.disabled,
    hoverBackground: 'transparent',
    hoverOutline: 'transperant',
    hoverText: theme.palette.text.primary
  },
  warning: {
    text: theme.palette.error.contrastText,
    background: theme.palette.error.main,
    border: theme.palette.common.white,
    hoverBackground: theme.palette.error.dark,
    hoverOutline: theme.palette.common.white,
    hoverText: theme.palette.error.contrastText
  },
  'warning-outlined': {
    text: theme.palette.error.main,
    background: theme.palette.common.white,
    border: theme.palette.error.main,
    hoverBackground: theme.palette.common.white,
    hoverOutline: theme.palette.error.dark,
    hoverText: theme.palette.error.main
  },
  'icon-selected': {
    text: theme.palette.primary.main,
    background: 'transparent',
    border: 'transparent',
    hoverBackground: 'transparent',
    hoverOutline: 'transparent',
    hoverText: theme.palette.primary.main
  },
  'icon-deselected': {
    text: theme.palette.text.disabled,
    background: 'transparent',
    border: 'transparent',
    hoverBackground: 'transparent',
    hoverOutline: 'transparent',
    hoverText: theme.palette.text.disabled
  }
};

export const Button: React.FC<ButtonProps> = (props) => {
  const color = colors[props.color] ? colors[props.color] : colors.default;
  const size = props.size ? props.size : 'medium';
  const type = props.type ? props.type : 'button';
  const variant = props.variant ? props.variant : 'contained';

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!props.isLoading) {
      props.onClick(event);
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: props.fullWidth ? '100%' : undefined,
        cursor: props.disabled ? 'not-allowed' : 'pointer'
      }}
    >
      <ButtonContainer
        className={props.className}
        disableRipple
        fullWidth={props.fullWidth}
        // color={variant === 'text' ? 'secondary' : undefined}
        disabled={props.disabled || props.isLoading}
        onClick={onClick}
        size={size}
        style={props.style}
        startIcon={
          props.startIcon ? (
            props.startIcon
          ) : props.icon ? (
            <span className={props.iconMuiClass || 'material-icons'}>{props.icon}</span>
          ) : null
        }
        endIcon={props.endIcon ? <span className="material-icons">{props.endIcon}</span> : null}
        variant={variant}
        data-cy={props.dataCy}
        type={type}
        sx={{
          minWidth: props.fullWidth ? undefined : props.useMinWidth ? '6rem' : undefined,
          height: props.height ? props.height : props.headerComponent ? '36px' : '41px',
          width: props.width ? props.width : props.fullWidth ? '100%' : 'auto',
          textDecoration: 'none',
          backgroundColor: color.background,
          color: color.text,
          border: `1px solid ${color.border}`,
          borderRadius: '6px',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: color.hoverBackground,
            border: `1px solid ${color.hoverOutline}`,
            color: color.hoverText,
            '& svg': props.headerComponent
              ? {
                  '& path': {
                    fill: `${color.hoverText}`
                  }
                }
              : {}
          },
          '&:focus': {
            boxShadow: 'none'
          },
          '&[disabled]': {
            backgroundColor: props.color === 'secondary-text' ? 'white' : 'background.secondary',
            color: 'text.disabled',
            border: 'none'
          }
        }}
      >
        {props.isLoading ? (
          <CircularProgress
            size={24}
            sx={{
              color: (theme) => theme.palette.primary.main,
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        ) : (
          props.label
        )}
      </ButtonContainer>
    </Box>
  );
};
