import React, { useEffect, useMemo, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { VerboseQuestionnaireMetadata } from '@gcv/shared';
import { Box } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QuestionnairesApi, ReportType } from 'api';
import type { TimePeriod } from 'stores/AppViewStateStore';
import { getFiBankStore } from 'stores/FiBankStore';
import { getFiDispensaryStore } from 'stores/FiDispensaryStore';
import { getFiTemplateStore } from 'stores/FiTemplateStore';
import type { FilterListChild, ListOption, Option, Report } from 'ui';
import { Button, Container, ContainerItem, Dialog, InputRadio, InputSelect } from 'ui';
import { sortArrayByField } from 'util/array.util';
import AccountsList from './accounts-list';
import MultiStatusReportsDropdown from './multi-status-reports-dropdown';
import ReportStatus from './reports-status';
import SingleStatusReportsDropdown from './single-status-reports-dropdown';
import TimePeriodReportsDropdown from './time-period-reports-dropdown';
import UserReportRadioButtons, { UserReportAccountType } from './user-reports-radio-buttons';

export enum QuestionnaireReportAccountType {
  Accounts = 'accounts',
  Questionnaires = 'questionnaires'
}

export enum AccountsSummaryReportAccountType {
  Accounts = 'accounts',
  DueDiligenceTemplate = 'dueDiligenceTemplate'
}

interface Props {
  report: Report;
  reportType: ReportType;
  title: string;
  description: string;
  isOpen: boolean;
  handleClose: () => void;
  exportAction: (data: Record<string, any>) => Promise<boolean>;
  confirmationCallback: () => void;
}

export const ReportsModal = (props: Props) => {
  const questionnairesApi = new QuestionnairesApi();
  const { archivedCrbs } = useFlags();

  const form = useForm({
    defaultValues: {
      sales_statuses: ['unchecked'],
      status: '',
      userReportRadioButtonsField: UserReportAccountType.Users,
      questionnaires_report_radio_buttons: QuestionnaireReportAccountType.Accounts,
      account_summary_radio_buttons: AccountsSummaryReportAccountType.Accounts
    } as FieldValues,
    mode: 'onBlur'
  });

  const [timePeriod, setTimePeriod] = useState<TimePeriod>();
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [options, setOptions] = useState<ListOption[]>([]);
  const [filterToggle] = useState(false);
  const [userReportAccountType, setUserReportAccountType] = useState(UserReportAccountType.Users);
  const [questionnaireReportType, setQuestionnaireReportType] = useState(
    QuestionnaireReportAccountType.Accounts
  );
  const [accountsSummaryReportType, setAccountsSummaryReportType] = useState(
    AccountsSummaryReportAccountType.Accounts
  );
  const [questionnaires, setQuestionnaires] =
    useState<{ [templateId: string]: VerboseQuestionnaireMetadata }>();

  const [templateOptions, setTemplateOptions] = useState<{ value: string; label: string }[]>([]);
  const reportType = props.reportType;
  const [selections, setSelections] = useState<Option[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [shouldShowArchiveButton, setShouldShowArchiveButton] = useState(false);
  const [includeSelectedArchived, setIncludeSelectedArchived] = useState(false);
  const showArchivedRef = React.useRef(false);
  showArchivedRef.current = showArchived;

  useEffect(() => {
    questionnairesApi
      .getQuestionnairesForBank(getFiBankStore().bank.id)
      .then((response) => {
        setQuestionnaires(response.questionnaires);
      })
      .catch((error) => {
        console.log(`Error loading questionnaires: ${error}`);
      });
  }, []);

  useEffect(() => {
    setSelectedAccounts([]);
    setSelections([]);

    if (props.isOpen) {
      if (reportType === ReportType.USER) {
        toggleUsersContacts(userReportAccountType);
      } else if (reportType === ReportType.QUESTIONNAIRES) {
        toggleQuestionnaireAccounts(questionnaireReportType);
        form.setValue('questionnaires_report_radio_buttons', questionnaireReportType);
      } else if (reportType === ReportType.ACCOUNT) {
        toggleAccountsSummaryAccounts(accountsSummaryReportType);
        form.setValue('account_summary_radio_buttons', accountsSummaryReportType);
      } else {
        updateAccountsList();
      }
    }
  }, [props.isOpen, userReportAccountType, questionnaireReportType, reportType]);

  // this useEffect tells whether the Show/Hide Archive button should be there or not
  useEffect(() => {
    let showButton = false;
    if (props.isOpen) {
      switch (reportType) {
        case ReportType.ACCOUNT:
          showButton = accountsSummaryReportType === AccountsSummaryReportAccountType.Accounts;
          break;
        case ReportType.QUESTIONNAIRES:
          showButton = questionnaireReportType === QuestionnaireReportAccountType.Accounts;
          break;
        case ReportType.USER:
          showButton = userReportAccountType === UserReportAccountType.Contacts;
          break;
        case ReportType.COMMENTS:
        case ReportType.DAILY:
        case ReportType.DEPOSITS:
        case ReportType.FINCENCTR:
        case ReportType.FINCENSAR:
        case ReportType.LICENSE:
        case ReportType.RAWSALES:
        case ReportType.SALES:
        case ReportType.TRANSACTION:
          showButton = true;
      }
    }

    setShouldShowArchiveButton(showButton);
  }, [props.isOpen, userReportAccountType, questionnaireReportType, accountsSummaryReportType]);

  /** Return combined list of MUO's and dispensaries. */
  const getAccounts = (): ListOption[] => {
    const getDispensaries = (): ListOption[] => {
      return getFiDispensaryStore()
        .dispensaries.filter((dispensary) => !dispensary.muo_id)
        .map((dispensary) => {
          return {
            value: dispensary.id,
            label: dispensary.name,
            children: [],
            archivedOption: false,
            selected: selectedAccounts.includes(dispensary.id)
          } as ListOption;
        });
    };

    const getDispensariesArchived = (): ListOption[] => {
      return getFiDispensaryStore()
        .dispensariesArchived.filter((dispensary) => !dispensary.muo_id)
        .map((dispensary) => {
          return {
            value: dispensary.id,
            label: dispensary.name,
            children: [],
            selected: selectedAccounts.includes(dispensary.id),
            archivedOption: true
          } as ListOption;
        });
    };

    const getMUOs = (): ListOption[] => {
      return getFiDispensaryStore().bankMuos.map((muo) => {
        let children = getFiDispensaryStore()
          .dispensaries.filter((dispensary) => dispensary.muo_id === muo.id)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((dispensary) => {
            return {
              value: dispensary.id,
              label: dispensary.name,
              selected: selectedAccounts.includes(dispensary.id),
              parentValue: muo.id,
              archivedOption: false,
              children: []
            } as FilterListChild;
          });
        if (archivedCrbs) {
          children = [
            ...children,
            ...getFiDispensaryStore()
              .dispensariesArchived.filter((dispensary) => dispensary.muo_id === muo.id)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((dispensary) => {
                return {
                  value: dispensary.id,
                  label: dispensary.name,
                  selected: selectedAccounts.includes(dispensary.id),
                  parentValue: muo.id,
                  children: [],
                  archivedOption: true
                } as FilterListChild;
              })
          ];
        }
        return {
          value: muo.id,
          label: muo.name,
          selected: selectedAccounts.includes(muo.id),
          children
        } as ListOption;
      });
    };

    const getMUOsArchived = (): ListOption[] => {
      return getFiDispensaryStore().bankMuosArchived.map((muo) => {
        return {
          value: muo.id,
          label: muo.name,
          selected: selectedAccounts.includes(muo.id),
          archivedOption: true,
          children: getFiDispensaryStore()
            .dispensariesArchived.filter((dispensary) => dispensary.muo_id === muo.id)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((dispensary) => {
              return {
                value: dispensary.id,
                label: dispensary.name,
                selected: selectedAccounts.includes(dispensary.id),
                parentValue: muo.id,
                children: [],
                archivedOption: true
              } as FilterListChild;
            })
        } as ListOption;
      });
    };

    if (!archivedCrbs) {
      return [...getMUOs(), ...getDispensaries()].sort((a, b) => a.label.localeCompare(b.label));
    }

    return [...getMUOs(), ...getDispensaries(), ...getDispensariesArchived(), ...getMUOsArchived()].sort(
      (a, b) => a.label.localeCompare(b.label)
    );
  };

  const getAccountIds = () => {
    const getIds = (accounts: ListOption[]) => {
      const ids: string[] = [];

      for (const account of accounts) {
        ids.push(account.value);

        if (account.children && account.children.length > 0) {
          ids.push(...getIds(account.children));
        }
      }

      return ids;
    };

    return getIds(getAccounts());
  };

  const updateAccountsList = () => {
    const list = getAccounts();
    setOptions(list);
    setSelectedAccounts([]);
  };

  const toggleUsersContacts = (value: UserReportAccountType) => {
    setUserReportAccountType(value);

    if (!hasRadioButtons() || value === UserReportAccountType.Contacts) {
      updateAccountsList();
    } else {
      const account = {
        value: getFiBankStore().bank.id,
        label: getFiBankStore().bank.orgName,
        selected: true,
        children: []
      };
      setOptions([account]);
      setSelectedAccounts([getFiBankStore().bank.id]);
    }
  };

  const toggleQuestionnaireAccounts = (value: QuestionnaireReportAccountType) => {
    setQuestionnaireReportType(value);

    if (value === QuestionnaireReportAccountType.Accounts) {
      updateAccountsList();
    } else {
      if (questionnaires) {
        const questionnaireOptions = Object.values(questionnaires).map((q) => {
          return {
            value: q.template_id,
            label: q.name,
            selected: false,
            children: []
          };
        });

        setOptions(questionnaireOptions);
      }
    }
  };

  const toggleAccountsSummaryAccounts = (value: AccountsSummaryReportAccountType) => {
    setAccountsSummaryReportType(value);

    if (value === 'dueDiligenceTemplate') {
      form.setValue('dueDiligenceTemplate', !!templateOptions?.length ? templateOptions[0].value : undefined);
    }

    if (value === AccountsSummaryReportAccountType.Accounts) {
      updateAccountsList();
    } else {
      if (templateOptions) {
        setSelectedTemplateId(!!templateOptions?.length ? templateOptions[0].value : '');
        const accountTemplateOptions = Object.values(templateOptions).map((q) => {
          return {
            value: q.value,
            label: q.label,
            selected: false,
            children: []
          };
        });

        setOptions(accountTemplateOptions);
      }
    }
  };

  const hasField = (name: string) => {
    return props.report.fields.map((f) => f.name).includes(name);
  };

  const handleSelect = (value: boolean) => {
    const selected: string[] = [];

    const addOptionToSelected = (option: ListOption) => {
      let shouldSelect = value;
      if (showArchivedRef.current || includeSelectedArchived) {
        setIncludeSelectedArchived(true);
      } else if (!option.archivedOption) {
        selected.push(option.value);
      } else {
        shouldSelect = false;
      }
      return shouldSelect;
    };

    const newOptions = options.map((o) => {
      if (o.children) {
        const children = o.children.map((oc) => {
          const shouldSelect = addOptionToSelected(oc);
          oc = { ...oc, selected: shouldSelect };

          return oc;
        });
        const shouldSelect = addOptionToSelected(o);
        o = { ...o, selected: shouldSelect, children };
      }
      return o;
    });

    setOptions(newOptions);

    if (value) {
      setSelectedAccounts(selected);
    } else {
      setSelectedAccounts([]);
    }
  };

  // clearAll of Multi-select was not working correctly for reports,
  // hence reports has it's own clearAll function
  const clearAllSelectedAccounts = () => {
    handleSelect(false);
  };

  // selectAll of Multi-select was not working correctly for reports,
  // hence reports has it's own selectAll function
  const selectAllAcounts = () => {
    handleSelect(true);
  };

  /* TimePeriodReportsDropdown */

  const timePeriodDropdown = useMemo(() => {
    if (hasField('TimePeriod')) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const field = props.report.fields.find((f) => f.name === 'TimePeriod')!;

      return (
        <ContainerItem padding="0" margin="0.5rem 0 0.5rem 0" width="100%">
          <TimePeriodReportsDropdown
            limitTimePeriodBy90Days={reportType === ReportType.SALES}
            label={field.label ?? 'Time Period'}
            emitData={setTimePeriod}
          />
        </ContainerItem>
      );
    } else {
      return null;
    }
  }, [props.report]);

  /* StatusReportsDropdown */

  const singleSelectStatusDropdown = useMemo(() => {
    if (hasField('SingleSelectStatus')) {
      return (
        <ContainerItem padding="0" margin="0 0 0.5rem 0" width="100%">
          <SingleStatusReportsDropdown form={form} reportType={reportType} />
        </ContainerItem>
      );
    } else {
      return null;
    }
  }, [props.report, form]);

  /* MultiSelectStatusReportsDropdown */

  const multiSelectStatusDropdown = useMemo(() => {
    if (hasField('MultiSelectStatus')) {
      return (
        <ContainerItem padding="0" margin="0 0 0.5rem 0" width="100%">
          <MultiStatusReportsDropdown
            form={form}
            reportType={reportType}
            setSelections={(s) => {
              if (Array.isArray(s)) {
                setSelections(s);
              }
            }}
          />
        </ContainerItem>
      );
    } else {
      return null;
    }
  }, [props.report, form]);

  /* AccountsList */

  const hasRadioButtons = () => {
    // Only AccountsList has radio buttons option.
    return props.report.fields.find((f) => f.name === 'AccountsList')?.radioButtons;
  };

  const radioButtons = useMemo(() => {
    if (hasRadioButtons()) {
      if (reportType === ReportType.USER) {
        return (
          <ContainerItem padding="0" margin="0 0 0.5rem 0" width="100%">
            <UserReportRadioButtons form={form} callback={toggleUsersContacts} />
          </ContainerItem>
        );
      } else if (reportType === ReportType.QUESTIONNAIRES) {
        toggleQuestionnaireAccounts(QuestionnaireReportAccountType.Accounts);

        return (
          <ContainerItem padding="0" margin="0 0 0.5rem 0" width="100%">
            <InputRadio
              onChange={(v) => toggleQuestionnaireAccounts(v as QuestionnaireReportAccountType)}
              justifyContent="row"
              label={''}
              name={'questionnaires_report_radio_buttons'}
              options={[
                { label: 'By Account', value: QuestionnaireReportAccountType.Accounts },
                { label: 'By Questionnaire', value: QuestionnaireReportAccountType.Questionnaires }
              ]}
              {...form}
            />
          </ContainerItem>
        );
      } else if (reportType === ReportType.ACCOUNT) {
        toggleAccountsSummaryAccounts(AccountsSummaryReportAccountType.Accounts);

        return (
          <ContainerItem padding="0" margin="0 0 0.5rem 0" width="100%">
            <InputRadio
              onChange={(v) => toggleAccountsSummaryAccounts(v as AccountsSummaryReportAccountType)}
              justifyContent="row"
              label={''}
              name={'account_summary_radio_buttons'}
              options={[
                { label: 'By Account', value: AccountsSummaryReportAccountType.Accounts },
                {
                  label: 'By Due Diligence Template',
                  value: AccountsSummaryReportAccountType.DueDiligenceTemplate
                }
              ]}
              {...form}
            />
          </ContainerItem>
        );
      }
    } else {
      return null;
    }
  }, [props.report, form]);

  const title = useMemo(() => {
    return reportType === ReportType.QUESTIONNAIRES &&
      form.watch('questionnaires_report_radio_buttons') === QuestionnaireReportAccountType.Questionnaires
      ? 'Questionnaires'
      : 'Accounts';
  }, [questionnaires, form, form.watch('questionnaires_report_radio_buttons')]);

  const accountsList = useMemo(() => {
    if (props.report.fields.map((f) => f.name).includes('AccountsList')) {
      if (accountsSummaryReportType === 'dueDiligenceTemplate') {
        return null;
      }

      return (
        <ContainerItem padding="0" margin="0.75rem 0 0.5rem 0" width="100%">
          <AccountsList
            title={title}
            options={options}
            setSelectedAccounts={(value: string) => {
              setSelectedAccounts((prev: string[]) => {
                const newSelected = [...prev];

                const index = newSelected.findIndex((id) => id === value);

                if (index < 0) {
                  newSelected.push(value);
                } else {
                  newSelected.splice(index, 1);
                }

                return newSelected;
              });
            }}
            showTopActions={shouldShowArchiveButton}
            toggleArchived={(value) => {
              setShowArchived(value);
            }}
            clearAllSelected={clearAllSelectedAccounts}
            selectAll={selectAllAcounts}
          />
        </ContainerItem>
      );
    } else {
      return null;
    }
  }, [props.report, radioButtons, options, shouldShowArchiveButton, selectedAccounts]);

  /* Due Diligence Template */
  useEffect(() => {
    const fiTemplateStore = getFiTemplateStore();
    const templates = Object.keys(fiTemplateStore.templates.onboarding)
      .map((key) => {
        const { template_id, name, published_date, plant_touching } = {
          ...fiTemplateStore.templates.onboarding[key]
        };
        return {
          value: template_id,
          label: name,
          published: published_date !== undefined,
          plant_touching: plant_touching
        };
      })
      .filter((t) => t.published);

    setTemplateOptions(sortArrayByField(templates, 'label'));
  }, []);

  const dueDiligenceTemplate = useMemo(() => {
    if (reportType === ReportType.ACCOUNT && accountsSummaryReportType === 'dueDiligenceTemplate') {
      return (
        <ContainerItem width="100%" margin="10px 0" padding="0">
          <InputSelect
            placeholder="No templates found"
            name="dueDiligenceTemplate"
            label="Due Diligence Template"
            defaultValue={!!templateOptions?.length ? templateOptions[0].value : undefined}
            options={templateOptions}
            additionalOnChange={(e) => setSelectedTemplateId(e.target.value)}
            addTag="Marijuana-Licensed"
            rules={{ required: { message: 'is required', value: true } }}
            {...form}
          />
        </ContainerItem>
      );
    } else {
      return null;
    }
  }, [props.report, radioButtons, options]);

  const isDueDiligenceReportDisabled = useMemo(() => {
    return !!dueDiligenceTemplate && !templateOptions?.length;
  }, [dueDiligenceTemplate, templateOptions]);

  useEffect(() => {
    const fieldName = 'dueDiligenceTemplate';

    if (isDueDiligenceReportDisabled) {
      form.trigger(fieldName);
    } else {
      form.clearErrors(fieldName);
    }
  }, [isDueDiligenceReportDisabled]);

  /* Report components */

  const components = useMemo(() => {
    return (
      <>
        {radioButtons}
        {timePeriodDropdown}
        {singleSelectStatusDropdown}
        {multiSelectStatusDropdown}
        {accountsList}
        {dueDiligenceTemplate}
      </>
    );
  }, [timePeriodDropdown, singleSelectStatusDropdown, multiSelectStatusDropdown, accountsList]);

  return (
    <Dialog
      title={props.title}
      isOpen={props.isOpen}
      height="570px"
      handleClose={() => {
        props.handleClose();
        setShowArchived(false);
      }}
      action={
        <Button
          disabled={isDueDiligenceReportDisabled}
          label="Export Report"
          onClick={() => {
            const getQuestionnaireIds = () => {
              const selected = options.filter((o) => selectedAccounts.includes(o.value));
              const hasNoneSelected = !selected.length;

              return hasNoneSelected ? options.map((o) => o.value) : selected.map((o) => o.value);
            };

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: Record<string, any> = {};
            data.accounts = selectedAccounts.length > 0 ? selectedAccounts : getAccountIds();

            if (timePeriodDropdown) {
              data.timePeriod = timePeriod;
            }

            if (reportType === ReportType.DEPOSITS) {
              const status = form.getValues(['status'])[0];

              if (status.length === 0) {
                data.status = new ReportStatus(reportType).getAllReportValues();
              } else {
                data.status = status;
              }
            }

            if (reportType === ReportType.FINCENCTR || reportType === ReportType.FINCENSAR) {
              data.status = form.getValues(['status'])[0];
            }

            if (multiSelectStatusDropdown) {
              const statuses =
                selections.length > 0
                  ? selections.map((s) => s.value)
                  : new ReportStatus(reportType).getAllReportValues();

              if (reportType === ReportType.SALES) {
                data.salesStatuses = statuses;
              } else {
                data.licenseStatuses = statuses;
              }
            }

            if (reportType === ReportType.QUESTIONNAIRES) {
              const type = form.watch('questionnaires_report_radio_buttons');

              if (type === QuestionnaireReportAccountType.Questionnaires) {
                data.fullReport = true;
              } else {
                data.fullReport = false;
              }

              data.ids = data.fullReport ? getQuestionnaireIds() : [...data.accounts];
            }

            if (reportType === ReportType.ACCOUNT) {
              const type = form.watch('account_summary_radio_buttons');

              if (type === AccountsSummaryReportAccountType.DueDiligenceTemplate) {
                data.fullReport = true;
              } else {
                data.fullReport = false;
              }

              data.ids = data.fullReport ? selectedTemplateId : [...data.accounts];
            }

            props.exportAction(data).then(() => {
              props.confirmationCallback();
            });

            props.handleClose();
          }}
          color="primary"
        />
      }
    >
      <Box
        sx={{
          overflow: 'hidden',
          '& .filterSelectButton-topBox': {
            display: 'block'
          },
          '& .filterSelectButton-labelBox': {
            marginTop: '1rem',
            marginBottom: '0.4rem'
          },
          '& .filterDropdownBasic-topBox': {
            width: '100%'
          },
          '& .filterSelectButton-buttonBox': {
            borderColor: filterToggle ? 'primary.main' : '#C6CCDA'
          }
        }}
      >
        <Container column padding={0}>
          <ContainerItem padding="0" margin="0 0 1rem 0" width="100%">
            {props.description}
          </ContainerItem>
        </Container>

        <Container width="100%" padding={'0'}>
          {components}
        </Container>
      </Box>
    </Dialog>
  );
};
