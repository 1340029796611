import * as React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';

import { ReactComponent as UserIcon } from 'assets/images/ic-user.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { FiBankStore } from 'stores/FiBankStore';
import { Button, Container, ContainerItem, Dialog, InputDate, InputSelect, Text } from 'ui';
import { setDefaultValues } from 'util/forms.util';
import { CommentsPresenter } from '../comments.presenter';

interface Props extends Record<string, unknown> {
  submitCommentAndTask: (data: FieldValues) => Promise<void>;
}

export const CommentsModalTaskCreate: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(CommentsPresenter);
  const bankStore = useInjection(FiBankStore);

  const form = useForm({
    defaultValues: setDefaultValues({
      assignee:
        presenter.viewModel.mentionedUsers.length === 1 ? presenter.viewModel.mentionedUsers[0].id : '',
      dueDate: DateTime.now()
        .plus({ days: 7 })
        .setZone(bankStore.bank.iana_timezone, { keepLocalTime: true })
        .startOf('day')
        .toUTC()
        .toISO()
    })
  });

  const postCommentAndCreateTask = (data: FieldValues) => {
    props.submitCommentAndTask(data);
    presenter.openCreateTaskModal(false);
  };

  return (
    <Dialog
      title={'Create Task'}
      handleClose={() => presenter.openCreateTaskModal(false)}
      isOpen={presenter.viewModel.isCreateTaskModalOpen}
      width="460px"
      action={
        <Button
          color="primary"
          label="Post Comment and Create Task"
          onClick={form.handleSubmit(postCommentAndCreateTask)}
        />
      }
    >
      <Container padding={0} width="100%">
        <ContainerItem padding="0 0 24px 0" width="100%">
          <Text content="The assigned user will receive a notification for the new task." type="body2" />
        </ContainerItem>

        <ContainerItem padding="0 0 24px 0" width="100%">
          <InputSelect
            {...form}
            disablePortal
            placeholder="Select assignee"
            label={'Assignee'}
            name="assignee"
            options={presenter.viewModel.mentionedUsers.map((u) => {
              return {
                label: u.display.replace('@', ''),
                value: u.id,
                icon: (
                  <UserIcon
                    style={{ position: 'relative', top: '3px', right: '5px', height: '24px', width: '24px' }}
                  />
                )
              };
            })}
            rules={{ required: { message: 'is required', value: true } }}
          />
        </ContainerItem>

        <ContainerItem padding={0} width="100%">
          <InputDate
            {...form}
            disablePastDates
            label={'Due Date'}
            name="dueDate"
            timezone={bankStore.bank.iana_timezone}
            rules={{ required: { message: 'is required', value: true } }}
          />
        </ContainerItem>
      </Container>
    </Dialog>
  );
});
