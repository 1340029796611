import type {
  DailySummary,
  DispensaryDailySummaryOverview,
  DispensaryDepositOverview,
  IANATimezones,
  MinifiedDispensary,
  MUO,
  MUOBankResponse
} from '@gcv/shared';
import { DueDiligenceStatus, OrganizationType } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe, runInAction } from 'mobx';

import type { TimePeriod } from 'stores/AppViewStateStore';
import { AppPage, AppViewStateStore } from 'stores/AppViewStateStore';
import { FiDispensaryStore } from 'stores/FiDispensaryStore';
import { getSnackbarStore, SnackbarStore } from 'stores/SnackBarStore';
import type { FilterListChild, FilterListItem } from 'ui';
import { DateTimeHelpers } from 'util/dateTime.util';
import type { PM } from './daily-summaries.repo';
import { FiDailySummariesRepo } from './daily-summaries.repo';
interface DispensarySalesMap {
  [id: string]: DispensaryDailySummaryOverview;
}
interface DispensaryDepositsMap {
  [id: string]: DispensaryDepositOverview;
}

export interface VM {
  currentFilter: FilterListChild[];
  dailySummaryFilterOptions: FilterListItem[];
  dailySummaryFilterWith: FilterListItem[];
  dailySummaries: DailySummary[];
  filterOptions: FilterListItem[];
  filteredDailySummaryDispensaries: (MinifiedDispensary | MUO)[];
  defaultTimeRangeValue: string;
  isLoading: boolean;
  loadingDailySummaries: boolean;
  filteringData: boolean;
  timezone: () => IANATimezones;
  accountsData: { active: number; reviewInProgress: number; waiting: number; invited: number };
  loadingDataError: boolean;
}

@injectable()
export class FiDailySummariesPresenter {
  @inject(FiDailySummariesRepo)
  private repo: FiDailySummariesRepo;

  @inject(AppViewStateStore)
  private appViewStore: AppViewStateStore;

  @inject(FiDispensaryStore)
  private dispStore: FiDispensaryStore;

  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  private get timezone() {
    return this.repo.programmersModel.timezone;
  }

  private get startDate() {
    return this.appViewStore.dailySummariesTimePeriod.dateRange.start;
  }

  private get endDate() {
    return this.appViewStore.dailySummariesTimePeriod.dateRange.end;
  }

  private get salesDate() {
    return this.startDate === this.endDate
      ? DateTimeHelpers.formatDateStringToFriendlyDateString(this.startDate, this.timezone)
      : DateTimeHelpers.formatDateStringToFriendlyDateString(this.startDate, this.timezone) +
          ' - ' +
          DateTimeHelpers.formatDateStringToFriendlyDateString(this.endDate, this.timezone);
  }

  public viewModel: VM = {
    currentFilter: [],
    dailySummaryFilterOptions: [],
    dailySummaryFilterWith: [],
    dailySummaries: [],
    filterOptions: [],
    filteredDailySummaryDispensaries: [],
    defaultTimeRangeValue: 'last7Days',
    isLoading: true,
    loadingDailySummaries: true,
    timezone: () => this.timezone,
    filteringData: true,
    accountsData: { active: 0, reviewInProgress: 0, invited: 0, waiting: 0 },
    loadingDataError: false
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  private loadStore = action(async () => {
    try {
      await this.repo.load();
    } catch (e) {
      const error = e as Error;
      this.snackbarStore.showErrorSnackbarMessage(error.message);
    }
  });

  private reloadData = action(async () => {
    await this.loadStore();
    const accountsData = this.filterAndFormatData();

    this.updateViewModel({ isLoading: false, accountsData });
  });

  public load = action(async () => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      try {
        this.updateViewModel({
          dailySummaries: programmersModel.dailySummaries,
          loadingDailySummaries: programmersModel.loadingDailySummaries,
          loadingDataError: programmersModel.loadingDataError
        });
      } catch (e) {
        // In this example, we're going to decide to show a generic message.
        getSnackbarStore().showErrorSnackbarMessage(
          'There was an issue loading page data. Contact support for additional help.'
        );
      }
    });

    await this.reloadData();
    await this.selectFilter();

    if (this.appViewStore.dailySummariesAccounts && this.appViewStore.dailySummariesAccounts.length > 0) {
      this.applyFilter(this.appViewStore.dailySummariesAccounts);
    }
  });

  public applyFilter = action((data: any[]) => {
    // apply filter to page to show correct results
    this.setFilterWith(data);

    this.updateViewModel({ isLoading: false });
  });

  public selectFilter = action(async () => {
    this.setFilterWith([]);

    await this.setDispensaries();

    this.setCurrentFilter(this.viewModel.dailySummaryFilterOptions);
    this.setFilterWith(this.viewModel.dailySummaryFilterWith);
    this.setFilterOptions(this.viewModel.dailySummaryFilterOptions);
  });

  public filterAndFormatData = () => {
    const accounts = this.getAccountsList();
    const accountsData = { active: 0, reviewInProgress: 0, waiting: 0, invited: 0 };
    accounts.forEach((a) => {
      if (a.orgType === OrganizationType.MUO) {
        accountsData.active = accountsData.active + 1;
      }

      if (a.orgType === OrganizationType.DISPENSARY) {
        if (this.isActive(a)) {
          accountsData.active = accountsData.active + 1;
        }
        if (this.isInvited(a)) {
          accountsData.invited = accountsData.invited + 1;
        }
        if (this.isWaiting(a)) {
          accountsData.waiting = accountsData.waiting + 1;
        }
        if (this.isReviewInProgress(a)) {
          accountsData.reviewInProgress = accountsData.reviewInProgress + 1;
        }
      }
    });
    this.viewModel.filteringData = false;
    return accountsData;
  };

  isActive = (dispensary: MinifiedDispensary) => {
    return dispensary.assigned_onboarding_template.status === DueDiligenceStatus.BANK_APPROVED;
  };

  isReviewInProgress = (dispensary: MinifiedDispensary) => {
    const status = dispensary.assigned_onboarding_template.status;
    return status === DueDiligenceStatus.BANK_REVIEW_IN_PROGRESS;
  };

  isWaiting = (dispensary: MinifiedDispensary) => {
    return dispensary.assigned_onboarding_template.status === DueDiligenceStatus.BANK_AWAITING_REVIEW;
  };

  isInvited = (dispensary: MinifiedDispensary) => {
    const status = dispensary.assigned_onboarding_template.status;
    return (
      status === DueDiligenceStatus.BANK_IN_PROGRESS ||
      status === DueDiligenceStatus.BANK_PENDING ||
      status === DueDiligenceStatus.GCV_PENDING ||
      status === DueDiligenceStatus.GCV_IN_PROGRESS ||
      status === DueDiligenceStatus.GCV_AWAITING_REVIEW ||
      status === DueDiligenceStatus.GCV_APPROVED
    );
  };

  /** Return combined list of MUO's and dispensaries. */
  public getAccountsList = (): (MinifiedDispensary | MUOBankResponse)[] => {
    const getDispensaries = (): MinifiedDispensary[] => {
      return this.dispStore.dispensaries;
    };

    const getMUOs = (): MUOBankResponse[] => {
      return this.dispStore.bankMuos;
    };

    return [...getMUOs(), ...getDispensaries()].sort((a, b) => a.name.localeCompare(b.name));
  };

  public resetFilterOptions = action(() => {
    this.updateViewModel({
      dailySummaryFilterOptions: this.viewModel.filteredDailySummaryDispensaries
        .filter((dispensary) => !(dispensary as MinifiedDispensary).muo_id)
        .map((dispensary) => {
          if (dispensary.orgType === 'muo') {
            return {
              value: dispensary.id || '',
              label: dispensary.name || '',
              children: this.dispStore.dispensaries
                .filter((subdisp) => subdisp.muo_id === dispensary.id)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((subdisp) => {
                  return {
                    value: subdisp.id,
                    label: subdisp.name,
                    selected: false,
                    parentValue: subdisp.id,
                    children: []
                  } as FilterListChild;
                })
            };
          } else {
            return {
              value: dispensary.id || '',
              label: dispensary.name || '',
              children: []
            };
          }
        })
    });
  });

  public setCurrentFilter = action((currentFilter: FilterListChild[]) => {
    this.updateViewModel({ currentFilter: currentFilter });
  });

  public setDispensaries = action(async () => {
    try {
      this.updateViewModel({
        filteredDailySummaryDispensaries: this.getAccountsList()
      });

      this.resetFilterOptions();
    } catch (e) {
      this.snackbarStore.showErrorSnackbarMessage(
        'There was an issue loading page data. Contact support for additional help.'
      );
    }
  });

  public setFilterOptions = action((filterOptions: FilterListItem[]) => {
    this.updateViewModel({ filterOptions: filterOptions });
  });

  public setFilterWith = action(async (options: FilterListItem[]) => {
    if (options === null) {
      return;
    }

    const selected = options.filter((option) => option.selected);

    if (!Array.isArray(options)) {
      options = [options];
    }

    let filteredDispensaries: MinifiedDispensary[] = [];

    if (selected.length === 0) {
      filteredDispensaries = this.dispStore.dispensaries;
    } else {
      const findDispensary = (dispId: string) =>
        this.dispStore.dispensaries.find((dispensary) => {
          if (dispensary.id === dispId) {
            filteredDispensaries.push(dispensary);
          }
        });

      selected.forEach((filterTerm) => {
        findDispensary(filterTerm.value);

        if (filterTerm.children && filterTerm.children.length > 0) {
          filterTerm.children.forEach((child) => {
            if (child.selected) {
              findDispensary(child.value);
            }
          });
        }
      });
    }

    runInAction(() => {
      this.updateViewModel({
        filteredDailySummaryDispensaries: filteredDispensaries,
        dailySummaryFilterWith: selected
      });
    });
  });

  public onEmitTimePeriod = action((data: TimePeriod) => {
    if (data.value !== this.appViewStore.dailySummariesTimePeriod.value) {
      this.setTimePeriod(data);
      if (!this.viewModel.isLoading) {
        this.reloadData();
      }
    }
  });

  private setTimePeriod = action((data: TimePeriod) => {
    this.appViewStore.setTimePeriod(data, AppPage.dailySummaries);
  });
}
