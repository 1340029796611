import * as React from 'react';
import { Box } from '@mui/material';

import { Container, ContainerItem, Text } from 'ui';
import { Dialog } from '..';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const IdleUserModal: React.FC<Props> = (props) => {
  const [timer, setTimer] = React.useState<NodeJS.Timer>();
  const [timeout, setTimeout] = React.useState(30);

  React.useEffect(() => {
    setTimer(
      setInterval(() => {
        setTimeout((prev) => prev - 1);
      }, 1000)
    );

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  React.useEffect(() => {
    if (timeout <= 0) {
      props.setIsOpen(false);
      window.location.replace(window.location.origin + '/logout');
    }
  }, [timeout]);

  return (
    <Dialog noActions title="" handleClose={() => props.setIsOpen(false)} isOpen={props.isOpen}>
      <Container column width="100%">
        <ContainerItem justify="center" width="100%">
          <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25.5 0C11.6895 0 0.5 11.1895 0.5 25C0.5 38.8105 11.6895 50 25.5 50C39.3105 50 50.5 38.8105 50.5 25C50.5 11.1895 39.3105 0 25.5 0ZM25.5 45.1613C14.3105 45.1613 5.33871 36.1895 5.33871 25C5.33871 13.9113 14.3105 4.83871 25.5 4.83871C36.5887 4.83871 45.6613 13.9113 45.6613 25C45.6613 36.1895 36.5887 45.1613 25.5 45.1613ZM31.6492 34.6774C32.254 35.0806 32.9597 34.9798 33.3629 34.375L35.2782 31.8548C35.6815 31.25 35.5806 30.5444 34.9758 30.1411L28.3226 25.2016V10.8871C28.3226 10.2823 27.7177 9.67742 27.1129 9.67742H23.8871C23.1815 9.67742 22.6774 10.2823 22.6774 10.8871V27.5202C22.6774 27.8226 22.7782 28.2258 23.0806 28.4274L31.6492 34.6774Z"
              fill="#03A65B"
            />
          </svg>
        </ContainerItem>
        <ContainerItem justify="center" width="100%">
          <Box sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: 700, m: '20px 0' }}>
            Still Working?
          </Box>
        </ContainerItem>
        <ContainerItem justify="center" width="100%">
          <Text content="You've been away for a while." />
          <Text content="Come back to this window or your session will expire in..." />
        </ContainerItem>
        <ContainerItem justify="center" width="100%">
          <Box sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: 700, m: '20px 0' }}>
            {timeout} Seconds
          </Box>
        </ContainerItem>
      </Container>
    </Dialog>
  );
};
