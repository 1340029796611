import type {
  CommentIdComponents,
  MinifiedTask,
  QuestionnaireSystemTaskData,
  SystemTaskData,
  TaskInternalType,
  TaskStatus,
  User
} from '@gcv/shared';
import { CommentType, IANATimezones } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';

import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { SnackbarStore } from 'stores/SnackBarStore';
import { removeUuid } from 'util/format.util';
import type { PM } from './inbox.repo';
import { CrbInboxRepo } from './inbox.repo';

export interface TaskRow {
  id: string;
  from: string;
  fromId: string;
  crbId: string;
  name: string;
  assignee: string;
  account: string;
  status: TaskStatus;
  received_on: string;
  due_date: string;
  completed_on?: string;
  completed_by?: string;
  completed_note?: string;
  actions?: React.ReactNode;
  type: TaskInternalType | undefined;
  description: string;
  questionnaire_id?: string;
  ready_to_complete?: boolean;
  commentType: CommentType;
  commentIdComponents: CommentIdComponents;
  data: SystemTaskData;
  last_reminder_date?: string;
  next_reminder_date?: string;
}

export interface DecoratedTask {
  id: string;
  isGrouped?: boolean;
  groupKey: string;
  name: string;
  tasks: MinifiedTask[];
}

interface VM {
  isLoading: boolean;
  filteredTasks: TaskRow[];
  iana_timezone: IANATimezones;
  searchTerm: string;
}

@injectable()
export class CrbInboxPresenter {
  @inject(CrbInboxRepo)
  repo: CrbInboxRepo;

  @inject(CrbDispensaryStore)
  dispStore: CrbDispensaryStore;

  @inject(SnackbarStore)
  snackbarStore: SnackbarStore;

  vm: VM = {
    isLoading: true,
    filteredTasks: [],
    iana_timezone: IANATimezones.America_NewYork,
    searchTerm: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  public load = action(async (taskStatus: 'open' | 'completed') => {
    this.vm = {
      filteredTasks: [],
      iana_timezone: this.dispStore.currentDispensary.iana_timezone,
      isLoading: true,
      searchTerm: ''
    };

    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      this.vm = {
        filteredTasks: this.mapTaskToTaskRow(programmersModel.tasks),
        iana_timezone: this.dispStore.currentDispensary.iana_timezone,
        isLoading: programmersModel.isLoading,
        searchTerm: ''
      };
    });

    await this.repo.load(taskStatus);
    this.vm.isLoading = false;
  });

  private mapTaskToTaskRow = (tasks: DecoratedTask[]): TaskRow[] => {
    return tasks.map((t) => {
      const users = t.tasks[0].assigned_users ?? [];
      const groups = t.tasks[0].assigned_groups ?? [];
      const status = t.tasks[0].status;
      const taskType = t.tasks[0].internal_type;
      let assignee = '--';
      if (users.length > 1) {
        assignee = 'Multiple';
      } else if (users[0]) {
        assignee = users[0].firstName + ' ' + users[0].lastName;
      } else if (groups[0]) {
        assignee = groups.map((g) => g.name).join(', ');
      }
      const completedByUser = t.tasks[0].completed_by_user as User;
      const completedByName = completedByUser
        ? completedByUser?.firstName + ' ' + completedByUser?.lastName
        : '--';

      let questionnaireData: QuestionnaireSystemTaskData | null = t.tasks[0]
        .data as QuestionnaireSystemTaskData;
      if (!questionnaireData || !questionnaireData.questionnaire) {
        questionnaireData = null;
      }

      const questionnaireId = questionnaireData?.questionnaire?.id;
      const description =
        taskType &&
        (taskType === 'comment_mention' || taskType === 'comment_reply' || taskType === 'comments') &&
        t.tasks[0].description
          ? removeUuid(t.tasks[0].description)
          : t.tasks[0].description;

      const taskRow: TaskRow = {
        id: t.id,
        type: taskType,
        from: t.tasks[0].org?.name ?? '--',
        fromId: t.tasks[0].org.id ?? '--',
        crbId: t.tasks[0].assigned_org.id,
        name: t.name,
        assignee: assignee,
        account: t.tasks[0].assigned_org.name ?? '--',
        status: status,
        received_on: t.tasks[0].date_created,
        due_date: t.tasks[0].process_date,
        completed_on: t.tasks[0].completed_on,
        completed_by: completedByName,
        completed_note: t.tasks[0].completed_note,
        description: description ?? '--',
        questionnaire_id: questionnaireId,
        actions: null,
        ready_to_complete: questionnaireData
          ? questionnaireData.questionnaire.status === 'ready_to_complete'
          : false,
        commentType: t.tasks[0].comment_type ?? CommentType.ACCOUNT,
        commentIdComponents: t.tasks[0].comment_id_components ?? {
          fiId: t.tasks[0].org.id,
          crbId: t.tasks[0].assigned_org.id,
          orgId: t.tasks[0].assigned_org.id
        },
        data: t.tasks[0].data ?? {},
        last_reminder_date: t.tasks[0].last_reminder_date,
        next_reminder_date: t.tasks[0].next_reminder_date
      };

      return taskRow;
    });
  };

  public searchTasks = action((searchTerm: string) => {
    const filteredTasks = this.repo.programmersModel.tasks.filter((t) => {
      const search = searchTerm.toLowerCase();
      const from = t.tasks[0].org?.name?.toLowerCase() ?? '';
      const name = t.name.toLowerCase();
      const users = t.tasks[0].assigned_users ?? [];
      const groups = t.tasks[0].assigned_groups ?? [];
      const assignee = users[0]
        ? users[0].firstName?.toLowerCase() + ' ' + users[0].lastName?.toLowerCase()
        : groups[0]
        ? groups.map((g) => g.name.toLowerCase()).join(', ')
        : '--';
      const status =
        t.tasks[0].status === 'open'
          ? 'new'
          : t.tasks[0].status === 'processing'
          ? 'in progress'
          : 'completed';

      return (
        from.includes(search) || name.includes(search) || assignee.includes(search) || status.includes(search)
      );
    });

    this.vm.searchTerm = searchTerm;
    this.vm.filteredTasks = this.mapTaskToTaskRow(filteredTasks);
  });

  public completeTask = async (task: TaskRow, completed_note: string) => {
    this.vm.isLoading = true;
    await this.repo.completeTask(task.crbId, task.id, this.vm.iana_timezone, completed_note);
    this.vm.isLoading = false;
  };

  public completeQuestionnaire = async (task: TaskRow) => {
    this.vm.isLoading = true;

    if (task.questionnaire_id) {
      await this.repo.completeQuestionnaire(task.crbId, task.id, task.questionnaire_id);
      this.load('open');
    } else {
      this.snackbarStore.showErrorSnackbarMessage('Invalid Questionnaire ID');
    }
  };
}
