import * as React from 'react';
import { FormControlLabel, Paper } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ReactComponent as HelpIcon } from 'assets/images/ic-help.svg';
import type { ListOption } from 'ui';
import { Button, CheckBox, Container, ContainerItem, Header, InputSearch, List, SubTitle, Tooltip } from 'ui';
interface Props {
  dataCy?: string;
  title: string;
  onSelect?: (value: string, isSelected?: boolean) => void;
  onSelectedOnly?: (selectedOnly: boolean) => void;
  onUserClickItem?: (value: string, selected: boolean) => void;
  options: ListOption[];
  searchLabel?: string;
  background?: 'white' | 'gray';
  listHeight?: string;
  listMinHeight?: string;
  listMaxHeight?: string;
  indentListItems?: boolean;
  showSearchInput?: boolean;
  showSelectionButtons?: boolean;
  disabledItemTooltip?: string;
  archiveIcon?: React.ReactNode;
  topActions?: React.ReactNode;
  showArchived?: boolean;
  clearAll?: () => void; // this optinal prop is used for a special case in reports
  selectAll?: () => void; // this optinal prop is used for a special case in reports
}

export const MultiSelectSearch: React.FC<Props> = (props) => {
  const [listOptions, setListOptions] = React.useState<ListOption[]>([]);
  const [showSelected, setShowSelected] = React.useState(false);
  const [selectedAccounts, setSelectedAccounts] = React.useState<ListOption[]>([]);
  const { archivedCrbs } = useFlags();

  const showSearchInput = props.showSearchInput ?? true;
  const showSelectionButtons = props.showSelectionButtons ?? true;

  // shows or hides archived accounts based on ShowArchived button state
  // special case show all active & archived accounts when show Selected only checkbox is checkbox
  // that's the reason for optiona parameter showSeected
  const showHideArchived = (options: ListOption[], showSelected?: boolean) => {
    if (showSelected || props.showArchived) {
      return options;
    }
    if (!props.showArchived) {
      const activeOptions: ListOption[] = [];
      options.forEach((o) => {
        if (o.children && o.children.length) {
          const newChildren = o.children.filter((ochild) => !ochild.archivedOption);
          o = { ...o, children: newChildren };
        }
        if (!o.archivedOption) {
          activeOptions.push(o);
        }
      });
      return activeOptions;
    }
    return options;
  };

  // run use effect whenever show/hide is toggled
  // as this use effect decides which options to display
  React.useEffect(() => {
    let options: ListOption[] = props.options;

    if (archivedCrbs) {
      options = showHideArchived(options);
    }

    if (showSelected) {
      setListOptions(options.filter((o) => o.selected));
    } else {
      setListOptions(options);
    }
  }, [props.options, props.showArchived]);

  const findSelected = () => {
    const selected = [];

    for (const opt of props.options) {
      if (opt.children && opt.children.length) {
        for (const child of opt.children) {
          if (child.selected) {
            selected.push(child);
          }
        }
      } else if (opt.selected) {
        selected.push(opt);
      }
    }

    return selected;
  };

  const findByLabel = (searchList: ListOption[], searchTerm: string) => {
    const flatten = (listOptions: ListOption[]): ListOption[] => {
      const options: ListOption[] = [];

      for (const opt of listOptions) {
        const o = { ...opt };

        if (o.children && o.children.length) {
          options.push(...flatten(o.children));
          options.push(o);
        } else {
          options.push(o);
        }
      }

      return options;
    };

    const foundOptions: ListOption[] = [];

    for (const option of flatten(searchList)) {
      if (option.label.toLowerCase().includes(searchTerm.toLowerCase())) {
        foundOptions.push(option);
      }
    }

    // GS-9878 - adding this filter because if search term matches both a parent & child, exclude child
    // in the search list as that child comes under it's parent. To avoid repetition CC: Cherie
    const foundParentOptions = foundOptions
      .filter((o) => o.children && o.children.length)
      .map((o) => {
        return o.value;
      });
    const finalOptions = foundOptions.filter((o) => !foundParentOptions.includes(o.parentValue ?? ''));

    return finalOptions;
  };

  const onSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let options: ListOption[];

    const renderOptions = (showSelected: boolean) => {
      let optionsSelected = showSelected ? [...selectedAccounts] : [...props.options];
      for (const opt of listOptions) {
        optionsSelected.forEach((item: ListOption) => {
          const childOption = item.children?.find((child: ListOption) => child.value === opt.value);
          if (childOption) {
            childOption.selected = opt.selected;
          }
          if (item.value === opt.value) {
            item.selected = opt.selected;
          }
        });
      }
      // render options is using raw options from props which is not filtered based on archive status
      // hence showHideArchived function is used here
      optionsSelected = showHideArchived(optionsSelected, showSelected);
      return optionsSelected;
    };

    if (!e.target.value) {
      options = renderOptions(showSelected);
    } else {
      options = findByLabel(renderOptions(showSelected), e.target.value);
    }
    setListOptions(options);
  };

  const handleSelect = (value: string, selected: boolean) => {
    setListOptions((prev) => {
      const options = [...prev];
      const parent = options.find((opt) => opt.value === value);

      if (parent) {
        parent.selected = selected;

        if (parent.children) {
          for (const child of parent.children) {
            child.selected = selected;
          }
        }
      } else {
        for (const opt of options) {
          if (opt.children) {
            const child = opt.children.find((c) => c.value === value);

            if (child) {
              child.selected = selected;
              break;
            }
          }
        }
      }

      return options;
    });

    if (props.onSelect) {
      props.onSelect(value, selected);
    }
  };

  const toggleOptions = (options: ListOption[], isSelected: boolean) => {
    options.forEach((opt) => {
      handleSelect(opt.value, isSelected);

      if (opt.children) {
        toggleOptions(opt.children, isSelected);
      }
    });
  };

  const onSelectAll = () => {
    if (props.selectAll) {
      props.selectAll();
    } else {
      const options = [...listOptions];

      toggleOptions(
        options.filter((o) => !o.disabled),
        true
      );
      setListOptions(options);
    }
  };

  const onClearAll = () => {
    if (props.clearAll) {
      props.clearAll();
    } else {
      const options = [...listOptions];

      toggleOptions(options, false);
      setListOptions(options);
    }
  };

  const onShowSelected = (checked: boolean) => {
    setShowSelected(checked);
    const options = JSON.parse(JSON.stringify(props.options));

    if (props.onSelectedOnly) {
      props.onSelectedOnly(checked);
    }

    if (checked) {
      const hideUnselected = (opts: ListOption[]) => {
        return opts.reduce<ListOption[]>((acc, curr) => {
          if (curr.children && curr.children.length > 0) {
            curr.children = hideUnselected(curr.children);

            for (const child of curr.children) {
              if (findSelected().find((selected) => selected.value === child.value)) {
                acc.push(curr);
                break;
              }
            }
          }

          if (findSelected().find((selected) => selected.value === curr.value)) {
            const index = acc.findIndex((opt) => opt.value == curr.value);

            if (index < 0) {
              curr.selected = true;
              acc.push(curr);
            }
          }

          return acc;
        }, []);
      };
      const selected = hideUnselected(options);
      setListOptions(selected);
      setSelectedAccounts(selected);
    } else {
      const showUnselected = (opts: ListOption[]) => {
        opts.forEach((o) => {
          if (o.children && o.children.length > 0) {
            showUnselected(o.children);
          }

          if (findSelected().find((selected) => selected.value === o.value)) {
            o.selected = true;
          } else {
            o.selected = false;
          }
        });

        return opts;
      };

      const newOptions = showHideArchived(showUnselected(options), checked);

      setListOptions(newOptions);
      setSelectedAccounts([]);
    }
  };

  return (
    <Container column dataCy={props.dataCy} padding={0} width="100%">
      <ContainerItem flex justify="between" align="center" padding="0" margin="0 0 0.5rem 0" width="100%">
        <Header dataCy="multi-select-search-header" content={props.title} type="h5" />
        {props.topActions ? props.topActions : null}
      </ContainerItem>

      {showSearchInput ? (
        <ContainerItem padding="0" margin="0 0 0.5rem 0" width="100%">
          <InputSearch
            background={props.background}
            label={props.searchLabel ? props.searchLabel : 'Search'}
            onChange={onSearch}
          />
        </ContainerItem>
      ) : null}

      <ContainerItem padding="0" margin="0 0 0.5rem 0" width="100%">
        <Paper variant="outlined" sx={{ borderRadius: '6px' }}>
          <List
            height={props.listHeight ?? '185px'}
            minHeight={props.listMinHeight}
            maxHeight={props.listMaxHeight}
            options={listOptions}
            onSelect={handleSelect}
            indentItems={props.indentListItems}
            onUserClickCallback={props.onUserClickItem}
            disabledItemTooltip={props.disabledItemTooltip}
            archiveOptionIcon={props.archiveIcon}
          />
        </Paper>

        {showSelectionButtons ? (
          <Container align="center" padding={0}>
            <ContainerItem padding="0">
              <Button
                color="primary-text"
                dataCy="select-all-link"
                label="Select All"
                onClick={onSelectAll}
                size="small"
                style={{ paddingLeft: 2 }}
              />
            </ContainerItem>

            <ContainerItem grow padding="0">
              {findSelected().length > 0 ? (
                <Button
                  color="primary-text"
                  dataCy="clear-all-link"
                  label="Clear All"
                  onClick={onClearAll}
                  size="small"
                  style={{ paddingLeft: 0 }}
                />
              ) : undefined}
            </ContainerItem>

            <ContainerItem padding="0">
              <SubTitle content={`${findSelected().length} selected`} />
            </ContainerItem>
          </Container>
        ) : null}
      </ContainerItem>

      {showSelectionButtons ? (
        <ContainerItem flex align="center" width="100%" padding="0" margin="0 0 0.5rem 0">
          <FormControlLabel
            control={
              <CheckBox
                labelPlacement="end"
                name="showSelected"
                onChange={(_, checked) => onShowSelected(checked)}
                value={showSelected}
                dataCy="multi-select-show-selected-checkbox"
              />
            }
            label="Show selected only"
            labelPlacement={'end'}
            sx={{
              ml: 0
            }}
          />
          {archivedCrbs && props.archiveIcon ? (
            <Tooltip title="Includes selected active and archived accounts." placement="top-start">
              <HelpIcon style={{ marginLeft: '-10px' }} />
            </Tooltip>
          ) : null}
        </ContainerItem>
      ) : null}
    </Container>
  );
};
