import type { ChangeEvent } from 'react';
import type { Theme } from '@mui/material';
import { Checkbox as MaterialCheckbox, TableCell as MaterialTableCell } from '@mui/material';
import type { SxProps } from '@mui/system';

import type { Row } from 'ui/organisms';

interface Props<T> {
  selected: string[];
  rows: Row<T>[];
  orderBy: keyof T;
  order: 'asc' | 'desc';
  handleSelectAllClick: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleSort: (event: React.MouseEvent<unknown>) => void;
  sx?: SxProps<Theme>;
}

export const TableHeaderCheckboxCell = <T,>(props: Props<T>) => {
  return (
    <MaterialTableCell padding="none" key={'0'} sx={{ ...props.sx, p: 0, pl: '0.75rem', width: '30px' }}>
      <MaterialCheckbox
        color="primary"
        indeterminate={props.selected.length > 0 && props.selected.length < props.rows.length}
        checked={props.rows.length > 0 && props.selected.length === props.rows.length}
        onChange={props.handleSelectAllClick}
        sx={{ p: 0, color: (theme) => theme.palette.text.disabled }}
      />
    </MaterialTableCell>
  );
};
