import React from 'react';
import { InputAdornment, TextField } from '@mui/material';

import palette from 'ui/theme/palette';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-icon.svg';

interface Props {
  autoFocus?: boolean;
  style?: React.CSSProperties;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  defaultValue?: string;
  dataCy?: string;
  background?: 'white' | 'gray';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: React.Ref<any>;
  headerComponent?: boolean;
  disabled?: boolean;
}

export const InputSearch: React.FC<Props> = (props) => {
  return (
    <TextField
      inputRef={props.inputRef}
      disabled={props.disabled}
      sx={{
        backgroundColor: (theme) => (props.background ? props.background : theme.palette.background.paper),
        borderColor: (theme) =>
          props.background === 'gray' ? theme.palette.grey[500] : theme.palette.grey[400],
        height: props.headerComponent ? '36px' : '41px',
        width: props.headerComponent ? '180px' : '100%',
        borderRadius: '5px',
        '&:focus-within': {
          boxShadow: `0px 0px 0px 3px rgba(3, 166, 91, 0.25)`
        },
        '.MuiOutlinedInput-root': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C6CCDA'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${palette.primary.main}`
          },
          paddingRight: '10px',
          '& input:disabled': {
            color: (theme) => theme.palette.text.primary
          },
          '&:hover fieldset': {
            outline: 'none',
            borderColor: `${palette.primary.main}`
          }
        },
        '.MuiInputBase-input': {
          fontFamily: 'Lato',
          fontSize: props.headerComponent ? '14px' : '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          height: props.headerComponent ? '36px' : '41px',
          lineHeight: '20px',
          padding: '0 0 0 13px'
        },
        '& fieldset': {
          borderColor: '#c6ccda'
        }
      }}
      // fullWidth
      style={props.style}
      autoFocus={props.autoFocus}
      placeholder={props.label}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ marginLeft: props.headerComponent ? '0' : '8px' }}>
            <SearchIcon
              style={{
                width: props.headerComponent ? '16px' : '18px',
                height: props.headerComponent ? '16px' : '18px'
              }}
            />
          </InputAdornment>
        ),
        disableUnderline: true,
        sx: {
          borderRadius: '5px',
          '&:focus': {
            boxShadow: `0px 0px 0px 3px rgba(3, 166, 91, 0.25)`
          }
        }
      }}
      data-cy={props.dataCy}
    />
  );
};
