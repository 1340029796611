import { BaseError, ErrorCodes } from 'errors';

import type { GenericResponse } from './api-response';

interface BackendError extends Error {
  message: string;
  response?: { data: GenericResponse; status?: number };
}

export class ApiError extends BaseError {
  private _cause: BackendError;
  name: string;
  code: ErrorCodes;
  isHTTPError: boolean;

  /**
   * True if back-end set a custom error message in a response object; false, otherwise.
   */
  get hasCustomMessage(): boolean {
    return !!this._cause.response?.data.message;
  }

  /**
   * When an API returns a 4xx or 5xx, an error is thrown with this response populated
   * When an API fails for a CORS or other error, this response will be undefined and a generic error should be displayed
   */
  response?: GenericResponse;

  constructor(error: BackendError) {
    super(error);

    this.name = error.name;
    this.code = ErrorCodes.Unknown;
    this.isHTTPError = false;
    this._cause = error;

    if (error.response) {
      this.response = error.response.data;
      this.message = error.response.data.message;

      if (error.response.status) {
        const status = error.response.status;

        // The back-end response gives us HTTP error codes, of which we've mapped the most common.
        if (399 < status && status < 600) {
          this.isHTTPError = true;

          if (ErrorCodes[status] !== undefined) {
            this.code = status;
          } else {
            this.code = ErrorCodes.OtherHTTPError;
          }
        }
      }
    }
  }
}
