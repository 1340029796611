// Adapted from: https://medium.com/nerd-for-tech/custom-react-router-prompt-d325538b4d2b
//
// This relies on the getUserConfirmation function of the react-router-dom BrowserRouter
// component being overwritten. See file: src/index.tsx.

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSavePromptContext } from 'app-context';

import type { TabNavigationDelegate } from 'ui';
import { Button, Container, ContainerItem, Dialog, Text } from 'ui';

/**
 * Dialog to prompt user to save changes before leaving page.
 *
 * @param props 'when' (boolean) to show prompt; 'tabController' when tab navigation is involved.
 * @returns modal dialog component.
 */
export const SaveChangesModal: React.FC<{
  when: boolean;
  tabController?: TabNavigationDelegate;
  onLeave?: () => void;
}> = (props) => {
  const { when, tabController, onLeave } = props;

  const blockRef = React.useRef<() => void | undefined>();

  const navigate = useNavigate();
  const [savePromptState] = useSavePromptContext();

  const [isBlockingTabChange, setBlockingTabChange] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [notificationCount, setNotificationCount] = useState(0);

  const sendNotification = () => {
    setNotificationCount((count) => {
      const newCount = count + 1;
      return newCount;
    });
  };

  const resetHistory = () => {
    // This bit of sorcery fixes the 'Warning: A history supports only one prompt at a time' spam.
    if (blockRef.current) {
      blockRef.current();
      blockRef.current = undefined;
    }
  };

  useEffect(() => {
    return () => {
      resetHistory();
      savePromptState.isBlocking = false;
      savePromptState.allowHistoryBlock = true;
      savePromptState.callback = undefined;
      tabController?.setAllowTabChange(true);
    };
  }, []);

  useEffect(() => {
    if (savePromptState) {
      savePromptState.notify = sendNotification;
    }
  }, [savePromptState]);

  useEffect(() => {
    if (savePromptState.callback) {
      setShowPrompt(true);
    }
  }, [notificationCount]);

  useEffect(() => {
    if (tabController) {
      tabController.setBlockedTabChangeCallback(() => {
        setBlockingTabChange(true);
        setShowPrompt(true);
      });
    }
  }, [tabController]);

  useEffect(() => {
    if (savePromptState.allowHistoryBlock && when) {
      savePromptState.isBlocking = true;
      tabController?.setAllowTabChange(false);

      // Store the 'unregister' function and fire as appropriate, to avoid warning. (See above!)
      // @ben @elle
      // blockRef.current = history.block((prompt) => {
      //   const pathname = `${prompt.pathname}${prompt.search}`;
      //   setCurrentPath(pathname);
      //   setShowPrompt(true);
      //   return 'true';
      // });
    } else {
      savePromptState.isBlocking = false;
      savePromptState.callback = undefined;
      tabController?.setAllowTabChange(true);

      resetHistory();
    }

    return () => {
      resetHistory();
    };
  }, [history, when, tabController, savePromptState, notificationCount]);

  const handleOnStay = () => {
    setBlockingTabChange(false);
    setShowPrompt(false);
    savePromptState.callback = undefined;
  };

  const handleOnLeave = () => {
    if (onLeave) {
      onLeave();
    }
    tabController?.setAllowTabChange(true);

    if (savePromptState.callback) {
      const callback = savePromptState.callback;
      savePromptState.callback = undefined;
      resetHistory();
      callback();
    } else if (isBlockingTabChange) {
      tabController?.triggerTabChange();
    } else {
      resetHistory();
      if (currentPath !== '/' && currentPath !== '') {
        navigate(currentPath);
      }
    }

    setShowPrompt(false);
  };

  return (
    <Dialog
      isOpen={showPrompt}
      title={'Leave without saving changes?'}
      cancelButtonText="Leave without saving changes"
      handleClose={handleOnStay}
      onCancel={handleOnLeave}
      action={<Button label={'Go back'} onClick={handleOnStay} color={'primary'} />}
    >
      <Container margin="1rem 0" padding="0">
        <ContainerItem padding="0">
          <Text content="To save your changes, go back and click Save." />
        </ContainerItem>
      </Container>
    </Dialog>
  );
};
