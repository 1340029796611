import * as React from 'react';
import type { Control, FieldValues, Validate } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { InputBaseComponentProps } from '@mui/material';
import { FormControl, Typography } from '@mui/material';

import { Container, ContainerItem, Error, Icon, Input, InputLabel, Tooltip } from 'ui';
import { theme } from 'ui/theme';
import { errorMessages } from 'util/forms.util';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputTextProps {
  noLabel?: boolean;
  background?: 'white' | 'gray';
  color?: string;
  control?: Control;
  dataCy?: string;
  defaultValue?: string;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  inputProps?: InputBaseComponentProps;
  label: string;
  name: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  viewOnly?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  tooltip?: string | React.ReactElement;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  inputRef?: any;
  descriptionText?: string;
  disableAutoWidth?: boolean;
  fullWidth?: boolean;
  autoFocus?: boolean;
  darkLabel?: boolean;
  children?: React.ReactNode;
}

export const InputText: React.FC<InputTextProps> = (props) => {
  return (
    <FormControl fullWidth data-cy={props.dataCy}>
      <Container padding={0} width="100%" align="flex-start">
        {!props.noLabel ? (
          <ContainerItem padding={0} align="center" flex margin="0 0 6px 0">
            <InputLabel
              dataCy={'input-label'}
              label={props.label}
              name={props.name}
              required={props.viewOnly ? false : props.rules?.required?.value}
              readOnly={props.readOnly}
              rules={props.rules}
              viewOnly={props.viewOnly}
              darkLabel={props.darkLabel}
            />
          </ContainerItem>
        ) : null}

        {props.tooltip ? (
          <ContainerItem padding={0} align="center" flex margin="-3px 0 0 2px">
            <Tooltip variant="text" title={props.tooltip} placement="right">
              <Icon type="help" color="primary" />
            </Tooltip>
          </ContainerItem>
        ) : null}
      </Container>

      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
          <>
            <Input
              focus={props.autoFocus}
              readOnly={props.readOnly}
              name={props.name}
              dataCy={'input-text'}
              onBlur={() => {
                if (props.onBlur) {
                  props.onBlur();
                }

                onBlur();
              }}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }

                onChange(e.target.value);
              }}
              value={
                (props.readOnly || props.viewOnly) && (value == null || value === '')
                  ? '--'
                  : value === undefined || value === null
                  ? ''
                  : value
              }
              error={error}
              background={props.background}
              placeholder={props.placeholder}
              inputComponent={
                props.viewOnly && (!value || value === '' || value === '--')
                  ? undefined
                  : props.inputComponent
              }
              inputProps={
                props.viewOnly && (!value || value === '' || value === '--') ? undefined : props.inputProps
              }
              viewOnly={props.viewOnly}
              multiline={props.multiline}
              inputRef={props.inputRef}
              maxRows={props.maxRows}
              minRows={props.readOnly || props.viewOnly ? 1 : props.minRows}
              disableAutoWidth={props.disableAutoWidth}
              fullWidth={props.fullWidth}
            />

            <Error
              content={error ? `* ${props.label} ${error.message || errorMessages()[error.type]}` : ''}
              dataCy="validation-message"
            />

            {props.descriptionText && (
              <Typography
                data-cy={'description-text'}
                variant={'body1'}
                color={theme.palette.text.primary}
                fontSize={'12px'}
              >
                {props.descriptionText}
              </Typography>
            )}
          </>
        )}
      />
    </FormControl>
  );
};
