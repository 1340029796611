import * as React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { CustomField, CustomSection, DocumentUpload } from '@gcv/shared';
import { CustomFieldDataTypeOptions, CustomFieldType, CustomFieldTypeOptions } from '@gcv/shared';

import { DocumentsApi } from 'api';
import { ReactComponent as LockIcon } from 'assets/images/ic-lock-dark.svg';
import { environment } from 'environments/environment';
import { getFiBankStore } from 'stores/FiBankStore';
import {
  Button,
  Container,
  ContainerItem,
  Dialog,
  FileContainer,
  Form,
  InputCheckBox,
  InputSelect,
  InputText,
  InputTextArea,
  Text
} from 'ui';
import { theme } from 'ui/theme';
import { setDefaultValues } from 'util/forms.util';

interface Props {
  field?: CustomField;
  formStatus: 'draft' | 'published';
  isOpen: boolean;
  onSave: (data: FieldValues, isCreatingAnotherField: boolean, isCreatingSmartRule: boolean) => void;
  section?: string;
  sectionOptions:
    | {
        label: string;
        value: string;
      }[]
    | undefined;
  onSectionSelect: (value: string) => void;
  setIsOpen: (isOpen: boolean, smartRules: boolean) => void;
  isLoading?: boolean;
  formFields?: CustomField[];
  optionsLocked: { isTarget: boolean; smartRuleSubjects: (CustomField | CustomSection)[] };
  uploadDocuments: (files: File[]) => Promise<DocumentUpload[] | undefined>;
  fiDocuments?: DocumentUpload[] | undefined;
}

export const FieldModal: React.FC<Props> = (props) => {
  const [activeSection, setActiveSection] = React.useState('');
  const [tempFiles, setTempFiles] = React.useState<File[]>([]);
  const [existingFiles, setExistingFiles] = React.useState<DocumentUpload[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [removedDocIds, setRemovedDocIds] = React.useState<string[]>([]);

  // to reset the FileContainer when checked Create Another Field check box as data needs to be cleared
  const [refreshFileComponent, setRefreshFileComponent] = React.useState(false);

  const bankStore = getFiBankStore();
  const documentsApi = new DocumentsApi();

  const form = useForm({
    mode: 'onBlur',
    defaultValues: setDefaultValues({
      type: props.field?.type ?? '',
      label: props.field?.label ?? '',
      helpText: props.field?.helpText ?? '',
      required: props.field?.required ?? false,
      showHelpText: !!props.field?.helpText,
      section: (props.section || props.field?.section) ?? '',
      createAnotherField: false,
      addSmartRules: false,
      paragraph: props.field?.paragraph ?? '',
      attachments: props.field?.attachments ?? [],
      ...props.field
    })
  });

  const checkIfFieldCanBeSmartRule = (field: CustomField) =>
    field.type === CustomFieldType.Radio || field.type === CustomFieldType.Select;
  const availableSmartRuleFields = props.formFields
    ? props.formFields.filter(checkIfFieldCanBeSmartRule)
    : [];

  React.useEffect(() => {
    setActiveSection(props.section ?? '');

    if (props.section) {
      form.setValue('section', props.section);
      form.trigger();
    }
  }, [props.section]);

  // case: Display Content - set existing files by comparing the docIds in field && fiDocuments
  React.useEffect(() => {
    if (props.field?.type === CustomFieldType.DisplayContent || props.field?.type === CustomFieldType.File) {
      const docIds = props.field?.attachments?.map((file) => {
        return file.id;
      });

      const existingFiles = props.fiDocuments?.filter((doc) => docIds?.includes(doc.id) && !doc.deleted);
      setExistingFiles(existingFiles ?? []);
    }
  }, [props.field]);

  const onRemoveExistingFile = (file: DocumentUpload) => {
    if (existingFiles && existingFiles.length) {
      existingFiles.splice(
        existingFiles.findIndex((a) => a.id === file.id),
        1
      );
    }

    setExistingFiles(existingFiles);
    setRemovedDocIds([...removedDocIds, file.id]);
  };

  const deleteDocuments = async () => {
    await removedDocIds.map(async (id) => {
      await documentsApi.deleteDocument(bankStore.bank.id, id);
    });
  };

  const triggerRefresh = (value: boolean) => {
    setRefreshFileComponent(value);
  };

  return (
    <Dialog
      title={props.field ? 'Edit Field' : 'Add New Field'}
      isOpen={props.isOpen}
      handleClose={() => {
        form.reset({});
        props.setIsOpen(false, false);
      }}
      supportingActions={
        !props.field ? (
          <InputCheckBox
            label="Create another field"
            name="createAnotherField"
            defaultValue={false}
            readOnly={form.watch('addSmartRules')}
            {...form}
          />
        ) : undefined
      }
      action={
        <Button
          label={form.watch('addSmartRules') === true ? 'Next' : props.field ? 'Save' : 'Add Field'}
          color="primary"
          isLoading={props.isLoading || isLoading}
          onClick={() => {
            form.handleSubmit(async (data) => {
              setIsLoading((value) => !value);
              if (!data.showHelpText) {
                data.helpText = '';
              }

              // newly uploaded files
              let attachments: { id: string; file_name: string }[] = []; // newly uploaded files
              if (tempFiles.length) {
                const uploadedFiles = (await props.uploadDocuments(tempFiles)) || [];
                attachments = uploadedFiles.map((file) => {
                  return {
                    id: file.id,
                    file_name: file.file_name
                  };
                });
              }
              // existing files
              const existingAttachments =
                existingFiles?.map((a) => {
                  return {
                    id: a.id,
                    file_name: a.file_name
                  };
                }) ?? [];
              // merging newly uploaded & existing files
              data.attachments = [...existingAttachments, ...attachments];

              const addSmartRules = data.addSmartRules;

              // ui only prop, do not send to db
              delete data.showHelpText;
              delete data.addSmartRules;

              setIsLoading((value) => !value);

              if (
                form.watch('type') === CustomFieldType.DisplayContent ||
                form.watch('type') === CustomFieldType.File
              ) {
                deleteDocuments();
              }

              props.onSave(data, data.createAnotherField, addSmartRules);
              if (data.createAnotherField) {
                form.setValue('label', undefined, { shouldValidate: false });
                form.setValue('helpText', undefined, { shouldValidate: false });
                form.setValue('options', undefined, { shouldValidate: false });
                form.setValue('paragraph', '', { shouldValidate: false });

                // to clear files
                setTempFiles([]);
                setExistingFiles([]);
                triggerRefresh(true);
              } else {
                form.reset({});
                props.setIsOpen(false, addSmartRules);
              }
            })();
          }}
          dataCy="save-field-button"
        />
      }
      dataCy="field-modal"
    >
      <Form>
        <Container padding="0" width="100%">
          <ContainerItem padding="0.5rem 0" width="100%">
            <InputSelect
              {...form}
              name="type"
              label="Type"
              options={CustomFieldTypeOptions}
              rules={{ required: { value: true, message: 'is required.' } }}
              readOnly={props.field && props.formStatus === 'published'}
              dataCy="field-type"
            />
          </ContainerItem>

          {form.watch('type') !== CustomFieldType.DisplayContent && (
            <ContainerItem padding="0.5rem 0" width="100%">
              <InputText
                {...form}
                name="label"
                label="Label"
                placeholder="Enter field label"
                rules={{ required: { value: true, message: 'is required.' } }}
                dataCy="field-label"
              />
            </ContainerItem>
          )}

          {form.watch('type') === CustomFieldType.TextSingleLine && (
            <ContainerItem padding="0.5rem 0" width="100%">
              <InputSelect
                {...form}
                name="dataType"
                label="Data Type"
                options={CustomFieldDataTypeOptions}
                rules={{ required: { value: true, message: 'is required.' } }}
                readOnly={props.field && props.formStatus === 'published'}
                dataCy="field-data-type"
              />
            </ContainerItem>
          )}

          {(form.watch('type') === CustomFieldType.Checkbox ||
            form.watch('type') === CustomFieldType.Radio ||
            form.watch('type') === CustomFieldType.Select) && (
            <>
              <ContainerItem padding="0.5rem 0" width="100%">
                <InputText
                  {...form}
                  name="options"
                  label="Options"
                  descriptionText="Enter options separated by ;"
                  placeholder="i.e. First Choice; Second Choice; Third Choice"
                  rules={{
                    validate: (data) => {
                      const value = data as string;

                      if (!value) {
                        return 'is required.';
                      }

                      if (form.watch('type') === CustomFieldType.Checkbox) {
                        return !!value.length || 'invalid. Enter at least one option.';
                      } else {
                        const split = value.split(';');
                        return (
                          (split.length > 1 && split.every((s) => s.length)) ||
                          'invalid. Enter at least two options.'
                        );
                      }
                    },
                    required: { value: true, message: 'is required.' }
                  }}
                  dataCy="field-options"
                  readOnly={props.optionsLocked.isTarget}
                />
              </ContainerItem>
              {props.optionsLocked.isTarget && (
                <>
                  <Container nowrap padding="0" align="center">
                    <ContainerItem>
                      <LockIcon />
                    </ContainerItem>
                    <ContainerItem padding="0">
                      <Text
                        type="body3"
                        content="The options are locked because smart rules depend on them. In order to modify these options, remove the smart rules targeting the following fields or sections: "
                      />
                    </ContainerItem>
                  </Container>
                  <Container padding="0 8px">
                    <ContainerItem padding="0">
                      <ul style={{ marginTop: 0 }}>
                        {props.optionsLocked.smartRuleSubjects.map((field) => {
                          return (
                            <li style={{ margin: 0 }}>
                              <Text type="body3" content={field.label} />
                            </li>
                          );
                        })}
                      </ul>
                    </ContainerItem>
                  </Container>
                </>
              )}
            </>
          )}

          {form.watch('type') !== CustomFieldType.DisplayContent && (
            <ContainerItem padding="0.5rem 0" width="100%">
              <InputCheckBox
                {...form}
                name="showHelpText"
                label="Show help text"
                dataCy="field-show-help-text-checkbox"
              />
            </ContainerItem>
          )}

          {form.watch('type') !== CustomFieldType.DisplayContent && form.watch('showHelpText') === true && (
            <ContainerItem padding="0.5rem 0" width="100%">
              <InputText
                {...form}
                name="helpText"
                label="Help Text"
                placeholder="Enter field help text or short description"
                dataCy="field-help-text"
              />
            </ContainerItem>
          )}

          {form.watch('type') !== CustomFieldType.DisplayContent && (
            <ContainerItem padding="0.5rem 0" width="100%">
              <InputCheckBox
                {...form}
                name="required"
                label="Required field"
                dataCy="field-required-checkbox"
              />
            </ContainerItem>
          )}

          {form.watch('type') === CustomFieldType.DisplayContent && (
            <>
              <ContainerItem padding="0.5rem 0" width="100%">
                <InputText
                  name="label"
                  label="Heading"
                  dataCy="field-header"
                  placeholder="Enter heading text"
                  {...form}
                />
              </ContainerItem>
              <ContainerItem padding="0.5rem 0" width="100%">
                <InputTextArea
                  background="lightGray"
                  name="paragraph"
                  label="Paragraph"
                  dataCy="field-paragraph"
                  placeholder="Enter paragraph text"
                  minRows={3}
                  maxRows={3}
                  {...form}
                />
              </ContainerItem>
              <ContainerItem margin="-0.6rem 0 0.5rem 0" width="100%" padding="0">
                <FileContainer
                  newFilesPadding="12px 5px 12px 12px" // added this padding because, the existingFilees & newFiles are not aligned properly.
                  existingFilesLabel="Attachments"
                  uploadFilesLabel={existingFiles.length === 0 ? 'Attachments' : undefined}
                  fileBucket={environment.storageConfig.sharedDocument}
                  existingS3Files={existingFiles}
                  fullWidth
                  multiple
                  acceptedTypes="all"
                  onRemoveExistingFile={onRemoveExistingFile}
                  onNewFilesChange={(files: File[]) => {
                    setTempFiles([...files]);
                  }}
                  hardResetComponent={refreshFileComponent} // to reset the FileContainer when checked Create Another Field check box as data needs to be cleared
                  triggerRefresh={triggerRefresh}
                />
              </ContainerItem>
            </>
          )}

          <ContainerItem padding="0.5rem 0" width="100%">
            <InputSelect
              {...form}
              name="section"
              label="Section"
              options={props.sectionOptions?.filter((s) => s.label !== '') ?? []}
              additionalOnChange={(e) => props.onSectionSelect(e.target.value)}
              value={activeSection}
              emptyLabel="Select a section..."
              dataCy="field-section"
              rules={{
                required: { value: true, message: 'is required.' },
                validate: (data) => {
                  const value = data as string;

                  if (value === 'create-section') {
                    return false;
                  }

                  return true;
                }
              }}
            />
          </ContainerItem>

          {form.watch('type') === CustomFieldType.File && (
            <ContainerItem margin="0.2rem 0 0.5rem 0" width="100%" padding="0">
              <FileContainer
                newFilesPadding="12px 5px 12px 12px" // added this padding because, the existingFilees & newFiles are not aligned properly.
                existingFilesLabel="Attachments"
                uploadFilesLabel={existingFiles.length === 0 ? 'Attachments' : undefined}
                fileBucket={environment.storageConfig.sharedDocument}
                existingS3Files={existingFiles}
                fullWidth
                multiple
                acceptedTypes="all"
                onRemoveExistingFile={onRemoveExistingFile}
                onNewFilesChange={(files: File[]) => {
                  setTempFiles([...files]);
                }}
                hardResetComponent={refreshFileComponent} // to reset the FileContainer when checked Create Another Field check box as data needs to be cleared
                triggerRefresh={triggerRefresh}
              />
            </ContainerItem>
          )}

          {!props.field && (
            <ContainerItem padding="0.5rem 0" width="100%">
              <InputCheckBox
                {...form}
                name="addSmartRules"
                label="Add Smart Rules"
                dataCy="smart-rules-checkbox"
                readOnly={form.watch('createAnotherField') || availableSmartRuleFields.length === 0}
              />
            </ContainerItem>
          )}
          {!props.field && availableSmartRuleFields.length === 0 && (
            <ContainerItem padding="0.5rem 0" width="100%">
              <Text
                type="body3"
                sx={{ color: theme.palette.text.secondary }}
                content="To add Smart Rules, first create a field with the Radio Buttons or Select Lists field type to base a rule upon."
              />
            </ContainerItem>
          )}
        </Container>
      </Form>
    </Dialog>
  );
};
