import type {
  AccessMetadata,
  AccessRequestParams,
  AccountInternalMap,
  AggregatedPosDataFetcherResults,
  Bank,
  BankDispensaryMetadata,
  BankInternalTransactionCodesRequest,
  BankSFTPCoreUploadMapMetadata,
  BankSFTPCoreUploadMapMetadataData,
  BankTemplateMetadata,
  ComplianceRuleDefinition,
  ComplianceRuleMetaData,
  ComplianceRuleType,
  CorePaymentTypeMap,
  CoreTransactionPaymentType,
  CustomFieldsImportRow,
  DataFetcherStrategy,
  DataFetcherWorkflowTypes,
  Dispensary,
  ImportOrgsRequest,
  IngestionReport,
  IntegrationTiming,
  IntegrationTimingMetaV3,
  InviteNewUserRequest,
  LicenseDataMap,
  LicenseManagerData,
  LicenseManagerMeta,
  MainFeatures,
  MinifiedUser,
  OrgFeature,
  PosDataFetcherResults,
  StateRulesResponse,
  Template,
  TemplateResponse,
  UpdateAccessParams,
  UpdateTransactionCodesDryRunResponse,
  User,
  USStates
} from '@gcv/shared';
import { PosType } from '@gcv/shared';
import { injectable } from 'inversify';

import type {
  GcvProductType,
  ProductCategoryMapAuditLogEntry,
  ProductCategoryMapEntry,
  ProductCategorySuggestion,
  ProductMapPutRequest
} from 'ui/apps/gcv/data-generation/model';
import { api, banksApi } from './api-util/api';

@injectable()
export class GcvAdminApi {
  // Triggers a cash logistics file in s3 to get reingested
  async reingestCashLogisticsFile(bankId: string, vendorId: string, filename: string): Promise<null> {
    return await api().post(`/gcv/tools/sftp-cash-logistics/reingestFile`, {
      bank_id: bankId,
      vendor_id: vendorId,
      filename
    });
  }

  // Triggers all cash logistics files in sftp and manual buckets to get reingested
  // set sftp to false to reingest manual files
  async reingestAllCashLogisticsFiles(bankId: string, vendorId: string): Promise<null> {
    return await api().post(`/gcv/tools/sftp-cash-logistics/reingestAllFiles`, {
      bank_id: bankId,
      vendor_id: vendorId
    });
  }

  // Triggers a core transaction file in s3 to get reingested
  async reingestCoreTransactionFile(bankId: string, filename: string): Promise<null> {
    return await api().post(`/gcv/tools/sftp-core-transactions/reingestFile`, { bank_id: bankId, filename });
  }

  // Triggers all core transaction files in sftp and manual buckets to get reingested
  // set sftp to false to reingest manual files
  async reingestAllCoreTransactionFiles(bankId: string, sftp: boolean): Promise<null> {
    return await api().post(`/gcv/tools/sftp-core-transactions/reingestAllFiles`, { bank_id: bankId, sftp });
  }

  // Triggers a dry run for all core transaction files in sftp and manual buckets to get reingested
  // set sftp to false to reingest manual files
  async dryRunReingestAllCoreTransactionFiles(bankId: string, sftp: boolean): Promise<null> {
    return await api().post(`/gcv/tools/sftp/dryRunReingestAllFiles`, { bank_id: bankId, sftp });
  }

  async getCorePaymentTypeMapping(bankId: string): Promise<CorePaymentTypeMap> {
    return await banksApi().get(`/banks/${bankId}/core-payment-types`);
  }

  async addBankTransactionCodes(bankId: string, codes: BankInternalTransactionCodesRequest) {
    return await api().post(`/gcv/banks/${bankId}/internal-transaction-codes`, { internal_codes: codes });
  }

  async updateBankTransactionCodes(bankId: string, codes: BankInternalTransactionCodesRequest) {
    return await api().put(`/gcv/banks/${bankId}/internal-transaction-codes`, { internal_codes: codes });
  }

  async postCorePaymentTypeMapping(
    bankId: string,
    values: { [key in CoreTransactionPaymentType]: CoreTransactionPaymentType }
  ): Promise<CorePaymentTypeMap> {
    return await banksApi().post(`/banks/${bankId}/core-payment-types`, { mapping: values });
  }

  async getIngestionsForDispensary(
    dispensaryId: string,
    startDate?: string,
    endDate?: string
  ): Promise<IngestionReport[]> {
    return await api().get(
      '/gcv/data/sales-ingestion-report?searchBy=dispensaryId&dispensaryId=${dispensaryId}',
      { searchBy: 'dispensaryId', dispensaryId, startDate, endDate }
    );
  }

  async getIngestionsForPosType(
    posType: PosType,
    startDate?: string,
    endDate?: string
  ): Promise<IngestionReport[]> {
    return await api().get('/gcv/data/sales-ingestion-report', {
      searchBy: 'posType',
      posType,
      startDate,
      endDate
    });
  }

  async getCoreTransactionsInformation(
    bankId: string,
    s3Key?: string,
    startDate?: string,
    endDate?: string,
    dispensaryId?: string
  ): Promise<{ numberOfTransactions: number; dateCreated: string }> {
    return await api().get(`/gcv/banks/${bankId}/core-transactions`, {
      s3Key,
      startDate,
      endDate,
      dispensaryId
    });
  }

  async deleteCoreTransactions(
    bankId: string,
    s3Key?: string,
    startDate?: string,
    endDate?: string,
    dispensaryId?: string
  ): Promise<{ numberOfSales: number; dateCreated: string }> {
    return await api().delete(`/gcv/banks/${bankId}/core-transactions`, {
      s3Key,
      startDate,
      endDate,
      dispensaryId
    });
  }

  async rerunIngestions(
    executionIds: string[]
  ): Promise<{ type: 'success' | 'error'; executionId?: string; errorMessage?: string }[]> {
    return await api().post('/gcv/tools/executions/retrigger', {
      executionIds
    });
  }

  async deleteDocument(
    dispensaryId: string,
    bankId: string,
    requirementId: string,
    documentId: string
  ): Promise<Dispensary> {
    return await api().delete(
      `/gcv/dispensaries/${dispensaryId}/banks/${bankId}/requirements/${requirementId}/documents/${documentId}`
    );
  }

  async getAllLicenseData(): Promise<LicenseDataMap> {
    return await api().get(`/gcv/licenses`);
  }

  async getBankTemplateMetadata(bankId: string): Promise<BankTemplateMetadata> {
    return await api().get(`/gcv/banks/${bankId}/template-metadata`);
  }

  async putBankTemplateMetadata(
    bankId: string,
    templates_allowed: number,
    questionnaires_allowed: number
  ): Promise<BankTemplateMetadata> {
    return await api().put(`/gcv/banks/${bankId}/template-metadata`, {
      templates_allowed,
      questionnaires_allowed
    });
  }

  async importCustomFields(
    bankId: string,
    templateId: string,
    customFields: CustomFieldsImportRow[],
    version: string,
    dryRun: boolean
  ): Promise<TemplateResponse> {
    return await api().put(
      `/gcv/banks/${bankId}/templates/${templateId}/import-custom-fields`,
      {
        customFields
      },
      { version, dryRun }
    );
  }

  async toggleMfa(orgId: string, mfaEnabled: boolean): Promise<Bank | Dispensary> {
    return await api().put(`/gcv/organization/${orgId}/toggle-mfa`, {
      mfa_enabled: mfaEnabled
    });
  }

  async resetMfaForUser(userId: string): Promise<{ mfaEnabled: boolean; mfaRequired: boolean }> {
    return await api().put(`/gcv/user/${userId}/reset-mfa`, {});
  }

  async getBankSftpColumnMapping(bankId: string): Promise<BankSFTPCoreUploadMapMetadata> {
    return await api().get(`/gcv/banks/${bankId}/sftp-core-mapping`);
  }

  async putBankSftpColumnMapping(
    bankId: string,
    sftpColumnMapping: BankSFTPCoreUploadMapMetadataData
  ): Promise<BankSFTPCoreUploadMapMetadata> {
    return await api().put(`/gcv/banks/${bankId}/sftp-core-mapping`, sftpColumnMapping);
  }

  async importOrgs(
    bankId: string,
    importOrgRequest: ImportOrgsRequest
  ): Promise<{ successes: number; failures: number }> {
    return await api().post(`/gcv/banks/${bankId}/import-orgs`, importOrgRequest);
  }

  async deleteCrbmMapping(dispensaryId: string, documentId: string, crbmId: string): Promise<any> {
    return await api().delete(`/gcv/dispensaries/${dispensaryId}/documentId/${documentId}/crbmId/${crbmId}`);
  }

  async migrateAccountNumber(
    bankId: string,
    sourceCrbId: string,
    targetCrbId: string,
    accountNumbers: AccountInternalMap,
    dryRun?: boolean
  ): Promise<UpdateTransactionCodesDryRunResponse | BankDispensaryMetadata> {
    return await api().put(
      `/gcv/banks/${bankId}/migrate-account-numbers`,
      {
        sourceCrbId,
        targetCrbId,
        accountNumbers
      },
      { dryRun }
    );
  }

  async putBankFeatures(bankId: string, features: { [key in MainFeatures]: OrgFeature }): Promise<any> {
    return await api().put(`/gcv/banks/${bankId}/features`, { features: features });
  }

  async getProductMapAuditLogs(): Promise<ProductCategoryMapAuditLogEntry[]> {
    const productMap = await api().get(`/gcv/tools/product-map-audit-logs/`);
    return productMap as ProductCategoryMapAuditLogEntry[];
  }

  async putDefaultProductMapEntry(
    entry: ProductCategoryMapEntry,
    mode: 'upsert' | 'delete',
    ipAddress: string
  ): Promise<void> {
    const productMap: { [key: string]: GcvProductType } = {};
    productMap[entry.posCategory] = entry.gcvCategory;
    const body: ProductMapPutRequest = {
      productMap,
      mode,
      ipAddress
    };
    return await api().put(`/gcv/tools/product-map/default`, body);
  }

  async getProductSuggestions(limit: number, offset: number): Promise<ProductCategorySuggestion[]> {
    const PosTypes = Object.values(PosType);
    const promises = [];
    for (const posType of PosTypes) {
      const promise = api().get(`/gcv/tools/product-category-suggestion`, {
        posType,
        hasOverride: false,
        resolvedStatus: false,
        limit,
        offset
      });
      promises.push(promise);
    }
    const results = await Promise.all(promises);
    const productSuggestions = results.flat();
    return productSuggestions as ProductCategorySuggestion[];
  }

  async putProductCategorySuggestions(suggestions: ProductCategorySuggestion[]): Promise<void> {
    await api().put(`/gcv/tools/product-category-suggestion`, { suggestions });
  }

  async inviteGcvUser(newUserRequest: InviteNewUserRequest): Promise<{ user: User; org: Bank }> {
    return await api().post(`/gcv/invite-user`, newUserRequest);
  }

  async getAllUsers(): Promise<MinifiedUser[]> {
    return await api().get(`/gcv/users`);
  }

  async getDispensariesSearch(searchString: string): Promise<{ [crbId: string]: string[] }> {
    return await api().get(`/gcv/dispensaries-search`, { name: searchString });
  }

  async copyCustomFields(
    sourceBankId: string,
    sourceTemplateId: string,
    targetFiId: string,
    targetTemplateId: string,
    action: 'replace' | 'append'
  ): Promise<Template> {
    return await api().post(
      `/gcv/banks/${sourceBankId}/copy-custom-requirements`,
      {
        targetFiId,
        sourceTemplateId,
        targetTemplateId
      },
      { action }
    );
  }

  async getAccessMetadata(client_id?: string, org_id?: string, user_id?: string): Promise<AccessMetadata[]> {
    return await api().get(`/gcv/access`, { client_id, org_id, user_id });
  }

  async createAccessMetadata(
    request: AccessRequestParams
  ): Promise<{ accessMetadata: AccessMetadata; secret: string }> {
    return await api().post(`/gcv/access`, request);
  }

  async getAllAccessMetadata(): Promise<AccessMetadata[]> {
    return await api().get(`/gcv/all-access`);
  }

  async updateAccessMetadata(client_id: string, updateRequest: UpdateAccessParams): Promise<AccessMetadata> {
    return await api().put(`/gcv/access/${client_id}`, updateRequest);
  }

  async rotateAccessApiKey(client_id: string): Promise<{ client_id: string; client_secret: string }> {
    return await api().put(`/gcv/access/${client_id}/rotate`, {});
  }

  async getAllTimingConfigs(): Promise<IntegrationTimingMetaV3[]> {
    return await api().get(`/gcv/timing-configs`);
  }

  async upsertTimingConfig(
    posType: PosType,
    timingConfigs: IntegrationTiming[]
  ): Promise<IntegrationTimingMetaV3> {
    return await api().put(`/gcv/timing-configs`, { posType, timingConfigs });
  }

  async kickoffDataFetcher(
    posType: PosType,
    dataWorkflowType: DataFetcherWorkflowTypes,
    strategy: DataFetcherStrategy,
    toDate: string,
    fromDate: string,
    crbId?: string
  ): Promise<void> {
    return await api().post(`/gcv/kickoff-data-fetcher`, {
      posType,
      dataWorkflowType,
      strategy,
      crbId,
      toDate,
      fromDate
    });
  }

  async getAggregatedDataFetcherHistory(
    startDate: string,
    endDate: string
  ): Promise<AggregatedPosDataFetcherResults[]> {
    return await api().get(`/gcv/aggregated-data-fetcher-history`, {
      startDate,
      endDate
    });
  }

  async getDataFetcherHistory(
    startDate: string,
    endDate: string,
    filterParams: { crbId?: string; posType?: PosType }
  ): Promise<PosDataFetcherResults[]> {
    return await api().get(`/gcv/data-fetcher-history`, {
      startDate,
      endDate,
      ...filterParams
    });
  }

  /**
   * For each compliance context in each state, return last updated date and last updated by user ID
   */
  async getComplianceRulesLastModifiedData(): Promise<StateRulesResponse> {
    return await api().get(`/gcv/rules`);
  }

  async getStateComplianceMetadata(state: USStates): Promise<(ComplianceRuleMetaData | undefined)[]> {
    return await api().get(`/gcv/rules/state/${state}`);
  }

  async createStateComplianceMetadata(
    type: ComplianceRuleType,
    state: USStates,
    value: ComplianceRuleDefinition
  ): Promise<ComplianceRuleMetaData> {
    return await api().post(`/gcv/rules/state`, { type, state, value });
  }

  async updateStateComplianceMetadata(
    type: ComplianceRuleType,
    state: USStates,
    value: ComplianceRuleDefinition
  ): Promise<ComplianceRuleMetaData> {
    return await api().put(`/gcv/rules/state/${state}/type/${type}`, { value });
  }

  async getStateLicenseInformation(state: USStates): Promise<LicenseManagerMeta | null> {
    return await api().get(`/gcv/license-information/state/${state}`);
  }

  async createStateLicenseInformation(
    state: USStates,
    licenseData: LicenseManagerData
  ): Promise<LicenseManagerMeta> {
    return await api().post(`/gcv/license-information`, { value: licenseData, state });
  }

  async updateStateLicenseInformation(
    state: USStates,
    licenseData: LicenseManagerData
  ): Promise<LicenseManagerMeta> {
    return await api().put(`/gcv/license-information/state/${state}`, { value: licenseData });
  }
}
