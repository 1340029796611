import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';

import { theme } from 'ui/theme';
import palette from 'ui/theme/palette';

interface Props {
  dataCy?: string;
  ignoreSidebar?: boolean;
  children?: React.ReactNode;
}

export const Background: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });
  return (
    <Box
      data-cy={props.dataCy}
      sx={{
        backgroundColor: palette.background.default,
        height: '100%',
        width: isMobile || props.ignoreSidebar ? '100%' : 'calc(100% - 260px)'
      }}
    >
      {props.children}
    </Box>
  );
};
