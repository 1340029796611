import type {
  ActivityReportReadyForDownloadWebsocketData,
  GreenCheckWebSocketMessage,
  NewNotificationWebsocketData,
  Notification
} from '@gcv/shared';
import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import { action, makeAutoObservable, runInAction } from 'mobx';

import { NotificationsApi } from 'api';
import { downloadFileFromSignedUrl } from 'util/files.util';
import {
  filterNotificationsByBannerOrDrawerType,
  filterNotificationsByOrg,
  renderSnackBarNotification
} from 'util/notifications.util';
import { getSnackbarStore, SnackbarSeverity } from './SnackBarStore';
import { getUserStore } from './UserStore';

@injectable()
export class NotificationStore {
  interval: NodeJS.Timeout | undefined;
  isDownloadingReport = false;
  isPolling = false;
  notifications: Notification[] = [];
  notificationsApi = new NotificationsApi();
  pendingReportsToDisplay = [] as GreenCheckWebSocketMessage[];
  showReportSnackbar = true;
  webSocketMessage: GreenCheckWebSocketMessage = {} as GreenCheckWebSocketMessage;
  snackbarStore = getSnackbarStore();
  currentOrgId = '';

  constructor() {
    makeAutoObservable(this);
  }

  storeNotifications = (data: Notification[]) => {
    const { bannerNotifications, drawerNotifications } = filterNotificationsByBannerOrDrawerType(data);
    const notifications = filterNotificationsByOrg(this.currentOrgId, drawerNotifications);

    runInAction(() => {
      for (const d of notifications) {
        const index = this.notifications.findIndex((n) => n.id === d.id);

        if (index === -1) {
          this.notifications.push(d);
        }
      }
    });

    for (const bannerNotification of bannerNotifications) {
      renderSnackBarNotification(bannerNotification);
    }
  };

  getAllUserNotifications = (orgId: string, userId: string) => {
    this.notifications = [];
    this.currentOrgId = orgId;
    this.notificationsApi.getNotifications(orgId, userId).then(this.storeNotifications);
  };

  removeNotification = (notificationId: string) => {
    const index = this.notifications.findIndex((n) => n.id === notificationId);

    if (index !== -1) {
      this.notifications.splice(index, 1);
    }
  };

  /**
   * Start notifications polling.
   *
   * Polling starts only if it isn't already running.
   *
   * @param orgId ID of bank, dispensary, etc.
   * @param userId ID of user
   * @returns true if polling was started; false, if already running.
   */
  startPolling = (orgId: string, userId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      if (!this.isPolling) {
        runInAction(() => {
          this.isPolling = true;
        });

        this.interval = setInterval(() => {
          this.notificationsApi
            .getNotifications(orgId, userId, DateTime.utc().minus({ minutes: 10 }).toISO())
            .then(this.storeNotifications);
        }, 1000 * 60 * 10); // 10 minutes

        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  stopPolling = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }

    runInAction(() => {
      this.isPolling = false;
    });
  };

  handleWebsocket = action((socketMessage: GreenCheckWebSocketMessage) => {
    // save it to the store for other pages to react to
    this.webSocketMessage = socketMessage;

    // handle global actions like reports snackbar and notifications
    if (socketMessage.action === 'activity_report_ready_for_download') {
      this.handleReportWebsocket(socketMessage);
    } else if (socketMessage.action === 'new_notification') {
      const notificationMessage = socketMessage.data as NewNotificationWebsocketData;
      this.storeNotifications([notificationMessage.notification]);
    }
  });

  handleReportWebsocket = action((socketMessage: GreenCheckWebSocketMessage) => {
    if (this.showReportSnackbar) {
      this.displayReportSnackbar(socketMessage);
    } else {
      this.pendingReportsToDisplay.push(socketMessage);
    }
  });

  displayReportSnackbar = (socketMessage: GreenCheckWebSocketMessage) => {
    const socketData = socketMessage.data as ActivityReportReadyForDownloadWebsocketData;
    this.snackbarStore.showSnackbar(
      SnackbarSeverity.Info,
      `Your report is ready. Click to download ${socketData.friendlyReportName}.`,
      async () => {
        if (!this.isDownloadingReport) {
          try {
            runInAction(() => {
              this.isDownloadingReport = true;
            });
            downloadFileFromSignedUrl(socketData.s3Link);
          } catch (error) {
            getSnackbarStore().showErrorSnackbarMessage('Failed to download the report');
          } finally {
            runInAction(() => {
              this.isDownloadingReport = false;
            });
            getSnackbarStore().hideSnackbar();
          }
        }
      },
      true
    );
  };

  setShowReportSnackbar = action((value: boolean) => {
    this.showReportSnackbar = value;
  });

  showPendingSnackbars = action(() => {
    for (let index = 0; index < this.pendingReportsToDisplay.length; index++) {
      const socketMessage = this.pendingReportsToDisplay[index];
      this.displayReportSnackbar(socketMessage);
      this.pendingReportsToDisplay.splice(index, 1);
    }
  });

  clearStore = action(() => {
    this.notifications = [];
  });

  userStore = getUserStore();
}

let notificationStore: NotificationStore | undefined;

export function getNotificationStore(): NotificationStore {
  if (!notificationStore) {
    notificationStore = new NotificationStore();
  }

  return notificationStore;
}
