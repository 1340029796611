import React, { useMemo, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type {
  BusinessLicenseDocument,
  Dispensary,
  DocumentUpload,
  DocumentUploadRoot,
  DueDiligenceDocument,
  MUO,
  OnboardingDocumentRequirement,
  RequirementReference,
  User,
  USStates
} from '@gcv/shared';
import { CommentType, CRBLicenseStatus, DueDiligenceDocumentStatus, RequirementType } from '@gcv/shared';
import { Box, Grid } from '@mui/material';

import { TemplateApi } from 'api';
import { ReactComponent as FileIcon } from 'assets/images/ic-file-green-circle.svg';
import { USStateOptions } from 'domain/consts';
import { useComponent } from 'hooks';
import { getCommentStore } from 'stores/CommentStore';
import { getCrbBankStore } from 'stores/CrbBankStore';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { Button, Container, ContainerItem, Divider, Drawer, Icon, Text, Tooltip } from 'ui';
import { DateTimeHelpers } from 'util/dateTime.util';
import { downloadFile, getMimeType, openFileInNewWindow } from 'util/files.util';
import { getDocResponse, viewDocument } from 'util/s3.util';

/* Grid component */

interface DrawerInfoItemProps extends Record<string, unknown> {
  label: string;
  value?: string;
  tooltip?: string;
  fullWidth?: boolean;
}

const DrawerInfoItem: React.FC<DrawerInfoItemProps> = useComponent((props) => {
  return (
    <Grid item xs={12} md={props.fullWidth ? 12 : 6}>
      <Container align="center" height={'fit-content'} margin="0" padding={0}>
        <ContainerItem flex align="center" margin="0" padding={0}>
          <Text sx={{ fontWeight: 700, fontSize: '13px', color: '#757783' }} content={props.label} />
        </ContainerItem>
        {props.tooltip ? (
          <ContainerItem padding={0} align="center" flex margin="-3px 0 0 2px">
            <Tooltip variant="text" title={props.tooltip} placement="right">
              <Icon type="help" color="primary" />
            </Tooltip>
          </ContainerItem>
        ) : null}
      </Container>
      <Text content={props.value ?? '--'} />
    </Grid>
  );
});

/* Main component */

interface Props extends Record<string, unknown> {
  isOpen: boolean;
  onClose: () => void;
  dispensaryId: string;
  dueDiligenceTemplate: OnboardingDocumentRequirement & RequirementReference;
  user?: User;
  license?: BusinessLicenseDocument;
  document?: DocumentUpload;
  documentMetadata?: DueDiligenceDocument;
  bankId: string;
  templateId?: string;
  updateOnboardingDocumentRequirementResult?: any;
  currentDispensary: Dispensary | MUO;
  bankStore?: any;
}

const DocsDrawer: React.FC<Props> = useComponent((props) => {
  const {
    isOpen,
    dispensaryId,
    license,
    document,
    user,
    dueDiligenceTemplate,
    documentMetadata,
    templateId,
    updateOnboardingDocumentRequirementResult,
    currentDispensary,
    bankStore,
    bankId
  } = {
    ...props
  };
  const [isDownloadingDoc, setDownloadingDoc] = useState(false);
  const [isApprovalLoading, setApprovalLoading] = useState(false);

  const form = useForm({ defaultValues: { note: '' } });

  const snackbarStore = getSnackbarStore();

  const onClose = () => {
    getCommentStore().setCurrentPost({
      type: CommentType.REQUIREMENT,
      title: dueDiligenceTemplate.name,
      idComponents: {
        requirementId: dueDiligenceTemplate.requirement_id,
        crbId: currentDispensary.id,
        fiId: bankId
      }
    });

    props.onClose();
  };

  const openDocument = async (data: { docId: string; document: DocumentUpload }) => {
    const { docId, document } = data;
    const blob = await viewDocument({ orgId: currentDispensary.id, userType: 'dispensary' }, docId);

    if (blob) {
      openFileInNewWindow(blob, getMimeType(document.file_name), document.file_name);
    }
  };

  const downloadDocument = async (data: { docId: string; document: DocumentUpload | undefined }) => {
    try {
      setDownloadingDoc(true);
      const { docId, document } = data;
      const response = await getDocResponse({ orgId: currentDispensary.id, userType: 'dispensary' }, docId);

      if (response) {
        await downloadFile(response, document?.file_name);
      }
    } catch (error) {
      snackbarStore.showErrorSnackbarMessage('Could not download the file');
    } finally {
      setDownloadingDoc(false);
    }
  };

  const getDocumentType = () => {
    return dueDiligenceTemplate.requirement_id === 'business_licenses' ||
      dueDiligenceTemplate.requirement_id === 'employee_licenses'
      ? 'license'
      : 'document';
  };

  const lookupState = (stateId: USStates) => {
    const state = USStateOptions.find((option) => option.value === stateId);
    return state ? state.label : undefined;
  };

  const isMarijuanaLicense = useMemo(() => {
    return getDocumentType() === 'license' && dueDiligenceTemplate.type === RequirementType.BusinessLicense;
  }, [dueDiligenceTemplate.type]);

  const isEmployeeLicense = useMemo(() => {
    return getDocumentType() === 'license' && dueDiligenceTemplate.type === RequirementType.EmployeeLicense;
  }, [isMarijuanaLicense, dueDiligenceTemplate.type]);

  const approveDoc = async (data: FieldValues) => {
    if (!document?.id) {
      getSnackbarStore().showErrorSnackbarMessage('There was an issue approving the document.');
      return;
    }

    setApprovalLoading(true);

    const templateApi = new TemplateApi();
    await templateApi
      .updateOnboardingDocumentRequirementDocumentStatus(
        bankStore.bank.id,
        dispensaryId,
        templateId || '',
        dueDiligenceTemplate.requirement_id,
        document.id,
        DueDiligenceDocumentStatus.APPROVED,
        data.note
      )
      .then((requirementResult) => {
        updateOnboardingDocumentRequirementResult(requirementResult);
        getSnackbarStore().showSuccessSnackbarMessage('Document has been approved successfully.');
      })
      .catch(() => {
        getSnackbarStore().showErrorSnackbarMessage('There was an issue approving document.');
      })
      .finally(() => {
        setApprovalLoading(false);
        onClose();
      });
  };

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={isOpen}
      title={documentMetadata?.bank_friendly_name ? documentMetadata.bank_friendly_name : document?.file_name}
      subtitle={currentDispensary.name}
      width="large"
    >
      <Container column margin="0" padding="1.5rem" width="100%">
        <Container width="100%" margin="0 0 0.5rem 0" padding="0">
          <Box
            sx={{
              margin: '0',
              padding: '0',
              height: '100px',
              width: '100%',
              backgroundColor: '#F9FAFD',
              borderStyle: 'solid',
              borderWidth: 'thin',
              borderColor: '#C6CCDA',
              borderRadius: '8px'
            }}
          >
            <Container justify="space-between" margin="0" padding="0" height="inherit">
              <Container grow align="center" margin="0" padding="0" height="inherit">
                <ContainerItem margin="0 0 0 1.5rem" padding="0">
                  <Button
                    onClick={() => {
                      if (document) {
                        openDocument({ docId: document?.id ?? '', document });
                      }
                    }}
                    label={'View File'}
                    color="primary"
                  />
                </ContainerItem>
                <ContainerItem margin="0 0 0 0.5rem" padding="0">
                  <Button
                    onClick={() => {
                      if (document) {
                        downloadDocument({ docId: document?.id ?? '', document });
                      }
                    }}
                    label={'Download File'}
                    color="primary"
                    isLoading={isDownloadingDoc}
                  />
                </ContainerItem>
              </Container>
              <Container grow justify="flex-end" margin="0 1.5rem 0 0" padding="0" height="inherit">
                <Container align="center" margin="0" padding="0" height="inherit">
                  <FileIcon />
                </Container>
              </Container>
            </Container>
          </Box>
        </Container>

        {isMarijuanaLicense ? (
          <Container width="100%" margin="1rem 0" padding="0">
            <ContainerItem width="100%" margin="0" padding="0">
              <Text sx={{ fontWeight: 700 }} content="Verification Details" />
            </ContainerItem>
            <ContainerItem width="100%" margin="0" padding="0">
              <Container width="100%" margin="1rem 0" padding="0">
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <DrawerInfoItem
                    label="Business Name"
                    value={
                      license &&
                      license.business_name &&
                      license.license_status !== CRBLicenseStatus.Unmatched
                        ? license.business_name
                        : undefined
                    }
                  />
                  <DrawerInfoItem
                    label="Status"
                    value={
                      license &&
                      license.license_status &&
                      license.license_status !== CRBLicenseStatus.Unmatched
                        ? license.license_status
                        : undefined
                    }
                    tooltip="We use a third-party software to retrieve information about a given business license, including: 'Status' and 'Date Verified'. The information presented here should be used only as a guide and ultimately verified against state records."
                  />
                  <DrawerInfoItem
                    label="Reported License Type"
                    value={
                      license &&
                      license.license_status !== CRBLicenseStatus.Unmatched &&
                      license.reported_license_type_description
                        ? license.reported_license_type_description
                        : undefined
                    }
                    tooltip="This is the license type given to this license by the licensing authority."
                  />
                  <DrawerInfoItem
                    label="Uniform License Type"
                    value={
                      license && license.license_status !== CRBLicenseStatus.Unmatched
                        ? license.uniform_license_type_description
                        : undefined
                    }
                    tooltip="This is a standardized version of the license type, which has been standardized by our third-party licensing software."
                  />
                  <DrawerInfoItem
                    label="Date Verified"
                    value={
                      license &&
                      license.date_verified &&
                      license.license_status !== CRBLicenseStatus.Unmatched
                        ? DateTimeHelpers.formatISOToFriendlyDateString(
                            license.date_verified,
                            currentDispensary.iana_timezone
                          )
                        : undefined
                    }
                  />
                  <DrawerInfoItem
                    label="Verified Expiration Date"
                    value={
                      license &&
                      license.verified_expiration_date &&
                      license.license_status !== CRBLicenseStatus.Unmatched
                        ? DateTimeHelpers.formatISOToFriendlyDateString(
                            license.verified_expiration_date,
                            currentDispensary.iana_timezone
                          )
                        : undefined
                    }
                  />
                  <DrawerInfoItem
                    label="Licensing Authority"
                    value={
                      license && license.license_status !== CRBLicenseStatus.Unmatched
                        ? license.licensing_authority
                        : undefined
                    }
                  />
                  {!currentDispensary.is_tribal_nation && (
                    <DrawerInfoItem
                      label="State"
                      value={
                        license && license.state
                          ? lookupState(license.state)
                          : documentMetadata && documentMetadata.state
                          ? lookupState(documentMetadata.state)
                          : undefined
                      }
                    />
                  )}
                  <DrawerInfoItem
                    label="Verified License Number"
                    value={
                      license && license.license_status !== CRBLicenseStatus.Unmatched
                        ? license.license_identifier
                        : undefined
                    }
                  />
                  {license && license.tribal_nation && (
                    <DrawerInfoItem label="Tribal Nation" value={license?.tribal_nation} />
                  )}
                </Grid>
              </Container>
            </ContainerItem>
            <ContainerItem width="100%" margin="1rem 0 0 0" padding="0">
              <Divider />
            </ContainerItem>
          </Container>
        ) : null}

        <Container width="100%" margin="1rem 0" padding="0">
          <Container width="100%" margin="0" padding="0">
            <ContainerItem width="100%" margin="0" padding="0">
              <Text sx={{ fontWeight: 700 }} content="Document Information" />
            </ContainerItem>
            <ContainerItem width="100%" margin="0" padding="0">
              <Container width="100%" margin="1rem 0" padding="0">
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <DrawerInfoItem
                    label="Document Name"
                    value={documentMetadata?.bank_friendly_name ?? document?.file_name}
                  />
                  <DrawerInfoItem label="File Name" value={document?.file_name} />
                  {!isMarijuanaLicense ? (
                    <>
                      <DrawerInfoItem
                        fullWidth={isEmployeeLicense ? false : true}
                        label="Requirement"
                        value={dueDiligenceTemplate.name}
                      />
                      {isEmployeeLicense ? (
                        <>
                          <DrawerInfoItem label="Employee Name" value={documentMetadata?.license_name} />
                          <DrawerInfoItem label="License Number" value={documentMetadata?.license_number} />
                          <DrawerInfoItem
                            label="License State"
                            value={documentMetadata?.state ? lookupState(documentMetadata?.state) : undefined}
                          />
                        </>
                      ) : null}
                    </>
                  ) : null}
                  <DrawerInfoItem
                    label="Uploaded On"
                    value={`${
                      document?.upload_date
                        ? DateTimeHelpers.formatISOToFriendlyDateString(
                            document.upload_date,
                            currentDispensary.iana_timezone
                          )
                        : ''
                    } ${
                      document?.upload_date
                        ? DateTimeHelpers.formatISOToTimeString(
                            document.upload_date,
                            currentDispensary.iana_timezone
                          )
                        : ''
                    }`}
                  />
                  <DrawerInfoItem
                    label="Uploaded By"
                    value={
                      user
                        ? `${user.firstName} ${user.lastName}`
                        : document && (document as DocumentUploadRoot).third_party_upload_org_id
                        ? getCrbBankStore().banks[
                            (document as DocumentUploadRoot).third_party_upload_org_id as string
                          ].orgName
                        : undefined
                    }
                  />
                  <DrawerInfoItem
                    label="Reviewed On"
                    value={
                      documentMetadata?.review_date
                        ? `${
                            documentMetadata?.review_date
                              ? DateTimeHelpers.formatISOToFriendlyDateString(
                                  documentMetadata?.review_date,
                                  currentDispensary.iana_timezone
                                )
                              : ''
                          } ${
                            documentMetadata?.review_date
                              ? DateTimeHelpers.formatISOToTimeString(
                                  documentMetadata?.review_date,
                                  currentDispensary.iana_timezone
                                )
                              : ''
                          }`
                        : undefined
                    }
                  />
                  {bankStore ? (
                    <DrawerInfoItem
                      label="Reviewed By"
                      value={
                        documentMetadata?.reviewed_by
                          ? bankStore.getStaffNameById(documentMetadata.reviewed_by)
                          : undefined
                      }
                    />
                  ) : null}

                  {isMarijuanaLicense &&
                    (license?.license_status === CRBLicenseStatus.Unmatched ||
                      !license?.license_identifier) && (
                      <DrawerInfoItem label="User-Entered License Number" value={license?.license_number} />
                    )}

                  {isMarijuanaLicense && !license?.verified_expiration_date && (
                    <DrawerInfoItem
                      label="User-Entered Expiration Date"
                      value={
                        license?.expiration_date
                          ? DateTimeHelpers.formatISOToFriendlyDateString(
                              license.expiration_date,
                              currentDispensary.iana_timezone
                            )
                          : undefined
                      }
                    />
                  )}
                </Grid>
              </Container>
            </ContainerItem>
          </Container>
        </Container>

        {!documentMetadata?.review_date &&
          !documentMetadata?.reviewed_by &&
          updateOnboardingDocumentRequirementResult && (
            <ContainerItem justify="center" width="100%">
              <Button
                color="primary"
                label="Mark as Reviewed"
                isLoading={isApprovalLoading}
                onClick={form.handleSubmit(approveDoc)}
              />
            </ContainerItem>
          )}
      </Container>
    </Drawer>
  );
});

export default DocsDrawer;
