import type { FieldValues } from 'react-hook-form';
import type {
  ComplianceRuleDefinition,
  License,
  PaymentType,
  PurchaseLimit,
  PurchaseLimitByTypeMap,
  USStates
} from '@gcv/shared';
import { ComplianceRuleType, PurchaseLimitCheckType, RuleActivationGroup } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';

import { USStateOptions } from 'domain/consts';
import type { Row } from 'ui/organisms';
import type { StateContextPM } from './state-context.repo';
import { StateContextRepo } from './state-context.repo';

export interface StateContextVM {
  isLoading: boolean;
  /**
   * Full state name used in title
   */
  stateName: string;
  /**
   * Keeps track of which tab is active so we know where we are saving/updating
   */
  activeTab: ComplianceRuleType;
  /**
   * True/false if edit mode is on
   */
  editComplianceRules: boolean;
  /**
   * Current set of compliance rules values when changing tabs
   */
  currentComplianceRulesFormValues?: FieldValues;
  /**
   * Current purchase limit field values
   */
  currentPurchaseLimitFormValues?: FieldValues;
  /**
   * Purchase limits object, to be manipulated on the FE before user saves
   */
  purchaseLimitRulesList?: PurchaseLimit[];

  licenseRows: Row<License>[];
  addLicenseModalOpen: boolean;
}

export enum TransactionFlagTypes {
  Warning = 'warning',
  Failure = 'failure'
}

export interface DataFieldValues extends FieldValues {
  allowAnonymousSales: boolean;
  minimumAge: number | undefined;
  minimumHour: number | undefined;
  maximumHour: number | undefined;
  validPaymentTypes: PaymentType[] | undefined;
  forbiddenWords: string | undefined;
}

@injectable()
export class StateContextPresenter {
  @inject(StateContextRepo)
  private repo: StateContextRepo;

  constructor() {
    makeAutoObservable(this);
  }

  public stateContextViewModel: StateContextVM = {
    isLoading: true,
    activeTab: ComplianceRuleType.STATE,
    stateName: '',
    editComplianceRules: false,
    licenseRows: [],
    addLicenseModalOpen: false
  };

  public load = action(async (state: USStates, contextTab?: ComplianceRuleType) => {
    this.updateViewModel({
      isLoading: true,
      stateName: USStateOptions.find((o) => o.value === state)?.label,
      activeTab: contextTab ?? ComplianceRuleType.STATE
    });

    observe(this.repo, 'stateContextProgrammersModel', (obj) => {
      const programmersModel = obj.newValue as StateContextPM;

      const context = this.getCurrentComplianceContext(this.stateContextViewModel.activeTab);
      const purchaseLimitRulesList = JSON.parse(JSON.stringify(context.purchaseLimit ?? []));
      this.updateViewModel({
        isLoading: programmersModel.isLoading,
        stateName: USStateOptions.find((o) => o.value === state)?.label,
        currentComplianceRulesFormValues: this.getComplianceRulesFormValues(context),
        purchaseLimitRulesList: purchaseLimitRulesList,
        currentPurchaseLimitFormValues: this.getPurchaseLimitFormValues(purchaseLimitRulesList),
        licenseRows:
          programmersModel.licenseData?.business_license_types.map((license) => {
            return {
              id: license.value,
              data: {
                value: license.value,
                viewValue: license.viewValue
              }
            };
          }) ?? []
      });
    });

    await this.repo.load(state);
  });

  private updateViewModel = action((viewModel: Partial<StateContextVM>) => {
    this.stateContextViewModel = { ...this.stateContextViewModel, ...viewModel };
  });

  public resetForm = action(() => {
    const context = this.getCurrentComplianceContext(this.stateContextViewModel.activeTab);
    const currentComplianceRules = this.getComplianceRulesFormValues(context);
    const purchaseLimitRulesList = JSON.parse(JSON.stringify(context.purchaseLimit ?? []));
    const currentPurchaseLimitValues = this.getPurchaseLimitFormValues(purchaseLimitRulesList);

    this.updateViewModel({
      currentComplianceRulesFormValues: currentComplianceRules,
      purchaseLimitRulesList,
      currentPurchaseLimitFormValues: currentPurchaseLimitValues,
      editComplianceRules: false
    });
  });

  private getComplianceRulesFormValues = (rulesDefinition: ComplianceRuleDefinition | undefined) => {
    const formValues: DataFieldValues = {
      // base prop, not a rule
      allowAnonymousSales: rulesDefinition?.allowAnonymousSales ?? false,
      // base prop tied to minAgeGroup
      minimumAge: rulesDefinition?.minimumAge,
      // base prop tied to saleWasNotBeforeGroup
      minimumHour: rulesDefinition?.minimumHour,
      // base prop tied to saleWasNotAfterGroup
      maximumHour: rulesDefinition?.maximumHour,
      // base prop tied to validPaymentTypeGroup
      validPaymentTypes: rulesDefinition?.validPaymentTypes,
      // base prop tied to productsSoldContainNoForbiddenWordsGroup
      forbiddenWords: rulesDefinition?.forbiddenWords?.join(';')
    };

    // filter out SALE_WAS_NOT_BEFORE_GROUP because that is combined with SALE_WAS_NOT_AFTER_GROUP
    for (const rule of Object.values(RuleActivationGroup).filter(
      (rule) => rule !== RuleActivationGroup.SALE_WAS_NOT_BEFORE_GROUP
    )) {
      const values = this.getRuleDefaultValues(rulesDefinition, rule);
      formValues[rule] = values.enabled;
      formValues[`${rule}-type`] = values.enabled ? values.type : undefined;
    }
    return formValues;
  };

  private getPurchaseLimitFormValues = (purchaseLimits?: PurchaseLimit[]) => {
    // construct purchase limit form values using indexes
    const purchaseLimitFields: Record<string, string | number> = {};

    if (!purchaseLimits) {
      return purchaseLimitFields;
    }

    purchaseLimits.forEach((pr, index) => {
      Object.entries(pr.limits).forEach(([productLimitType, value], limitIndex) => {
        if (pr.limitCheckType === PurchaseLimitCheckType.GramsBoughtByType && productLimitType === 'limit') {
          return;
        }
        purchaseLimitFields[`productLimitType-${index}-${limitIndex}`] = productLimitType;
        purchaseLimitFields[`productLimitValue-${index}-${limitIndex}`] = value;
      });
      purchaseLimitFields[`limitCheckType-${index}`] = pr.limitCheckType;
      purchaseLimitFields[`purchaseLimitPeriodDays-${index}`] = pr.purchaseLimitPeriodDays;
    });

    return purchaseLimitFields;
  };

  private getCurrentComplianceContext = (activeTab: ComplianceRuleType): ComplianceRuleDefinition => {
    let currentComplianceRules: ComplianceRuleDefinition | undefined;

    switch (activeTab) {
      case ComplianceRuleType.STATE:
        currentComplianceRules = this.repo.stateContextProgrammersModel.stateContext;
        break;
      case ComplianceRuleType.REC:
        currentComplianceRules = this.repo.stateContextProgrammersModel.adultContext;
        break;
      case ComplianceRuleType.MED:
        currentComplianceRules = this.repo.stateContextProgrammersModel.medicalContext;
        break;
    }

    if (!currentComplianceRules) {
      return {
        warnings: [],
        failures: [],
        name: `${this.repo.stateContextProgrammersModel.state} ${
          this.stateContextViewModel.activeTab.charAt(0).toUpperCase() +
          this.stateContextViewModel.activeTab.slice(1)
        }`,
        priority: this.stateContextViewModel.activeTab === ComplianceRuleType.STATE ? 1 : 2
      };
    }
    return JSON.parse(JSON.stringify(currentComplianceRules));
  };

  private getRuleDefaultValues = (
    currentComplianceRules: ComplianceRuleDefinition | undefined,
    rule: RuleActivationGroup
  ) => {
    const isWarning = currentComplianceRules?.warnings.includes(rule);
    const isFailure = currentComplianceRules?.failures.includes(rule);
    return {
      enabled: isWarning || isFailure,
      type: isWarning ? TransactionFlagTypes.Warning : TransactionFlagTypes.Failure
    };
  };

  public saveComplianceContext = (data: DataFieldValues) => {
    const context = this.getCurrentComplianceContext(this.stateContextViewModel.activeTab);
    context.warnings = [];
    context.failures = [];

    context.allowAnonymousSales = data.allowAnonymousSales;

    for (const rule of Object.values(RuleActivationGroup)) {
      if (data[rule]) {
        this.addRuleToContext(context, rule, data);

        // add rule specific context data depending on the rule
        if (rule === RuleActivationGroup.VALID_PAYMENT_TYPE_GROUP) {
          context.validPaymentTypes = data.validPaymentTypes;
        }
        if (rule === RuleActivationGroup.MIN_AGE_GROUP) {
          context.minimumAge = data.minimumAge;
        }
        // sale was not before and sale was not after rules are combined into one, using SALE_WAS_NOT_AFTER_GROUP
        if (rule === RuleActivationGroup.SALE_WAS_NOT_AFTER_GROUP) {
          context.minimumHour = data.minimumHour;
          context.maximumHour = data.maximumHour;
        }
        if (
          rule === RuleActivationGroup.PRODUCT_SOLD_CONTAINS_NO_FORBIDDEN_WORDS_GROUP &&
          data.forbiddenWords
        ) {
          context.forbiddenWords = data.forbiddenWords.split(';');
        }
        if (rule === RuleActivationGroup.PURCHASE_LIMIT_CHECK_GROUP) {
          context.validPaymentTypes = data.validPaymentTypes;
        }
        if (rule === RuleActivationGroup.VALID_PAYMENT_TYPE_GROUP) {
          context.validPaymentTypes = data.validPaymentTypes;
        }
        if (rule === RuleActivationGroup.PURCHASE_LIMIT_CHECK_GROUP) {
          context.purchaseLimit = this.getPurchaseLimitsFromFormValues(data);
        }
      }
    }
    this.repo.saveComplianceRules(this.stateContextViewModel.activeTab, context).finally(() => {
      this.updateViewModel({ editComplianceRules: false });
    });
  };

  private getPurchaseLimitsFromFormValues = (data: DataFieldValues): PurchaseLimit[] => {
    let finishedPurchaseLimits = false;
    const purchaseLimits: PurchaseLimit[] = [];
    for (let index = 0; finishedPurchaseLimits === false; index++) {
      const purchaseLimitType = data[`limitCheckType-${index}`];
      if (!purchaseLimitType) {
        finishedPurchaseLimits = true;
      } else {
        const individualLimits: PurchaseLimitByTypeMap = {};
        let finishedIndividualLimits = false;
        for (let limitIndex = 0; finishedIndividualLimits === false; limitIndex++) {
          if (
            !data[`productLimitType-${index}-${limitIndex}`] ||
            (purchaseLimitType === PurchaseLimitCheckType.GramsBought && limitIndex === 1)
          ) {
            finishedIndividualLimits = true;
          } else {
            const limitType: string = data[`productLimitType-${index}-${limitIndex}`];
            individualLimits[limitType as keyof PurchaseLimitByTypeMap] =
              data[`productLimitValue-${index}-${limitIndex}`];
          }
        }
        purchaseLimits.push({
          limits: individualLimits,
          purchaseLimitPeriodDays: data[`purchaseLimitPeriodDays-${index}`],
          limitCheckType: data[`limitCheckType-${index}`]
        });
      }
    }
    return purchaseLimits;
  };

  private addRuleToContext = (
    context: ComplianceRuleDefinition,
    rule: RuleActivationGroup,
    data: DataFieldValues
  ) => {
    const warningOrFailuresList =
      data[`${rule}-type`] === TransactionFlagTypes.Warning ? context.warnings : context.failures;

    warningOrFailuresList.push(rule);

    // these rules are combined into one component, save them together
    if (rule === RuleActivationGroup.SALE_WAS_NOT_AFTER_GROUP) {
      warningOrFailuresList.push(RuleActivationGroup.SALE_WAS_NOT_BEFORE_GROUP);
    }
  };

  public cancelUpdate = action(() => {
    this.resetForm();
  });

  public updateActiveTab = action((activeTab: ComplianceRuleType) => {
    this.updateViewModel({ activeTab });
    this.resetForm();
  });

  public updateEditComplianceRules = action((editComplianceRules: boolean) => {
    this.updateViewModel({ editComplianceRules });
  });

  public updateIsLoading = action((isLoading: boolean) => {
    this.updateViewModel({ isLoading });
  });

  public updatePurchaseLimit = action(
    (
      values: {
        purchaseLimitCheckType?: PurchaseLimitCheckType;
        addProductLimit?: boolean;
        removeProductLimit?: keyof PurchaseLimitByTypeMap;
        productLimitType?: keyof PurchaseLimitByTypeMap;
        purchaseLimitPeriodDays?: number;
        purchaseLimitGramsValue?: number;
      },
      index: number,
      limitIndex?: number
    ) => {
      const purchaseLimit = this.stateContextViewModel.purchaseLimitRulesList?.[index];
      if (purchaseLimit) {
        if (values.purchaseLimitCheckType) {
          purchaseLimit.limitCheckType = values.purchaseLimitCheckType;
          purchaseLimit.limits = { limit: 0 };
        }
        if (values.addProductLimit) {
          purchaseLimit.limits = { ...purchaseLimit.limits, limit: 0 };
        }
        if (values.removeProductLimit) {
          delete purchaseLimit.limits[values.removeProductLimit];
        }
        if (
          (values.productLimitType !== undefined || values.purchaseLimitGramsValue !== undefined) &&
          limitIndex != null
        ) {
          const newLimits: PurchaseLimitByTypeMap = {};
          Object.entries(purchaseLimit.limits).forEach(([productKey, productValue], productLimitIndex) => {
            if (
              productLimitIndex === limitIndex // find matching limit by index
            ) {
              if (values.productLimitType && !purchaseLimit.limits.hasOwnProperty(values.productLimitType)) {
                // ensure we won't overwrite any existing product type
                newLimits[values.productLimitType as keyof PurchaseLimitByTypeMap] = productValue;
              }
              if (values.purchaseLimitGramsValue !== undefined) {
                newLimits[productKey as keyof PurchaseLimitByTypeMap] = values.purchaseLimitGramsValue;
              }
            } else {
              newLimits[productKey as keyof PurchaseLimitByTypeMap] = productValue;
            }
          });
          purchaseLimit.limits = newLimits;
        }

        if (values.purchaseLimitPeriodDays != null) {
          purchaseLimit.purchaseLimitPeriodDays = values.purchaseLimitPeriodDays;
        }

        this.stateContextViewModel.purchaseLimitRulesList?.splice(index, 1, purchaseLimit);

        const updatedValues = this.getPurchaseLimitFormValues(
          this.stateContextViewModel.purchaseLimitRulesList
        );
        this.updateViewModel({
          purchaseLimitRulesList: this.stateContextViewModel.purchaseLimitRulesList,
          currentPurchaseLimitFormValues: updatedValues
        });
      }
    }
  );

  public addPurchaseLimit = action(() => {
    this.stateContextViewModel.purchaseLimitRulesList?.push({
      limitCheckType: PurchaseLimitCheckType.GramsBought,
      limits: { limit: 0 },
      purchaseLimitPeriodDays: 0
    });
    const values = this.getPurchaseLimitFormValues(this.stateContextViewModel.purchaseLimitRulesList);
    this.updateViewModel({
      purchaseLimitRulesList: this.stateContextViewModel.purchaseLimitRulesList,
      currentPurchaseLimitFormValues: values
    });
  });

  public removePurchaseLimit = action((index: number) => {
    this.stateContextViewModel.purchaseLimitRulesList?.splice(index, 1);
    const values = this.getPurchaseLimitFormValues(this.stateContextViewModel.purchaseLimitRulesList);
    this.updateViewModel({
      purchaseLimitRulesList: this.stateContextViewModel.purchaseLimitRulesList,
      currentPurchaseLimitFormValues: values
    });
  });

  public updateAddLicenseModalOpen = action((addLicenseModalOpen: boolean) => {
    this.updateViewModel({ addLicenseModalOpen });
  });

  public addNewLicense = action((newLicense: string) => {
    this.repo.addNewLicense(newLicense).finally(() => {
      this.updateViewModel({ addLicenseModalOpen: false });
    });
  });

  public archiveLicense = action((licenseToArchive: string) => {
    this.repo.archiveLicense(licenseToArchive);
  });
}
