import * as React from 'react';
import { Link } from 'react-router-dom';
import { DueDiligenceStatus, GREEN_CHECK_ONBOARDING_BANK_ID } from '@gcv/shared';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import type { StepperStep, StepProps } from 'ui';
import { Background, Divider, Drawer, Stepper } from 'ui';
import useQueryString from 'util/queryString.util';
import { useComponent } from '../../../../hooks';
import { useInjection } from '../../../../ioc';
import { OnboardingPresenter } from './onboarding.presenter';
import { AccountOwners } from './pages/account-owners';
import { AdditionalInformation } from './pages/additional-info';
import BusinessDetails from './pages/business-details';
import { Documentation } from './pages/documentation';
import { IdVerification } from './pages/id-verification';
import { OperationalDetails } from './pages/operational-details';

export const OnboardingStepper: React.FC = useComponent(() => {
  const presenter = useInjection(OnboardingPresenter);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'), { noSsr: true });
  const [queryStringStep, setQueryStringStep] = useQueryString('step', 'account-owners');

  const steps: StepperStep[] = React.useMemo(() => {
    const accountOwners: StepperStep = {
      label: 'Account Owners',
      id: 'account-owners',
      Content: (props: StepProps) => <AccountOwners {...props} />,
      complete: true
    };

    const businessDetails: StepperStep = {
      label: 'Business Details',
      id: 'business-details',
      Content: (props: StepProps) => <BusinessDetails {...props} />,
      complete: presenter.viewModel.isBusinessDetailsSectionComplete
    };

    const operationalDetails: StepperStep = {
      label: 'Operational Details',
      id: 'operational-details',
      Content: (props: StepProps) => <OperationalDetails {...props} />,
      complete: presenter.viewModel.isOperationalDetailsSectionComplete
    };

    const documentation: StepperStep = {
      label: 'Documentation',
      id: 'documentation',
      Content: (props: StepProps) => <Documentation {...props} />,
      complete: presenter.viewModel.isDocumentationDetailsSectionComplete
    };

    const idVerification: StepperStep = {
      label: 'ID Verification',
      id: 'id-verification',
      Content: (props: StepProps) => <IdVerification {...props} />,
      complete: presenter.viewModel.isIdVerificationSectionComplete
    };

    const additionalInformation: StepperStep = {
      label: 'Additional Information',
      id: 'additional-information',
      Content: (props: StepProps) => <AdditionalInformation {...props} />,
      complete: presenter.viewModel.isAdditionalInfoSectionComplete
    };

    let dueDiligenceSteps = [
      accountOwners,
      businessDetails,
      operationalDetails,
      documentation,
      idVerification
    ];

    const activeCustomRequirements = presenter.getActiveRequirements(
      presenter.viewModel.onboardingTemplate.custom_requirements
    );
    const hasAdditionalInfo =
      activeCustomRequirements &&
      Object.keys(presenter.viewModel.onboardingTemplate?.custom_requirements).length > 0;

    if (presenter.viewModel.provider.id !== GREEN_CHECK_ONBOARDING_BANK_ID) {
      switch (presenter.viewModel.onboardingTemplateResult.status) {
        case DueDiligenceStatus.GCV_PENDING:
        case DueDiligenceStatus.BANK_PENDING:
        case DueDiligenceStatus.BANK_IN_PROGRESS:
        case DueDiligenceStatus.GCV_IN_PROGRESS: {
          if (hasAdditionalInfo) {
            dueDiligenceSteps = [...dueDiligenceSteps, additionalInformation];
          } else {
            dueDiligenceSteps = [...dueDiligenceSteps];
          }
          break;
        }
      }
    }
    return dueDiligenceSteps;
  }, [
    presenter.viewModel.onboardingTemplateResult.id,
    presenter.viewModel.isBusinessDetailsSectionComplete,
    presenter.viewModel.isOperationalDetailsSectionComplete,
    presenter.viewModel.isDocumentationDetailsSectionComplete,
    presenter.viewModel.isIdVerificationSectionComplete,
    presenter.viewModel.isAdditionalInfoSectionComplete
  ]);

  const getDefaultStepFromQueryString = (): number => {
    if (queryStringStep) {
      const step = steps.find((s) => s.id === queryStringStep);
      if (step && steps.indexOf(step) !== undefined) {
        return steps.indexOf(step);
      }
    }
    return 0;
  };

  const [activeStep, setActiveStep] = React.useState(getDefaultStepFromQueryString());

  const isLastStep = React.useMemo(() => {
    return activeStep === steps.length - 1;
  }, [activeStep]);

  const handleStep = (step: number) => {
    setActiveStep(step);
    setQueryStringStep(steps[step].id);

    if (isMobile) {
      presenter.setOpenNavDrawer(false);
    }
  };

  const handleNext = React.useCallback(() => {
    const activeCustomRequirements = presenter.getActiveRequirements(
      presenter.viewModel.onboardingTemplate.custom_requirements
    );
    const hasAdditionalInfo =
      activeCustomRequirements &&
      Object.keys(presenter.viewModel.onboardingTemplate?.custom_requirements).length > 0;

    const allStepsCompleted =
      presenter.viewModel.isBusinessDetailsSectionComplete &&
      presenter.viewModel.isOperationalDetailsSectionComplete &&
      presenter.viewModel.isDocumentationDetailsSectionComplete &&
      presenter.viewModel.isIdVerificationSectionComplete &&
      ((hasAdditionalInfo && presenter.viewModel.isAdditionalInfoSectionComplete) || !hasAdditionalInfo);
    if (isLastStep && allStepsCompleted) {
      presenter.submitOnboardingTemplateResults();
    } else {
      const newActiveStep =
        isLastStep && !allStepsCompleted
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has not been completed
            steps.findIndex((step) => !step.complete)
          : activeStep + 1;
      setActiveStep(newActiveStep);
      setQueryStringStep(steps[newActiveStep]?.id);
    }
  }, [activeStep]);

  const handleBack = React.useCallback(() => {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setQueryStringStep(steps[activeStep - 1].id);
    }
  }, [activeStep]);

  const CurrentStep = React.useMemo(() => {
    return steps[activeStep]?.Content;
  }, [activeStep]);

  return (
    <>
      <Drawer
        width="xsmall"
        anchor="left"
        hideBackdrop={!isMobile}
        onClose={() => presenter.setOpenNavDrawer(false)}
        open={presenter.viewModel.navDrawerOpen}
        permanent={!isMobile}
        dataCy={'crb-onboarding-stepper'}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderRight: '1px solid #bdbdbd',
            display: 'flex',
            height: '73px',
            minHeight: '73px',
            justifyContent: 'center',
            width: '261px',
            pt: '6px'
          }}
        >
          <Link to={'/secure/crb/dashboard'} data-cy="logo-link">
            <img src="/img/logo-2.svg" alt="Green Check Verified" height={'22px'} width={'168px'} />
          </Link>
        </Box>
        <Divider />
        <Box sx={{ width: '260px', pt: '41px' }}>
          <Stepper
            activeStep={activeStep}
            handleStep={handleStep}
            steps={steps}
            dataCy="onboarding-stepper"
          />
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1
        }}
      >
        <Background ignoreSidebar>
          {CurrentStep && (
            <CurrentStep
              totalSteps={steps.length}
              onNext={handleNext}
              onBack={handleBack}
              stepNumber={activeStep + 1}
              isLastStep={isLastStep}
            />
          )}
        </Background>
      </Box>
    </>
  );
});
