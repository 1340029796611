import * as React from 'react';
import type { CrbServiceProvider } from '@gcv/shared';
import { useMediaQuery, useTheme } from '@mui/material';

import { Button, Container, ContainerItem, Hyperlink, Label, Text } from 'ui/atoms';
import { Dialog } from 'ui/organisms';
import { formatAmPm, formatPhoneNumber } from 'util/format.util';

interface Props {
  showBankInfo: boolean;
  setShowBankInfo: (isOpen: boolean) => void;
  serviceProviderDetails?: CrbServiceProvider | null;
}

export const BankInfoDialog: React.FC<Props> = (props) => {
  const isContactEmpty = !(
    props.serviceProviderDetails?.primary_point_of_contact?.firstName &&
    props.serviceProviderDetails?.primary_point_of_contact?.lastName &&
    props.serviceProviderDetails?.orgName &&
    props.serviceProviderDetails?.primary_point_of_contact?.phoneNumber &&
    props.serviceProviderDetails?.primary_point_of_contact?.emailAddress
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  // this function is created to handle the default state (showing -- incase of no data)
  const address = () => {
    let address = '';

    if (props.serviceProviderDetails?.address) {
      address = `${props.serviceProviderDetails?.address}, `;
    }
    if (props.serviceProviderDetails?.city) {
      address = `${address}${props.serviceProviderDetails?.city}, `;
    }
    if (props.serviceProviderDetails?.state) {
      address = `${address}${props.serviceProviderDetails?.state}, `;
    }
    if (props.serviceProviderDetails?.postalCode) {
      address = `${address}${props.serviceProviderDetails?.postalCode} `;
    }

    if (address === '') {
      address = '--';
    }
    return address;
  };

  // this function is created to handle the default state (showing -- incase of no data)
  const name = () => {
    let name = '';

    if (props.serviceProviderDetails?.primary_point_of_contact?.firstName) {
      name = `${props.serviceProviderDetails?.primary_point_of_contact?.firstName} `;
    }
    if (props.serviceProviderDetails?.primary_point_of_contact?.lastName) {
      name = name + props.serviceProviderDetails?.primary_point_of_contact?.lastName;
    }

    if (name === '') {
      name = '--';
    }
    return name;
  };

  return (
    <Dialog
      title={props.serviceProviderDetails?.orgName || ''}
      isOpen={props.showBankInfo}
      handleClose={() => props.setShowBankInfo(false)}
      noCancelAction
      action={<Button fullWidth color="primary" label="Done" onClick={() => props.setShowBankInfo(false)} />}
    >
      <Container padding="0" column dataCy="bank-info-container">
        <ContainerItem padding="0">
          <Label
            sx={{ color: '#757783', marginBottom: '2px' }}
            content="Address"
            dataCy="bank-info-address-label"
          />
          <Text sx={{ fontSize: '14px', color: '#3A3C45' }} content={address()} dataCy="bank-info-address" />
        </ContainerItem>

        <ContainerItem padding="16px 0">
          <Label
            sx={{ color: '#757783', marginBottom: '2px' }}
            content="Hours"
            dataCy="bank-info-hours-label"
          />
          <table style={{ marginLeft: '-3px' }}>
            <tbody style={{ fontSize: '14px' }}>
              <tr>
                <td width="200px">Monday</td>
                <td>
                  {props.serviceProviderDetails?.businessHours?.monday.isOpen && (
                    <span>
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.monday.openHour)} -{' '}
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.monday.closeHour)}
                    </span>
                  )}
                  {!props.serviceProviderDetails?.businessHours?.monday.isOpen && <span>Closed</span>}
                </td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>
                  {props.serviceProviderDetails?.businessHours?.tuesday.isOpen && (
                    <span>
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.tuesday.openHour)} -{' '}
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.tuesday.closeHour)}
                    </span>
                  )}
                  {!props.serviceProviderDetails?.businessHours?.tuesday.isOpen && <span>Closed</span>}
                </td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>
                  {props.serviceProviderDetails?.businessHours?.wednesday.isOpen && (
                    <span>
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.wednesday.openHour)} -{' '}
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.wednesday.closeHour)}
                    </span>
                  )}
                  {!props.serviceProviderDetails?.businessHours?.wednesday.isOpen && <span>Closed</span>}
                </td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>
                  {props.serviceProviderDetails?.businessHours?.thursday.isOpen && (
                    <span>
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.thursday.openHour)} -{' '}
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.thursday.closeHour)}
                    </span>
                  )}
                  {!props.serviceProviderDetails?.businessHours?.thursday.isOpen && <span>Closed</span>}
                </td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>
                  {props.serviceProviderDetails?.businessHours?.friday.isOpen && (
                    <span>
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.friday.openHour)} -{' '}
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.friday.closeHour)}
                    </span>
                  )}
                  {!props.serviceProviderDetails?.businessHours?.friday.isOpen && <span>Closed</span>}
                </td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>
                  {props.serviceProviderDetails?.businessHours?.saturday.isOpen && (
                    <span>
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.saturday.openHour)} -{' '}
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.saturday.closeHour)}
                    </span>
                  )}
                  {!props.serviceProviderDetails?.businessHours?.saturday.isOpen && <span>Closed</span>}
                </td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>
                  {props.serviceProviderDetails?.businessHours?.sunday.isOpen && (
                    <span>
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.sunday.openHour)} -{' '}
                      {formatAmPm(props.serviceProviderDetails?.businessHours?.sunday.closeHour)}
                    </span>
                  )}
                  {!props.serviceProviderDetails?.businessHours?.sunday.isOpen && <span>Closed</span>}
                </td>
              </tr>
            </tbody>
          </table>
        </ContainerItem>

        <ContainerItem padding="8px 0">
          <Label
            sx={{ color: '#757783', marginBottom: '4px' }}
            content="Contact"
            dataCy="bank-info-contact-label"
          />
          {isContactEmpty ? (
            <Text sx={{ fontSize: '14px' }} content="--" />
          ) : (
            <>
              <Text sx={{ fontSize: '14px' }} content={name()} dataCy="bank-info-contact" />
              <Text
                sx={{ fontSize: '14px' }}
                content={`${props.serviceProviderDetails?.orgName}`}
                dataCy="bank-info-title"
              />
              <Hyperlink
                style={{ fontSize: '14px' }}
                label={`${props.serviceProviderDetails?.primary_point_of_contact?.emailAddress ?? '--'}`}
                url={`mailto:${props.serviceProviderDetails?.primary_point_of_contact?.emailAddress}`}
                dataCy="bank-info-email"
              />
              <br />
              {isMobile ? (
                <Hyperlink
                  style={{ fontSize: '14px' }}
                  label={`${formatPhoneNumber(
                    props.serviceProviderDetails?.primary_point_of_contact?.phoneNumber ?? '--'
                  )}`}
                  url={`tel:${formatPhoneNumber(
                    props.serviceProviderDetails?.primary_point_of_contact?.phoneNumber
                  )}`}
                  dataCy="bank-info-phone"
                />
              ) : (
                <Text
                  sx={{ fontSize: '14px' }}
                  content={`${formatPhoneNumber(
                    props.serviceProviderDetails?.primary_point_of_contact?.phoneNumber ?? '--'
                  )}`}
                  dataCy="bank-info-phone"
                />
              )}
            </>
          )}
        </ContainerItem>
      </Container>
    </Dialog>
  );
};
