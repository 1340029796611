import type { IANATimezones, MinifiedDispensary, Sale, SaleProduct } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { DateTime } from 'luxon';
import { action, makeAutoObservable, observe } from 'mobx';

import { FiBankStore } from 'stores/FiBankStore';
import { DateTimeHelpers } from 'util/dateTime.util';
import type { DailySummaryWithAbsTotals, PM } from './daily-summary-details.repo';
import { FiDailySummariesDetailsRepo } from './daily-summary-details.repo';

export interface VM {
  canShowLastSyncedDate: () => boolean;
  currentDispensary: MinifiedDispensary;
  drawerData: Sale;
  drawerOpen: boolean;
  formattedDate: () => string;
  isLoading: () => boolean;
  lastSyncedDate: string;
  loadingDispensary: boolean;
  loadingSales: boolean;
  loadingSummary: boolean;
  midDate: () => string;
  sales: Sale[];
  summary: DailySummaryWithAbsTotals;
  summaryDate: string;
  timezone: () => IANATimezones;
  visualDate: () => string;
}

@injectable()
export class FiDailySummariesDetailsPresenter {
  @inject(FiDailySummariesDetailsRepo)
  private repo: FiDailySummariesDetailsRepo;

  @inject(FiBankStore)
  private bankStore: FiBankStore;

  constructor() {
    makeAutoObservable(this);
  }

  public viewModel: VM = {
    canShowLastSyncedDate: () => this.canShowLastSyncedDate,
    currentDispensary: {} as MinifiedDispensary,
    drawerData: { products_sold: [] as SaleProduct[] } as Sale,
    drawerOpen: false,
    formattedDate: () => this.formattedDate,
    isLoading: () => this.isLoading,
    lastSyncedDate: '',
    loadingDispensary: true,
    loadingSales: true,
    loadingSummary: true,
    midDate: () => this.midDate,
    sales: [],
    summary: {} as DailySummaryWithAbsTotals,
    summaryDate: '',
    timezone: () => this.timezone,
    visualDate: () => this.visualDate
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  // show lastSynced only if sales was synced after 12AM
  private get canShowLastSyncedDate() {
    return (
      DateTime.fromISO(this.viewModel.lastSyncedDate, {
        zone: this.viewModel.currentDispensary.iana_timezone
      }).toISO() > this.viewModel.midDate() &&
      this.viewModel.visualDate() ===
        DateTimeHelpers.formatISOToTableDateString(
          this.viewModel.lastSyncedDate,
          this.viewModel.currentDispensary.iana_timezone
        )
    );
  }

  // convert UTC time to dispensary timezone and format to HH:MM AM/PM
  private get lastSyncedLocalTime() {
    return DateTime.fromISO(this.viewModel.lastSyncedDate, {
      zone: this.viewModel.currentDispensary.iana_timezone
    }).toLocaleString(DateTime.TIME_SIMPLE);
  }

  // required text for date as per design
  private get formattedDate() {
    return `Today (last synced at ${this.lastSyncedLocalTime.toUpperCase()})`;
  }

  private get isLoading() {
    return this.viewModel.loadingDispensary && this.viewModel.loadingSales && this.viewModel.loadingSummary;
  }

  private get timezone() {
    return this.viewModel.currentDispensary.iana_timezone;
  }

  private get midDate() {
    return DateTimeHelpers.getStartOfTodayIsoString(this.bankStore.bank.iana_timezone);
  }

  private get visualDate() {
    return DateTimeHelpers.formatDateStringToTableDateString(
      this.viewModel.summaryDate,
      this.bankStore.bank.iana_timezone
    );
  }

  public load = action(async (date: string, dispensaryId: string) => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      this.viewModel = {
        ...this.viewModel,
        currentDispensary: programmersModel.currentDispensary,
        loadingDispensary: programmersModel.loadingDispensary,
        loadingSales: programmersModel.loadingSales,
        loadingSummary: programmersModel.loadingSummary,
        sales: programmersModel.sales,
        summary: programmersModel.summary,
        summaryDate: programmersModel.summaryDate
      };
    });

    await this.repo.load(date, dispensaryId);
  });

  public setDrawerData = action((data: Sale) => {
    this.updateViewModel({ drawerData: data });
  });

  public toggleDrawer = action((drawerOpen: boolean) => {
    this.updateViewModel({ drawerOpen: drawerOpen });
  });
}
