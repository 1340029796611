import * as React from 'react';
import type { Theme } from '@mui/material';
import { Typography } from '@mui/material';
import type { SxProps } from '@mui/system';

interface Props {
  content: string;
  dataCy?: string;
  inline?: boolean;
  type?: 'subtitle1' | 'subtitle2';
  sx?: SxProps<Theme>;
}

export const Label: React.FC<Props> = (props) => {
  const component = props.inline ? 'span' : 'div';
  const variant = props.type ? props.type : 'subtitle1';

  return (
    <Typography component={component} data-cy={props.dataCy} variant={variant} sx={{ ...props.sx }}>
      {props.content}
    </Typography>
  );
};
