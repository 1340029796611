import * as React from 'react';
import { ErrorBoundary } from '@rollbar/react'; // <-- Provider imports 'rollbar' for us
import { observer } from 'mobx-react';

import { logRocketConfig } from '../third-party-integrations/log-rocket';

const ErrorDisplay = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '75px',
        height: '100%',
        width: '100%'
      }}
    >
      <span className="material-icons">error</span> &nbsp; An error has occurred
    </div>
  );
};

/**
 * Return wrapped component configured to work with MobX observable models.
 *
 * The hook attaches the following functionality to a React component:
 *
 *   1. memoizes component for performance
 *   2. provides React error boundary for catching app level errors and displaying error message
 *   3. configures component as a MobX observer connected to a model
 *
 * @param Component a React component.
 * @returns wrapped React component configured to work with MobX observable models.
 */
export const useComponent = <T extends Record<string, unknown>>(Component: (props: T) => JSX.Element) => {
  const WrappedComponent = React.memo((props: T) => {
    const Element = observer(Component);

    return (
      <ErrorBoundary fallbackUI={ErrorDisplay} extra={{ additional: logRocketConfig.session_url }}>
        <Element {...props} />
      </ErrorBoundary>
    );
  });

  return WrappedComponent;
};
