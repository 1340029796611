import { useEffect, useState } from 'react';

import { useComponent } from 'hooks';
import { Button, Tooltip } from 'ui/atoms';

interface Props extends Record<string, unknown> {
  id: number;
  disabled?: boolean;
  selected?: boolean;
  tooltip?: string;
  onClickCallback?: (id: number) => void;
}

interface PrivateProps extends Props {
  color: 'icon-selected' | 'icon-deselected';
}

const FavoriteButton = (props: PrivateProps) => {
  return (
    <Button
      size="large"
      label={''}
      onClick={() => {
        if (!props.disabled && props.onClickCallback) {
          props.onClickCallback(props.id);
        }
      }}
      color={props.color}
      icon="star"
    />
  );
};

export const Favorite = useComponent((props: Props) => {
  const [color, setColor] = useState<'icon-selected' | 'icon-deselected'>('icon-deselected');

  useEffect(() => {
    setColor(props.selected ? 'icon-selected' : 'icon-deselected');
  }, [props.selected]);

  const config: PrivateProps = { ...props, color };

  return props.tooltip ? (
    <Tooltip title={props.tooltip} placement="top-start">
      <FavoriteButton {...config} />
    </Tooltip>
  ) : (
    <FavoriteButton {...config} />
  );
});
