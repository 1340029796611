import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Box, ClickAwayListener, Divider, Paper } from '@mui/material';

import { ReactComponent as ArchiveIcon } from 'assets/images/ic-archive-dispensary.svg';
import { ReactComponent as HelpIcon } from 'assets/images/ic-help.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import {
  Button,
  Container,
  ContainerItem,
  Header,
  InputCheckBox,
  InputSearch,
  List,
  Spinner,
  SubTitle,
  Text,
  Tooltip
} from 'ui';
import { DataTransformer } from 'util/data.util';
import type { FilterSelectConfig, FilterSelectOption } from './filter-select.organism.model';
import { FilterSelectPresenter } from './filter-select.organism.presenter';

interface BaseProps extends Record<string, unknown> {
  options: FilterSelectOption[];
  onApply?: (data: any[], all: string[], added: string[], removed: string[]) => void;
  onCancel?: () => void;
  onSelect?: (data: any[], all: string[], added: string[], removed: string[]) => void;
  config: Partial<FilterSelectConfig>;
}

const FilterSelectBase: React.FC<BaseProps> = useComponent((props) => {
  const presenter = useInjection(FilterSelectPresenter);
  const form = useForm();

  const SelectAllClearAll = (props: { justify: 'flex-start' | 'flex-end' }) => (
    <Container align={'center'} justify={props.justify} padding={0} width="100%">
      <ContainerItem padding="0">
        <Button
          color="primary-text"
          dataCy="select-all-link"
          label="Select All"
          onClick={presenter.selectAll}
          size="small"
          style={{ padding: 0 }}
        />
      </ContainerItem>

      <ContainerItem padding="0">
        {presenter.selectedCount > 0 ? (
          <Button
            color="primary-text"
            dataCy="clear-all-link"
            label="Clear All"
            onClick={presenter.clearAll}
            size="small"
            style={{ padding: 0 }}
          />
        ) : undefined}
      </ContainerItem>
    </Container>
  );

  if (presenter.viewModel.isLoading) {
    return <Spinner />;
  }

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.onCancel) {
          props.onCancel();
        }
      }}
      mouseEvent={'onMouseUp'}
    >
      <Box
        sx={{
          background: '#fff',
          borderRadius: '6px',
          boxShadow: presenter.viewModel.config.type.includes('dropdown')
            ? '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
            : 'unset',
          position: presenter.viewModel.config.type.includes('dropdown') ? 'absolute' : 'relative',
          width: presenter.viewModel.config.type.includes('dropdown') ? '350px' : '100%',
          zIndex: '1000'
        }}
      >
        <Container column padding={0} width="100%">
          {(presenter.viewModel.config.selectAllClearAll.isHidden ||
            presenter.viewModel.config.selectAllClearAll.position === 'bottom') && (
            <ContainerItem flex justify="between" align="center" width="100%">
              <ContainerItem flex align="center" width="100%">
                <Header content={presenter.viewModel.config.title} type="h2" />
              </ContainerItem>

              {!presenter.viewModel.config.showHidden.isHidden && (
                <ContainerItem flex align="center" justify="right" padding="0" width="100%">
                  <Box
                    onClick={() => {
                      presenter.showHidden(!presenter.viewModel.showHidden);
                    }}
                    sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}
                  >
                    <Text
                      sx={{ fontWeight: '700', fontSize: '13px', color: '#828599', marginRight: '4px' }}
                      content={
                        presenter.viewModel.showHidden
                          ? `Hide ${presenter.viewModel.config.showHidden.label}`
                          : `Show ${presenter.viewModel.config.showHidden.label}`
                      }
                    />
                    <ArchiveIcon />
                  </Box>
                </ContainerItem>
              )}
            </ContainerItem>
          )}

          {!presenter.viewModel.config.selectAllClearAll.isHidden &&
            presenter.viewModel.config.selectAllClearAll.position === 'top' && (
              <>
                <ContainerItem flex align="center" width="100%">
                  <Header content={presenter.viewModel.config.title} type="h2" />
                  <SelectAllClearAll justify="flex-end" />
                </ContainerItem>
                <Divider sx={{ margin: '0', height: '1px', width: '100%' }} />
              </>
            )}

          {!presenter.viewModel.config.search.isHidden && (
            <ContainerItem
              padding={presenter.viewModel.config.type.includes('dropdown') ? '0.5rem 0.5rem 0 0.5rem' : '0'}
              margin="0 0 0.5rem 0"
              width={'100%'}
            >
              <InputSearch
                {...form}
                defaultValue={presenter.viewModel.search}
                label={presenter.viewModel.config.search.label ?? 'Search'}
                onChange={(e) => presenter.search(e.target.value)}
              />
            </ContainerItem>
          )}

          <ContainerItem
            padding="0"
            margin={presenter.viewModel.config.type === 'dropdown' ? '0 0 0.5rem 0' : undefined}
            width="100%"
          >
            <Paper
              variant="outlined"
              sx={{
                border: presenter.viewModel.config.type.includes('dropdown') ? 'none' : undefined,
                borderRadius: '6px',
                height: presenter.viewModel.config.height ?? 'auto',
                overflow: 'auto'
              }}
            >
              <List
                archiveOptionIcon={<ArchiveIcon />}
                options={presenter.viewModel.options}
                onSelect={presenter.select}
                onClearChildren={
                  presenter.viewModel.config.clearChildren.isHidden ? undefined : presenter.clearChildren
                }
              />
            </Paper>

            {!presenter.viewModel.config.selectAllClearAll.isHidden &&
              presenter.viewModel.config.selectAllClearAll.position === 'bottom' && (
                <SelectAllClearAll justify="flex-start" />
              )}
          </ContainerItem>

          <ContainerItem padding="0" width="100%">
            <Container column={false} padding="0" width="100%">
              {!presenter.viewModel.config.showSelectedOnly.isHidden && (
                <ContainerItem
                  flex
                  align="center"
                  justify="left"
                  width={presenter.viewModel.config.count.isHidden ? '100%' : '50%'}
                  padding="0"
                  margin="0 0 0.5rem 1rem"
                >
                  <InputCheckBox
                    {...form}
                    label={'Show selected only'}
                    name={'showSelectedOnly'}
                    onChange={(_, checked) => presenter.showSelectedOnly(checked)}
                  />
                  {presenter.viewModel.config.showSelectedOnly.tooltip && (
                    <Tooltip title={presenter.viewModel.config.showSelectedOnly.tooltip}>
                      <HelpIcon />
                    </Tooltip>
                  )}
                </ContainerItem>
              )}

              {!presenter.viewModel.config.count.isHidden && (
                <ContainerItem
                  flex
                  justify="right"
                  width={presenter.viewModel.config.showSelectedOnly.isHidden ? '100%' : '50%'}
                >
                  <SubTitle
                    content={`${presenter.selectedCount} ${
                      presenter.viewModel.config.count.label ?? 'selected'
                    }`}
                  />
                </ContainerItem>
              )}
            </Container>
          </ContainerItem>
        </Container>

        {presenter.viewModel.config.type === 'dropdown' && (
          <>
            <Divider />
            <Container align="center" justify="flex-end" width="100%">
              <ContainerItem>
                <Button
                  label={'Cancel'}
                  color={'default-outlined'}
                  size="small"
                  onClick={() => {
                    if (props.onCancel) {
                      props.onCancel();
                    }
                  }}
                />
              </ContainerItem>
              <ContainerItem>
                <Button
                  label={'Apply'}
                  color={'primary'}
                  size="small"
                  onClick={() => {
                    if (props.onApply) {
                      props.onApply(
                        presenter.defaultOptions,
                        presenter.viewModel.selected.all,
                        presenter.viewModel.selected.added,
                        presenter.viewModel.selected.removed
                      );
                    }
                  }}
                />
              </ContainerItem>
            </Container>
          </>
        )}
      </Box>
    </ClickAwayListener>
  );
});

interface Props extends BaseProps {
  transform: keyof DataTransformer;
}

export const FilterSelect: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(FilterSelectPresenter);
  const [currentOptions, setCurrentOptions] = React.useState<FilterSelectOption[]>(props.options);

  React.useEffect(() => {
    presenter.load(props.options, props.onSelect, props.config);
  }, []);

  const transformer = new DataTransformer();

  const formatOptions = (options: FilterSelectOption[]) => {
    let opts = [...options];

    if (props.config.emitExcludeParent) {
      const parents = options.filter((option) => option.children && option.children.length);
      const standalone = options.filter((option) => !option.children || !option.children.length);
      opts = standalone.concat(parents.map((parent) => parent.children ?? []).flat());
    }

    return opts;
  };

  const onSelect = (options: FilterSelectOption[], all: string[], added: string[], removed: string[]) => {
    options = formatOptions(options);

    if (props.onSelect) {
      props.onSelect(transformer[props.transform](options), all, added, removed);
    }
  };

  const onApply = (options: FilterSelectOption[], all: string[], added: string[], removed: string[]) => {
    setCurrentOptions(options);
    options = formatOptions(options);

    const transformed = transformer[props.transform](options);
    if (props.onApply) {
      props.onApply(transformed, all, added, removed);
    }
  };

  const onCancel = () => {
    if (presenter.viewModel.config.type === 'dropdown-auto-apply' && props.onApply) {
      props.onApply(
        presenter.defaultOptions,
        presenter.viewModel.selected.all,
        presenter.viewModel.selected.added,
        presenter.viewModel.selected.removed
      );
    } else {
      setCurrentOptions(currentOptions);
    }
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <FilterSelectBase
      config={props.config}
      onApply={onApply}
      onCancel={onCancel}
      onSelect={onSelect}
      options={currentOptions}
    />
  );
});
