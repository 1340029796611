import type { AccessMetadata, UpdateAccessParams } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

import { GcvAdminApi } from 'api';
import { SnackbarStore } from 'stores/SnackBarStore';
import { GcvAccessPresenter } from '../../manage-access.presenter';

export interface GcvEditAccessPM {
  isLoading: boolean;
  accessMetadata?: AccessMetadata;
}

@injectable()
export class GcvEditAccessRepo {
  @inject(GcvAdminApi)
  private gcvAdminApi: GcvAdminApi;
  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;
  @inject(GcvAccessPresenter)
  private accessPresenter: GcvAccessPresenter;

  constructor() {
    makeAutoObservable(this);
  }

  public gcvEditAccessProgrammersModel: GcvEditAccessPM = {
    isLoading: true
  };

  load = action(async (client_id: string) => {
    await this.loadAccessRecord(client_id);
  });

  loadAccessRecord = action(async (client_id: string) => {
    try {
      this.updateProgrammersModel({ isLoading: true });
      const records = await this.gcvAdminApi.getAccessMetadata(client_id);
      if (records.length === 1) {
        this.updateProgrammersModel({ accessMetadata: records[0], isLoading: false });
      } else {
        this.snackbarStore.showErrorSnackbarMessage('Could not find client information');
      }
    } catch {
      this.snackbarStore.showErrorSnackbarMessage('Failed to load Access client information');
    } finally {
      this.updateProgrammersModel({ isLoading: false });
    }
  });

  updateProgrammersModel = action((programmersModel: Partial<GcvEditAccessPM>) => {
    this.gcvEditAccessProgrammersModel = { ...this.gcvEditAccessProgrammersModel, ...programmersModel };
  });

  rotateApiKey = action(async (client_id: string) => {
    await this.accessPresenter.rotateApiKey(client_id);
  });

  updateAccessConfig = action(async (client_id: string, updateRequest: UpdateAccessParams) => {
    await this.gcvAdminApi.updateAccessMetadata(client_id, updateRequest).then((updatedMeta) => {
      this.updateProgrammersModel({ accessMetadata: updatedMeta, isLoading: false });
    });
  });
}
