import type { FieldValues } from 'react-hook-form';
import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

import { DispensariesApi } from 'api';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';
import { OnboardingRepo } from '../../onboarding.repo';

export interface BusinessDetailsProgrammersModel {}

@injectable()
export class BusinessDetailsRepo {
  @inject(OnboardingRepo)
  public repo: OnboardingRepo;

  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(CrbDispensaryStore)
  public dispensaryStore: CrbDispensaryStore;

  @inject(DispensariesApi)
  public dispensariesApi: DispensariesApi;

  constructor() {
    makeAutoObservable(this);
  }

  updateBusinessDetails = async (data: FieldValues, onNext: () => void) => {
    if (data.sameMailingAddress) {
      data.mailingCity = data.city;
      data.mailingPostalCode = data.postalCode;
      data.mailingState = data.state;
      data.mailingStreetAddress = data.streetAddress;
    }

    await this.dispensariesApi
      .updateDispensaryBusinessDetails(this.dispensaryStore.currentDispensary.id, data)
      .then((updatedDispensary) => {
        this.dispensaryStore.updateDispensary(updatedDispensary);
        onNext();
        this.snackbarStore.showSnackbar(
          SnackbarSeverity.Success,
          'Business details have been updated successfully.'
        );
      })
      .catch(() => {
        this.snackbarStore.showSnackbar(
          SnackbarSeverity.Error,
          'There was an issue updating business details'
        );
      });
  };

  autoSaveBusinessDetails = async (data: FieldValues) => {
    try {
      this.repo.updateProgrammersModel({ isAutoSaving: true });
      if (data.sameMailingAddress) {
        data.mailingCity = data.city;
        data.mailingPostalCode = data.postalCode;
        data.mailingState = data.state;
        data.mailingStreetAddress = data.streetAddress;
      }

      const dispensary = await this.dispensariesApi.updateDispensaryBusinessDetails(
        this.dispensaryStore.currentDispensary.id,
        data
      );
      this.dispensaryStore.updateDispensary(dispensary);
      this.repo.updateProgrammersModel({ isAutoSaving: false });
    } catch (error) {
      console.log('Issue auto saving business details', error);
    }
  };
}
