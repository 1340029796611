import type { MinifiedDispensary } from '@gcv/shared';
import { IANATimezones } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe, runInAction } from 'mobx';

import { BanksApi } from 'api';
import { USStateOptions } from 'domain/consts';
import { AppViewStateStore } from 'stores/AppViewStateStore';
import { FiBankStore } from 'stores/FiBankStore';
import { FiRelationshipManagerStore } from 'stores/FiRelationshipManagerStore';
import type { FilterListChild, FilterListItem } from 'ui/organisms';
import { getLicenseType } from 'util/org.util';
import type { AccountRow, PM } from './archived.repo';
import { FiAccountsArchivedRepo } from './archived.repo';

export interface VM {
  filterList: FilterListItem[];
  filterToggle: boolean;
  filteredArchivedAccountsList: AccountRow[];
  iana_timezone: IANATimezones;
  isLoading: boolean;
  searchTerm: string;
  selectedFilters: FilterListChild[];
}

@injectable()
export class FiAccountsArchivedPresenter {
  @inject(FiAccountsArchivedRepo)
  private repo: FiAccountsArchivedRepo;

  @inject(BanksApi)
  private banksApi: BanksApi;

  @inject(FiBankStore)
  private bankStore: FiBankStore;

  @inject(FiRelationshipManagerStore)
  private bankUserStore: FiRelationshipManagerStore;

  @inject(AppViewStateStore)
  private appViewStateStore: AppViewStateStore;

  constructor() {
    makeAutoObservable(this);
  }

  public viewModel: VM = {
    filterList: [],
    filterToggle: false,
    filteredArchivedAccountsList: [],
    iana_timezone: IANATimezones.America_NewYork,
    isLoading: true,
    searchTerm: '',
    selectedFilters: []
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  private filterDispensaries = action(async (filterTerms: FilterListChild[]) => {
    if (!filterTerms.length) {
      this.updateViewModel({ filteredArchivedAccountsList: this.repo.programmersModel.archivedAccountsList });
      return;
    }

    const newFilterTerms: { [T: string]: string[] } = {};
    filterTerms.forEach((term) => {
      term.parentValue = term.parentValue ? term.parentValue : 'unknown';
      newFilterTerms[term.parentValue] = newFilterTerms[term.parentValue]
        ? [...newFilterTerms[term.parentValue], term.value]
        : [term.value];
    });

    const dedupedFilterTerms: { [T: string]: Set<string> } = {};
    Object.keys(newFilterTerms).forEach((filterTerm) => {
      dedupedFilterTerms[filterTerm] = new Set(newFilterTerms[filterTerm]);
    });

    const filterFn = (dispensary: AccountRow) => {
      let stateFiltered = false;
      let licenseFiltered = false;
      let muoFiltered = false;
      let typeFiltered = false;
      let reasonFiltered = false;
      let relationshipManagerFiltered = false;

      if (dedupedFilterTerms['relationship_manager']) {
        const relationshipManager = this.bankUserStore.relationshipManagers[dispensary.id];

        if (relationshipManager) {
          relationshipManagerFiltered = dedupedFilterTerms['relationship_manager'].has(relationshipManager);
        } else if (dedupedFilterTerms['relationship_manager'].has('--')) {
          relationshipManagerFiltered = true;
        }
      } else {
        relationshipManagerFiltered = true;
      }

      if (dedupedFilterTerms['parent_account']) {
        if (dispensary.orgType === 'dispensary' && dispensary.muo_id) {
          muoFiltered = dedupedFilterTerms['parent_account'].has(dispensary.muo_id);
        }
      } else {
        muoFiltered = true;
      }

      if (dedupedFilterTerms['type_of_account']) {
        const isMuo = dedupedFilterTerms['type_of_account'].has('muo');
        const isDispensary = dedupedFilterTerms['type_of_account'].has('dispensary_location');

        if (isMuo && dispensary.orgType === 'muo') {
          typeFiltered = dispensary.orgType === 'muo';
        }
        if (isDispensary && dispensary.orgType === 'dispensary' && dispensary.muo_id) {
          typeFiltered = dispensary.orgType === 'dispensary' && dispensary.muo_id != undefined;
        }
      } else {
        typeFiltered = true;
      }

      if (dedupedFilterTerms['state'] && dispensary.state) {
        stateFiltered = dedupedFilterTerms['state'].has(dispensary.state);
      } else {
        stateFiltered = true;
      }

      if (dispensary.orgType === 'dispensary' && dedupedFilterTerms['license_type']) {
        licenseFiltered = dedupedFilterTerms['license_type'].has(
          getLicenseType(dispensary as MinifiedDispensary)
        );
      } else if (dispensary.orgType === 'muo' && dedupedFilterTerms['license_type']) {
        if (dedupedFilterTerms['license_type']?.has('--')) {
          licenseFiltered = true;
        } else {
          licenseFiltered = false;
        }
      } else {
        licenseFiltered = true;
      }

      if (dedupedFilterTerms['reason']) {
        reasonFiltered = dedupedFilterTerms['reason'].has(
          dispensary.assigned_onboarding_template.archived_meta_data?.archived_reason ?? ''
        );
      } else {
        reasonFiltered = true;
      }

      return (
        stateFiltered &&
        licenseFiltered &&
        muoFiltered &&
        typeFiltered &&
        reasonFiltered &&
        relationshipManagerFiltered
      );
    };

    runInAction(() => {
      this.updateViewModel({
        filteredArchivedAccountsList: this.repo.programmersModel.archivedAccountsList.filter(filterFn)
      });
    });
  });

  private getInitializedFilters = () => {
    const filters = [
      {
        label: 'Type of Account',
        value: 'type_of_account',
        children: [
          {
            label: 'Parent Account',
            value: 'muo',
            selected:
              this.appViewStateStore.archivedAccountRelationshipManagerFilters.selectedAccountFilters.some(
                (selected) => selected.value === 'muo'
              )
          },
          {
            label: 'Child Location',
            value: 'dispensary_location',
            selected:
              this.appViewStateStore.archivedAccountRelationshipManagerFilters.selectedAccountFilters.some(
                (selected) => selected.value === 'dispensary_location'
              )
          }
        ].sort((a, b) => a.label.localeCompare(b.label))
      }
    ];

    if (this.repo.dispensaryParentAccounts.length > 0) {
      filters.push({
        label: 'Parent Account',
        value: 'parent_account',
        children: this.repo.dispensaryParentAccounts
          .map((disp) => {
            return {
              label: disp.label,
              value: disp.value,
              selected:
                this.appViewStateStore.archivedAccountRelationshipManagerFilters.selectedAccountFilters.some(
                  (selected) => selected.value === disp.value
                )
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      });
    }

    const relationshipManagers = Object.values(this.bankUserStore.relationshipManagers);

    filters.push({
      label: 'Relationship Manager',
      value: 'relationship_manager',
      children: [
        {
          label: 'None',
          value: '--',
          selected:
            this.appViewStateStore.archivedAccountRelationshipManagerFilters.selectedAccountFilters.some(
              (selected) => selected.value === '--'
            )
        },
        ...relationshipManagers
          .filter((value, index) => relationshipManagers.indexOf(value) === index)
          .filter((value) => value !== '--')
          .map((rm) => {
            return {
              label: rm,
              value: rm,
              selected:
                this.appViewStateStore.archivedAccountRelationshipManagerFilters.selectedAccountFilters.some(
                  (selected) => selected.value === rm
                )
            };
          })
      ]
    });

    filters.push({
      label: 'License Type',
      value: 'license_type',
      children: this.repo.dispensaryLicenseTypes.map((licenseType) => {
        return {
          label: licenseType.label,
          value: licenseType.value,
          selected:
            this.appViewStateStore.archivedAccountRelationshipManagerFilters.selectedAccountFilters.some(
              (selected) => selected.value === licenseType.value
            )
        };
      })
    });

    filters.push({
      label: 'State',
      value: 'state',
      children: JSON.parse(
        JSON.stringify(
          USStateOptions.map((state) => {
            return {
              label: state.label,
              value: state.value,
              selected:
                this.appViewStateStore.archivedAccountRelationshipManagerFilters.selectedAccountFilters.some(
                  (selected) => selected.value === state.value
                )
            };
          }).filter((state) =>
            this.repo.programmersModel.archivedAccountsList.map((item) => item.state).includes(state.value)
          )
        )
      )
    });

    filters.push({
      label: 'Reason for Archiving',
      value: 'reason',
      children: [
        { label: 'Not a fit for program', value: 'Not a fit for program' },
        { label: 'Risk or compliance concerns', value: 'Risk or compliance concerns' },
        { label: 'Account no longer needed', value: 'Account no longer needed' },
        { label: 'Account opted-out', value: 'Account opted-out' },
        { label: 'Other', value: 'Other' }
      ].map((reason) => {
        return {
          label: reason.label,
          value: reason.value,
          selected:
            this.appViewStateStore.archivedAccountRelationshipManagerFilters.selectedAccountFilters.some(
              (selected) => selected.value === reason.value
            )
        };
      })
    });

    return filters;
  };

  public load = action(async () => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      this.updateViewModel({ isLoading: programmersModel.isLoading });
    });

    await this.repo.load();
    const relationshipManagers: Record<string, string> = this.bankUserStore.relationshipManagers;

    const selectedFilters =
      this.appViewStateStore.archivedAccountRelationshipManagerFilters.selectedAccountFilters;

    this.updateViewModel({
      filteredArchivedAccountsList: this.repo.programmersModel.archivedAccountsList,
      filterList: this.getInitializedFilters(),
      iana_timezone: this.repo.programmersModel.bank?.iana_timezone,
      selectedFilters: selectedFilters
    });
    this.filterDispensaries(selectedFilters);
  });

  public setSearchTerms = action((value: string) => {
    const filteredList = this.repo.programmersModel.archivedAccountsList.filter((a) =>
      a.name.toLowerCase().includes(value.toLowerCase())
    );

    this.updateViewModel({ filteredArchivedAccountsList: filteredList, searchTerm: value });
  });

  public toggleFilter = action(() => {
    this.updateViewModel({ filterToggle: !this.viewModel.filterToggle });
  });

  public onApply = action((selected: FilterListChild[]) => {
    this.toggleFilter();
    this.updateViewModel({ selectedFilters: selected });
    this.filterDispensaries(selected);
    this.appViewStateStore.archivedAccountRelationshipManagerFilters.selectedAccountFilters = selected;
  });
}
