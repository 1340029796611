import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, IconButton, Popover, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as DropdownArrow } from 'assets/images/drop-down-arrow.svg';
import { NAV_DRAWER_WIDTH } from 'domain/consts/ui';
import { useComponent } from 'hooks';
import { getFiBankStore } from 'stores/FiBankStore';
import { getUserStore } from 'stores/UserStore';
import { AppBar, Button, Container, ContainerItem, Divider, Icon, Text } from 'ui';
import { NotificationsDrawer } from 'ui/systems';

interface Props extends Record<string, unknown> {
  toggleNavDrawer: () => void;
}

export const GcvAppBar: React.FC<Props> = useComponent((props) => {
  const navigate = useNavigate();
  const navHeight = '74px';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const bankStore = getFiBankStore();
  const userStore = getUserStore();

  const [notificationDrawerOpen, setNotificationDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const logout = () => {
    navigate('/logout', { replace: true });
  };

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <AppBar>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          height: navHeight,
          justifyContent: 'flex-start',
          ml: 4,
          width: isMobile ? 'auto' : NAV_DRAWER_WIDTH
        }}
      >
        {isMobile ? (
          <IconButton
            disableRipple
            color="inherit"
            onClick={() => props.toggleNavDrawer()}
            size="large"
            sx={{ mx: 2 }}
          >
            <Icon dataCy="mobile-menu-icon" type="menu" />
          </IconButton>
        ) : (
          <Link to="/secure/fi/dashboard">
            <img src="/img/logo-2.svg" alt="Green Check Verified" height={'22px'} width={'168px'} />
          </Link>
        )}
      </Box>

      <Divider vertical />

      <Container align="center" dataCy="mui-dropdown-container" onClick={handlePopoverClick} width="275px">
        <ContainerItem grow>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              padding: '1rem',
              cursor: 'pointer'
            }}
          >
            <Text
              inline
              content={`${userStore.user.firstName} ${userStore.user.lastName}`}
              dataCy="app-bar-user-name"
            />
          </Box>
        </ContainerItem>
        <ContainerItem>
          <DropdownArrow />
        </ContainerItem>
      </Container>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Container column dataCy="gcv-logout-container" width="358px">
          <ContainerItem>{userStore.user.email}</ContainerItem>
          <ContainerItem dataCy="logout-link" width="100%">
            <Button fullWidth color="default" label="Log Out" onClick={() => logout()} variant="text" />
          </ContainerItem>
        </Container>
      </Popover>

      <NotificationsDrawer
        drawerOpen={notificationDrawerOpen}
        setDrawerOpen={setNotificationDrawerOpen}
        org={bankStore.bank}
      />
    </AppBar>
  );
});
