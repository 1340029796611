import * as React from 'react';
import type { OnboardingDocumentRequirement, OnboardingDocumentRequirementResult } from '@gcv/shared';
import { Box } from '@mui/system';

import { ReactComponent as CommentsIcon } from 'assets/images/ic-comments-thin.svg';
import { ReactComponent as EditIcon } from 'assets/images/ic-pencil.svg';
import { ReactComponent as TrashIcon } from 'assets/images/ic-trash.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { Container, ContainerItem, Text } from 'ui';
import { OnboardingPresenter } from 'ui/apps/crb/onboarding-two/onboarding.presenter';
import { AddCommentModal } from './add-comment-modal';

export const CommentAddedBox: React.FC<{
  requirement: OnboardingDocumentRequirement;
  result?: OnboardingDocumentRequirementResult;
}> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const [addCommentModalOpen, setAddCommentModalOpen] = React.useState(false);

  const removeComment = () => {
    presenter.updateDoNotHaveCommentOnRequirement(props.requirement.requirement_id, false, '');
  };

  return (
    <>
      <div
        style={{
          border: '1px solid #C6CCDA',
          borderRadius: '6px',
          maxWidth: '400px',
          minWidth: '250px'
        }}
      >
        <Container align="center" justify="center" padding="12px 22px" nowrap minWidth="200px">
          <ContainerItem flex dataCy="comment-added-container" padding="0 4px 0 0">
            <CommentsIcon data-cy="comment-icon" />
          </ContainerItem>
          <ContainerItem padding="0 12px 0 0">
            <Text content="Reason added." type="body3" />
          </ContainerItem>
          <ContainerItem flex padding="0 5px 0 0">
            <Box
              sx={{
                cursor: 'pointer',
                '& svg path': { fill: '#C6CCDA' },
                ':hover svg path': { fill: '#3A3C45' }
              }}
            >
              <EditIcon onClick={() => setAddCommentModalOpen(true)} />
            </Box>
          </ContainerItem>
          <ContainerItem flex dataCy="remove-comment-container" padding="0">
            <Box
              sx={{
                cursor: 'pointer',
                '& svg path': { fill: '#C6CCDA' },
                ':hover svg path': { fill: '#3A3C45' }
              }}
            >
              <TrashIcon data-cy="remove-comment-icon" onClick={() => removeComment()} />
            </Box>
          </ContainerItem>
        </Container>
      </div>
      <AddCommentModal
        requirement={props.requirement}
        result={props.result}
        title="Edit Comment"
        addCommentModalOpen={addCommentModalOpen}
        onClose={() => setAddCommentModalOpen(false)}
      />
    </>
  );
});
