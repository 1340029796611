import type {
  CustomRequirementResult,
  DocumentUpload,
  QuestionnaireResponse,
  QuestionnaireResultResponse
} from '@gcv/shared';
import { QuestionnaireStatus } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

import { SnackbarStore } from 'stores/SnackBarStore';
import { DocumentsApi, QuestionnairesApi } from '../../../../../../api';
import { CrbBankStore } from '../../../../../../stores/CrbBankStore';
import { CrbDispensaryStore } from '../../../../../../stores/CrbDispensaryStore';
import { AdditionalInfoUtil } from '../../../../../../util/additional-info.util';
import { getObjectMap } from '../../../../../../util/objectUtils';
import { hasDispensaryFulfilledAllAddtionalRequirements } from '../../../../../../util/org.util';

export interface CrbEditViewQuestionnaireProgrammersModel {
  isLoading: boolean;
  isSaving: boolean;
  questionnaire: QuestionnaireResponse;
  questionnaireResult: QuestionnaireResultResponse;
  allDocumentsMap: { [id: string]: DocumentUpload };
}

@injectable()
export class CrbEditViewQuestionnaireRepo {
  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(CrbDispensaryStore)
  public dispensaryStore: CrbDispensaryStore;

  @inject(CrbBankStore)
  public crbBankStore: CrbBankStore;

  @inject(DocumentsApi)
  public documentsApi: DocumentsApi;

  @inject(QuestionnairesApi)
  public questionnaireApi: QuestionnairesApi;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: CrbEditViewQuestionnaireProgrammersModel = {
    isLoading: true,
    isSaving: false,
    questionnaire: {} as QuestionnaireResponse,
    questionnaireResult: {} as QuestionnaireResultResponse,
    allDocumentsMap: {}
  };

  updateProgrammersModel = action((programmersModel: Partial<CrbEditViewQuestionnaireProgrammersModel>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = async (questionnaireId: string, questionnaireResultId: string) => {
    try {
      this.updateProgrammersModel({ isLoading: true });
      let allDocumentsMap = {};
      const documentsPromise = this.documentsApi
        .getDocuments(this.dispensaryStore.currentDispensary.id)
        .then((documents) => {
          allDocumentsMap = getObjectMap(documents, 'id');
        });

      const questionnaireResult = await this.questionnaireApi.getCRBQuestionnaireResponse(
        this.dispensaryStore.currentDispensary.id,
        questionnaireId,
        questionnaireResultId
      );
      const questionnaire = await this.questionnaireApi.getQuestionnaire(
        questionnaireResult.bank_id,
        questionnaireId,
        questionnaireResult.template_version
      );
      await documentsPromise;
      this.updateProgrammersModel({ questionnaire, questionnaireResult, allDocumentsMap });
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('There was a problem loading the questionnaire');
    } finally {
      this.updateProgrammersModel({ isLoading: false });
    }
  };

  saveQuestionnaireSection = async (section: {
    customFieldValues: Record<string, any>;
    sectionId: string;
  }) => {
    this.updateProgrammersModel({ isSaving: true });
    const additionalInfoUtil = new AdditionalInfoUtil(
      this.programmersModel.questionnaire,
      this.dispensaryStore.currentDispensary.id,
      false
    );
    // TODO this additional info util can likely be simplified/deprecated. Just call the API
    const updatedSection = await additionalInfoUtil.saveQuestionnaireSection(
      section.customFieldValues,
      this.programmersModel.questionnaireResult.id,
      section.sectionId
    );
    if (updatedSection) {
      await this.updateCustomRequirementResults(updatedSection);
    }
    this.updateProgrammersModel({ isSaving: false });
  };

  updateCustomRequirementResults = action(async (requirementResult: CustomRequirementResult) => {
    const existingRequirement = this.programmersModel.questionnaireResult.custom_requirement_result.find(
      (r) => r.requirement_id === requirementResult.requirement_id
    );
    if (existingRequirement) {
      const index =
        this.programmersModel.questionnaireResult.custom_requirement_result.indexOf(existingRequirement);
      this.programmersModel.questionnaireResult.custom_requirement_result.splice(index, 1, requirementResult);
    } else {
      this.programmersModel.questionnaireResult.custom_requirement_result.push(requirementResult);
    }
    this.programmersModel.questionnaireResult.date_updated =
      requirementResult.custom_fields.last_updated_date;
    this.programmersModel.questionnaireResult.custom_requirement_modified_by =
      requirementResult.custom_fields.last_updated_by;

    // if the questionnaire was open and we updated it, we can set to In Progress
    if (this.programmersModel.questionnaireResult.status === QuestionnaireStatus.Open) {
      this.programmersModel.questionnaireResult.status = QuestionnaireStatus.InProgress;
    }

    // if we've fulfilled all required fields, can set status to ready to complete
    const isReadyToComplete = hasDispensaryFulfilledAllAddtionalRequirements(
      this.programmersModel.questionnaire,
      this.programmersModel.questionnaireResult
    );

    // update to InProgress or ReadyToComplete based on current status and custom field responses
    if (
      (isReadyToComplete &&
        this.programmersModel.questionnaireResult.status === QuestionnaireStatus.InProgress) ||
      (!isReadyToComplete &&
        this.programmersModel.questionnaireResult.status === QuestionnaireStatus.ReadyToComplete)
    ) {
      this.programmersModel.questionnaireResult =
        await this.questionnaireApi.markQuestionnaireAsReadyToComplete(
          isReadyToComplete,
          this.programmersModel.questionnaire.template_id,
          this.programmersModel.questionnaireResult.id,
          this.programmersModel.questionnaireResult.crb_id,
          this.programmersModel.questionnaireResult.bank_id
        );
    }

    this.updateProgrammersModel({ questionnaireResult: this.programmersModel.questionnaireResult });
  });

  completeQuestionnaire = action(async () => {
    this.updateProgrammersModel({ isSaving: true });
    const updatedResults = await this.questionnaireApi.completeQuestionnaireResultForCRB(
      this.programmersModel.questionnaire.template_id,
      this.programmersModel.questionnaireResult.id,
      this.dispensaryStore.currentDispensary.id,
      this.programmersModel.questionnaire.bank_id
    );
    this.updateProgrammersModel({ isSaving: false, questionnaireResult: updatedResults });
  });

  addNewDocuments = action((docs: DocumentUpload[]) => {
    for (const doc of docs) {
      this.programmersModel.allDocumentsMap[doc.id] = doc;
    }
    this.updateProgrammersModel({ allDocumentsMap: this.programmersModel.allDocumentsMap });
  });
}
