import * as React from 'react';
import { Box } from '@mui/system';

import type { StepperStep } from 'ui';
import { Stepper } from 'ui';

interface Props {
  steps: StepperStep[];
  handleStep: (step: number) => void;
  disableStepperButton?: boolean;
}

export const UnorderedStepper: React.FC<Props> = (props) => {
  return (
    <Box
      sx={{
        marginTop: '25px',
        '& .MuiCollapse-root': {
          marginBottom: '0px',
          paddingLeft: '19px',
          position: 'relative',
          top: '-80px'
        },
        '& .MuiStep-root:first-child': {
          '& .MuiCollapse-root': {
            paddingLeft: '19px'
          }
        },
        '& .MuiStepContent-root': {
          marginBottom: '-41px !important',
          paddingRight: '0'
        },
        '& .MuiStepContent-last ': {
          height: '0px'
        },
        '& .card-header > div': {
          paddingLeft: '36px',
          paddingRight: '36px'
        },
        '& .MuiStep-root.MuiStep-vertical:last-child .MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiCard-root':
          {
            marginBottom: '41px'
          }
      }}
    >
      <Stepper
        steps={props.steps}
        orientation={'vertical'}
        handleStep={props.handleStep}
        disableStepperButton={props.disableStepperButton}
      />
    </Box>
  );
};
