import * as React from 'react';
import { Popover } from '@mui/material';

import { Button, Container, ContainerItem } from 'ui';

interface Props {
  buttons: {
    label: string;
    onClick: (event: any) => void;
    dataCy?: string;
    disabled?: boolean;
    toolTip?: string;
  }[];
  label: string;
  accordionOpen?: boolean;
}

export const DropButton: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.accordionOpen) {
      event.stopPropagation();
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.accordionOpen) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="primary-outlined"
        label={props.label}
        endIcon="expand_more"
        onClick={handlePopoverClick}
        height="36px"
        width="125px"
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Container column padding={0}>
          {props.buttons.map((btn) => {
            return (
              <ContainerItem justify="center" width="100%" key={btn.label}>
                <Button
                  fullWidth
                  color="primary-text"
                  variant="text"
                  label={btn.label}
                  onClick={btn.onClick}
                />
              </ContainerItem>
            );
          })}
        </Container>
      </Popover>
    </>
  );
};
