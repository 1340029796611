import * as React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { debounce, useMediaQuery, useTheme } from '@mui/material';

import { useComponent } from 'hooks/useComponent';
import { useInjection } from 'ioc';
import { Button, Container, ContainerItem, OnBoardingPage } from 'ui';
import { BusinessDetailsPresenter } from './business-details-presenter';
import { AddressCard } from './components/address-card';
import { BusinessDetailsCard } from './components/business-details-card';
import { ContactCard } from './components/contact-card';

interface Props extends Record<string, unknown> {
  onNext: () => void;
  onBack: () => void;
  isLastStep: boolean;
  stepNumber: number;
  totalSteps: number;
}

const BusinessDetails: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(BusinessDetailsPresenter);
  const theme = useTheme();
  const { bank_id } = useParams() as { bank_id: string };
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    presenter.load(bank_id);
  }, []);

  const onChangeSave = React.useCallback(
    debounce(() => {
      presenter.autoSaveBusinessDetails(form.getValues());
    }, 2000),
    []
  );

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: presenter.crbDispensaryStore.currentDispensary.name,
      dba: presenter.crbDispensaryStore.currentDispensary.dba,
      entityType: presenter.crbDispensaryStore.currentDispensary.entityType,
      establishedDate: presenter.crbDispensaryStore.currentDispensary.establishedDate,
      iana_timezone: presenter.crbDispensaryStore.currentDispensary.iana_timezone,
      ein: presenter.crbDispensaryStore.currentDispensary.ein,
      streetAddress: presenter.crbDispensaryStore.currentDispensary.streetAddress,
      city: presenter.crbDispensaryStore.currentDispensary.city,
      state: presenter.crbDispensaryStore.currentDispensary.state,
      postalCode: presenter.crbDispensaryStore.currentDispensary.postalCode,
      mailingStreetAddress: presenter.crbDispensaryStore.currentDispensary.mailingStreetAddress,
      mailingCity: presenter.crbDispensaryStore.currentDispensary.mailingCity,
      mailingState: presenter.crbDispensaryStore.currentDispensary.mailingState,
      mailingPostalCode: presenter.crbDispensaryStore.currentDispensary.mailingPostalCode,
      phone: presenter.crbDispensaryStore.currentDispensary.phone,
      website: presenter.crbDispensaryStore.currentDispensary.website,
      sameMailingAddress:
        !presenter.crbDispensaryStore.currentDispensary.mailingStreetAddress ||
        presenter.crbDispensaryStore.currentDispensary.streetAddress ===
          presenter.crbDispensaryStore.currentDispensary.mailingStreetAddress
    } as FieldValues
  });

  return (
    <OnBoardingPage
      alignBottomActions="center"
      title="Business Details"
      subtitle={`${presenter.viewModel.serviceProviderName} will use this information to confirm details about your business.`}
      step={props.stepNumber}
      totalSteps={props.totalSteps}
      bgCard
      bottomActions={
        <Container padding={0}>
          <ContainerItem>
            <Button color="default-outlined" label="Back" onClick={props.onBack} dataCy="back-button" />
          </ContainerItem>
          <ContainerItem>
            <Button
              label={props.isLastStep ? 'Submit' : 'Continue'}
              color="primary"
              onClick={form.handleSubmit((data) => presenter.submitBusinessDetails(data, props.onNext))}
              dataCy="save-button"
              isLoading={presenter.viewModel.isSaving}
            />
          </ContainerItem>
        </Container>
      }
      dataCy="business-details-page"
    >
      <Container padding="0">
        <ContainerItem padding="0" width="100%">
          <BusinessDetailsCard form={form} isMobile={isMobile} onChange={onChangeSave} />
        </ContainerItem>

        <ContainerItem padding="0" width="100%">
          <AddressCard form={form} isMobile={isMobile} onChange={onChangeSave} />
        </ContainerItem>

        <ContainerItem padding="0" width="100%">
          <ContactCard form={form} isMobile={isMobile} onChange={onChangeSave} />
        </ContainerItem>
      </Container>
    </OnBoardingPage>
  );
});
export default BusinessDetails;
