import * as React from 'react';
import type { Theme } from '@mui/material';
import type { SxProps } from '@mui/system';
import { Box } from '@mui/system';

import { theme } from 'ui/theme';

interface Props {
  label: string | React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => any;
  url?: string;
  dataCy?: string;
  newTab?: boolean;
  color?: 'link' | 'subtle' | 'green';
  style?: React.CSSProperties;
  containerSx?: SxProps<Theme>;
}

export const Hyperlink: React.FC<Props> = (props) => {
  const color =
    props.color === 'subtle' ? '#3A3C45' : props.color === 'green' ? theme.palette.primary.main : '#1270FC';
  const prefix =
    props.url?.indexOf('http') === -1 &&
    props.url?.indexOf('mailto:') === -1 &&
    props.url?.indexOf('tel:') === -1
      ? '//'
      : '';

  let containerStyles = {
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
        color: '#1270FC'
      }
    }
  };

  if (props.containerSx) {
    containerStyles = {
      ...containerStyles,
      ...props.containerSx
    };
  }

  return (
    <Box component={'span'} sx={containerStyles}>
      <a
        href={props.url ? prefix + props.url : undefined}
        data-cy={props.dataCy}
        target={props.newTab ? '_blank' : ''}
        onClick={(e) => props.onClick && props.onClick(e)}
        style={{ ...props.style, minHeight: '1px', minWidth: '1px' }}
        rel={props.url?.includes('mailto:') ? 'noopener noreferrer' : undefined}
      >
        {props.label}
      </a>
    </Box>
  );
};
