import { injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

import type { FilterSelectConfig, FilterSelectOption } from './filter-select.organism.model';

interface VM {
  options: FilterSelectOption[];
  onApply?: (options: FilterSelectOption[], all: string[], added: string[], removed: string[]) => void;
  onCancel?: () => void;
  onSelect?: (options: FilterSelectOption[], all: string[], added: string[], removed: string[]) => void;
  config: FilterSelectConfig;
  isLoading: boolean;
  search: string;
  showHidden: boolean;
  selected: {
    added: string[];
    removed: string[];
    all: string[];
  };
}

@injectable()
export class FilterSelectPresenter {
  constructor() {
    makeAutoObservable(this);
  }

  public defaultOptions: FilterSelectOption[] = [];

  public viewModel: VM = {
    options: [],
    onApply: undefined,
    onCancel: undefined,
    onSelect: undefined,
    config: {
      clearChildren: {
        isHidden: true
      },
      count: {
        isHidden: true
      },
      emitExcludeParent: false,
      emitAllOnEmpty: false,
      enforceSingleSelect: false,
      search: {
        isHidden: true,
        label: ''
      },
      selectAllClearAll: {
        isHidden: true,
        position: 'bottom'
      },
      showSelectedOnly: {
        isHidden: true,
        tooltip: ''
      },
      showHidden: {
        isHidden: true,
        label: ''
      },
      title: '',
      type: 'fixed'
    },
    isLoading: true,
    search: '',
    showHidden: false,
    selected: {
      added: [],
      removed: [],
      all: []
    }
  };

  public get selectedCount(): number {
    const count = this.defaultOptions.reduce((parentCount, parent) => {
      if (parent.selected) {
        if (this.viewModel.config.emitExcludeParent && parent.children && parent.children.length > 0) {
          // noop
        } else {
          parentCount += 1;
        }
      }

      if (parent.children && parent.children.length > 0) {
        parentCount += parent.children.reduce((childCount, child) => {
          if (child.selected) {
            childCount += 1;
          }

          return childCount;
        }, 0);
      }

      return parentCount;
    }, 0);

    return count;
  }

  private addSelection = (value: string) => {
    // place in added array
    if (!this.viewModel.selected.added.includes(value)) {
      this.viewModel.selected.added.push(value);
    }

    // place in all array
    if (!this.viewModel.selected.all.includes(value)) {
      this.viewModel.selected.all.push(value);
    }

    // remove from the opposite array if needed
    if (this.viewModel.selected.removed.includes(value)) {
      this.viewModel.selected.removed = this.viewModel.selected.removed.filter(
        (removedOption) => removedOption !== value
      );
    }
  };

  private emitData = () => {
    const isEmptySelection = this.selectedCount === 0;

    if (isEmptySelection && this.viewModel.config.emitAllOnEmpty) {
      this.updateViewModel({ options: [...this.defaultOptions] });

      this.viewModel.selected.all = this.viewModel.options
        .filter((o) => !o.isHidden)
        .reduce((acc, curr) => {
          acc = [...acc, curr.value];

          if (curr.children && curr.children.length > 0) {
            acc = [
              ...acc,
              ...curr.children.map((c) => {
                return c.value;
              })
            ];
          }

          return acc;
        }, [] as string[]);
    }

    if (this.viewModel.onSelect) {
      this.viewModel.onSelect(
        this.viewModel.options,
        this.viewModel.selected.all,
        this.viewModel.selected.added,
        this.viewModel.selected.removed
      );
    }
  };

  private filterHiddenOptions = (options: FilterSelectOption[]) => {
    return options
      .filter((option) => !option.isHidden)
      .map((option) => {
        return {
          children: option.children?.filter((c) => !c.isHidden),
          icon: option.icon,
          label: option.label,
          selected: option.selected,
          value: option.value
        };
      });
  };

  private removeSelection = (value: string) => {
    // place in removed array
    if (!this.viewModel.selected.removed.includes(value)) {
      this.viewModel.selected.removed.push(value);
    }

    // remove from all array
    const allIndex = this.viewModel.selected.all.findIndex((o) => o === value);
    if (allIndex > -1) {
      this.viewModel.selected.all.splice(allIndex, 1);
    }

    // remove from the opposite array if needed
    if (this.viewModel.selected.added.includes(value)) {
      this.viewModel.selected.added = this.viewModel.selected.added.filter(
        (addedOption) => addedOption !== value
      );
    }
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  public load = action(
    (
      options: FilterSelectOption[],
      onSelect?: (options: FilterSelectOption[], all: string[], added: string[], removed: string[]) => void,
      config?: Partial<FilterSelectConfig>
    ) => {
      this.defaultOptions = [...options];
      this.viewModel.search = '';

      const defaultSelected: string[] = [];

      options.forEach((opt) => {
        if (opt.selected) {
          defaultSelected.push(opt.value);
        }

        if (opt.children && opt.children.length > 0) {
          const children = opt.children.filter((child) => child.selected).map((child) => child.value);
          defaultSelected.push(...children);
        }
      });

      this.updateViewModel({
        options: this.filterHiddenOptions(options),
        onSelect,
        config: { ...this.viewModel.config, ...config },
        isLoading: false,
        selected: {
          added: [],
          removed: [],
          all: defaultSelected
        }
      });

      if (this.viewModel.search) {
        this.search(this.viewModel.search);
      }
    }
  );

  public clearAll = () => {
    const options = [...this.viewModel.options];

    options.forEach((option) => {
      this.select(option.value, false);
    });
  };

  public clearChildren = (parentValue: string) => {
    const options = [...this.defaultOptions];

    const parentOption = options.find((option) => option.value === parentValue);
    if (!parentOption) {
      return;
    } else {
      parentOption.selected = false;
    }

    if (parentOption.children && parentOption.children.length > 0) {
      parentOption.children.forEach((child) => {
        child.selected = false;
      });
    }

    this.updateViewModel({ options });
  };

  public search = (searchTerm: string) => {
    if (!searchTerm) {
      this.updateViewModel({ options: this.defaultOptions, search: searchTerm });
      return;
    }

    const results = this.defaultOptions.filter((option) => {
      if (option.isHidden) {
        return false;
      }

      let children: FilterSelectOption[] = [];

      if (option.children && option.children.length > 0) {
        children = option.children.filter((child) => {
          if (child.isHidden) {
            return false;
          }

          return child.label.toLowerCase().includes(searchTerm.toLowerCase());
        });
      }

      if (children.length > 0) {
        return true;
      }

      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });

    this.updateViewModel({ options: results, search: searchTerm });
  };

  // selecting or deselecting an option
  public select = (value: string, selected: boolean) => {
    const options = [...this.defaultOptions];
    const selectedParents = options.filter((o) => o.selected);
    const optIndex = options.findIndex((option) => option.value === value); // look for the option in the parent list

    if (optIndex < 0) {
      // look for the option in the child list if no parent found
      options.forEach((option) => {
        if (option.children && option.children.length > 0) {
          // select the child option and its parent
          option.children.forEach((child) => {
            if (child.value === value) {
              if (
                this.viewModel.config.enforceSingleSelect &&
                selectedParents.length > 0 &&
                selectedParents.findIndex((p) => p.value === option.value) === -1
              ) {
                return;
              }

              child.selected = selected;

              // select the parent if the child is checked
              // track the selection changes in the added and removed arrays
              if (child.selected) {
                option.selected = true;

                if (!this.viewModel.config.emitExcludeParent) {
                  this.addSelection(option.value);
                }

                this.addSelection(child.value);
              } else {
                this.removeSelection(child.value);

                if (option.children && option.children.filter((c) => c.selected).length === 0) {
                  option.selected = false;
                  this.removeSelection(option.value);
                }
              }
            }
          });
        }
      });
    } else {
      if (selected && this.viewModel.config.enforceSingleSelect && selectedParents.length > 0) {
        return;
      }

      const opt = options[optIndex];
      opt.selected = selected;

      // track the selection changes in the added and removed arrays
      if (opt.selected) {
        if (this.viewModel.config.emitExcludeParent && opt.children && opt.children.length > 0) {
          // noop
        } else {
          this.addSelection(opt.value);
        }
      } else {
        this.removeSelection(opt.value);
      }

      // select all children if the parent is selected
      if (opt.children && opt.children.length > 0) {
        if (opt.selected) {
          opt.children.forEach((child) => {
            child.selected = true;
            this.addSelection(child.value);
          });
        } else {
          opt.children.forEach((child) => {
            child.selected = false;
            this.removeSelection(child.value);
          });
        }
      }
    }

    this.updateViewModel({ options });
    this.emitData();

    // re-apply the search after the selection has been made on the default list
    if (this.viewModel.search) {
      this.search(this.viewModel.search);
    }
  };

  public selectAll = () => {
    const options = [...this.viewModel.options];

    options.forEach((option) => {
      this.select(option.value, true);
    });
  };

  public showHidden = (show: boolean) => {
    const options = show ? [...this.defaultOptions] : this.filterHiddenOptions(this.defaultOptions);

    for (const option of options) {
      if (this.viewModel.selected.all.includes(option.value)) {
        option.selected = true;
      }
    }

    this.updateViewModel({ options, showHidden: show });
  };

  public showSelectedOnly = (checked: boolean) => {
    const options: FilterSelectOption[] = [];

    if (!checked) {
      const selectedOptions = this.defaultOptions.map((o) => ({
        ...o,
        selected: this.viewModel.selected.all.includes(o.value)
      }));

      const optionsList = this.viewModel.showHidden
        ? selectedOptions
        : this.filterHiddenOptions(selectedOptions);

      for (const option of optionsList) {
        if (this.viewModel.selected.all.includes(option.value)) {
          option.selected = true;
        }

        if (option.children && option.children.length > 0) {
          for (const child of option.children) {
            if (this.viewModel.selected.all.includes(child.value)) {
              child.selected = true;
            }
          }
        }
      }

      this.updateViewModel({ options: optionsList });
    } else {
      this.viewModel.options.forEach((option) => {
        if (option.selected) {
          options.push(option);
        }

        if (option.children && option.children.length > 0) {
          option.children.forEach((child) => {
            if (child.selected && !options.includes(option)) {
              options.push(option);
            }
          });
        }
      });

      this.updateViewModel({ options });
    }
  };
}
