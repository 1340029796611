import { TableBody as MaterialTableBody } from '@mui/material';

import type { Column, Row } from 'ui/organisms';
import { TableRow } from '../TableRow/table-row.molecule';

interface Props<T> {
  rows: Row<T>[];
  selectable: boolean;
  clickable: boolean;
  columns: Column<T>[];
  handleSelect: (event: React.MouseEvent<unknown>, id: string) => void;
  handleClick: (event: React.MouseEvent<unknown>, id: string, data: T) => void;
  handleRadio: (value: string) => void;
  selected: string[];
  page: number;
  rowsPerPage: number;
  singleSelectable: boolean;
  selectedRow: string;
}

export const TableBody = <T,>(props: Props<T>) => {
  const isSelected = (name: string) => props.selected.indexOf(name) !== -1;
  return (
    <MaterialTableBody>
      {props.rows
        .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
        .map((row: Row<T>, index: number) => {
          const isItemSelected = isSelected(row.id);

          return (
            <TableRow
              key={row.id}
              isRowSelected={isItemSelected}
              selectable={props.selectable}
              singleSelectable={props.singleSelectable}
              selected={props.selectedRow === row.id}
              clickable={props.clickable}
              row={row}
              columns={props.columns}
              handleCheckboxSelect={props.handleSelect}
              handleCellClick={props.handleClick}
              handleRadioSelect={props.handleRadio}
              isLastItem={
                index ===
                props.rows.slice(
                  props.page * props.rowsPerPage,
                  props.page * props.rowsPerPage + props.rowsPerPage
                ).length -
                  1
              }
            />
          );
        })}
    </MaterialTableBody>
  );
};
