import { Box } from '@mui/system';

import { Badge, ContainerItem } from 'ui/atoms';

interface TabProps {
  title: string;
  count?: number;
  icon?: React.ReactNode;
  countColor?: 'error' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
}

export const TabLabel: React.FC<TabProps> = (props) => {
  if (props.count !== undefined) {
    return (
      <ContainerItem dataCy="tab-label">
        {props.icon ? props.icon : null}
        {props.title}
        {props.count > 0 ? (
          <Box
            sx={{
              marginLeft: '13px',
              padding: props.count > 99 ? '0 6px 0 4px' : props.count > 9 ? '0 4px 0 0' : '0',
              display: 'inline-block',
              '& .MuiBadge-root': {
                marginTop: '-4px'
              }
            }}
          >
            <Badge
              dataCy="tab-counter"
              badgeContent={props.count as number}
              color={props.countColor || 'error'}
            ></Badge>
          </Box>
        ) : null}
      </ContainerItem>
    );
  }
  return (
    <ContainerItem flex align="center">
      {props.icon ? <Box sx={{ display: 'flex', mr: '6px' }}>{props.icon}</Box> : null}
      {props.title}
    </ContainerItem>
  );
};
