import type {
  CreateDocumentsRequest,
  CustomRequirementResult,
  DocumentUpload,
  DueDiligenceDocument,
  LicenseDocumentDataRequest,
  OnboardingDocumentRequirement,
  OnboardingDocumentRequirementResult,
  TemplateResponse,
  TemplateResultResponse
} from '@gcv/shared';
import FileSaver from 'file-saver';
import { inject, injectable } from 'inversify';
import JSZip from 'jszip';
import { action, makeAutoObservable } from 'mobx';

import { DispensariesApi, DocumentsApi, TemplateApi, UsersApi } from 'api';
import { environment } from 'environments/environment';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';
import { UserStore } from 'stores/UserStore';
import { AdditionalInfoUtil } from 'util/additional-info.util';
import { getMimeType, openFileInNewWindow } from 'util/files.util';
import { getObjectMap } from 'util/objectUtils';
import { uploadOrgDocumentToS3 } from 'util/s3.util';
import { CrbBankStore } from '../../../../stores/CrbBankStore';

export interface OnboardingProgrammersModel {
  loading: boolean;
  onboardingTemplate: TemplateResponse;
  onboardingResult: TemplateResultResponse;
  allDocumentsMap: { [id: string]: DocumentUpload };
  sharedRootDocs: {
    requirement: OnboardingDocumentRequirement;
    ddDocuments: DueDiligenceDocument[];
    documents: DocumentUpload[];
  }[];
  idFront: DocumentUpload | undefined;
  idBack: DocumentUpload | undefined;
  isAutoSaving: boolean;
}

@injectable()
export class OnboardingRepo {
  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(UserStore)
  public userStore: UserStore;

  @inject(CrbDispensaryStore)
  public dispensaryStore: CrbDispensaryStore;

  @inject(CrbBankStore)
  public crbBankStore: CrbBankStore;

  @inject(DispensariesApi)
  public dispensariesApi: DispensariesApi;

  @inject(DocumentsApi)
  public documentsApi: DocumentsApi;

  @inject(UsersApi)
  public usersApi: UsersApi;

  @inject(TemplateApi)
  public templateApi: TemplateApi;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: OnboardingProgrammersModel = {
    loading: true,
    onboardingTemplate: {} as TemplateResponse,
    onboardingResult: {} as TemplateResultResponse,
    allDocumentsMap: {},
    idFront: undefined,
    idBack: undefined,
    sharedRootDocs: [],
    isAutoSaving: false
  };

  updateProgrammersModel = action((programmersModel: Partial<OnboardingProgrammersModel>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  loadOnboarding = async (templateId: string, templateResultId: string, bankId: string) => {
    try {
      const onboardingTemplateResultPromise = this.templateApi.getCrbTemplateResult(
        this.dispensaryStore.currentDispensary.id,
        templateId,
        templateResultId
      );
      const onboardingTemplatePromise = this.templateApi.getBankTemplate(
        bankId,
        templateId,
        this.crbBankStore.banks[bankId].templates.value.onboarding.template_version ?? 'latest'
      );
      const docsPromise = this.getUserDocs();
      const docMapPromise = this.getCrbDocuments();
      const sharedRootDocsPromise = this.getSharedDcouments(this.dispensaryStore.currentDispensary.id);
      const [onboardingTemplateResult, onboardingTemplate, docs, docMap, sharedRootDocs] = await Promise.all([
        onboardingTemplateResultPromise,
        onboardingTemplatePromise,
        docsPromise,
        docMapPromise,
        sharedRootDocsPromise
      ]);
      this.updateProgrammersModel({
        onboardingTemplate: onboardingTemplate,
        onboardingResult: onboardingTemplateResult,
        sharedRootDocs,
        idFront: docs.idFront,
        idBack: docs.idBack,
        allDocumentsMap: docMap,
        loading: false
      });
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('There was a problem loading application information');
    }
  };

  public getUserDocs = async (): Promise<{
    idFront: DocumentUpload | undefined;
    idBack: DocumentUpload | undefined;
  }> => {
    try {
      let idFront: DocumentUpload | undefined = undefined;
      if (this.userStore.user.identification?.idFront?.document_id) {
        idFront = await this.documentsApi.getUserDocument(
          this.userStore.user.id,
          this.userStore.user.identification.idFront.document_id
        );
      }
      let idBack: DocumentUpload | undefined = undefined;
      if (this.userStore.user.identification?.idBack?.document_id) {
        idBack = await this.documentsApi.getUserDocument(
          this.userStore.user.id,
          this.userStore.user.identification.idBack.document_id
        );
      }
      return { idFront, idBack };
    } catch (error) {
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'There was an issue getting the user identification documents.'
      );
      return { idFront: undefined, idBack: undefined };
    }
  };

  submitOnboardingTemplateResults = async () => {
    this.updateProgrammersModel({ loading: true });
    const updatedTemplate = await this.templateApi.completeCrbOnboardingTemplate(
      this.dispensaryStore.currentDispensary.id,
      this.programmersModel.onboardingTemplate.template_id
    );
    this.crbBankStore.updateOnboardingTemplateInfo(
      this.programmersModel.onboardingTemplate.bank_id,
      updatedTemplate
    );
    this.updateProgrammersModel({ loading: false });
  };

  getCrbDocuments = async () => {
    const documents = await this.documentsApi.getDocuments(this.dispensaryStore.currentDispensary.id);
    const documentsMap = getObjectMap(documents, 'id');
    return documentsMap;
  };

  addNewDocuments = action((docs: DocumentUpload[]) => {
    for (const doc of docs) {
      this.programmersModel.allDocumentsMap[doc.id] = doc;
    }
    this.updateProgrammersModel({ allDocumentsMap: this.programmersModel.allDocumentsMap });
  });

  uploadOnboardingTemplateDocument = async (
    requirementId: string,
    fieldId: string,
    file: File
  ): Promise<DocumentUpload | undefined> => {
    try {
      if (this.programmersModel.onboardingTemplate) {
        const docId = await uploadOrgDocumentToS3(file, this.dispensaryStore.currentDispensary.id);
        const document = await this.documentsApi.putDocument(
          file.name,
          docId,
          this.dispensaryStore.currentDispensary.id
        );

        const updatedDocMap = {
          ...this.programmersModel.allDocumentsMap
        };
        updatedDocMap[document.id] = document;
        this.programmersModel.allDocumentsMap = updatedDocMap;

        return document;
      }
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('Could not upload the file');
    }
  };

  deleteOnboardingTemplateDocument = async (requirementId: string, fieldId: string, file: DocumentUpload) => {
    try {
      if (this.programmersModel.onboardingTemplate) {
        await this.documentsApi.deleteDocument(file.org_id, file.id);

        const updatedDocMap = {
          ...this.programmersModel.allDocumentsMap
        };
        delete updatedDocMap[file.id];
        this.programmersModel.allDocumentsMap = updatedDocMap;
      }
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('Could not delete the file');
    }
  };

  saveAndCompleteOnboardingTemplate = async (customFieldValues: Record<string, any>) => {
    this.updateProgrammersModel({ loading: true });
    const additionalInfoUtil = new AdditionalInfoUtil(
      this.programmersModel.onboardingTemplate,
      this.dispensaryStore.currentDispensary.id,
      false
    );

    const updatedSections = await additionalInfoUtil.saveAndCompleteOnboardingTemplate(customFieldValues);
    this.updateCustomRequirementResults(updatedSections);
    this.updateProgrammersModel({ loading: false });
  };

  isSectionChanged = (customFieldValues: Record<string, any>, oldResponses: CustomRequirementResult) => {
    let changed = false;
    if (Object.entries(oldResponses.custom_fields.responses).length === 0) {
      // if no responses exist for the section already we need to check the values against the template to see if they're
      // in this section
      const templateSection =
        this.programmersModel.onboardingTemplate.custom_requirements[oldResponses.requirement_id];
      templateSection.custom_section.fields.forEach((field) => {
        if (customFieldValues[field.id]) {
          changed = true;
        }
      });
    } else {
      Object.entries(oldResponses.custom_fields.responses).forEach((oldResponse) => {
        if (
          customFieldValues[oldResponse[0]] !== undefined &&
          JSON.stringify(customFieldValues[oldResponse[0]]) !== JSON.stringify(oldResponse[1])
        ) {
          changed = true;
        }
      });
    }
    return changed;
  };

  autoSaveAdditionalInfoSections = async (
    sections: { customFieldValues: Record<string, any>; sectionId: string }[]
  ) => {
    const additionalInfoUtil = new AdditionalInfoUtil(
      this.programmersModel.onboardingTemplate,
      this.dispensaryStore.currentDispensary.id,
      false
    );
    const updateSectionsPromises = sections.map((section) =>
      additionalInfoUtil.saveSection(section.customFieldValues, section.sectionId)
    );
    const updatesSections = (await Promise.all(updateSectionsPromises)).filter(
      Boolean
    ) as CustomRequirementResult[];
    this.updateCustomRequirementResults(updatesSections);
  };

  // Id verification functionality

  downloadRequirementAttachment = async (requirement: OnboardingDocumentRequirement) => {
    try {
      if (requirement.attachments && requirement.attachments.length > 0 && requirement.bank_id) {
        const zip = new JSZip();
        const attachmentsZip = zip.folder('attachments');
        for (let index = 0; index < requirement?.attachments.length; index++) {
          const attachmentDoc = requirement?.attachments[index];
          await this.documentsApi
            .getDocument(requirement.bank_id, attachmentDoc.id)
            .then(async (document) => {
              await this.documentsApi
                .getDocumentLink(environment.storageConfig.sharedDocument, document.s3_key, 'get')
                .then(async (docS3url) => {
                  await fetch(docS3url.s3LinkPath).then(async (docResponse) => {
                    if (requirement.attachments && requirement.attachments.length === 1) {
                      await docResponse.blob().then(async (docBlob) => {
                        openFileInNewWindow(
                          docBlob,
                          getMimeType(attachmentDoc.file_name),
                          attachmentDoc.file_name
                        );
                      });
                    } else {
                      await docResponse.text().then(async (docBase64) => {
                        attachmentsZip?.file(attachmentDoc.file_name, docBase64, { base64: true });
                      });
                    }
                  });
                });
            });
        }
        if (requirement.attachments.length > 1) {
          zip.generateAsync({ type: 'blob' }).then(async (content) => {
            FileSaver.saveAs(content, 'attachments.zip');
          });
        }
      }
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('Could not download documents');
    }
  };

  setOnboardingDocumentRequirementDoNotHave = async (
    dispensaryId: string,
    templateId: string,
    requirementId: string,
    doNotHave: boolean,
    doNotHaveComment: string
  ) => {
    const updatedRequirement = await this.templateApi.setOnboardingDocumentRequirementDoNotHave(
      dispensaryId,
      templateId,
      requirementId,
      doNotHave,
      doNotHaveComment
    );
    this.updateRequirementResult(updatedRequirement);
    return updatedRequirement;
  };

  updateRequirementResult = action((requirementResult: OnboardingDocumentRequirementResult) => {
    const existingRequirement = this.programmersModel.onboardingResult.requirements_results.find(
      (r) => r.requirement_id === requirementResult.requirement_id
    );
    if (existingRequirement) {
      const index = this.programmersModel.onboardingResult.requirements_results.indexOf(existingRequirement);
      this.programmersModel.onboardingResult.requirements_results.splice(index, 1, requirementResult);
    } else {
      this.programmersModel.onboardingResult.requirements_results.push(requirementResult);
    }
    this.updateProgrammersModel({ onboardingResult: this.programmersModel.onboardingResult });
  });

  updateCustomRequirementResults = action((requirementResults: CustomRequirementResult[]) => {
    for (const requirementResult of requirementResults) {
      const existingRequirement = this.programmersModel.onboardingResult.custom_requirement_result.find(
        (r) => r.requirement_id === requirementResult.requirement_id
      );
      if (existingRequirement) {
        const index =
          this.programmersModel.onboardingResult.custom_requirement_result.indexOf(existingRequirement);
        this.programmersModel.onboardingResult.custom_requirement_result.splice(index, 1, requirementResult);
      } else {
        this.programmersModel.onboardingResult.custom_requirement_result.push(requirementResult);
      }
    }
    this.updateProgrammersModel({ onboardingResult: this.programmersModel.onboardingResult });
  });

  getAtttachment = async (bankId: string, documentId: string) => {
    this.documentsApi.getDocument(bankId, documentId).then((document) => {
      this.documentsApi
        .getDocumentLink(environment.storageConfig.sharedDocument, document.s3_key, 'get')
        .then((s3Url) =>
          fetch(s3Url.s3LinkPath).then(async (response) => {
            const blob = await response.blob();
            openFileInNewWindow(blob, getMimeType(document.file_name), document.file_name);
          })
        );
    });
  };

  removeDocumentFromRequirement = action(
    async (
      requirement: OnboardingDocumentRequirement,
      documentId: string,
      dispensaryId: string,
      templateId: string
    ) => {
      const updatedRequirement = await this.templateApi.removeDocumentFromOnboardingDocumentRequirement(
        dispensaryId,
        templateId,
        requirement.requirement_id,
        documentId
      );
      this.updateRequirementResult(updatedRequirement);
    }
  );

  addDocumentToRequirement = action(
    async (
      requirement: OnboardingDocumentRequirement,
      dispensaryId: string,
      templateId: string,
      documentsRequest: CreateDocumentsRequest
    ) => {
      const result = await this.templateApi.addOnboardingDocumentRequirementDocumentsCrbV2(
        dispensaryId,
        templateId,
        requirement.requirement_id,
        documentsRequest
      );
      const updatedDocs = await this.getCrbDocuments();
      this.updateProgrammersModel({ allDocumentsMap: updatedDocs });
      this.updateRequirementResult(result);
    }
  );

  updateLicenseDocument = action(
    async (
      requirement: OnboardingDocumentRequirement,
      documentId: string,
      data: LicenseDocumentDataRequest,
      templateId: string
    ) => {
      const reqResult = await this.templateApi.updateLicenseDocument(
        this.dispensaryStore.currentDispensary.id,
        templateId,
        requirement.requirement_id,
        documentId,
        data
      );
      this.updateRequirementResult(reqResult);
    }
  );

  addOnboardingDocumentRequirementDocuments = action(
    async (
      dispensaryId: string,
      templateId: string,
      requirementId: string,
      newDocuments: CreateDocumentsRequest
    ) => {
      const reqResult = await this.templateApi.addOnboardingDocumentRequirementDocumentsCrbV2(
        dispensaryId,
        templateId,
        requirementId,
        newDocuments
      );
      const updatedDocs = await this.getCrbDocuments();
      this.updateProgrammersModel({ allDocumentsMap: updatedDocs });
      this.updateRequirementResult(reqResult);
    }
  );

  getSharedDcouments = action(
    async (
      crbId: string
    ): Promise<
      {
        requirement: OnboardingDocumentRequirement;
        ddDocuments: DueDiligenceDocument[];
        documents: DocumentUpload[];
      }[]
    > => {
      const sharedRootDocs = await this.dispensariesApi.getSharedRootDocuments(crbId);
      return sharedRootDocs;
    }
  );
}
