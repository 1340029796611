import * as React from 'react';

import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { SnackbarStore } from 'stores/SnackBarStore';
import { Snackbar } from 'ui';
import { MessagesPresenter } from './messages.presenter';

export const Messages = useComponent(() => {
  const presenter = useInjection(MessagesPresenter);

  const snackbarStore = useInjection(SnackbarStore);

  React.useEffect(() => {
    presenter.init();
  }, []);

  React.useEffect(() => {
    if (presenter.messages.length) {
      for (const message of presenter.messages) {
        snackbarStore.showSnackbar(message.type, message.content, message.onClick);
      }
    }
  }, [presenter.messages]);

  const handleCloseNotification = (event: React.SyntheticEvent<any> | Event, reason: string | undefined) => {
    if (event) {
      event.stopPropagation();
    }

    if (reason === 'clickaway') {
      return;
    }

    snackbarStore.hideSnackbar();
  };

  return (
    <>
      <Snackbar
        disableAutoHide={snackbarStore.snackbar.disableAutoHide}
        isOpen={snackbarStore.snackbar.isOpen}
        message={snackbarStore.snackbar.message}
        onClick={snackbarStore.snackbar.onClick}
        onClose={handleCloseNotification}
        type={snackbarStore.snackbar.severity}
        autoHideOverrideInMilliseconds={snackbarStore.snackbar.autoHideOverrideInMilliseconds}
      />
    </>
  );
});
