import type { IANATimezones } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { DateTime } from 'luxon';
import { action, makeAutoObservable, observe } from 'mobx';

import { USStateOptions } from 'domain/consts';
import { getGcvAdminStore } from 'stores/GcvAdminStore';
import type { Row } from 'ui/organisms';
import { DateTimeHelpers } from 'util/dateTime.util';
import type { ComplianceRulesPM } from './cre.repo';
import { ComplianceRulesRepo } from './cre.repo';

export interface ComplianceRulesVM {
  isLoading: boolean;
  rows: Row<StateComplianceData>[];
}

export interface StateComplianceData {
  state: string;
  shared: string;
  medical: string;
  adultUse: string;
}

@injectable()
export class ComplianceRulesPresenter {
  @inject(ComplianceRulesRepo)
  private repo: ComplianceRulesRepo;

  private gcvAdminStore = getGcvAdminStore();

  constructor() {
    makeAutoObservable(this);
  }

  public complianceRulesViewModel: ComplianceRulesVM = {
    isLoading: true,
    rows: []
  };

  public load = action(() => {
    observe(this.repo, 'complianceRulesProgrammersModel', (obj) => {
      const programmersModel = obj.newValue as ComplianceRulesPM;

      this.updateViewModel({
        isLoading: programmersModel.isLoading,
        rows: USStateOptions.map((stateOption) => {
          const data = programmersModel.stateRules[stateOption.value];
          const complianceRow: StateComplianceData = {
            state: stateOption.label,
            shared: this.getUpdatedByText(data?.shared),
            medical: this.getUpdatedByText(data?.medical),
            adultUse: this.getUpdatedByText(data?.adultUse)
          };
          return { id: stateOption.value, data: complianceRow };
        })
      });
    });

    this.repo.load();
  });

  private updateViewModel = action((viewModel: Partial<ComplianceRulesVM>) => {
    this.complianceRulesViewModel = { ...this.complianceRulesViewModel, ...viewModel };
  });

  private getUpdatedByText = (contextData?: { modified: string; modifiedBy: string }) => {
    if (!contextData?.modified) {
      return '--';
    }

    // doing a from ISO with no zone will take user's local data
    const localDate = DateTime.fromISO(contextData.modified);
    const localDateString = DateTimeHelpers.formatISOToDateAtTimeString(
      localDate.toISO(),
      localDate.zoneName as IANATimezones,
      true
    );
    const userName = contextData.modifiedBy
      ? ` by ${this.gcvAdminStore.users[contextData.modifiedBy].firstName} ${
          this.gcvAdminStore.users[contextData.modifiedBy].lastName
        }`
      : '';
    return `${localDateString}${userName}`;
  };
}
