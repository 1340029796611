import * as React from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { EntityTypes } from 'domain/consts/entity-type';
import { useInjection } from 'ioc';
import { Container, ContainerItem, Form, Header, InputDate, InputEin, InputSelect, InputText } from 'ui';
import { BusinessDetailsPresenter } from '../business-details-presenter';

interface Props {
  form: UseFormReturn<FieldValues, any>;
  isMobile: boolean;
  onChange: () => void;
}

export const BusinessDetailsCard: React.FC<Props> = (props) => {
  const presenter = useInjection(BusinessDetailsPresenter);
  return (
    <Container padding="0" dataCy="business-details-card">
      <Container padding="0">
        <Header mobileHeader={props.isMobile} content="Business Details" type="h2" />
      </Container>
      <Form>
        <Container column={props.isMobile} padding={0} width="100%">
          <ContainerItem
            column
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputText
              {...props.form}
              name="name"
              label="Legal Entity Name"
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="legal-entity-name"
              onChange={props.onChange}
              onBlur={props.onChange}
            />
          </ContainerItem>
          <ContainerItem
            column
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputText
              {...props.form}
              name="dba"
              label="DBA"
              dataCy="dba"
              onChange={props.onChange}
              onBlur={props.onChange}
            />
          </ContainerItem>
          <ContainerItem
            column
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputSelect
              {...props.form}
              name="entityType"
              label="Entity Type"
              options={EntityTypes}
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="entity-type"
              additionalOnChange={props.onChange}
              onBlur={props.onChange}
            />
          </ContainerItem>

          <ContainerItem
            column
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputEin
              {...props.form}
              name="ein"
              label="EIN"
              rules={{
                required: { message: 'is required', value: true }
              }}
              dataCy="ein"
              onChange={props.onChange}
              onBlur={props.onChange}
            />
          </ContainerItem>
          <ContainerItem
            column
            padding={props.isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={props.isMobile ? 'none' : '20%'}
            minWidth={props.isMobile ? 'none' : '210px'}
          >
            <InputDate
              {...props.form}
              name="establishedDate"
              label="Date of Establishment"
              viewFormat="MM-dd-yyyy"
              timezone={presenter.crbDispensaryStore.currentDispensary.iana_timezone}
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="established-date"
              onChange={props.onChange}
              onBlur={props.onChange}
            />
          </ContainerItem>
        </Container>
      </Form>
    </Container>
  );
};
