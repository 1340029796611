import type { FieldValues } from 'react-hook-form';
import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

import { DispensariesApi } from 'api';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';
import { OnboardingRepo } from '../../onboarding.repo';

export interface OperationalDetailsProgrammersModel {}

@injectable()
export class OperationalDetailsRepo {
  @inject(OnboardingRepo)
  public repo: OnboardingRepo;

  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(CrbDispensaryStore)
  public dispensaryStore: CrbDispensaryStore;

  @inject(DispensariesApi)
  public dispensariesApi: DispensariesApi;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: OperationalDetailsProgrammersModel = {};

  updateOperationalDetails = async (data: FieldValues, onNext: () => void) => {
    try {
      await this.dispensariesApi
        .updateDispensaryOperationalDetails(this.dispensaryStore.currentDispensary.id, data)
        .then((updatedDispensary) => {
          this.dispensaryStore.updateDispensary(updatedDispensary);
          onNext();
          this.snackbarStore.showSnackbar(
            SnackbarSeverity.Success,
            'Operational details have been updated successfully.'
          );
        });
    } catch (e) {
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'There was an issue updating operational details.'
      );
    }
  };

  autoSaveOperationalDetails = async (data: FieldValues) => {
    this.repo.updateProgrammersModel({ isAutoSaving: true });
    const updatedDispensary = await this.dispensariesApi.updateDispensaryOperationalDetails(
      this.dispensaryStore.currentDispensary.id,
      data
    );
    this.dispensaryStore.updateDispensary(updatedDispensary);
    this.repo.updateProgrammersModel({ isAutoSaving: false });
  };
}
