import * as React from 'react';
import { useState } from 'react';
import { CommentType } from '@gcv/shared';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as NoDocumentsIcon } from 'assets/images/empty-states/ic-no-documents.svg';
import { ReactComponent as FileIcon } from 'assets/images/reports-icons/ic-file.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { getCommentStore } from 'stores/CommentStore';
import { Button, Container, ContainerItem, Dialog, Header, Spinner, Text, ZeroState } from 'ui';
import { OnboardingPresenter } from '../../../onboarding.presenter';
import { RequirementCardV2 } from './requirement-card/requirement-card';

export const RequirementsContainer: React.FC<{ isMobile: boolean }> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasDocumentRequirementModal, setHasDocumentRequirementModal] = useState(false);
  const commentStore = getCommentStore();

  React.useEffect(() => {
    commentStore.setCurrentPost({
      type: CommentType.ACCOUNT,
      title: presenter.crbDispensaryStore.currentDispensary.name,
      idComponents: {
        orgId: presenter.crbDispensaryStore.currentDispensary.id,
        crbId: presenter.crbDispensaryStore.currentDispensary.id,
        fiId: presenter.viewModel.provider.id
      }
    });
  }, []);

  React.useEffect(() => {
    // Modal needs to be shown once per the user when he first visits it
    // When user switches the crb accounts modal needs to be shown if the account has inherited documents form parent onboarding
    if (
      presenter.hasDocumentRequirement() &&
      (window.localStorage.getItem('hasDocumentRequirements') === 'false' ||
        !window.localStorage.getItem('hasDocumentRequirements'))
    ) {
      window.localStorage.setItem('hasDocumentRequirements', 'false');
      setHasDocumentRequirementModal(true);
    }
  }, []);

  if (presenter.viewModel.isLoadingDocumentation) {
    return <Spinner />;
  }

  return (
    <Container padding="0" dataCy="requirements-container" justify="center" width="100%">
      <Dialog
        width="450px"
        height="520px"
        isOpen={false}
        handleClose={() => {
          window.localStorage.setItem('hasDocumentRequirements', 'true');
          setHasDocumentRequirementModal(false);
        }}
        title={''}
        noActions={true}
      >
        <Container justify="center" align="center" padding="0" height="430px">
          <Container padding="8px 0" justify="center" align="center">
            <ContainerItem flex justify="center" align="center">
              <FileIcon />
            </ContainerItem>
            <ContainerItem padding="8px 0" flex justify="center" align="center">
              <Header content="Heads up! Some documents have been uploaded for your org already." type="h2" />
            </ContainerItem>
            <ContainerItem padding="8px 0" flex justify="center" align="center">
              <Text
                type="body1"
                content="Your banking partner copied documents from your parent account in order to fulfill existing requirements. Please look through the remaining requirements and upload additional documents as requested."
              />
            </ContainerItem>
          </Container>

          <Container width="100%" align="center" justify="center" padding="8px 0">
            <ContainerItem width={isMobile ? '100%' : 'fit-content'}>
              <Button
                width={isMobile ? '100%' : '335px'}
                label={'Okay, got it!'}
                color="primary"
                onClick={() => {
                  window.localStorage.setItem('hasDocumentRequirements', ' true');
                  setHasDocumentRequirementModal(false);
                }}
              />
            </ContainerItem>
          </Container>
        </Container>
      </Dialog>
      {presenter.viewModel.filteredRequirements.length > 0 ? (
        presenter.viewModel.filteredRequirements.map((requirement) => {
          return (
            <ContainerItem width="100%">
              <RequirementCardV2 key={requirement} requirementId={requirement} />
            </ContainerItem>
          );
        })
      ) : (
        <Box sx={{ pt: '20px', width: '100%', height: '100%' }}>
          <ZeroState
            title="You’ve fulfilled the required documentation."
            icon={<NoDocumentsIcon />}
            padding="32px 8px 48px 8px"
          />
        </Box>
      )}
    </Container>
  );
});
