import * as React from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import type {
  CustomCheckboxField,
  CustomField,
  CustomRadioField,
  CustomSection,
  CustomSelectField,
  CustomTextSingleLineField,
  DocumentUpload,
  IANATimezones,
  QuestionnaireResponse,
  TemplateResponse
} from '@gcv/shared';
import { CustomFieldDataType, CustomFieldType, SmartRuleAction, SmartRuleOperator } from '@gcv/shared';
import { Box, debounce, useMediaQuery, useTheme } from '@mui/material';

import {
  Container,
  ContainerItem,
  Error,
  Header,
  Input,
  InputCheckBox,
  InputDate,
  InputEmail,
  InputLabel,
  InputPhone,
  InputRadio,
  InputSelect,
  InputText,
  InputUrl,
  Text
} from 'ui';
import { OverlaySpinner } from 'ui/molecules';
import palette from 'ui/theme/palette';
import { errorMessages } from 'util/forms.util';
import { PreviewCustomFormDisplayContentType } from '../CustomForm/preview-custom-form-display-content-type';
import { CustomFileUploader } from './custom-file-uploader';
import { InputCurrencyClone } from './input-currency-clone';
import { InputNumberClone } from './input-number-clone';

interface Props extends Record<string, unknown> {
  form: UseFormReturn<FieldValues>;
  requirement: TemplateResponse | QuestionnaireResponse;
  editMode: boolean;
  onFileUpload: (
    requirementId: string,
    fieldId: string,
    files: File[]
  ) => Promise<DocumentUpload | undefined> | undefined;
  onFileDelete: (requirementId: string, fieldId: string, file: DocumentUpload) => void;
  documentMap: { [id: string]: DocumentUpload };
  isLoading: boolean;
  timezone: IANATimezones;
  onBlur?: (fieldId: string, sectionId?: string) => void;
  fieldWidth?: string | { xs?: string; sm?: string; md?: string; lg?: string };
  addExistingFilesToMap?: (fieldId: string, docIds: string[]) => void;
  refreshFileComponent?: boolean;
  disableFileDelete?: boolean;
}

export const shouldDisplayFieldWithSmartRule = (
  field: CustomField | CustomSection,
  formValues: FieldValues
) => {
  if (field && field.smart_rule) {
    let result = true;

    field.smart_rule.conditions.forEach((c) => {
      switch (c.operator) {
        case SmartRuleOperator.IsEmpty:
          result =
            result &&
            (!formValues[c.targetFieldId] ||
              formValues[c.targetFieldId] === '' ||
              formValues[c.targetFieldId] === '--');
          break;

        case SmartRuleOperator.IsEqualTo:
          result =
            result &&
            (formValues[c.targetFieldId] === c.targetValue?.trim() ||
              (typeof formValues[c.targetFieldId] === 'string' &&
                formValues[c.targetFieldId].includes(';') &&
                formValues[c.targetFieldId].split(';').find((i: string) => i === c.targetValue?.trim())));
          break;

        case SmartRuleOperator.IsFilled:
          result =
            result &&
            formValues[c.targetFieldId] &&
            formValues[c.targetFieldId] !== '' &&
            formValues[c.targetFieldId] !== '--';
          break;

        case SmartRuleOperator.IsNotEqualTo:
          result =
            result &&
            (formValues[c.targetFieldId] !== c.targetValue?.trim() ||
              (typeof formValues[c.targetFieldId] === 'string' &&
                !formValues[c.targetFieldId].includes(c.targetValue?.trim())));
          break;

        default:
          break;
      }
    });

    if (field.smart_rule.action === SmartRuleAction.Show) {
      return result;
    } else if (field.smart_rule.action === SmartRuleAction.Hide) {
      return !result;
    }
  }

  return true;
};

export const CustomFormGenerator: React.FC<Props> = (props) => {
  // Updates on change of radio/select fields as its used only for smart-rule
  const [formValues, setFormValues] = React.useState(props.form.getValues());
  const [animate, setAnimation] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const debouncedSaveFormValues = React.useCallback(
    debounce((data: FieldValues) => {
      setFormValues(data);
    }, 150),
    []
  );

  const resetFieldValue = (fieldId: string) => {
    if (props.form.getValues()[fieldId]) {
      props.form.setValue(fieldId, undefined);
    }
  };

  const onValueChange = (fieldId: string, sectionId?: string) => {
    if (props.onBlur) {
      props.onBlur(fieldId, sectionId);
    }
    debouncedSaveFormValues(props.form.getValues());
    if (!animate) {
      setAnimation(true);
    }
  };

  const onChange = React.useCallback(
    debounce((fieldId: string, sectionId?: string) => {
      if (props.onBlur) {
        props.onBlur(fieldId, sectionId);
      }
    }, 2000),
    []
  );

  const CurrencyInputWrapper: React.FC<any> = (wrapperProps) => {
    return <Input {...wrapperProps} disableAutoWidth fullWidth />;
  };

  const NumberInputWrapper: React.FC<any> = (wrapperProps) => {
    return <Input {...wrapperProps} disableAutoWidth fullWidth />;
  };

  const getField = (field: CustomField, requirementId: string) => {
    const existingValue = props.form.getValues()[field.id];
    switch (field.type) {
      case CustomFieldType.TextSingleLine:
        switch ((field as CustomTextSingleLineField).dataType) {
          case CustomFieldDataType.Currency:
            return (
              <InputCurrencyClone
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{ required: { message: 'is required', value: field.required || false } }}
                dataCy={field.id}
                onChange={() => onChange(field.id, requirementId)}
                customInput={CurrencyInputWrapper}
                onBlur={props.onBlur ? () => props.onBlur!(field.id, requirementId) : undefined}
                {...props.form}
              />
            );

          case CustomFieldDataType.Email:
            if (!existingValue || existingValue === '--') props.form.setValue(field.id, '');
            return (
              <InputEmail
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{ required: { message: 'is required', value: field.required || false } }}
                dataCy={field.id}
                onChange={() => onChange(field.id, requirementId)}
                onBlur={props.onBlur ? () => props.onBlur!(field.id, requirementId) : undefined}
                disableAutoWidth
                darkLabel
                {...props.form}
              />
            );

          case CustomFieldDataType.Number:
            return (
              <InputNumberClone
                unformatted
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{
                  required: { message: 'is required', value: field.required || false }
                }}
                dataCy={field.id}
                onChange={() => onChange(field.id, requirementId)}
                onBlur={props.onBlur ? () => props.onBlur!(field.id, requirementId) : undefined}
                customInput={NumberInputWrapper}
                {...props.form}
              />
            );

          case CustomFieldDataType.Phone:
            return (
              <InputPhone
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{ required: { message: 'is required', value: field.required || false } }}
                dataCy={field.id}
                onBlur={props.onBlur ? () => props.onBlur!(field.id, requirementId) : undefined}
                onChange={() => onChange(field.id, requirementId)}
                darkLabel
                {...props.form}
              />
            );

          case CustomFieldDataType.Text:
            if (!existingValue || existingValue === '--') props.form.setValue(field.id, '');
            return (
              <InputText
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{ required: { message: 'is required', value: field.required || false } }}
                dataCy={field.id}
                onBlur={props.onBlur ? () => props.onBlur!(field.id, requirementId) : undefined}
                onChange={() => onChange(field.id, requirementId)}
                disableAutoWidth
                darkLabel
                {...props.form}
              />
            );

          case CustomFieldDataType.Url:
            if (!existingValue || existingValue === '--') props.form.setValue(field.id, '');
            return (
              <InputUrl
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{ required: { message: 'is required', value: field.required || false } }}
                dataCy={field.id}
                onBlur={props.onBlur ? () => props.onBlur!(field.id, requirementId) : undefined}
                onChange={() => onChange(field.id, requirementId)}
                darkLabel
                {...props.form}
              />
            );

          default:
            break;
        }
        break;

      case CustomFieldType.TextMultiLine:
        if (!existingValue || existingValue === '--') props.form.setValue(field.id, '');
        return (
          <InputText
            label={field.label}
            name={field.id}
            multiline
            minRows={3}
            viewOnly={!props.editMode}
            rules={{ required: { message: 'is required', value: field.required || false } }}
            dataCy={field.id}
            onBlur={props.onBlur ? () => props.onBlur!(field.id, requirementId) : undefined}
            onChange={() => onChange(field.id, requirementId)}
            disableAutoWidth
            darkLabel
            {...props.form}
          />
        );

      case CustomFieldType.Select:
        return (
          <InputSelect
            listItemsWidth="auto"
            label={field.label}
            name={field.id}
            options={(field as CustomSelectField).options.split(';').map((option) => {
              return {
                label: option.trim(),
                value: option.trim()
              };
            })}
            viewOnly={!props.editMode}
            rules={{ required: { message: 'is required', value: field.required || false } }}
            dataCy={field.id}
            onBlur={props.onBlur ? () => props.onBlur!(field.id, requirementId) : undefined}
            additionalOnChange={() => onValueChange(field.id, requirementId)}
            {...props.form}
          />
        );

      case CustomFieldType.Radio:
        return (
          <InputRadio
            label={field.label}
            name={field.id}
            options={(field as CustomRadioField).options.split(';').map((option) => {
              return {
                label: option.trim(),
                value: option.trim()
              };
            })}
            justifyContent="column"
            viewOnly={!props.editMode}
            rules={{ required: { message: 'is required', value: field.required || false } }}
            dataCy={field.id}
            onBlur={props.onBlur ? () => props.onBlur!(field.id, requirementId) : undefined}
            onChange={
              props.onBlur
                ? () => props.onBlur!(field.id, requirementId)
                : () => onChange(field.id, requirementId)
            }
            {...props.form}
          />
        );

      case CustomFieldType.File:
        return (
          <CustomFileUploader
            field={field}
            form={props.form}
            editMode={props.editMode}
            documentMap={props.documentMap}
            onFileUpload={props.onFileUpload}
            onFileDelete={props.onFileDelete}
            requirementId={requirementId}
            addExistingFilesToMap={props.addExistingFilesToMap}
            refreshFileComponent={props.refreshFileComponent}
            disableFileDelete={props.disableFileDelete}
            orgId={props.requirement.bank_id}
          />
        );

      case CustomFieldType.Checkbox:
        return (
          <>
            <InputLabel label={field.label} name={field.id} required={field.required} darkLabel />
            <Box
              sx={{ display: 'inline-flex', flexDirection: 'column', paddingTop: '5px', flexWrap: 'wrap' }}
            >
              {(field as CustomCheckboxField).options.split(';').map((option, index) => {
                const trimmedOption = option.trim();
                const formFieldValue = props.form.watch(field.id);
                const isOptionChecked =
                  formFieldValue &&
                  typeof formFieldValue === 'string' &&
                  formFieldValue.includes(trimmedOption);

                return (
                  <Box key={`${option}_${index}`} sx={{ mb: '0.5rem' }}>
                    <InputCheckBox
                      /**
                       * As we have same `name` property to all the options,
                       * adding key forces the options to re-render whenever
                       * the form value change so they are all seeing the
                       * latest form value.
                       */
                      key={Math.random()}
                      label={trimmedOption}
                      name={field.id}
                      dataCy={field.id}
                      readOnly={!props.editMode}
                      viewOnly={!props.editMode}
                      checked={isOptionChecked}
                      doNotconsiderFormValue
                      doNotDisplayErrors
                      rules={{
                        validate: (val) => {
                          if (field.required) {
                            const formValue = props.form.getValues()[field.id];

                            return formValue && typeof formValue === 'string' && formValue.length > 0;
                          }
                          return true;
                        }
                      }}
                      transformAndSave={(checked: boolean, onChange: (value: any) => void) => {
                        let currentValue = props.form.getValues()[field.id];

                        if (checked) {
                          if (currentValue) {
                            if (typeof currentValue === 'string' && !currentValue.includes(trimmedOption)) {
                              currentValue += ';' + trimmedOption;
                            }
                          } else {
                            currentValue = trimmedOption;
                          }
                        } else {
                          if (
                            currentValue &&
                            typeof currentValue === 'string' &&
                            currentValue.includes(trimmedOption)
                          ) {
                            currentValue = currentValue
                              .split(';')
                              .filter((v) => v !== trimmedOption)
                              .join(';');
                          }
                        }

                        onChange(currentValue);

                        if (props.onBlur) {
                          props.onBlur(field.id, requirementId);
                        }
                      }}
                      {...props.form}
                    />
                  </Box>
                );
              })}
            </Box>

            {Object.keys(props.form.formState.errors).includes(field.id) && (
              <Error
                content={
                  props.form.formState.errors[field.id]
                    ? `* ${field.label} ${
                        props.form.formState.errors[field.id]?.message ||
                        errorMessages()[String(props.form.formState.errors[field.id]?.type)]
                      }`
                    : ''
                }
              />
            )}
          </>
        );

      case CustomFieldType.Date:
        return (
          <InputDate
            label={field.label}
            name={field.id}
            viewFormat="MM-dd-yyyy"
            timezone={props.timezone}
            viewOnly={!props.editMode}
            rules={{ required: { message: 'is required', value: field.required || false } }}
            dataCy={field.id}
            onBlur={props.onBlur ? () => props.onBlur!(field.id, requirementId) : undefined}
            onChange={
              props.onBlur
                ? () => props.onBlur!(field.id, requirementId)
                : () => onChange(field.id, requirementId)
            }
            darkLabel
            {...props.form}
          />
        );

      case CustomFieldType.DisplayContent:
        return (
          <Container padding="0" width={isMobile ? '100%' : '580px'}>
            <PreviewCustomFormDisplayContentType
              field={field}
              attachmentsPadding="10px 0 4px 0"
              orgId={props.requirement.bank_id}
            />
          </Container>
        );
    }
  };

  const fieldAnimation = (field: CustomField | CustomSection) =>
    field.smart_rule && animate ? '1.5s linear SMART_RULE_ANIM' : null;

  return (
    <Container padding="0" column dataCy="info-form-container" width="100%">
      {props.isLoading && <OverlaySpinner dataCy="overlay-spinner" />}
      {Object.keys(props.requirement.custom_requirements)
        .filter((sectionId) => {
          return !props.requirement.custom_requirements[sectionId].archived;
        })
        .sort((a, b) => {
          const aa = props.requirement.custom_requirements[a].order;
          const bb = props.requirement.custom_requirements[b].order;

          return aa > bb ? 1 : aa === bb ? 0 : -1;
        })
        .map((sectionId) => {
          const requirement = props.requirement.custom_requirements[sectionId];

          if (shouldDisplayFieldWithSmartRule(requirement.custom_section, props.form.getValues())) {
            return (
              <Container
                padding="0"
                column
                width="100%"
                dataCy={`form-section-${requirement.custom_section.id}`}
                key={requirement.custom_section.id}
              >
                <Box
                  sx={{
                    width: '100%',
                    WebkitAnimation: fieldAnimation(requirement.custom_section),
                    MozAnimation: fieldAnimation(requirement.custom_section),
                    OAnimation: fieldAnimation(requirement.custom_section),
                    animation: fieldAnimation(requirement.custom_section),
                    animationIterationCount: 1,
                    '@-webkit-keyframes SMART_RULE_ANIM': {
                      from: {
                        backgroundColor: '#FFFCE6'
                      },
                      to: {
                        backgroundColor: 'white'
                      }
                    }
                  }}
                >
                  <ContainerItem dataCy={requirement.id}>
                    <Header mobileHeader={isMobile} content={requirement.custom_section.label} type={'h2'} />
                  </ContainerItem>

                  {requirement.custom_section.description && (
                    <ContainerItem padding={'0 8px 8px'}>
                      <Text
                        sx={{ whiteSpace: 'pre-line' }}
                        content={requirement.custom_section.description}
                        type="body2"
                      />
                    </ContainerItem>
                  )}
                </Box>
                {requirement.custom_section.fields.map((item) => {
                  if (shouldDisplayFieldWithSmartRule(item, props.form.getValues())) {
                    return (
                      <Box
                        sx={{
                          width: '100%',
                          WebkitAnimation: fieldAnimation(item),
                          MozAnimation: fieldAnimation(item),
                          OAnimation: fieldAnimation(item),
                          animation: fieldAnimation(item),
                          animationIterationCount: 1,
                          '@-webkit-keyframes SMART_RULE_ANIM': {
                            from: {
                              backgroundColor: '#FFFCE6'
                            },
                            to: {
                              backgroundColor: 'white'
                            }
                          }
                        }}
                        key={item.id}
                      >
                        <ContainerItem dataCy="custom-section" width={props.fieldWidth}>
                          <Box
                            sx={{
                              mb: '0.5rem'
                            }}
                          >
                            {getField(item, sectionId)}
                          </Box>

                          {item.helpText && (
                            <Box>
                              <Text
                                content={item.helpText}
                                sx={{
                                  color: palette.text.secondary,
                                  fontFamily: 'Lato',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  fontSize: '13px',
                                  lineHeight: '18.2px'
                                }}
                              />
                            </Box>
                          )}
                        </ContainerItem>
                      </Box>
                    );
                  }
                  resetFieldValue(item.id);
                  return null;
                })}
              </Container>
            );
          } else {
            return null;
          }
        })}
    </Container>
  );
};
