import type {
  DueDiligenceRequirement,
  DueDiligenceRequirementTemplate,
  OnboardingDocumentRequirementResult,
  TemplateResponse
} from '@gcv/shared';
import { DueDiligenceRequirementStatus, DueDiligenceStatus, RequirementType } from '@gcv/shared';

import { filterObjectMap } from './objectUtils';

export interface DueDiligenceRequirementTemplateWithType extends DueDiligenceRequirementTemplate {
  type: string;
}

/**
 * Return placeholder for when CRB has not yet processed the new due diligence requirement.
 *
 * @param dueDiligenceTemplate the merged GCV and bank requirements.
 * @returns requirement data placeholder object.
 */
export const createPlaceholderRequirementData = (
  dueDiligenceTemplate: DueDiligenceRequirementTemplateWithType
) => {
  const info = dueDiligenceTemplate;

  return {
    review_date: undefined,
    last_updated: '',
    reviewed_by: undefined,
    documents: {},
    name: info.name,
    id: info.requirement_id,
    requirement_id: info.requirement_id,
    do_not_have: false,
    status: DueDiligenceRequirementStatus.NEEDS_REVIEW
  } as DueDiligenceRequirement;
};

export const isOnboardingDocumentRequirementFulfilled = (
  requirement?: OnboardingDocumentRequirementResult
): boolean => {
  return (
    requirement != undefined && (requirement.do_not_have || Object.keys(requirement.documents).length > 0)
  );
};

export const getOnboardingRequirementsForCrb = (
  template: TemplateResponse,
  isEmployeeLicenseRequired: boolean
) => {
  // filter out archived requirements and employee licenses if not required
  const activeBankRequirements = filterObjectMap(template.requirements, (req) => {
    return !req.archived && (isEmployeeLicenseRequired || req.type !== RequirementType.EmployeeLicense);
  });

  return activeBankRequirements;
};

/**
 * Gets a friendly string for due diligence status, used on the FI side under Due Diligence Details -> Accounts
 *  and Questionnaire Details -> Distribution
 *
 * The logic here mirrors the what we use to sort accounts for the four tabs
 * of our FI Accounts page: src/ui/apps/fi/accounts/actions.tsx
 * @param status DueDiligenceStatus
 * @returns 'Approved' | 'Review In Progress' | 'Awaiting Review' | 'Invited'
 */
export const getFriendlyDueDiligenceStatus = (
  status: DueDiligenceStatus
): 'Approved' | 'Review In Progress' | 'Awaiting Review' | 'Invited' => {
  if (status === DueDiligenceStatus.BANK_APPROVED) {
    return 'Approved';
  } else if (status === DueDiligenceStatus.BANK_REVIEW_IN_PROGRESS) {
    return 'Review In Progress';
  } else if (status === DueDiligenceStatus.BANK_AWAITING_REVIEW) {
    return 'Awaiting Review';
  } else {
    return 'Invited';
  }
};

export const getFriendlyCrbDueDiligenceStatus = (status: DueDiligenceStatus): any => {
  if (status === DueDiligenceStatus.BANK_AWAITING_REVIEW) {
    return 'Submitted';
  } else if (status === DueDiligenceStatus.GCV_IN_PROGRESS) {
    return 'Started';
  } else if (status === DueDiligenceStatus.BANK_REVIEW_IN_PROGRESS) {
    return 'Under Review';
  } else if (status === DueDiligenceStatus.BANK_APPROVED) {
    return 'Active';
  } else if (status === DueDiligenceStatus.BANK_DISCONNECTED) {
    return 'Disconnected';
  } else if (status === DueDiligenceStatus.GCV_PENDING) {
    return 'Not Started';
  } else {
    return 'Unknown';
  }
};
