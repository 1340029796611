import { useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type { Effect, Group } from '@gcv/shared';
import { v4 as uuid } from 'uuid';

import { BanksApi } from 'api';
import { ReactComponent as FolderIcon } from 'assets/images/ic-folder.svg';
import { ReactComponent as GroupIcon } from 'assets/images/ic-group.svg';
import { useComponent } from 'hooks/useComponent';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { getUserStore } from 'stores/UserStore';
import { Button, Container, ContainerItem, Dialog, EditGroupModal, Header, Hyperlink, Text } from 'ui';
import palette from 'ui/theme/palette';
import type { Organization } from 'util/org.util';
import { OrganizationType } from 'util/org.util';

interface Model {
  selectedArchiveUsers: string[];
  selectedUsers: string[];
  createGroupLoading: boolean;
}

interface Actions {
  archiveUsers: () => Promise<void>;
  unarchiveUsers: (userIds: string[], hasError?: boolean) => Promise<void>;
  createGroupFromUsers: (group: Group) => Promise<void>;
}

export interface ActiveUsersActionsProps extends Record<string, unknown> {
  model: Model;
  actions: Actions;
  organization: Organization;
  form: UseFormReturn<FieldValues, any>;
  selectedUserId: string;
}

const ActiveUsersActions = useComponent((props: ActiveUsersActionsProps) => {
  const navigate = useNavigate();
  const userStore = getUserStore();
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [archiveUsersModelOpen, setArchiveUsersModelOpen] = useState(false);
  const [error, setError] = useState('');
  const [appAreaEffects, setAppAreaEffects] = useState<Effect[]>([]);
  const [loadingSideEffects, setLoadingSideEffects] = useState(false);
  const banksApi = new BanksApi();
  const form = props.form;
  const model = props.model;
  const actions = props.actions;
  const organization = props.organization;
  const selectedUserId = props.selectedUserId;

  const selectedUser = organization.getStaff().find((u) => u.id === selectedUserId);

  useEffect(() => {
    if (selectedUser) {
      form.setValue('email', selectedUser?.email);
      form.setValue('firstName', selectedUser?.firstName);
      form.setValue('lastName', selectedUser?.lastName);
    }
  }, [selectedUserId]);

  const createGroup = async (data: FieldValues) => {
    setCreateGroupOpen(false);
    const newGroupId = uuid();
    const newGroup: Group = {
      id: newGroupId,
      name: data.name,
      type: 'user_created',
      roles: data.roles,
      users: data.users,
      description: data.description
    };
    await actions.createGroupFromUsers(newGroup);
    navigate(`/secure/${organization.getAbbreviation()}/users/group-details/${newGroupId}`);
  };

  const closeArchiveUsersModal = () => {
    setError('');
    setArchiveUsersModelOpen(false);
  };

  const archiveUsers = () => {
    if (model.selectedUsers.find((u) => u === userStore.user.id)) {
      setError(`Can't archive current user`);
    } else {
      closeArchiveUsersModal();
      actions.archiveUsers();
    }
  };

  const unarchiveUsers = async (hasError: boolean) => {
    await actions.unarchiveUsers(model.selectedUsers, hasError);
  };

  return (
    <Container padding="0" width="100%" align="center">
      <ContainerItem>
        <Text
          sx={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '16px'
          }}
          content={`${model.selectedUsers.length} Selected`}
        />
      </ContainerItem>
      <Button
        color="text"
        label="Combine into Group"
        onClick={() => {
          setCreateGroupOpen(true);
        }}
        isLoading={model.createGroupLoading}
        startIcon={<GroupIcon />}
        dataCy="combine-into-group-button"
      />
      <Button
        color="text"
        label="Archive"
        isLoading={loadingSideEffects}
        onClick={async () => {
          setLoadingSideEffects(true);

          try {
            if (organization.organizationType === OrganizationType.Bank) {
              const effects = await banksApi.getArchiveUserSideEffects(
                organization.getId(),
                model.selectedUsers
              );

              setAppAreaEffects(effects);
            }
            setArchiveUsersModelOpen(true);
          } catch (e) {
            setArchiveUsersModelOpen(false);
            getSnackbarStore().showErrorSnackbarMessage(
              'There was an issue archiving the selected user(s). Please try again.'
            );
          } finally {
            setLoadingSideEffects(false);
          }
        }}
        startIcon={loadingSideEffects ? null : <FolderIcon />}
        dataCy="archive-users-button"
      />
      <Dialog
        handleClose={closeArchiveUsersModal}
        title={''}
        noActions
        isOpen={archiveUsersModelOpen}
        dataCy="archive-users-modal"
      >
        <Container column align="center" justify="center" padding={0}>
          <ContainerItem>
            <span className="material-icons" style={{ color: palette.primary.main, fontSize: '60px' }}>
              help_outline
            </span>
          </ContainerItem>
          <ContainerItem justify="center">
            <Header
              inline
              content={`Are you sure you want to archive ${
                model.selectedUsers.length > 1 ? 'these users' : 'this user'
              }?`}
              type="h2"
            />
          </ContainerItem>
          <ContainerItem justify="center">
            {model.selectedUsers.map((id) => {
              const user = organization.getStaff().find((u) => u.id === id);
              const name = `${user?.firstName} ${user?.lastName}`;
              return <Text type="body1" content={name} />;
            })}
          </ContainerItem>
          {appAreaEffects.length > 0 && (
            <ContainerItem>
              <span style={{ display: 'inline', fontSize: '14px', lineHeight: '18px' }}>
                If any of these users were app area owners, the toggle will be disabled and tasks will be
                distributed based on permissions. To reassign at the app area level, visit{' '}
              </span>
              <span style={{ display: 'inline', fontSize: '14px', lineHeight: '18px' }}>
                <Hyperlink
                  label="Ownership settings."
                  color="green"
                  onClick={() => {
                    navigate(`/secure/fi/users?tab=ownership`);
                  }}
                />
              </span>
            </ContainerItem>
          )}
          <ContainerItem width="80%">
            <Button
              label={`Yes, archive ${model.selectedUsers.length > 1 ? 'these' : 'this'} user${
                model.selectedUsers.length > 1 ? 's' : ''
              }`}
              onClick={async () => {
                try {
                  archiveUsers();
                  if (organization.organizationType === OrganizationType.Bank) {
                    await banksApi.executeArchiveUserSideEffects(
                      organization.getId(),
                      model.selectedUsers,
                      appAreaEffects
                    );
                  }
                  getSnackbarStore().showSuccessSnackbarMessage('User has been archived successfully.');
                } catch (e) {
                  const hasError = true;
                  getSnackbarStore().clearStore();
                  getSnackbarStore().showErrorSnackbarMessage(
                    'There was an issue archiving the selected user(s). Please try again.'
                  );
                  setTimeout(() => {
                    unarchiveUsers(hasError);
                  }, 0);
                }
              }}
              color="primary"
              fullWidth
              dataCy="archive-button"
            />
          </ContainerItem>
          {error ? (
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
              }}
            >
              <Text content={error} />
            </div>
          ) : null}
          <ContainerItem>
            <span onClick={closeArchiveUsersModal} style={{ cursor: 'pointer' }} data-cy="cancel-button">
              <Text
                content={`I do not want to archive ${
                  model.selectedUsers.length > 1 ? 'these users' : 'this user'
                }`}
                type="body2"
              />
            </span>
          </ContainerItem>
        </Container>
      </Dialog>
      <EditGroupModal
        roleModifier={organization.getRoleModifier()}
        roleOptions={organization.getRoleOptions()}
        selectedUsers={model.selectedUsers}
        userRows={organization.getStaff()}
        isStaffLoaded={organization.isStaffLoaded()}
        isOpen={createGroupOpen}
        title="Create Group"
        handleClose={() => setCreateGroupOpen(false)}
        completeButton={{
          label: 'Create Group',
          onClick: createGroup,
          color: 'primary'
        }}
      />
    </Container>
  );
});

export default ActiveUsersActions;
