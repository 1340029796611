import type {
  BankTemplateType,
  CustomRequirement,
  QuestionnaireResponse,
  QuestionnaireSchedule,
  RequirementReference,
  TemplateId
} from '@gcv/shared';
import { QuestionnaireFrequency } from '@gcv/shared';
import { DateTime } from 'luxon';

import { QuestionnairesApi } from 'api';
import { getFiBankStore } from 'stores/FiBankStore';
import { DateTimeHelpers } from 'util/dateTime.util';

export { QuestionnaireFrequency } from '@gcv/shared';

interface GcvForm<TRequest, TResponse> {
  defaultValues: TRequest;
  onSubmit: (data: TRequest) => Promise<TResponse>;
}

export interface NewQuestionnaire {
  bankId: string;
  name: string;
  description: string;
  frequency: QuestionnaireFrequency;
  timeToRespond: string;
  reoccurring: boolean;
}

export interface UpdatedQuestionnaire {
  name: string;
  description: string;
  frequency: QuestionnaireFrequency;
  timeToRespond: string;
  reocurring: boolean;
}

export interface Questionnaire extends NewQuestionnaire {
  id: string; // uuid for this version of the template
  templateId: TemplateId; // This will be shared between all versions of this template
  dateCreated: string;
  dateUpdated: string;
  updatedBy?: string; // Who updated
  draftExists?: boolean;
  createdBy: string;
  publishedDate?: string; // Date of publish
  publishedBy?: string; // Who published
  customRequirements: { [requirementId: string]: CustomRequirement & RequirementReference };
  templateType: BankTemplateType;
  version: string; // Template version number version number
  distribute: boolean;
  distributedDate?: string; // ie startDate
  schedule: QuestionnaireSchedule; // ie startDate
}

export class QuestionnaireService {
  private _questionnairesApi: QuestionnairesApi;

  constructor() {
    this._questionnairesApi = new QuestionnairesApi();
  }

  public mapQuestionnaire(questionnaire: QuestionnaireResponse): Questionnaire {
    return {
      id: questionnaire.id,
      bankId: questionnaire.bank_id,
      name: questionnaire.name,
      description: questionnaire.description,
      frequency: questionnaire.schedule.frequency,
      timeToRespond: questionnaire.schedule.time_to_respond || '2',
      reoccurring: questionnaire.schedule.reoccurring,
      reminder: questionnaire.schedule.reminder.toString(),
      templateId: questionnaire.template_id,
      dateCreated: questionnaire.date_created,
      createdBy: questionnaire.created_by,
      dateUpdated: questionnaire.date_updated,
      publishedDate: questionnaire.published_date,
      publishedBy: questionnaire.published_by,
      customRequirements: questionnaire.custom_requirements,
      templateType: questionnaire.template_type,
      version: questionnaire.version,
      distribute: questionnaire.distribute,
      distributedDate: questionnaire.distributed_date,
      draftExists: questionnaire.draft_exists,
      schedule: questionnaire.schedule
    } as Questionnaire;
  }

  public newQuestionnaireForm: GcvForm<NewQuestionnaire, Questionnaire> = {
    defaultValues: {
      bankId: '',
      name: '',
      description: '',
      frequency: QuestionnaireFrequency.Weekly,
      timeToRespond: '2',
      reoccurring: true
    },

    onSubmit: async (data) => {
      const payload = {
        name: data.name,
        description: data.description,
        schedule: {
          reoccurring: data.reoccurring,
          frequency: data.frequency,
          time_to_respond: data.timeToRespond,
          reminder: '2'
        }
      };

      const questionnaire = await this._questionnairesApi.addNewQuestionnaire(data.bankId, payload);

      return this.mapQuestionnaire(questionnaire);
    }
  };

  public async updateQuestionnaire(data: UpdatedQuestionnaire, questionnaireId: string, bankId: string) {
    const payload = {
      name: data.name,
      description: data.description,
      schedule: {
        reoccurring: data.reocurring,
        frequency: data.frequency,
        time_to_respond: data.timeToRespond,
        reminder: data.timeToRespond
      }
    };

    return await this._questionnairesApi.updateQuestionnaire(bankId, questionnaireId, payload);
  }

  public findNextStartDate(currentStartDate: string, frequency: QuestionnaireFrequency): string {
    const bankTimezone = getFiBankStore().bank.iana_timezone;

    // if current date is in the future then use it
    if (DateTimeHelpers.parseFromISOString(currentStartDate, bankTimezone) > DateTime.utc()) {
      return currentStartDate;
    }

    // otherwise if date is in the past find new date that is in the future
    let nextStartDate: DateTime = DateTime.utc();

    switch (frequency) {
      case QuestionnaireFrequency.Annually:
        nextStartDate = DateTimeHelpers.parseFromISOString(currentStartDate, bankTimezone).plus({ years: 1 });
        break;
      case QuestionnaireFrequency.Monthly:
        nextStartDate = DateTimeHelpers.parseFromISOString(currentStartDate, bankTimezone).plus({
          months: 1
        });
        break;
      case QuestionnaireFrequency.Quarterly:
        nextStartDate = DateTimeHelpers.parseFromISOString(currentStartDate, bankTimezone).plus({
          months: 3
        });
        break;
      case QuestionnaireFrequency.SemiAnnually:
        nextStartDate = DateTimeHelpers.parseFromISOString(currentStartDate, bankTimezone).plus({
          months: 6
        });
        break;
      case QuestionnaireFrequency.Weekly:
        nextStartDate = DateTimeHelpers.parseFromISOString(currentStartDate, bankTimezone).plus({ weeks: 1 });
        break;
      default:
        break;
    }

    if (nextStartDate < DateTime.now()) {
      return this.findNextStartDate(nextStartDate.toUTC().toISO(), frequency);
    }

    return nextStartDate.toUTC().toISO();
  }
}
