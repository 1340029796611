import React from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { CustomField, DocumentUpload } from '@gcv/shared';
import { Box, FormControl } from '@mui/material';

import { Error, FileContainer, InputLabel, Text } from 'ui';
import { environment } from '../../../environments/environment';
import { errorMessages } from '../../../util/forms.util';
import { PreviewCustomFormAttachments } from '../CustomForm/preview-custom-form-attachments';

export const CustomFileUploader: React.FC<{
  field: CustomField;
  form: UseFormReturn<FieldValues>;
  editMode: boolean;
  documentMap: { [id: string]: DocumentUpload };
  requirementId: string;
  onFileUpload: (
    requirementId: string,
    fieldId: string,
    files: File[]
  ) => Promise<DocumentUpload | undefined> | undefined;
  onFileDelete: (requirementId: string, fieldId: string, file: DocumentUpload) => void;
  addExistingFilesToMap?: (fieldId: string, docIds: string[]) => void;
  refreshFileComponent?: boolean;
  disableFileDelete?: boolean;
  orgId?: string;
}> = (props) => {
  const [existingFiles, setExistingFiles] = React.useState<DocumentUpload[]>([]);

  // In questionnaires, cuz of autosave method, uploaded files also get updated as existingFiles
  // to remove the duplicacy, refreshFileComponent is used. to reset FileContainer
  const [refreshFileComponent, setRefreshFileComponent] = React.useState(false);

  const triggerRefresh = (value: boolean) => {
    setRefreshFileComponent(value);
  };

  React.useEffect(() => {
    if (props.refreshFileComponent) {
      triggerRefresh(props.refreshFileComponent);
    }
  }, [props.refreshFileComponent]);

  React.useEffect(() => {
    const ids = props.form.getValues()[props.field.id];
    const docs: DocumentUpload[] = [];

    // Before Multiple files support, Values for File field are different for questionnaires & Templates
    // Questionnaires takes DocumentUpload format && Templates takes just docIds
    // to support multiple files, these different formats are causing problems
    // so Now FormValue is made generic with docIDs as their values

    // Below chain of if else's are for same purpose,
    // to support single docID, single documentUpload & array of docIds(this has from multiple files support

    // existingFileMap is required while submititng the form,
    // existingFileMap is a map with key as fieldId and value as docIds
    if (Array.isArray(ids)) {
      ids.forEach((id) => {
        if (typeof id === 'string' && id.length > 0) {
          if (props.documentMap[id]) docs.push(props.documentMap[id]);
        }
        if (typeof id === 'object') {
          docs.push(id);
        }
      });
      if (props.addExistingFilesToMap) props.addExistingFilesToMap(props.field.id, ids);
    } else if (typeof ids === 'string') {
      if (props.documentMap[ids]) docs.push(props.documentMap[ids]);
      if (props.addExistingFilesToMap) props.addExistingFilesToMap(props.field.id, [ids]);
    } else if (typeof ids === 'object') {
      docs.push(ids);
      if (props.addExistingFilesToMap) props.addExistingFilesToMap(props.field.id, [ids?.id]);
    }
    setExistingFiles(docs);
  }, [props.form.getValues(props.field.id), refreshFileComponent]);

  return (
    <FormControl fullWidth>
      <Box data-cy={props.field.id}>
        <InputLabel
          dataCy={'input-label'}
          label={props.field.label}
          name={props.field.id}
          required={props.field.required}
          readOnly={!props.editMode}
          darkLabel
          rules={{}}
        />
      </Box>
      <PreviewCustomFormAttachments noLabel attachments={props.field.attachments} orgId={props.orgId} />
      {!props.editMode ? (
        <Text
          sx={{
            fontWeight: 700,
            lineHeight: '15px',
            fontSize: '13px',
            margin: '4px 0 0 5px',
            color: (theme) => theme.palette.text.primary
          }}
          content="Uploaded files"
        />
      ) : null}
      <Controller
        control={props.form.control}
        name={props.field.id}
        rules={{ required: { message: 'is required', value: props.field.required || false } }}
        render={({ fieldState: { error } }) => (
          <>
            {!existingFiles.length && !props.editMode ? (
              <Box sx={{ paddingTop: '8px' }}>
                <Text content="No files uploaded" type="body3" />
              </Box>
            ) : (
              <Box sx={{ paddingTop: '8px' }}>
                <FileContainer
                  fullWidth
                  acceptedTypes="all"
                  multiple
                  disableFileDelete={props.disableFileDelete}
                  viewOnly={!props.editMode}
                  readOnly={!props.editMode}
                  fileBucket={environment.storageConfig.orgDocument}
                  existingS3Files={existingFiles}
                  dataCy="file-container"
                  onNewFilesChange={async (files: File[]) => {
                    props.onFileUpload(props.requirementId, props.field.id, files);
                  }}
                  onRemoveExistingFile={(file: DocumentUpload) => {
                    props.onFileDelete(props.requirementId, props.field.id, file);
                    const updatedFiles = existingFiles.filter((doc) => doc.id !== file.id);
                    const ids = updatedFiles.map((doc) => doc.id);
                    setExistingFiles(updatedFiles);
                    props.form.setValue(props.field.id, ids);
                    props.form.trigger();
                  }}
                  hardResetComponent={refreshFileComponent}
                  triggerRefresh={triggerRefresh}
                  {...props.form}
                />
              </Box>
            )}
            {props.editMode && (
              <Error
                content={
                  error ? `* ${props.field.label} ${error.message || errorMessages()[error.type]}` : ''
                }
                dataCy="validation-message"
              />
            )}
          </>
        )}
      />
    </FormControl>
  );
};
