import type { DataFetcherHistoryStatus, PosType } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { DateTime } from 'luxon';
import { action, makeAutoObservable, observe } from 'mobx';

import type { TimePeriod } from 'stores/AppViewStateStore';
import type { Row } from 'ui/organisms';
import type { GcvAccessCrbPosDataFetcherHistoryPM } from './crb-pos-history.repo';
import { GcvAccessCrbPosDataFetcherHistoryRepo } from './crb-pos-history.repo';

export interface GcvAccessCrbPosDataFetcherHistoryVM {
  isLoading: boolean;

  selectedTimePeriod: string;
  dataFetcherHistoryRows: Row<DataFetcherHistoryRow>[];

  selectedPosType?: PosType;
  selectedCrbId?: string;
  selectedStarDate: string;
  selectedEndDate: string;
}

export interface DataFetcherHistoryRow {
  status: DataFetcherHistoryStatus;
  posType: PosType;
  dispId: string;
  startDate: string;
  workflow: string;
  toDate: string;
  fromDate?: string;
  duration?: string;
}

@injectable()
export class GcvAccessCrbPosDataFetcherHistoryPresenter {
  @inject(GcvAccessCrbPosDataFetcherHistoryRepo)
  private repo: GcvAccessCrbPosDataFetcherHistoryRepo;

  constructor() {
    makeAutoObservable(this);
  }

  public dataFetcherHistoryViewModel: GcvAccessCrbPosDataFetcherHistoryVM = {
    isLoading: false,
    dataFetcherHistoryRows: [],
    selectedTimePeriod: '',
    selectedStarDate: '',
    selectedEndDate: ''
  };

  public load = action(async (posType?: PosType) => {
    observe(this.repo, 'gcvDataFetcherHistoryProgrammersModel', (obj) => {
      const programmersModel = obj.newValue as GcvAccessCrbPosDataFetcherHistoryPM;

      this.updateViewModel({
        isLoading: programmersModel.isLoading,
        dataFetcherHistoryRows: programmersModel.crbPosHistoryRecords.flatMap((record) => {
          const duration = record.endDate
            ? DateTime.fromISO(record.endDate)
                .diff(DateTime.fromISO(record.startDate), 'minutes')
                .minutes.toFixed(1)
            : undefined;

          const formatLocalDate = (date?: string) => {
            return date ? DateTime.fromISO(date).toFormat('MM/dd/yyyy hh:mm a') : '';
          };
          const formatUtcDate = (date?: string) => {
            return date ? DateTime.fromISO(date, { zone: 'utc' }).toISO() : '';
          };
          return record.workflows.flatMap((workflow) => {
            const row: DataFetcherHistoryRow = {
              status: workflow.status,
              posType: record.posType,
              dispId: record.dispId,
              startDate: formatLocalDate(record.startDate),
              workflow: workflow.workflow?.replace('load', ''),
              toDate: formatUtcDate(workflow.toDate),
              fromDate: formatUtcDate(workflow.fromDate),
              duration
            };
            return {
              id: `${workflow.workflow}-${record.startDate}`,
              data: row
            };
          });
        })
      });
    });

    if (posType) {
      this.updateViewModel({ selectedPosType: posType });
      await this.repo.loadDataFetcherHistory(
        this.dataFetcherHistoryViewModel.selectedStarDate,
        this.dataFetcherHistoryViewModel.selectedEndDate,
        { posType }
      );
    }
  });

  public updateTimeRange = action(async (timeRange: TimePeriod) => {
    this.updateViewModel({
      selectedTimePeriod: timeRange.value,
      selectedStarDate: timeRange.dateRange.start,
      selectedEndDate: timeRange.dateRange.end
    });
    if (this.dataFetcherHistoryViewModel.selectedPosType) {
      await this.repo.loadDataFetcherHistory(timeRange.dateRange.start, timeRange.dateRange.end, {
        posType: this.dataFetcherHistoryViewModel.selectedPosType
      });
    }
  });

  public updateCrbOrPos = action(async (filterParams: { crbId?: string; posType?: PosType }) => {
    this.updateViewModel({ selectedPosType: filterParams.posType, selectedCrbId: filterParams.crbId });
    await this.repo.loadDataFetcherHistory(
      this.dataFetcherHistoryViewModel.selectedStarDate,
      this.dataFetcherHistoryViewModel.selectedEndDate,
      filterParams
    );
  });

  private updateViewModel = action((viewModel: Partial<GcvAccessCrbPosDataFetcherHistoryVM>) => {
    this.dataFetcherHistoryViewModel = { ...this.dataFetcherHistoryViewModel, ...viewModel };
  });
}
