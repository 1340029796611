import * as React from 'react';
import type { IANATimezones } from '@gcv/shared';
import type { Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';

import type { FilterListChild, FilterListItem } from 'ui/organisms';
import { FilterListSection } from '..';

interface Props {
  filterList: FilterListItem[];
  onChange: (listItem: FilterListChild) => void;
  selectedList: FilterListChild[];
  selectAllState: number;
  searchTerm: string;
  clearSubItems: (listItems: FilterListChild[]) => void;
  selectSubItems: (listItems: FilterListChild[], parentValue: string) => void;
  sx?: SxProps<Theme>;
  timezone?: IANATimezones;
  includeDate?: boolean;
}

export const FilterList: React.FC<Props> = (props) => {
  const hasParentWithAndWithOutChild = () => {
    let hasFilterItemsWithAndWithOutChild = false;
    props.filterList.map((listItem) => {
      if (listItem.children.length > 0) {
        hasFilterItemsWithAndWithOutChild = true;
      }
    });
    return hasFilterItemsWithAndWithOutChild;
  };
  return (
    <Box
      sx={{
        maxHeight: `calc(100vh - ${props.includeDate ? '471px' : '350px'})`,
        padding: '0',
        '& .MuiList-root.MuiList-padding': { padding: '0 8px' },
        ...props.sx
      }}
    >
      {props.filterList.map((listItem) => (
        <FilterListSection
          listItem={listItem}
          onChange={props.onChange}
          key={listItem.value}
          clearSubItems={props.clearSubItems}
          selectedList={props.selectedList}
          selectAllState={props.selectAllState}
          searchTerm={props.searchTerm}
          selectSubItems={props.selectSubItems}
          timezone={props.timezone}
          hasParentWithAndWithOutChild={hasParentWithAndWithOutChild()}
        ></FilterListSection>
      ))}
    </Box>
  );
};
