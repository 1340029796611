import * as React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { CustomField, CustomSection } from '@gcv/shared';
import { CustomFieldType } from '@gcv/shared';

import {
  Button,
  Container,
  ContainerItem,
  Dialog,
  Form,
  InputCheckBox,
  InputText,
  InputTextArea,
  Text
} from 'ui';
import { theme } from 'ui/theme';
import { setDefaultValues } from 'util/forms.util';

interface Props {
  section?: CustomSection;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (data: FieldValues, isCreatingSmartRule: boolean) => void;
  formFields?: CustomField[];
}

export const SectionModal: React.FC<Props> = (props) => {
  const form = useForm({
    mode: 'onBlur',
    defaultValues: setDefaultValues({
      id: props.section?.id,
      label: props.section?.label,
      description: props.section?.description,
      fields: props.section?.fields
    })
  });

  const checkIfFieldCanBeSmartRule = (field: CustomField) =>
    field.type === CustomFieldType.Radio || field.type === CustomFieldType.Select;
  const availableSmartRuleFields = props.formFields
    ? props.formFields.filter(checkIfFieldCanBeSmartRule)
    : [];

  return (
    <Dialog
      title={props.section ? 'Edit Section' : 'Add New Section'}
      isOpen={props.isOpen}
      handleClose={() => {
        form.reset({});
        props.setIsOpen(false);
      }}
      action={
        <Button
          label={form.watch('addSmartRules') === true ? 'Next' : props.section ? 'Save' : 'Add Section'}
          color="primary"
          onClick={() => {
            form.handleSubmit((data) => {
              const creatingSmartRule = data.addSmartRules;
              delete data.addSmartRules;
              props.onSave(data, creatingSmartRule);
              form.reset({});
              props.setIsOpen(false);
            })();
          }}
          dataCy="save-section-button"
        />
      }
      dataCy="section-modal"
    >
      <Form>
        <Container padding="0" width="100%">
          <ContainerItem padding="0.5rem 0" width="100%">
            <InputText
              {...form}
              name="label"
              label="Title"
              rules={{ required: { value: true, message: 'is required.' } }}
              dataCy="section-title"
              placeholder="Enter section title"
            />
          </ContainerItem>

          <ContainerItem padding="0.5rem 0" width="100%">
            <InputTextArea
              {...form}
              name="description"
              label="Paragraph (optional)"
              dataCy="section-description"
            />
          </ContainerItem>
        </Container>

        {!props.section && (
          <ContainerItem padding="0.5rem 0" width="100%">
            <InputCheckBox
              {...form}
              name="addSmartRules"
              label="Add Smart Rules"
              dataCy="smart-rules-checkbox"
              readOnly={availableSmartRuleFields.length === 0}
            />
          </ContainerItem>
        )}
        {!props.section && availableSmartRuleFields.length === 0 && (
          <ContainerItem padding="0.5rem 0" width="100%">
            <Text
              type="body3"
              sx={{ color: theme.palette.text.secondary }}
              content="To add Smart Rules, first create a field with the Radio Buttons or Select Lists field type to base a rule upon."
            />
          </ContainerItem>
        )}
      </Form>
    </Dialog>
  );
};
