import * as React from 'react';
import type { SnackbarCloseReason } from '@mui/material';
import { Alert, IconButton, Snackbar as SnackbarContainer } from '@mui/material';

export type SnackbarType = 'success' | 'info' | 'warning' | 'error';
interface Props {
  isOpen: boolean;
  message: string | JSX.Element;
  dataCy?: string;
  //onClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => void;
  onClose: (event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason | string) => void;
  type: SnackbarType;
  disableAutoHide?: boolean;
  onClick?: () => void;
  autoHideOverrideInMilliseconds?: number;
}

export const Snackbar: React.FC<Props> = (props) => {
  return (
    <SnackbarContainer
      style={{ top: '64px' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={props.isOpen}
      autoHideDuration={
        props.disableAutoHide
          ? undefined
          : props.autoHideOverrideInMilliseconds
          ? props.autoHideOverrideInMilliseconds
          : 10000
      }
      onClose={props.onClose}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      data-cy={props.dataCy || 'snackbar'}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={(e) => {
              props.onClose(e, '');
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </>
      }
      onClick={props.onClick}
    >
      <Alert
        severity={props.type}
        sx={{
          width: 'auto',
          fontWeight: 'bold',
          padding: '9px 12px',
          alignItems: 'center',
          borderRadius: '6px',
          boxShadow: '0px 2px 10px rgba(5,30,39,.2)',
          '& .MuiAlert-action .MuiButtonBase-root': { color: 'white', marginTop: '-5px' },
          '& .MuiAlert-icon': {
            paddingRight: '6px',
            marginRight: '0px'
          },
          '& .MuiAlert-message': {
            width: 'auto',
            color: 'white',
            marginRight: '46px'
          },
          cursor: props.onClick ? 'pointer' : 'default'
        }}
        onClose={(e) => {
          props.onClose(e, '');
        }}
        variant="filled"
        iconMapping={{
          info: (
            <span className="material-icons" style={{ color: 'white' }}>
              info
            </span>
          ),
          success: (
            <span className="material-icons" style={{ color: 'white' }}>
              check_circle
            </span>
          ),
          warning: (
            <span className="material-icons" style={{ color: 'white' }}>
              info
            </span>
          ),
          error: (
            <span className="material-icons" style={{ color: 'white' }}>
              cancel
            </span>
          )
        }}
      >
        {props.message}
      </Alert>
    </SnackbarContainer>
  );
};
