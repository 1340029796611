import * as React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { OnboardingDocumentRequirement, OnboardingDocumentRequirementResult } from '@gcv/shared';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as ClipIcon } from 'assets/images/ic-clip-gray.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { Button, Container, ContainerItem, Dialog, InputTextArea, Text } from 'ui';
import { OnboardingPresenter } from 'ui/apps/crb/onboarding-two/onboarding.presenter';
import { cropInput } from 'util/format.util';

interface AddCommentModalProps extends Record<string, unknown> {
  requirement: OnboardingDocumentRequirement;
  result?: OnboardingDocumentRequirementResult;
  title: string;
  addCommentModalOpen: boolean;
  onClose: () => void;
}

export const AddCommentModal: React.FC<AddCommentModalProps> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showAllAttachments, setShowAttachments] = React.useState(false);
  const defaultMaxFilesToShow = 5;
  const form = useForm({
    mode: 'onBlur',
    defaultValues: { do_not_have_comments: props.result?.do_not_have_comments } as FieldValues
  });

  const addComment = (data: FieldValues) => {
    presenter.updateDoNotHaveCommentOnRequirement(
      props.requirement.requirement_id,
      true,
      data.do_not_have_comments
    );
  };

  return (
    <Dialog
      action={
        <Button
          style={{ minWidth: isMobile ? '100%' : '100px' }}
          label="Save"
          color="primary"
          onClick={form.handleSubmit(addComment)}
          dataCy="add-button"
          isLoading={
            presenter.viewModel.isLoadingDocumentChange || presenter.viewModel.isLoadingDocumentUpload
          }
        />
      }
      handleClose={props.onClose}
      isOpen={props.addCommentModalOpen}
      title={props.title}
      dataCy="add-comment-modal"
    >
      <Container column align="center" justify="center" padding={0}>
        <Text
          content={`Before you add a reason, please ensure your provider hasn't uploaded any attachments to help you fulfill this requirement.`}
          sx={
            isMobile
              ? { fontSize: '13px', fontWeight: '500', lineHeight: '16px', mb: '1rem' }
              : { mb: '1rem' }
          }
        />
        {props.requirement.attachments?.length ? (
          <ContainerItem padding={0} width="100%" margin={'0 0 1rem 0'}>
            <Text
              content="Attachments"
              sx={{
                fontWeight: 'bold',
                fontSize: '13px',
                lineHeight: '15px',
                marginBottom: '0.5rem'
              }}
            />

            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {props.requirement.attachments
                .slice(0, showAllAttachments ? props.requirement.attachments.length : defaultMaxFilesToShow)
                .map((file, index) => (
                  <Box
                    key={file.id}
                    sx={{
                      width: 'auto',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginRight: '2px',
                      mr: '0.25rem'
                    }}
                    onClick={() => presenter.viewAttachment(file.id)}
                  >
                    <ClipIcon width="18px" height="18px" />
                    <Text
                      sx={{
                        fontWeight: '400',
                        fontSize: '13px',
                        lineHeight: '140%',
                        cursor: 'pointer',
                        color: (theme) => theme.palette.secondary.main
                      }}
                      content={cropInput(file.file_name, 30, 'document')}
                    />
                    {props.requirement.attachments && index !== props.requirement.attachments?.length - 1 && (
                      <Text
                        sx={{
                          fontWeight: '400',
                          fontSize: '13px',
                          lineHeight: '140%',
                          cursor: 'pointer',
                          color: (theme) => theme.palette.text.primary
                        }}
                        content=","
                      />
                    )}
                  </Box>
                ))}

              {props.requirement.attachments &&
                props.requirement.attachments?.length > defaultMaxFilesToShow && (
                  <Button
                    style={{ padding: 0, height: '16px', textDecoration: 'underline' }}
                    color="text"
                    label={showAllAttachments ? 'See less files' : 'See more files'}
                    onClick={() => {
                      setShowAttachments(!showAllAttachments);
                    }}
                  />
                )}
            </Box>
          </ContainerItem>
        ) : null}

        <ContainerItem minWidth="100%" padding={'0px'}>
          <InputTextArea
            {...form}
            name="do_not_have_comments"
            label="Reason"
            placeholder="I don't have this document because..."
            rules={{ required: { message: 'is required', value: true } }}
            focus={true}
            minRows={4}
            dataCy="do-not-have-comments"
          />
        </ContainerItem>
      </Container>
    </Dialog>
  );
});
