import { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { CustomField, CustomRadioField, CustomSelectField, SmartRule } from '@gcv/shared';
import { CustomFieldType, CustomFieldTypeOptions, SmartRuleAction, SmartRuleOperator } from '@gcv/shared';

import {
  Button,
  Card,
  Container,
  ContainerItem,
  Dialog,
  Form,
  Icon,
  InputSelect,
  InputSelectSearch,
  Text,
  Tooltip
} from 'ui';
import { setDefaultValues } from 'util/forms.util';

export enum SmartRuleState {
  New = 'new',
  Existing = 'existing'
}

interface Props {
  isOpen: boolean;
  field: FieldValues;
  formFields: CustomField[];
  onSave: (data: SmartRule, sectionId: string, field: FieldValues) => void;
  onCancel: () => void;
  onRemove: (field: FieldValues) => void;
  ruleState: SmartRuleState;
}

export const SmartRuleModal: React.FC<Props> = (props) => {
  const form = useForm({
    mode: 'onBlur',
    defaultValues: setDefaultValues({
      conditionTargetField:
        props.field && props.field.smart_rule
          ? props.field.smart_rule.conditions[0].targetFieldId
          : undefined,
      action: props.field && props.field.smart_rule ? props.field.smart_rule.action : SmartRuleAction.Show,
      conditionOperator:
        props.field && props.field.smart_rule ? props.field.smart_rule.conditions[0].operator : undefined,
      conditionTargetValue:
        props.field && props.field.smart_rule ? props.field.smart_rule.conditions[0].targetValue : undefined
    })
  });

  const [targetValueOptions, setTargetValueOptions] = useState([] as { label: string; value: string }[]);
  const actionOptions = [
    {
      label: 'Show this field',
      value: SmartRuleAction.Show
    },
    {
      label: 'Hide this field',
      value: SmartRuleAction.Hide
    }
  ];

  const checkIfFieldCanBeSmartRule = (field: CustomField) => {
    return (
      (field.type === CustomFieldType.Radio || field.type === CustomFieldType.Select) &&
      props.field !== undefined &&
      field.id !== props.field.id
    );
  };

  const availableFields = props.formFields.filter(checkIfFieldCanBeSmartRule).map((field) => {
    return {
      label: field.label,
      value: field.id
    };
  });

  const operationOptions = [
    {
      label: 'is equal to',
      value: SmartRuleOperator.IsEqualTo
    },
    {
      label: 'is not equal to',
      value: SmartRuleOperator.IsNotEqualTo
    }
    // future operations
    // {
    //   label: 'is filled',
    //   value: SmartRuleOperator.IsFilled
    // },
    // {
    //   label: 'is empty',
    //   value: SmartRuleOperator.IsEmpty
    // }
  ];

  useEffect(() => {
    if (!form.watch('conditionTargetField')) {
      setTargetValueOptions([]);
    } else {
      const fieldId = form.getValues('conditionTargetField');
      const customField = props.formFields.find((field) => field.id === fieldId);
      const options = (customField as CustomRadioField | CustomSelectField).options
        .split(';')
        .map((optionValue) => {
          return {
            label: optionValue,
            value: optionValue
          };
        });
      setTargetValueOptions(options);
    }
  }, [form.watch('conditionTargetField')]);

  return (
    <Dialog
      title="Smart Rules"
      isOpen={props.isOpen}
      handleClose={() => {
        form.reset({});
        props.onCancel();
      }}
      width="609px"
      action={
        <Button
          label="Save"
          color="primary"
          onClick={() => {
            form.handleSubmit((data) => {
              const smartRule: SmartRule = {
                action: data.action,
                conditions: [
                  {
                    operator: data.conditionOperator,
                    targetFieldId: data.conditionTargetField,
                    targetValue: data.conditionTargetValue
                  }
                ]
              };
              props.onSave(smartRule, props.field?.section as string, props.field);
            })();
          }}
        />
      }
      supportingActions={
        <Button
          label="Remove Rule"
          onClick={() => {
            props.onRemove(props.field);
          }}
          color="secondary-outlined"
          disabled={props.ruleState === 'new'}
        />
      }
    >
      <Card>
        <Form>
          <Container padding={0} width="100%">
            <Text content={CustomFieldTypeOptions.find((type) => type.value === props.field?.type)?.label} />
          </Container>
          <Container padding={0} width="100%">
            <Text content={props.field?.label} sx={{ fontWeight: 'bold' }} />
          </Container>
          <Container padding={'8px 0'} align="center">
            <ContainerItem padding={0} width="247px">
              <InputSelect
                noLabel
                name="action"
                label=""
                options={actionOptions}
                listItemsWidth="247"
                rules={{ required: { value: true, message: 'is required.' } }}
                {...form}
              />
            </ContainerItem>
            <ContainerItem>
              <Text content="when" type="body1" />
            </ContainerItem>
          </Container>
          <Container padding={'8px 0'} width="100%" align="center">
            <ContainerItem padding={0} grow>
              <InputSelectSearch
                noLabel
                name="conditionTargetField"
                label=""
                options={availableFields}
                placeholder="Search"
                rules={{ required: { value: true, message: 'is required.' } }}
                {...form}
              />
            </ContainerItem>
            <ContainerItem>
              <Tooltip
                variant="text"
                title="Rules can be applied based on Radio Buttons or Select Lists field types. Each field can have only one rule applied to it."
                placement="right"
              >
                <Icon type="info" color="primary" size={20} />
              </Tooltip>
            </ContainerItem>
          </Container>
          <Container padding={'8px 0'} width="100%" align="center" justify="space-between">
            <ContainerItem padding={0} width={'calc(50% - 4px)'}>
              <InputSelect
                noLabel
                name="conditionOperator"
                label=""
                options={operationOptions}
                emptyLabel="Choose field state"
                rules={{ required: { value: true, message: 'is required.' } }}
                {...form}
              />
            </ContainerItem>
            <ContainerItem padding={0} width={'calc(50% - 4px)'}>
              <InputSelect
                noLabel
                name="conditionTargetValue"
                label=""
                options={targetValueOptions}
                emptyLabel="Choose field value"
                readOnly={!form.watch('conditionTargetField')}
                rules={{ required: { value: true, message: 'is required.' } }}
                {...form}
              />
            </ContainerItem>
          </Container>
        </Form>
      </Card>
    </Dialog>
  );
};
