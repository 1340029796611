import React from 'react';
import { IANATimezones } from '@gcv/shared';
import { FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import { Box } from '@mui/system';

import type { TimePeriod } from 'stores/AppViewStateStore';
import type { FilterListChild } from 'ui';
import { CheckBox, TimePeriodDropdown } from 'ui';
import { capitalizeVariables } from 'util/strings.util';

interface Props {
  subListItem: FilterListChild;
  onSelectMenuItem: (
    checked: boolean,
    setChecked: React.Dispatch<React.SetStateAction<boolean>>,
    listItem: FilterListChild
  ) => void;
  selectedItems: number;
  key: string;
  selectAllState: number;
  parentTotal?: number;
  searchTerm?: string;
  sx?: React.CSSProperties;
  timezone?: IANATimezones;
}
export const FilterListSectionItem: React.FC<Props> = (props) => {
  const [checked, setChecked] = React.useState(
    props.subListItem.selected ? props.subListItem.selected : false
  );

  React.useEffect(() => {
    !props.selectedItems && setChecked(false);
    if (
      props.selectedItems > 0 &&
      props.subListItem.visible !== false &&
      props.subListItem.selected === true
    ) {
      setChecked(true);
    }
  }, [props.selectedItems]);

  React.useEffect(() => {
    if (props.selectAllState && props.subListItem.visible !== false) setChecked(true);
  }, [props.selectAllState]);

  React.useEffect(() => {
    setChecked(props.subListItem.selected ? props.subListItem.selected : false);
  }, [props.subListItem.selected]);

  const stopCheck = (event: React.SyntheticEvent | React.MouseEvent) => {
    event.stopPropagation();
  };

  const timeSelected = (timeperiod: TimePeriod) => {
    props.subListItem.selectValue = timeperiod;
  };
  return (
    <>
      <FormGroup
        sx={{
          padding: '4px 0 0 54px',
          minHeight: '34px',
          margin: '0',
          display: props.subListItem.visible === false ? 'none' : 'flex',
          backgroundColor: checked ? 'primary.screen' : 'none',
          transition: 'background-color 0.15s ease-in-out',
          '&:hover': {
            backgroundColor: 'primary.screen'
          },
          '& .MuiMenuItem-root.MuiMenuItem-gutters': { padding: '.1rem 0.1rem 0.5rem 0.5rem' },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'transparent'
          },
          '& .MuiCheckbox-root': {
            borderColor: 'transparent',
            marginRight: '-0.05rem'
          },
          '& .MuiFormControlLabel-label': {
            fontSize: '15px',
            fontWeight: '400'
          },
          ...props.sx
        }}
      >
        <FormControlLabel
          style={{ display: 'flex', alignItems: 'flex-start', fontSize: '15px' }}
          disabled={props.subListItem.disabled}
          control={
            <MenuItem key={props.subListItem.value} disableRipple>
              <CheckBox
                value={checked}
                onClick={stopCheck}
                labelPlacement={'end'}
                name={props.subListItem.value}
              />
            </MenuItem>
          }
          label={capitalizeVariables(props.subListItem.label)}
          onChange={() => {
            if (!props.subListItem.disabled) {
              props.onSelectMenuItem(checked, setChecked, props.subListItem);
            }
          }}
        />
      </FormGroup>
      {props.subListItem.selectValue !== undefined ? (
        <Box
          sx={{
            padding: '0 0 10px 88px',
            width: '100%'
          }}
        >
          <TimePeriodDropdown
            timezone={props.timezone || IANATimezones.America_Adak}
            emitData={timeSelected}
            verticalLabel
            fullWidth
            defaultValue={props.subListItem.selectValue.value}
            calendarMargin="0"
            calendarWidth="277px"
          />
        </Box>
      ) : null}
    </>
  );
};
