import type { FieldValues, UseFormReturn } from 'react-hook-form';
import type { IANATimezones, QuestionnaireResponse, QuestionnaireResultResponse } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';

import { FiBankStore } from 'stores/FiBankStore';
import { SnackbarStore } from 'stores/SnackBarStore';
import { DateTimeHelpers } from 'util/dateTime.util';
import type { PM } from './questionnaire.repo';
import { QuestionnaireRepo } from './questionnaire.repo';

export interface VM {
  bankId: () => string;
  commentCount: number;
  isLoading: boolean;
  loadingFormValues: boolean;
  loadingUser: boolean;
  questionnaireResponse: QuestionnaireResponse;
  questionnaireResultResponse: QuestionnaireResultResponse;
  timezone: () => IANATimezones;
  userFullname: string;
}

@injectable()
export class QuestionnairePresenter {
  constructor() {
    makeAutoObservable(this);
  }

  @inject(QuestionnaireRepo)
  private repo: QuestionnaireRepo;

  @inject(FiBankStore)
  private bankStore: FiBankStore;

  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  viewModel: VM = {
    bankId: () => this.bankId,
    commentCount: 0,
    isLoading: true,
    loadingFormValues: true,
    loadingUser: true,
    questionnaireResponse: {} as QuestionnaireResponse,
    questionnaireResultResponse: {} as QuestionnaireResultResponse,
    timezone: () => this.timezone,
    userFullname: '--'
  };

  get bankId() {
    return this.bankStore.bank.id;
  }

  get timezone() {
    return this.bankStore.bank.iana_timezone;
  }

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  reset = action(() => this.repo.reset());

  load = action(async (questionnaireId: string, crbId: string, responseId: string) => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      this.updateViewModel({
        isLoading: programmersModel.isLoading,
        questionnaireResponse: programmersModel.questionnaireResponse,
        questionnaireResultResponse: programmersModel.questionnaireResultResponse
      });
    });

    try {
      await this.repo.load(questionnaireId, crbId, responseId);
      this.updateViewModel({ loadingFormValues: false, loadingUser: false });
    } catch (e) {
      this.snackbarStore.showErrorSnackbarMessage(`There was an issue fetching the questionnaire data.`);
    }
  });

  composeCompletionDate = (questionnaire: QuestionnaireResultResponse) => {
    if (questionnaire.custom_requirement_completed_on) {
      return DateTimeHelpers.formatISOToTableDateString(
        questionnaire.custom_requirement_completed_on,
        this.bankStore.bank.iana_timezone
      );
    } else {
      return '--';
    }
  };

  setCount = action((count: number) => {
    this.updateViewModel({ commentCount: count });
  });

  setUser = action((name: string) => {
    this.updateViewModel({ userFullname: name });
  });

  updateCompletedForm = action((form: UseFormReturn<FieldValues, any>) => {
    this.updateViewModel({ loadingFormValues: true });

    if (
      this.viewModel.questionnaireResultResponse &&
      this.viewModel.questionnaireResultResponse.custom_requirement_result &&
      this.viewModel.questionnaireResultResponse.custom_requirement_result.length > 0
    ) {
      const updatedValues = {} as FieldValues;

      for (
        let index = 0;
        index < this.viewModel.questionnaireResultResponse.custom_requirement_result.length;
        index++
      ) {
        const customResult = this.viewModel.questionnaireResultResponse.custom_requirement_result[index];

        if (
          customResult.custom_fields &&
          customResult.custom_fields.responses &&
          Object.keys(customResult.custom_fields.responses).length > 0
        ) {
          Object.entries(customResult.custom_fields.responses).forEach((entry) => {
            updatedValues[entry[0]] = entry[1];
          });
        }
      }

      form.reset(updatedValues);
    }

    this.updateViewModel({ loadingFormValues: false });
  });
}
