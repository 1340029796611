import { RequirementCategory } from '@gcv/shared';

import { QuestionnaireFrequency } from 'services';
import type { SelectOption } from 'ui';

export const Categories = [
  {
    label: 'Legal',
    value: RequirementCategory.Legal
  },
  {
    label: 'Financial',
    value: RequirementCategory.Financial
  },
  {
    label: 'Operational',
    value: RequirementCategory.Operational
  },
  {
    label: 'Licenses',
    value: RequirementCategory.License
  }
];

/** Choose and order available enum entries. */
const frequencyConfig = [
  QuestionnaireFrequency.Weekly,
  QuestionnaireFrequency.Monthly,
  QuestionnaireFrequency.Quarterly,
  QuestionnaireFrequency.SemiAnnually,
  QuestionnaireFrequency.Annually,
  QuestionnaireFrequency.Once
];

/**
 * Return string representing UI label for QuestionnaireFrequency value.
 *
 * @param value the enum value for questionnaire frequency.
 * @returns label displayed in UI that corresponds to enum value.
 */
export const lookupQuestionnaireFrequencyLabel = (value: QuestionnaireFrequency): string => {
  const dictionary: { [key: string]: string } = {};
  dictionary[QuestionnaireFrequency.Once] = 'One-Time';
  dictionary[QuestionnaireFrequency.Daily] = 'Daily';
  dictionary[QuestionnaireFrequency.Weekly] = 'Weekly';
  dictionary[QuestionnaireFrequency.Monthly] = 'Monthly';
  dictionary[QuestionnaireFrequency.Quarterly] = 'Quarterly';
  dictionary[QuestionnaireFrequency.SemiAnnually] = 'Semi-Annually';
  dictionary[QuestionnaireFrequency.Annually] = 'Annually';

  return dictionary[value];
};

/**
 * Return options array for drop-down widget representing questionnaire frequency.
 *
 * @returns array of SelectOption objects for questionnaire frequency.
 */
export const getQuestionnaireFrequencyOptions = (): SelectOption[] => {
  return frequencyConfig.map((value) => {
    return {
      value: value,
      label: lookupQuestionnaireFrequencyLabel(value)
    } as SelectOption;
  });
};
