import * as React from 'react';
import { Box, Dialog as DialogContainer, useMediaQuery, useTheme } from '@mui/material';

import { Button, Container, ContainerItem, Text } from 'ui/atoms';
import { DialogContent, DialogFooter, DialogHeader } from 'ui/molecules';
import palette from 'ui/theme/palette';

interface Props {
  className?: string;
  action?: JSX.Element;
  secondAction?: JSX.Element;
  alignTitle?: 'left' | 'center' | 'right';
  cancelButtonText?: string;
  dataCy?: string;
  fullScreen?: boolean;
  handleClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => void;
  height?: string;
  width?: string;
  isOpen: boolean;
  noActions?: boolean;
  onCancel?: () => void;
  title: string;
  errorMessage?: string;
  sx?: React.CSSProperties;
  supportingActions?: JSX.Element;
  noCancelAction?: boolean;
  hideHeaderClose?: boolean;
  hideBackdrop?: boolean;
  disableCancelButton?: boolean;
  children?: React.ReactNode;
}

/**
 * Return dialog/modal component.
 *
 * NOTE: When adding Container and ContainerItem components to the body of a Dialog, the developer must
 * account for the default padding value of 1 these components have by overriding them manually (in almost
 * all cases). Otherwise, the Dialog body will not align with the Dialog header and footer components.
 *
 * @returns a Dialog (Modal) component.
 */
export const Dialog: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const alignTitle = props.alignTitle ? props.alignTitle : 'left';
  const width = isMobile ? '360px' : props.width ? props.width : '460px';
  const height = props.height ? props.height : 'auto';
  const errorMessage = props.errorMessage;

  return (
    <DialogContainer
      hideBackdrop={props.hideBackdrop}
      data-cy={props.dataCy}
      fullScreen={props.fullScreen}
      onClose={props.handleClose}
      open={props.isOpen}
      sx={{
        ...props.sx,
        justifyContent: 'center'
      }}
      PaperProps={{
        style: {
          overflow: 'visible',
          borderRadius: '6px',
          boxShadow: props.hideBackdrop
            ? 'none'
            : '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)'
        }
      }}
      className={props.className}
      maxWidth="xl"
    >
      <Box
        sx={{
          width: width,
          height: height,
          overflow: 'auto',
          justifyContent: 'space-between',
          flexDirection: 'column',
          display: 'flex'
        }}
      >
        <Box
          style={{
            overflow: 'visible',
            paddingBottom: '20px',
            marginBottom: props.noActions ? 0 : '74px'
          }}
        >
          <DialogHeader
            isMobile={isMobile}
            width={width}
            title={props.title}
            align={alignTitle}
            handleClose={props.handleClose}
            hideClose={props.hideHeaderClose}
          />

          <DialogContent isMobile={isMobile}>{props.children}</DialogContent>
        </Box>

        {!props.noActions && (
          <DialogFooter>
            <Box
              style={{
                backgroundColor: 'white',
                width: '100%',
                height: '74px',
                zIndex: '10',
                boxShadow: 'rgb(0 0 0 / 10%) 0px -1px 3px',
                borderTop: '1px solid #C6CCDA',
                borderRadius: '0 0 6px 6px',
                position: 'absolute',
                bottom: 0,
                right: 0
              }}
            >
              {props.noCancelAction ? (
                <Box sx={{ padding: '16px', width: '80%', textAlign: 'center', margin: 'auto' }}>
                  {props.action && props.action}
                </Box>
              ) : (
                <Container
                  margin={isMobile ? '24px 10px 17px 10px' : '18px 0px 17px 0px'}
                  padding="0 24px 0 16px"
                  width="100%"
                  align="center"
                  justify={isMobile ? 'space-between' : 'flex-end'}
                >
                  {props.supportingActions && (
                    <ContainerItem grow dataCy="dialog-support-action" padding="0" margin="0">
                      {props.supportingActions}
                    </ContainerItem>
                  )}
                  <ContainerItem
                    padding={isMobile ? '0 4px 0 0' : '0'}
                    margin="0"
                    width={
                      isMobile && !props.secondAction
                        ? '50%'
                        : isMobile && props.secondAction
                        ? '33%'
                        : 'fit-content'
                    }
                  >
                    <Button
                      useMinWidth
                      label={props.cancelButtonText ?? 'Cancel'}
                      color="default-outlined"
                      disabled={props.disableCancelButton}
                      variant="text"
                      onClick={(event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
                        if (props.onCancel) {
                          props.onCancel();
                        }
                        props.handleClose(event, reason);
                      }}
                      style={{ minWidth: isMobile ? '100%' : '100px' }}
                      dataCy="cancel-button"
                    />
                  </ContainerItem>
                  {props.action && (
                    <ContainerItem
                      dataCy="dialog-action"
                      padding={isMobile ? '0 0 0 4px' : '0'}
                      margin={isMobile ? '0' : '0 0 0 8px'}
                      width={
                        isMobile && !props.secondAction
                          ? '50%'
                          : isMobile && props.secondAction
                          ? '33%'
                          : 'fit-content'
                      }
                    >
                      {props.action}
                    </ContainerItem>
                  )}
                  {props.secondAction && (
                    <ContainerItem
                      dataCy="dialog-second-action"
                      padding={isMobile ? '0 0 0 4px' : '0'}
                      margin={isMobile ? '0' : '0 0 0 8px'}
                      width={isMobile ? '33%' : 'fit-content'}
                    >
                      {props.secondAction}
                    </ContainerItem>
                  )}
                </Container>
              )}
            </Box>
          </DialogFooter>
        )}
        {errorMessage && (
          <Container margin="0 24px 1.5rem 24px" padding="0">
            <ContainerItem margin="0" padding="0">
              <Text sx={{ color: palette.error.main }} content={errorMessage} />
            </ContainerItem>
          </Container>
        )}
      </Box>
    </DialogContainer>
  );
};
