import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Card, Container, ContainerItem, DeterminateSpinner, Text } from 'ui';
import palette from 'ui/theme/palette';

interface Props {
  bottomActions?: React.ReactNode;
  alignBottomActions?: 'flex-start' | 'flex-end' | 'center';
  dataCy: string;
  description?: string;
  subtitle?: string;
  title: string;
  stickyHeader?: boolean;
  bgCard?: boolean;
  step?: number;
  totalSteps?: number;
  topActions?: React.ReactNode;
  children?: React.ReactNode;
}

export const OnBoardingPage: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // 74 matches top nav bar height
  const bottomHeight = '74px';
  const mainHeight = props.bottomActions ? `calc(100% - 74px)` : '100%';

  return (
    <>
      <div
        style={{
          height: mainHeight,
          overflow: props.stickyHeader ? 'hidden' : 'auto'
        }}
      >
        <Container padding="0" column dataCy={props.dataCy} width="100%" overflow="visible">
          <Container padding="26px 36px 14px 32px" width="100%">
            {props.totalSteps && props.step != null ? (
              <ContainerItem padding="0" width="min-content">
                <DeterminateSpinner value={(props.step / props.totalSteps) * 100} size="40px" />
              </ContainerItem>
            ) : null}
            <Container
              padding={props.totalSteps ? '0 0 0 53px' : '0'}
              nowrap={!isMobile}
              justify="space-between"
              width="100%"
            >
              <ContainerItem padding="0">
                {props.totalSteps ? (
                  <Text
                    content={`STEP ${props.step} OF ${props.totalSteps}`}
                    sx={{
                      color: '#828599',
                      fontWeight: '700',
                      fontSize: '11px',
                      lineHeight: '16.5px',
                      letterSpacing: '1px'
                    }}
                  />
                ) : null}
                <Text
                  content={props.title}
                  sx={{
                    color: palette.text.primary,
                    fontWeight: '700',
                    fontSize: isMobile ? '19px' : '23px',
                    lineHeight: '24px'
                  }}
                />
                <Text
                  content={props.subtitle}
                  sx={{
                    color: '#828599',
                    fontWeight: '500',
                    fontSize: '13px',
                    lineHeight: '20px'
                  }}
                />
              </ContainerItem>
              {props.topActions ? (
                <ContainerItem padding="8px 0 8px 8px" width="100%" margin={isMobile ? '0 0 0 -53px' : '0'}>
                  {props.topActions}
                </ContainerItem>
              ) : null}
            </Container>
          </Container>

          <ContainerItem
            width="100%"
            margin={isMobile ? '0' : '0 0 60px 0'}
            padding={isMobile ? '0' : '0 26px'}
          >
            {props.bgCard ? (
              <Card
                noBorder={isMobile}
                contentPadding={isMobile ? '23px 25px' : '26px 29px'}
                contentOverflow="hidden"
              >
                {props.children}
              </Card>
            ) : (
              props.children
            )}
          </ContainerItem>
        </Container>
      </div>

      {props.bottomActions && (
        <Box
          sx={{
            alignItems: 'center',
            borderTop: '1px solid #dbe0eb',
            bottom: 0,
            display: 'flex',
            height: bottomHeight,
            position: 'relative',
            width: '100%',
            backgroundColor: 'background.paper',
            boxShadow: '0px -2px 10px rgba(5, 30, 39, 0.2)'
          }}
          data-cy="page-bottom-actions"
        >
          <Container align="center" justify={props.alignBottomActions || 'flex-end'} padding={0} width="100%">
            <ContainerItem>{props.bottomActions}</ContainerItem>
          </Container>
        </Box>
      )}
    </>
  );
};
